import { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import api from '@/lib/axiosInstance';
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription, 
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { 
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { SendIcon, Loader2 } from "lucide-react";
import { languageState } from '@/atoms';
import { useToast } from "@/hooks/use-toast";
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

export function LoginForm() {
  const { t, i18n } = useTranslation();  
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [language, setLanguage] = useAtom(languageState);
  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  const redirectPath = location.search.split("redirect=")[1] || '/';
  const [project_code, owner] = redirectPath.split("/").slice(1);

  const [resetEmail, setResetEmail] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isResetting, setIsResetting] = useState(false);

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await api.post('/login', {
        username,
        password,
        project_code,
        language,
        owner,
      });

      if (response.data.success) {
        toast({
          title: '로그인 성공',
          description: '로그인이 되었습니다.',
        });
        navigate(redirectPath);
      } else {
        toast({
          variant: 'destructive',  
          title: '문제가 있습니다.',
          description:  `${t('login.loginFailed')} ${response.data.message}`,
        });
      }
    } catch (error) {
      setError('로그인 중 오류가 발생했습니다.');
      
      toast({
        variant: 'destructive',
        title: '문제가 있습니다.',  
        description: `${t('login.resetFailed')} ${error}`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async (e:any) => {
    e.preventDefault();
    setIsResetting(true);

    try {
      const response = await api.post('/forgot-password', { email: resetEmail, language: language });
      
      if (response.data.success) {
        toast({
          variant: 'default',
          title: '비밀번호 재설정 요청 성공',
          description: response.data.message || '비밀번호 재설정 이메일을 확인해주세요.',
        });
        setIsDialogOpen(false);
        setResetEmail("");
      } else {
        toast({
          variant: 'destructive',
          title: '비밀번호 재설정 요청 실패',
          description: response.data.message || '비밀번호 재설정 요청에 실패했습니다.',  
        });
      }
    } catch (err:any) {
      console.error(err);
      const errorMessage = err.response?.data?.error || '비밀번호 재설정 요청에 문제가 발생했습니다. 시스템 관리자에게 연락하시기 바랍니다.';
      toast({
        variant: 'destructive',
        title: '비밀번호 재설정 요청 실패',
        description: errorMessage,
      });  
    } finally {
      setIsResetting(false);
    }
  };

  return (
    <Card className="mx-auto max-w-sm">
      <CardHeader>
        <CardTitle className="flex items-center justify-center">
          <img src="/images/sconlogoH.png" alt="Logo" className="w-2/3 pb-2" />
        </CardTitle>
        {/* <CardTitle className="text-2xl">{t('login.title')}</CardTitle> */}
        <CardDescription className="flex items-center justify-center text-xs">
          {t('login.passwordResetDescription')}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="username">{t('login.username')}</Label>
              <Input
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="grid gap-2">
              <div className="flex items-center">
                <Label htmlFor="password">{t('login.password')}</Label>
                <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                  <DialogTrigger asChild>
                    <span className="ml-auto inline-block text-sm underline cursor-pointer">
                    {t('login.forgotPassword')}
                    </span>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-md">
                    <DialogHeader>
                      <DialogTitle>   {t('login.resetPasswordTitle')}</DialogTitle>
                      <DialogDescription>  {t('login.resetPasswordDescription')}</DialogDescription>
                    </DialogHeader>
                    <form onSubmit={handleResetPassword}>
                      <div className="flex flex-col space-y-4">
                        <div className="flex items-center space-x-2">
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            placeholder={t('login.emailPlaceholder')}
                            value={resetEmail}
                            onChange={(e) => setResetEmail(e.target.value)}
                            required
                          />
                          <Button
                            type="submit"
                            size="sm"
                            className="px-3"
                            disabled={resetEmail === "" || isResetting}
                          >
                            <span className="sr-only">send</span>
                            {isResetting ? (
                              <Loader2 className="animate-spin" />
                            ) : (
                              <SendIcon />
                            )}
                          </Button>
                        </div>
                      </div>
                    </form>
                  </DialogContent>
                </Dialog>
              </div>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {error && <p className="text-red-500 text-sm">{error}</p>}
            <Button
              disabled={!username || !password || isLoading}
              type="submit"
              className="w-full flex items-center justify-center"
            >
              {isLoading ? (
                <>
                  <Loader2 className="animate-spin mr-2" />
                  {t('login.loginAttempt')}
                </>
              ) : (
              t('login.loginButton')
              )}
            </Button>
          </div>
        </form>
        <div className="mt-4 text-center text-sm">
          {t('login.signupQuestion')}{" "}
          <Link to="/signup" className="underline">
            {t('login.resetPasswordButton')}
          </Link>
        </div>
      </CardContent>
    </Card>
  );
}