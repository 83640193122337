
import { Skeleton } from "@/components/ui/skeleton";
import { useGetProjectSummaryData } from "@/lib/getData";



export function ProjectSummaryContent() {


  const { projectSummaryData } = useGetProjectSummaryData();

    return (
        <div className="flex-grow items-center justify-center overflow-y-auto">
            {projectSummaryData?.ProjectPhotoRT ? (
                <div
                    className="w-full h-full flex items-center justify-center"
                    dangerouslySetInnerHTML={{ __html: projectSummaryData.ProjectPhotoRT }}
                />
            ) : (
                <Skeleton className="w-full h-full flex items-center justify-center min-h-[200px]" />
            )}


            <div className="mb-4 mt-12 grid grid-cols-[20px_1fr] items-start pb-4 last:mb-0 last:pb-0">
                <span className="flex h-2 w-2 translate-y-1 rounded-full bg-sky-500" />

                <p className="text-sm font-medium leading-none">Project Summary</p>
                <div className="col-span-2 ml-2 space-y-1 mt-2">
                    <div className="p-2 space-y-2">
                        <div className="grid grid-cols-[1fr_auto] text-sm font-medium border-b border-gray-200 pb-1">
                            <span>Project Name</span>
                            <span className="text-gray-500 text-right max-w-[180px] break-words">
                                {projectSummaryData?.ugenProjectName || "N/A"}
                            </span>
                        </div>
                        <div className="grid grid-cols-[1fr_auto] text-sm font-medium border-b border-gray-200 pb-1">
                            <span>Location</span>
                            <span className={`text-gray-500 text-right max-w-[180px] break-words ${window.innerHeight <= 900 ? 'truncate' : ''}`}>
                                {projectSummaryData?.address || "N/A"}
                            </span>
                        </div>
                        <div className="grid grid-cols-[1fr_auto] text-sm font-medium border-b border-gray-200 pb-1">
                            <span>Contract Type</span>
                            <span className="text-gray-500 text-right max-w-[180px] break-words">
                           
                                {projectSummaryData?.genContractType || "N/A"}
                            </span>
                        </div>
                        <div className="grid grid-cols-[1fr_auto] text-sm font-medium border-b border-gray-200 pb-1">
                            <span>Client</span>
                            <span className="text-gray-500 text-right max-w-[180px] break-words">
                                {projectSummaryData?.genClient || "N/A"}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Construction Area 섹션 */}
            <div className="mb-4 mt-12 grid grid-cols-[20px_1fr] items-start pb-4 last:mb-0 last:pb-0">
                <span className="flex h-2 w-2 translate-y-1 rounded-full bg-sky-500" />

                <p className="text-sm font-medium leading-none">Construction Area</p>
                <div className="col-span-2 ml-2 space-y-1 mt-2">
                    <div className="p-2 space-y-2">
                        {projectSummaryData?._bp_lineitems && projectSummaryData._bp_lineitems.length > 0 ? (
                            projectSummaryData._bp_lineitems.map((v: any, index: any) => (
                                <div key={index} className="grid grid-cols-[1fr_auto] gap-4 text-sm font-medium border-b border-gray-200 pb-1">
                                    <span>{v.PJTItemName}</span>
                                    <span className={`text-gray-500 text-right max-w-[180px] break-words ${window.innerHeight <= 900 ? 'truncate' : ''}`}>
                                        
                                        {v.PJTItemDesc}</span>
                                </div>
                            ))
                        ) : (
                            <p className="text-xs text-gray-500">No data available</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
