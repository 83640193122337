
import { useGetPartMilestoneData } from "@/lib/getData";
import Timeline from "@/components/common/Timeline";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle, CardFooter
} from "@/components/ui/card";

export function EPCTimeLine() {

  const { oMilestoneData, cMilestoneData, startDate, endDate } = useGetPartMilestoneData();

  return (
    <>
      <CardHeader className="text-left">
        <CardTitle className='text-base leading-none tracking-tight'>Overall Milestone</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col justify-center items-center gap-2 h-[26vh]">
        <div className="w-full h-1/2 flex justify-center items-center relative" style={{ top: '0%' }}>
          <Timeline
            events={oMilestoneData}
            startDate={startDate}
            endDate={endDate}
            lineColor="border-slate-900"
            flagPosition="alternate"
          />
        </div>

        {/* 두 번째 Timeline */}
      </CardContent>
      {/* 레전드 섹션 */}
      <CardFooter className="flex justify-center items-center gap-4">
        {/* 첫 번째 타임라인 레전드 */}
        <div className="flex items-center gap-2">
          <span className="w-3 h-3 rounded-full bg-slate-900"></span>
          <span className="text-sm text-gray-700">Overall Milestone</span>
        </div>


      </CardFooter>
    </>
  )
}