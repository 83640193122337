

import * as React from "react";
import { useAtomValue } from "jotai";
import api from "@/lib/axiosInstance";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useToast } from "@/hooks/use-toast";
import { Loader2 } from "lucide-react";
import "swiper/css";
import "swiper/css/pagination";
import dayjs from "dayjs";
import './photo.css'
// @ts-ignore
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { sidebarCollapsedState } from "@/atoms";
import { useParams } from "react-router-dom";

export function PhotoHistroy() {
    const [loading, setLoading] = React.useState(true);
     const { projectCode } = useParams()
    const isSidebarCollapsed = useAtomValue(sidebarCollapsedState) ?? false;
    const [imageMetaDataList, setImageMetaDataList] = React.useState<any>([]);
    const [thumBlobList, setThumBlobList] = React.useState<(string | null)[]>([]);
    const [imageBlobList, setImageBlobList] = React.useState<(string | null)[]>([]);
    const [open, setOpen] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const { toast } = useToast();

    const lightboxImages = imageBlobList.map((url, index) => ({
        url,
        title: `${imageMetaDataList[index]?.phototitle || ""} ${dayjs(imageMetaDataList[index]?.photodate).format("YYYY년 MM월 DD일") || ""}`,
    }));



    React.useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                // Fetch image metadata
                const res = await api.get(`/getPhotoHistory?project=${projectCode}`);
                const imageDataList = res.data.Rows;

                const thumbUrls: (string | null)[] = [];
                const imageUrls: (string | null)[] = [];

                for (const image of imageDataList) {
                    // Fetch thumbnail
                    try {
                        const thumbResponse = await api.get(
                            `/gethistorythumnail?fileid=${image.fileid}`,
                            { responseType: "arraybuffer" }
                        );
                        const thumbBlob = window.URL.createObjectURL(new Blob([thumbResponse.data]));
                        thumbUrls.push(thumbBlob);
                    } catch (thumbError) {
                        console.error(`Failed to fetch thumbnail for fileid: ${image.fileid}`, thumbError);
                        thumbUrls.push(null);
                    }

                    // Fetch full image
                    try {
                        const imageResponse = await api.get(
                            `/getPhotohistroyFile?fileid=${image.fileid}`,
                            { responseType: "arraybuffer" }
                        );
                        const imageBlob = window.URL.createObjectURL(new Blob([imageResponse.data]));
                        imageUrls.push(imageBlob);
                    } catch (imageError) {
                        console.error(`Failed to fetch image for fileid: ${image.fileid}`, imageError);
                        imageUrls.push(null);
                    }
                }

                // Filter out entries where thumbnail or image is null
                const validEntries = imageDataList
                    .map((meta: any, index: number) => ({
                        ...meta,
                        thumbnail: thumbUrls[index],
                        image: imageUrls[index],
                    }))
                    .filter((entry: any) => entry.thumbnail !== null && entry.image !== null);

                // Update state
                setImageMetaDataList(validEntries);
                setThumBlobList(validEntries.map((entry: any) => entry.thumbnail));
                setImageBlobList(validEntries.map((entry: any) => entry.image));
            } catch (error) {
                console.error("Failed to fetch data:", error);
            } finally {
                setLoading(false);
            }
        };

        if (projectCode) fetchData();
    }, [projectCode]);

    const launchGallery = (index: number) => {
        if (imageBlobList.length > 0) {
            console.log("라이트 박스 오픈")
            setOpen(true);
            setCurrentIndex(index);

        } else {
            toast({
                variant: "destructive",
                title: "로딩 중...",
                description: "고화질 사진은 아직 로딩 중입니다. 잠시만 기다려 주세요.",
            });
        }
    };

    const [swiperWidth, setSwiperWidth] = React.useState<number>(0);
    const [slidesPerView, setSlidesPerView] = React.useState<number>(6);


    // Calculate Swiper width and slidesPerView
    React.useEffect(() => {
        const calculateSwiperWidth = () => {
            const sidebarWidth = isSidebarCollapsed ? 0: 16 * 16; // 접힌 경우 4rem, 기본 16rem
            const screenWidth = window.innerWidth;
            const availableWidth = screenWidth - sidebarWidth - 90;
            setSwiperWidth(availableWidth);

            // Calculate slidesPerView (min 1 slide, max as fits)
            const slideWidth = 200; // Each slide is 200px wide
            setSlidesPerView(Math.max(1, Math.floor(availableWidth / slideWidth)));
        };

        calculateSwiperWidth(); // Initial calculation
        window.addEventListener("resize", calculateSwiperWidth); // Update on resize
        return () => window.removeEventListener("resize", calculateSwiperWidth);
    }, [isSidebarCollapsed]);

    return (
        <>
            {loading ? (
                <div className="mx-auto my-auto">
                    <Loader2 className="w-5 h-5 animate-spin" />
                </div>

            ) :


                thumBlobList.length > 0 ?
                    <div style={{ width: swiperWidth, height: "21vh" }}>

                        <Swiper
                            slidesPerView={slidesPerView}
                            spaceBetween={10}
                            pagination={{ clickable: true, type: "progressbar" }}
                            modules={[Pagination]}
                            className="w-full"

                        >
                            <div className="swiper-pagination-progressbar absolute bottom-2 left-0 right-0 h-1 bg-gray-200">
                                <div className="swiper-pagination-progressbar-fill bg-black"></div>
                            </div>
                            {thumBlobList.map((thumbnail, idx) => (
                                <SwiperSlide key={idx} className="flex flex-col items-center">
                                    {thumbnail &&
                                        <>
                                            <div className="mt-2 flex w-full justify-between text-xs text-gray-700">
                                                <span>{imageMetaDataList[idx]?.phototitle}</span>
                                                {/* <span>{dayjs(imageMetaDataList[idx]?.photodate).format("YYYY년 MM월 DD일")}</span> */}
                                            </div>
                                            <img
                                                src={thumbnail}
                                                alt={imageMetaDataList[idx]?.title || "Thumbnail"}
                                                className="cursor-pointer rounded-md object-cover h-[200px]"
                                                onClick={() => launchGallery(idx)}
                                            />

                                        </>
                                    }
                                </SwiperSlide>
                            ))}
                        </Swiper>

                    </div>

                    : (
                        <div className="flex justify-center items-center h-full text-gray-500">
                            No data available
                        </div>
                    )}




            <div className={`fixed inset-0 z-50 bg-black bg-opacity-50 ${open ? "block" : "hidden"}`}>
                {open && (
                    <div className="relative w-full h-full flex items-center justify-center">
                        <Lightbox
                            startIndex={currentIndex}
                            images={lightboxImages}
                            onClose={() => setOpen(false)} // Lightbox 닫기
                        />
                    </div>
                )}
            </div>
        </>
    );
}