
import React, { useEffect, useState } from 'react';
import api from '@/lib/axiosInstance';
import { Loader2 } from 'lucide-react';
import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart"
import { Bar, BarChart, XAxis } from "recharts"
import { useParams } from "react-router-dom";


interface EnvActionData {
    cate: string;
    air: number;
    water: number;
    waste: number;
    noise: number;
}

interface EnvActionState {
    chartData: EnvActionData[];
    breach: any;
    complement: any;
}

const returnZeroIfInvalid = (value: any): number =>
    value === Infinity || isNaN(value) || value === false ? 0 : value;

export function EnvAction() {
    const [loading, setLoading] = useState(true);
const { projectCode } = useParams();

    const [envAction, setEnvAction] = useState<EnvActionState | null>(null);

    console.log(envAction)

    useEffect(() => {
        const fetchData = async () => {
            const body = {
                bpname: "Daily Environmental Checkup",
                lineitem: "yes",
                filter_criteria: {
                    join: "AND",
                    filter: [
                        { field: "status", value: "Terminated", condition_type: "neq" },
                        { field: "status", value: "Auto_Created", condition_type: "neq" },
                    ],
                },
            };

            try {
                const res = await api.post(`/getemsbprecord?path=${projectCode}`, body);
                const lineItems = res.data.data.data
                    .filter(
                        (item: any) =>
                            item.CheckupDate && item._bp_lineitems && item._bp_lineitems.length > 0
                    )
                    .flatMap((item: any) =>
                        item._bp_lineitems.filter(
                            (lineItem: any) => lineItem.uuu_tab_id === "일일점검 체크리스트"
                        )
                    );

                const { breachData, complementData } = lineItems.reduce(
                    (acc: any, item: any) => {
                        const key = item.CheckupResult === "위반" ? "breachData" : "complementData";
                        const category = item.CheckupPD;

                        if (!acc[key][category]) acc[key][category] = 0;
                        acc[key][category]++;

                        return acc;
                    },
                    { breachData: {}, complementData: {} }
                );

                const calculateCategory = (data: Record<string, number>) => ({
                    air: returnZeroIfInvalid(data["대기"] || 0),
                    water: returnZeroIfInvalid(data["수질"] || 0),
                    waste: returnZeroIfInvalid(data["폐기물"] || 0),
                    noise: returnZeroIfInvalid(data["소음진동"] || 0),
                });

                setEnvAction({
                    chartData: [
                        { cate: "위반", ...calculateCategory(breachData) },
                        { cate: "보완", ...calculateCategory(complementData) },
                    ],
                    breach: lineItems.filter((item: any) => item.CheckupResult === "위반").length > 0
                        ? returnZeroIfInvalid(
                            (lineItems.filter((item: any) => item.CheckupResult === "위반" && item.Statustxt === "승인").length /
                                lineItems.filter((item: any) => item.CheckupResult === "위반").length) *
                            100
                        ) + "%"
                        : "-",

                    complement: lineItems.filter((item: any) => item.CheckupResult === "보완").length > 0
                        ? returnZeroIfInvalid(
                            (lineItems.filter((item: any) => item.CheckupResult === "보완" && item.Statustxt === "승인").length /
                                lineItems.filter((item: any) => item.CheckupResult === "보완").length) *
                            100
                        ) + "%"
                        : "-",
                });

                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setLoading(false);
            }
        };

        if (projectCode) fetchData();
        else setLoading(false);
    }, [projectCode]);


    const chartConfig = {
        air: {
            label: "대기",
            color: "hsl(var(--chart-1))",
        },
        noise: {
            label: "소음진동",
            color: "hsl(var(--chart-2))",
        },
        waste: {
            label: "폐기물",
            color: "hsl(var(--chart-3))",
        },
        water: {
            label: "수질",
            color: "hsl(var(--chart-4))",
        },
    } satisfies ChartConfig


    if (loading) {
        return (
            <Card className="col-span-12 md:col-span-3 h-[47vh]">
                <CardHeader className="text-left">
                    <CardTitle className="text-base leading-none tracking-tight">개선조치사항(누적)</CardTitle>
                </CardHeader>
                <CardContent className="flex items-center justify-center overflow-hidden">
                    <Loader2 className="w-5 h-5 animate-spin" />
                </CardContent>
            </Card>
        );
    }

    if (!envAction) {
        return (
            <Card className="col-span-12 md:col-span-3 h-[47vh]">
                <CardHeader className="text-left">
                    <CardTitle className="text-base leading-none tracking-tight">개선조치사항(누적)</CardTitle>
                </CardHeader>
                <CardContent className="flex items-center justify-center overflow-hidden">
                    <p className="text-sm text-slate-500">No data available</p>
                </CardContent>
            </Card>
        );
    }

    return (
        <Card className="col-span-12 md:col-span-3 h-[47vh]">
            <CardHeader className="text-left">
                <CardTitle className="text-base leading-none tracking-tight">개선조치사항(누적)</CardTitle>
            </CardHeader>
            <CardContent className="flex-grow flex flex-col h-[32vh]">
                <ChartContainer className="flex-grow h-full" config={chartConfig}>
                    <BarChart
                        data={envAction.chartData}
                        width={undefined} // 부모 크기에 맞추기 위해 명시적 너비 제거
                        height={undefined} // 부모 크기에 맞추기 위해 명시적 높이 제거
                        style={{ flex: 1 }} // SVG가 부모 높이를 채우도록 설정
                        margin={{
                            top: 35,
                            left:20,
                            right:20,
                        }}
                    >
                        <XAxis
                            dataKey="cate"
                            tickLine={false}
                            tickMargin={10}
                            axisLine={false}
                        />
                        <Bar
                            dataKey="air"
                            stackId="a"
                            fill="var(--color-air)"
                            radius={[0, 0, 4, 4]}
                        />
                        <Bar
                            dataKey="noise"
                            stackId="a"
                            fill="var(--color-noise)"
                            radius={[0, 0, 0, 0]}
                        />
                        <Bar
                            dataKey="waste"
                            stackId="a"
                            fill="var(--color-waste)"
                            radius={[0, 0, 0, 0]}
                        />
                        <Bar
                            dataKey="water"
                            stackId="a"
                            fill="var(--color-water)"
                            radius={[4, 4, 0, 0]}
                        />
                        <ChartTooltip
                            content={<ChartTooltipContent />}
                            cursor={false}
                            defaultIndex={1}
                        />
                    </BarChart>
                </ChartContainer>
            </CardContent>
            <CardFooter>
                <Table className='text-xs'>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-1/2  p-1 h-6"></TableHead>
                            <TableHead className=" p-1 h-6">위반</TableHead>
                            <TableHead className=" p-1 h-6">보안</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell className=' p-1 h-6'>조치율</TableCell>
                            <TableCell className=' p-1 h-6'>{envAction.breach}</TableCell>
                            <TableCell className=' p-1 h-6'>{envAction.complement}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardFooter>
        </Card>
    );
}