import * as React from "react";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import { EnvIssue } from "@/components/env/issue";
import { EnvUdpate } from "@/components/env/updateHistory";
import { EnvAudit } from "@/components/env/audit";
import { EnvAction } from "@/components/env/action";
import { EnvWaste } from "@/components/env/waste";
import { EnvGHG } from "@/components/env/ghg";

export default function EnvPages() {
    return (
        <div className="flex flex-1 flex-col gap-4 p-4 h-screen overflow-hidden">
            {/* 화면 높이를 절반씩 사용 */}
            <div className="grid gap-4 md:grid-cols-12 h-1/2">
                {/* 각 카드가 높이를 완전히 차지하도록 설정 */}
                <EnvAudit />
                <EnvAction />
                <EnvGHG />
            </div>

            <div className="grid gap-4 md:grid-cols-12 h-1/2">
                <EnvUdpate />
                <EnvIssue />
                <EnvWaste />
            </div>
        </div>
    );
}