
import React, { useEffect, useState } from 'react';
import api from '@/lib/axiosInstance';
import { FileDown, FileDownIcon, Loader2 } from 'lucide-react';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import dayjs from 'dayjs';
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { useToast } from "@/hooks/use-toast";
import { useParams } from "react-router-dom";

export function Report() {
    const [loading, setLoading] = useState(true);
const { projectCode } = useParams();

    const [issueData, setIssueData] = useState<any>(null);
    const { toast } = useToast();

    useEffect(() => {
        const fetchData = async () => {
            let body = {
                bpname: "Weekly Reports",
                lineitem: "no",
                filter_condition: "status=Active"
            };

            try {
                const res = await api.post(`/getemsbprecord?path=${projectCode}`, body);
                setIssueData(res.data.data.data.sort((a: any, b: any) => b.record_no - a.record_no).reverse());
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setLoading(false);
            }
        };

        if (projectCode) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [projectCode]);


    const handleDownload = async (filename: string) => {

        const transformedFileName = dayjs(filename).format("YY년MM월DD일") + ".zip"

        try {
            const fetchData = await api.get(`/getConcernWeekly/download/${projectCode}/${transformedFileName}`, { responseType: "blob" });

            const name = transformedFileName;
            const downloadurl = window.URL.createObjectURL(
                new Blob([fetchData.data])
            );
            const link = document.createElement("a");

            link.href = downloadurl;
            link.setAttribute("download", name);
            link.style.cssText = "display:none";
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (err) {
            toast({
                title: "파일 다운로드 실패",
                description: "첨부된 파일이 없습니다.",
            });
        }
    };

    if (loading) {
        return (
            <Card className="col-span-12 md:col-span-4 h-full">
                <CardHeader className="text-left">
                    <CardTitle className="text-base leading-none tracking-tight">Construction Weekly Report</CardTitle>
                </CardHeader>
                <CardContent className="flex items-center justify-center overflow-hidden">
                    <Loader2 className="w-5 h-5 animate-spin" />
                </CardContent>
            </Card>
        );
    }

    return (
        <Card className="col-span-12 row-start-1  md:col-span-4 md:row-span-2 h-full">
            <CardHeader className="text-left">
                <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                    <span>Construction Weekly Report</span>
                    {/* <span className="ml-auto text-xs">{dayjs(issueData?.genSubmitDO).format("YYYY년 MM월 DD일")}</span> */}
                </CardTitle>
            </CardHeader>
            <CardContent className="flex items-center justify-center overflow-y-auto">
                {issueData ? (
                    <div className="w-full ">

                        <div className='overflow-hidden'>
                            <Table className='text-sm'>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead className="text-left p-2 h-8">Title</TableHead>
                                        <TableHead className="w-[200px] text-left p-2 h-8">Date</TableHead>
                                        <TableHead className="w-[80px] text-left p-2 h-8">Attachment</TableHead>
                                    </TableRow>
                                </TableHeader>
                            </Table>
                        </div>
                        <div className='overflow-auto h-[80vh]'>
                            <Table className='text-sm'>
                                <TableBody>
                                    {issueData?.map((v: any, index: number) => {

                                        return (
                                            <TableRow key={index}>
                                                <TableCell className="text-left p-2">{v.title}</TableCell>
                                                <TableCell className="w-[200px] text-left p-2">{dayjs(v.genWeeklyReportDO).format("YYYY년 MM월 DD일")}</TableCell>
                                                <TableCell className="w-[80px]  text-left p-2">
                                                    <FileDownIcon
                                                        className="h-5 w-5 cursor-pointer"
                                                        onClick={() => handleDownload(v.genWeeklyReportDO)}
                                                    />                                                
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </div>

                    </div>
                ) : (
                    <div className="flex justify-center items-center h-full text-xs text-slate-700">
                        No data available.
                    </div>
                )}
            </CardContent>
        </Card>
    );
}