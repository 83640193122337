

import * as React from "react"

import {
    Card,
    CardContent,
    CardHeader,
    CardTitle, CardFooter
} from "@/components/ui/card";
import { ComboboxTree } from "@/components/common/comboBoxTree";
import { useGetCLevelData, useGetCProgressData } from "@/lib/getData";
import SCurveChart from "@/components/common/ScurveChart";
import { Combobox } from "@/components/common//comboBox";
import BulletChart from "@/components/common/BulletChart";
import dayjs from "dayjs";
import weekOfYear from 'dayjs/plugin/weekOfYear';
import api from "@/lib/axiosInstance";
import { useAtom, useAtomValue } from 'jotai';
import { useParams } from "react-router-dom";
import { constructionEarnedValueAtom, constructionPlanValueAtom, projectCodeState } from "@/atoms";
dayjs.extend(weekOfYear);

interface ProgressItem {
    date: string;
    PV: number;
    EV: number;
    cumulativePV: number;
    cumulativeEV: number | null;
    // 필요한 다른 속성들을 추가하세요.
}

interface TransformedData {
    weekProgress: ProgressItem[];
    monthProgress: ProgressItem[];
}

interface TreeNode {
    label: string;
    value?: string;
    children?: TreeNode[];
}




export function CPgogressChartWithBullet() {


    const [selectedAreaValue, setSelectedAreaValue] = React.useState<string>("");
    const [selectedDisciplineValue, setSelectedDisciplineValue] = React.useState<string>("");
    const [selectedCutOffValue, setSelectedCutOffValue] = React.useState<string>(dayjs().format("YY년 MM월"));
    const [bulletData1, setBulletData1] = React.useState<any>(null);
    const [bulletData2, setBulletData2] = React.useState<any>(null);

    const itemsPerRow = 4;

    const rows = React.useMemo(() => {
        if (!bulletData1) return [];
        const result = [];
        for (let i = 0; i < bulletData1.length; i += itemsPerRow) {
            result.push(bulletData1.slice(i, i + itemsPerRow));
        }
        return result;
    }, [bulletData1]);

    const rowsVendor = React.useMemo(() => {
        if (!bulletData1) return [];
        const result = [];
        for (let i = 0; i < bulletData2.length; i += itemsPerRow) {
            result.push(bulletData2.slice(i, i + itemsPerRow));
        }
        return result;
    }, [bulletData2]);



    const [checked, setChecked] = React.useState<"월간" | "주간">("월간");
    const legendRef = React.useRef<HTMLDivElement | null>(null);

     const { projectCode } = useParams()

    React.useEffect(() => {
        setSelectedCutOffValue(
            checked === "월간" ? dayjs().format("YY년 MM월") : dayjs().format("YY년 ") + dayjs().week() + "주"
        );
    }, [checked]);




    const selectedAreaLevel1 = selectedAreaValue.split("||")[0] ? selectedAreaValue.split("||")[0] : "전체"
    const selectedAreaLevel2 = selectedAreaValue.split("||")[1] ? selectedAreaValue.split("||")[1] : "전체"
    const selectedAreaLevel3 = selectedAreaValue.split("||")[2] ? selectedAreaValue.split("||")[2] : "전체"

    const selectedDisciplineValueSend = selectedDisciplineValue ? selectedDisciplineValue : "전체"


    const { cLevelData } = useGetCLevelData();
    const { cProgressData } = useGetCProgressData(selectedAreaLevel1, selectedAreaLevel2, selectedAreaLevel3, selectedDisciplineValueSend);
    const [legendContainer, setLegendContainer] = React.useState<HTMLDivElement | null>(null);


    //Area 데이터 트리 구조로 만들기
    const createTree = (cLevelData: any): TreeNode[] => {
        const tree: TreeNode[] = [];

        cLevelData && cLevelData.forEach((item: any) => {
            let currentLevel = tree;
            let currentPath = ""; // To accumulate the value for each level

            [item.level1, item.level2, item.level3].forEach((level, index) => {
                if (!level) return; // Skip if level is undefined or null

                // Build the value path for the current node
                currentPath = currentPath ? `${currentPath}||${level}` : level;

                // Check if the current level node already exists
                let node = currentLevel.find(node => node.label === level);
                if (!node) {
                    // Create the node with the current accumulated path as the value
                    node = { label: level, value: currentPath };
                    currentLevel.push(node);
                }

                // Initialize children if it doesn't exist
                if (!node.children) {
                    node.children = [];
                }

                // Move to the next level's children
                currentLevel = node.children;
            });
        });

        return tree;
    };

    const optionsAreaTree: TreeNode[] = createTree(cLevelData);

    // const legendRef = React.useRef<HTMLElement | null>(null);
    const cardContentRef = React.useRef<HTMLDivElement>(null);
    const [chartHeight, setChartHeight] = React.useState(500); // Default height


    React.useEffect(() => {
        const fetchData = async () => {

            try {
                const res = await api.get(`/getBulletDataDiscipline?project=${projectCode}&level1=${selectedAreaLevel1}&level2=${selectedAreaLevel2}&level3=${selectedAreaLevel3}&level4=${selectedDisciplineValueSend}`);
                const res2 = await api.get(`/getBulletDataVendor?project=${projectCode}&level1=${selectedAreaLevel1}&level2=${selectedAreaLevel2}&level3=${selectedAreaLevel3}&level4=${selectedDisciplineValueSend}`);
                setBulletData1(res.data.data)
                setBulletData2(res2.data.data)

            } catch (error) {
                console.error(`Failed to fetch data for:`, error);
            }
        };

        if (projectCode) {
            fetchData();
        } else {
        }
    }, [projectCode, selectedAreaLevel1, selectedAreaLevel2, selectedAreaLevel3, selectedDisciplineValueSend]);

    React.useLayoutEffect(() => {
        const updateChartHeight = () => {
            if (cardContentRef.current) {
                const cardContentHeight = cardContentRef.current.clientHeight;
                // Adjust the height as needed (e.g., subtracting padding or other elements)
                setChartHeight(cardContentHeight); // Example adjustment
            }
        };
        updateChartHeight();
        window.addEventListener('resize', updateChartHeight);
        return () => window.removeEventListener('resize', updateChartHeight);
    }, [cardContentRef]);



    const optionsDiscipline = cLevelData && Array.from(
        new Set(cLevelData.map(item => item.level4))
    ).map(value => ({ value, label: value }));


    const handleAreaValueChange = (value: string) => {
        setSelectedAreaValue(value);
    };

    const handleCutOffValueChange = (value: string) => {
        setSelectedCutOffValue(value);
    };

    const handleDisciplineValueChange = (value: string) => {
        setSelectedDisciplineValue(value);
    };

    const sumWeightValue = cProgressData ? cProgressData
        .filter((v: any) => v.monthweek === "M")
        .reduce((sum: number, item: any) => sum + item.PV_DR, 0) : 0;


    const groupData = (data: any, monthweek: string) => {
        return data
            .filter((item: any) => item.monthweek === monthweek)
            .reduce((acc: any, item: any) => {
                if (!acc[item.date]) {
                    acc[item.date] = { EV_DR: 0, PV_DR: 0 };
                }
                acc[item.date].EV_DR += item.EV_DR;
                acc[item.date].PV_DR += item.PV_DR;
                return acc;
            }, {});
    };

    const calculatePercentages = (groupedData: any, sumWeight: number) =>
        Object.keys(groupedData).map(date => ({
            date,
            'EV': (groupedData[date].EV_DR / sumWeight) * 100,
            'PV': (groupedData[date].PV_DR / sumWeight) * 100,
        }));

    const groupData_W = cProgressData && cProgressData?.length > 0 && calculatePercentages(groupData(cProgressData, "W"), sumWeightValue);
    const groupData_M = cProgressData && cProgressData?.length > 0 && calculatePercentages(groupData(cProgressData, "M"), sumWeightValue);

    const calculateCumulativeValues = (data: any) => {
        let cumulativePV = 0;
        let cumulativeEV = 0;
        return data.map((item: any) => {
            cumulativePV += item.PV;
            cumulativeEV += item.EV || 0;
            return {
                ...item,
                cumulativePV,
                cumulativeEV,
            };
        });
    };

    const criteriaDate = dayjs().add(-1, 'days'); // 기준 날짜 설정 필요

    const transformedDataWithCumulative: TransformedData = {
        weekProgress: groupData_W && groupData_W.length > 0 && calculateCumulativeValues(groupData_W).map((item: any) => {
            const itemDate = dayjs(item.date, 'YY년 WW주');
            if (itemDate.isAfter(criteriaDate)) {
                return { ...item, cumulativeEV: null };
            }
            return item;
        }),
        monthProgress: groupData_M && groupData_M.length > 0 && calculateCumulativeValues(groupData_M).map((item: any) => {
            const itemDate = dayjs(item.date, 'YY년 MM월');
            if (itemDate.isAfter(criteriaDate)) {
                return { ...item, cumulativeEV: null };
            }
            return item;
        }),
    };

    const chartData = cProgressData && checked === "주간" ? transformedDataWithCumulative.weekProgress : transformedDataWithCumulative.monthProgress




    React.useEffect(() => {
        setLegendContainer(legendRef.current);
    }, [transformedDataWithCumulative]);







    const currentDateString = checked === "주간"
        ? dayjs().format("YY년 ") + dayjs().week() + "주"
        : dayjs().format("YY년 MM월");

    // Attempt to find the data point for the current date
    let currentDataPoint = chartData && chartData.find(v => v.date === currentDateString);

    // If not found or cumulativeEV is undefined, find the data point with the maximum cumulativeEV
    if (!currentDataPoint || currentDataPoint.cumulativeEV == null) {
        // Filter out data points where cumulativeEV is null or undefined
        const validDataPoints = chartData && chartData.filter(v => v.cumulativeEV != null);

        if (validDataPoints && validDataPoints.length > 0) {
            // Find the data point with the maximum cumulativeEV
            currentDataPoint = validDataPoints.reduce((max: any, v: ProgressItem) => {
                return v.cumulativeEV && v.cumulativeEV > max.cumulativeEV ? v : max;
            });
        } else {
            // If no valid data points, set currentDataPoint to undefined
            currentDataPoint = undefined;
        }
    }

    const earnedValue = currentDataPoint ? currentDataPoint.cumulativeEV : 0;
    const planValue = currentDataPoint ? currentDataPoint.cumulativePV : 0;
    const dev = earnedValue && earnedValue - planValue;


    const [, setEarnedValue] = useAtom(constructionEarnedValueAtom);
    const [, setPlanValue] = useAtom(constructionPlanValueAtom);

    React.useEffect(() => {
        if (chartData && chartData.length > 0) {
            // Sort chartData by date
            const sortedChartData = [...chartData].sort((a, b) => {
                return a.date.localeCompare(b.date);
            });

            // Get the most recent entry
            const mostRecentData = sortedChartData[sortedChartData.length - 1];

            // Update atoms with the values from the most recent entry
            setEarnedValue(mostRecentData?.cumulativeEV ?? 0);
            setPlanValue(mostRecentData.cumulativePV);
        }
    }, [chartData, setEarnedValue, setPlanValue]);



    const cutoffdates = checked === "주간" ? cProgressData &&
        Array.from(
            new Set(cProgressData.filter(v => v.monthweek === "W").map(item => item.date))
        ).map(value => ({ value, label: value }))

        : cProgressData && Array.from(
            new Set(cProgressData.filter(v => v.monthweek === "M").map(item => item.date))
        ).map(value => ({ value, label: value }))


    const columnItems =
    {
        week: [{
            name: "주간 계획",
            field_name: "PV",
            color: "#FEAE65",
        },
        {
            name: "주간 실적",
            field_name: "EV",
            color: '#48CAE4'
        },]
        ,
        month: [{
            name: "월간 계획",
            field_name: "PV",
            color: "#FEAE65",
        },
        {
            name: "월간 실적",
            field_name: "EV",
            color: '#48CAE4',
        },]
    }

    const lineItems = [
        {
            name: "누적 계획",
            field_name: "cumulativePV",
            color: "#FEAE65",
        },
        {
            name: "누적 실적",
            field_name: "cumulativeEV",
            color: '#48CAE4',
        },
    ];


    return (
        <>
            <Card className="h-[50vh] w-full rounded-xl border bg-card text-card-foreground shadow overflow-y-auto">

                <CardHeader className="text-left">
                    <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                        <span>Construction Progress</span>
                        <div
                            className="flex-grow text-xs flex justify-end"
                            style={{
                                zIndex: 99999,
                                height: '40px',
                                maxWidth: '550px',
                                whiteSpace: 'nowrap',
                            }}
                            ref={legendRef}
                        ></div>
                    </CardTitle>
                </CardHeader>
                {/* <CardContent className="h-[40vh] flex-grow flex items-center justify-center "> */}
                <CardContent className="grid grid-cols-1 md:grid-cols-3 gap-4 h-[40vh]" ref={cardContentRef}>

                    {/* Left Section - Full width on mobile, 1/3 on larger screens */}
                    <div className="col-span-1 h-full relative flex flex-col">

                        {/* Comboboxes row */}
                        <div className="flex flex-wrap md:space-x-4 md:flex-nowrap mb-4">
                            {/* Combobox 1 */}
                            <div className="flex flex-col flex-1">
                                <label htmlFor="combo1" className="text-xs mb-1">Area</label>
                                <ComboboxTree
                                    options={optionsAreaTree ?? []}
                                    value={selectedAreaValue}
                                    onValueChange={handleAreaValueChange}
                                    placeholder="Select an Area"
                                    className="w-full md:w-40 overflow-hidden whitespace-nowrap text-ellipsis"
                                />
                            </div>

                            {/* Combobox 2 */}
                            <div className="flex flex-col flex-1">
                                <label htmlFor="combo2" className="text-xs mb-1">Discipline</label>
                                <Combobox
                                    options={optionsDiscipline ?? []}
                                    value={selectedDisciplineValue}
                                    onValueChange={handleDisciplineValueChange}
                                    placeholder="Select a Discipline"
                                    className="w-full md:w-40 truncate"
                                />
                            </div>

                            {/* Combobox 3 */}
                            <div className="flex flex-col flex-1">
                                <label htmlFor="combo3" className="text-xs mb-1">Cut off</label>
                                <Combobox
                                    options={cutoffdates ?? []}
                                    value={selectedCutOffValue}
                                    onValueChange={handleCutOffValueChange}
                                    placeholder="Select a Cut off"
                                    className="w-full md:w-40 truncate"
                                />
                            </div>
                        </div>

                        <div className="flex-1 flex items-center justify-center">
                            <div className="flex items-center space-x-8">
                                {/* Plan */}
                                <div className="flex flex-col items-center">
                                    <label className="text-sm mb-1 text-gray-600">Plan</label>
                                    <div className="text-2xl text-center">{planValue && planValue?.toFixed(2)}%</div>
                                </div>
                                {/* Separator */}
                                <div className="h-12 border-l border-gray-300 hidden md:block"></div>
                                {/* Actual */}
                                <div className="flex flex-col items-center">
                                    <label className="text-sm mb-1 text-gray-600">Actual</label>
                                    <div className="text-2xl text-center text-blue-800">{earnedValue && earnedValue?.toFixed(2)}%</div>
                                </div>
                                {/* Separator */}
                                <div className="h-12 border-l border-gray-300 hidden md:block"></div>
                                {/* Dev */}
                                <div className="flex flex-col items-center">
                                    <label className="text-sm mb-1  text-gray-600">Dev.</label>
                                    <div
                                        className={`text-2xl text-center ${dev && dev >= 0 ? 'text-emerald-600' : 'text-red-600'}`}
                                    >{dev && dev?.toFixed(2)}%</div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* Right Section - Full width on mobile, 2/3 on larger screens */}
                    <div className="col-span-1 md:col-span-2 grid gap-0 h-[30vh]">

                        {transformedDataWithCumulative && transformedDataWithCumulative.monthProgress &&
                            <SCurveChart
                                data={chartData}
                                columnItems={columnItems}
                                lineItems={lineItems}
                                chart_id={"C-Scurve"}
                                height={chartHeight}
                                category='date'
                                lineLabel={true}
                                unit={"%"}
                                legendContainer={legendContainer}
                                maxYaxis={110}
                                checked={checked}
                                onCheckedChange={setChecked}
                            />
                        }
                    </div>
                </CardContent>
            </Card>

            <Card className=" w-full  rounded-xl border bg-card text-card-foreground shadow overflow-y-auto">
                <CardHeader className="text-left">
                    <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                        <span>Construction Progress (Discipline)</span>
                        <span className="ml-auto flex items-center text-xs space-x-4 hidden sm:flex">
                            <div className="flex items-center space-x-1">
                                <span className="w-4 h-2 bg-red-500 inline-block"></span>
                                <span>behind (Dev. &lt; -5%)</span>
                            </div>
                            <div className="flex items-center space-x-1">
                                <span className="w-4 h-2 bg-yellow-500 inline-block"></span>
                                <span>on-schedule (-5% ≤ Dev. ≤ +5%)</span>
                            </div>
                            <div className="flex items-center space-x-1">
                                <span className="w-4 h-2 bg-emerald-500 inline-block"></span>
                                <span>ahead (Dev. &gt; +5%)</span>
                            </div>
                        </span>
                    </CardTitle>

                </CardHeader>
                <CardContent className="flex-grow items-center justify-center space-y-4">
                    {rows && rows.map((row, rowIndex) => (
                        <div key={rowIndex} className="flex justify-around w-full space-x-4">
                            {row.map((item: any, index: any) => {
                                return (
                                    <div key={index} className="flex flex-col items-start space-y-1 w-full">
                                        <span className="text-sm font-semibold  text-gray-700">{item.공종}</span>
                                        <div className="flex space-x-2">
                                            <span className="text-xs text-gray-500">계획: {Math.round(item.PV)}%</span>
                                            <span className="text-xs text-gray-500">실적: {Math.round(item.EV)}%</span>
                                            <span className="text-xs text-gray-500">차이: {Math.round(item.Dev)}%</span>
                                        </div>
                                        <BulletChart
                                            spark={false}
                                            maxValue={100}
                                            targetValue={item.PV}
                                            actualValue={item.EV}
                                            height={75}
                                            divId={`${item.공종}`}
                                            unit="%"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </CardContent>
            </Card>
            <Card className=" w-full  rounded-xl border bg-card text-card-foreground shadow overflow-y-auto">
                <CardHeader className="text-left">
                    <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                        <span>Construction Progress (Biz Partner)</span>
                        <span className="ml-auto flex items-center text-xs space-x-4">
                            <div className="flex items-center space-x-1">
                                <span className="w-4 h-2 bg-red-500 inline-block"></span>
                                <span>behind (Dev. &lt; -5%)</span>
                            </div>
                            <div className="flex items-center space-x-1">
                                <span className="w-4 h-2 bg-yellow-500 inline-block"></span>
                                <span>on-schedule (-5% ≤ Dev. ≤ +5%)</span>
                            </div>
                            <div className="flex items-center space-x-1">
                                <span className="w-4 h-2 bg-emerald-500 inline-block"></span>
                                <span>ahead (Dev. &gt; +5%)</span>
                            </div>
                        </span>
                    </CardTitle>
                </CardHeader>
                <CardContent className="flex-grow items-center justify-center space-y-4">
                    {rowsVendor.map((row, rowIndex) => (
                        <div key={rowIndex} className="flex justify-around w-full space-x-4">
                            {row.map((item: any, index: any) => {

                                return (
                                    <div key={index} className="flex flex-col items-start space-y-1 w-full">
                                        <span className="text-sm font-semibold text-gray-700">{item.vendor}</span>
                                        <div className="flex space-x-2">
                                            <span className="text-xs text-gray-500">계획: {Math.round(item.PV)}%</span>
                                            <span className="text-xs text-gray-500">실적: {Math.round(item.EV * 100)}%</span>
                                            <span className="text-xs text-gray-500">차이: {Math.round(item.Dev)}%</span>
                                        </div>
                                        <BulletChart
                                            spark={false}
                                            maxValue={100}
                                            targetValue={item.PV}
                                            actualValue={item.EV}
                                            height={75}
                                            divId={`${item.vendor}`}
                                            unit="%"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </CardContent>
            </Card>
        </>
    )
}