
import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@/components/ui/table";
import { useAtom } from 'jotai';
import { divisionState } from '@/atoms';
import api from '@/lib/axiosInstance';
import dayjs from 'dayjs';
import { Loader2 } from 'lucide-react';

interface PTWProps {
}


const PTWTotal: React.FC<PTWProps> = () => {
    const [loading, setLoading] = useState<boolean>(true); // Loading state
    const [division] = useAtom(divisionState);
    const [PTWData, setPWTData] = useState([]);

    const tableData = division === null ? Object.values(PTWData.reduce((acc:any, curr) => {
        const { division, normalWork, criticalWork } = curr;
        
        if (!acc[division]) {
          acc[division] = {
            division,
            normalWork: 0,
            criticalWork: 0
          };
        }
        
        acc[division].normalWork += normalWork;
        acc[division].criticalWork += criticalWork;
        
        return acc;
      }, {})
    ).map((v:any)=>({...v, category:v.division}))
      
      : PTWData.filter((v:any)=>v.division === division).map((v:any)=>({...v, category:v.projectName}));

    
    const sumNormalWork =   tableData.reduce((sum: number, item: any) => sum + item.normalWork, 0);
    const sumCriticalWork =   tableData.reduce((sum: number, item: any) => sum + item.criticalWork, 0);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading
            try {
                const res = await api.get(`/getreportrows?reportname=PTW Dash`);

                setPWTData(
                    res.data.data.filter((v:any)=>v.c2 !== "반도체")
                    .map((v:any)=>({
                        projectName:v.c3,
                        division:v.c2,
                        normalWork:parseFloat(v.c6) + parseFloat(v.c7),
                        criticalWork:parseFloat(v.c8)
                    }))
                )

            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false); // End loading
            }
        }

        fetchData();
    }, []);

    return(
        <>
            {loading ?

                (<div className="flex justify-center items-center h-full">
                    <Loader2 className="w-5 h-5 animate-spin" />
                </div>) :

                <div className="flex flex-col w-full h-full">
                    <div className="grid grid-cols-2 gap-4 flex-1">

                    <div className="flex items-center justify-center space-x-4 ">
                                {/* 특별 */}
                                <div className="flex flex-col items-center">
                                    <label className="text-xs mb-1 text-gray-600">특별위험작업</label>
                                    <div className="text-sm text-center text-red-500"><span>{sumCriticalWork}</span ><span className='text-xs text-gray-600'>개</span></div>
                                </div>
                                {/* Separator */}
                                <div className="h-12 border-l border-gray-300 hidden md:block"></div>
                                {/* Actual */}
                                <div className="flex flex-col items-center">
                                <label className="text-xs mb-1 text-gray-600">중점위험작업</label>
                                    <div className="text-sm text-center"><span>{sumNormalWork}</span ><span className='text-xs text-gray-600'>개</span></div>
                                </div>
                                
                            </div>

                        <div className="flex flex-col justify-center item-center text-center">
                            <div className="overflow-auto">
                                <Table className="text-xs text-center">
                                    <TableBody>
                                        {tableData.map((v, index) => (
                                            <TableRow key={index}>
                                                <TableCell className="p-2 pl-0 pr-0 w-[60px]  text-red-500">{v.criticalWork}<span className='text-gray-600'>개</span></TableCell>
                                                <TableCell className="p-2 pl-0 pr-0 w-[5px]">/</TableCell>
                                                <TableCell className="p-2 pl-0 pr-0 w-[60px]">{v.normalWork}<span className='text-gray-600'>개</span></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>

                    </div>
                </div>
            }
        </>
    )

};
export default PTWTotal;