"use client"

import { Table } from "@tanstack/react-table"
import { CheckCircle, ChevronDown, ChevronDownCircle, ChevronsDown, ChevronsUp, Circle, Timer, X } from "lucide-react"

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"

// import { statuses } from "../data/data"
import { DataTableFacetedFilter } from "./data-table-faceted-filter"
import { DataTableViewOptions } from "./data-table-view-options"

interface DataTableToolbarProps<TData> {
  table: Table<TData>
}

export function DataTableToolbar<TData>({
  table,
}: DataTableToolbarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0


  const statuses =[
    {
        value: "Not Started",
        label: "Not Started",
        icon: Circle,
      },
      {
        value: "In Progress",
        label: "In Progress",
        icon: Timer,
      },
      {
        value: "Completed",
        label: "Completed",
        icon: CheckCircle,
      }

  ]
  
  const statuses2 =[
    {
        value: "Behind",
        label: "Behind",
        icon: ChevronsDown,
      },
      {
        value: "On Schedule",
        label: "On Schedule",
        icon: Timer,
      },
      {
        value: "Ahead",
        label: "Ahead",
        icon: ChevronsUp,
      }

  ]
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <Input
          placeholder="Filter activities..."
          value={(table.getColumn("name")?.getFilterValue() as string) ?? ""}
          onChange={(event) =>
            table.getColumn("name")?.setFilterValue(event.target.value)
          }
          className="h-8 w-[150px] lg:w-[250px]"
        />
        {table.getColumn("status") && (
          <DataTableFacetedFilter
            column={table.getColumn("status")}
            title="Activity Status"
            options={statuses}
          />
        )}
              {table.getColumn("Progress Status") && (
          <DataTableFacetedFilter
            column={table.getColumn("Progress Status")}
            title="Progress Status"
            options={statuses2}
          />
        )}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <X />
          </Button>
        )}
      </div>
      <DataTableViewOptions table={table}/>
    </div>
  )
}