
import React, { useEffect, useState } from 'react';
import api from '@/lib/axiosInstance';
import { useParams } from "react-router-dom";
import { Loader2 } from 'lucide-react';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import dayjs from 'dayjs';
import { DateRangePicker } from '../ui/date-range-picker';
import { LabelList, Pie, PieChart, BarChart, XAxis, CartesianGrid, Bar, YAxis } from "recharts"
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart"

export function NCRChart() {
    const [loading, setLoading] = useState(true);
     const { projectCode } = useParams()

    const [ncrData, setNCRData] = useState<any>(null);
    const [dataRange, setDataRange] = useState<any>({ from: dayjs().add(-3, "months"), to: dayjs() });

    useEffect(() => {
        const fetchData = async () => {

            const from = dayjs(dataRange.from).format("YYYY-MM-DD");
            const to = dayjs(dataRange.to).format("YYYY-MM-DD");

            try {
                const res = await api.get(`/getpunch?project=${projectCode}&type=NCR&from=${from}&to=${to}`);
                setNCRData(res.data.Rows);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setLoading(false);
            }
        };

        if (projectCode) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [projectCode, dataRange]);


    const barChartData = [
        { type: "NCR", Total: ncrData && ncrData.filter((v: any) => v.qualitytype === "NCR").length, Open: ncrData && ncrData.filter((v: any) => v.qualitytype === "NCR" && v.status !== "Closed").length, Close: ncrData && ncrData.filter((v: any) => v.qualitytype === "NCR" && v.status === "Closed").length },
        { type: "CAR", Total: ncrData && ncrData.filter((v: any) => v.qualitytype === "CAR").length, Open: ncrData && ncrData.filter((v: any) => v.qualitytype === "CAR" && v.status !== "Closed").length, Close: ncrData && ncrData.filter((v: any) => v.qualitytype === "CAR" && v.status === "Closed").length },
        { type: "PAR", Total: ncrData && ncrData.filter((v: any) => v.qualitytype === "PAR").length, Open: ncrData && ncrData.filter((v: any) => v.qualitytype === "PAR" && v.status !== "Closed").length, Close: ncrData && ncrData.filter((v: any) => v.qualitytype === "PAR" && v.status === "Closed").length },
    ]

    const barChartConfig = {
        type: {
            label: "Type",
        },
        Open: {
            label: "취소",
            color: "#FEAE65",
        },
        Close: {
            label: "승인",
            color: "#D1D1D1",
        },

    } satisfies ChartConfig

    const transformData = (dataArray: any) => {
        // 1. Subcontractor_dpk로 그룹화하기 위한 중간 객체 생성
        if (dataArray) {
            const vendorGroups = dataArray.reduce((acc: any, item: any) => {
                if (!acc[item.Subcontractor_dpk]) {
                    acc[item.Subcontractor_dpk] = {
                        vendor: item.Subcontractor_dpk,
                        records: []
                    };
                }
                acc[item.Subcontractor_dpk].records.push(item);
                return acc;
            }, {});

            // 2. 각 그룹에 대해 open/close 카운트 계산
            const result = Object.values(vendorGroups).map((group: any) => ({
                vendor: group.vendor,
                Open: group.records.filter((record: any) => record.status !== 'Closed').length,
                Close: group.records.filter((record: any) => record.status === 'Closed').length,
                Total: group.records.length
            }))
                .filter(v => (v.Total) !== 0);

            return result;
        }
    };

    const barChartData2 = ncrData && transformData(ncrData);

    const barChartConfig2 = {
        vendor: {
            label: "업체명",
        },
        Open: {
            label: "취소",
            color: "#FEAE65",
        },
        Close: {
            label: "승인",
            color: "#D1D1D1",
        }
    } satisfies ChartConfig

    if (loading) {
        return (<>
            <CardHeader className="text-left">
                <CardTitle className="text-base leading-none tracking-tight">Punch 현황</CardTitle>
            </CardHeader>
            <CardContent className="flex items-center justify-center overflow-hidden">
                <Loader2 className="w-5 h-5 animate-spin" />
            </CardContent>
        </>
        );
    }

    return (
        <>
            <CardHeader className="text-left">
                <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                    <span>NCR/CAR/PAR</span>
                    <span className='ml-auto text-xs"'>
                        <DateRangePicker
                            initialDateFrom={dayjs().add(-3, 'month').format("YYYY-MM-DD")}
                            initialDateTo={dayjs().format("YYYY-MM-DD")}
                            locale={"ko-KR"}
                            showCompare={false}
                            onUpdate={(values) => setDataRange(values.range)} />
                    </span>

                </CardTitle>
            </CardHeader>
            <CardContent className="flex items-center justify-center overflow-hidden">

                {ncrData && ncrData.length > 0 ?
                    <>
                        <div className='w-1/3 h-full'>
                            {barChartData && barChartData.length > 0 &&
                                <ChartContainer config={barChartConfig} className="mx-auto min-h-[150px] max-h-[250px]">
                                    <BarChart
                                        data={barChartData}
                                        margin={{
                                            top: 25,
                                            bottom: 15,
                                            left: 25,
                                            right: 25
                                        }}
                                    >
                                        <CartesianGrid vertical={false} horizontal={false} />
                                        <XAxis
                                            dataKey="type"
                                            tickLine={false}
                                            tickMargin={10}
                                            axisLine={false}
                                            tickFormatter={(value) => value}
                                            interval={0}
                                        />
                                        <Bar dataKey="Open" stackId="a" fill={`var(--color-Open)`} radius={[0, 0, 12 / barChartData.length, 12 / barChartData.length]} />
                                        <Bar dataKey="Close" stackId="a" fill={`var(--color-Close)`} radius={[12 / barChartData.length, 12 / barChartData.length, 0, 0]} >
                                            <LabelList
                                                dataKey="Total"
                                                position="top"
                                                offset={8}
                                                className="fill-foreground"
                                                fontSize={12}
                                            />
                                        </Bar>

                                        <ChartTooltip
                                            content={
                                                <ChartTooltipContent
                                                    className="w-[80px]"
                                                    formatter={(value, name, item, index) => (
                                                        <>
                                                            <div
                                                                className="h-2.5 w-2.5 shrink-0 rounded-[2px] bg-[--color-bg]"
                                                                style={
                                                                    {
                                                                        "--color-bg": `var(--color-${name})`,
                                                                    } as React.CSSProperties
                                                                }
                                                            />
                                                            {barChartConfig[name as keyof typeof barChartConfig]?.label ||
                                                                name}
                                                            <div className="ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground">
                                                                {value}
                                                            </div>

                                                        </>
                                                    )}
                                                />
                                            }
                                            cursor={false}
                                        // defaultIndex={1}
                                        />
                                    </BarChart>
                                </ChartContainer>
                            }


                        </div>
                        <div className='w-2/3'>
                            {barChartData2 && barChartData2.length > 0 &&
                                <ChartContainer config={barChartConfig2} className="mx-auto min-h-[150px] max-h-[200px]">
                                    <BarChart
                                        data={barChartData2}
                                        accessibilityLayer
                                        layout="vertical"
                                        margin={{
                                            // top: 15,
                                            // bottom: 15,
                                            left: 50,
                                            right: 25
                                        }}
                                    >
                                        <CartesianGrid vertical={false} horizontal={false} />

                                        {barChartData2.length > 12 ?
                                            <YAxis
                                                dataKey="vendor"
                                                type="category"
                                                tickLine={false}
                                                tickMargin={5}
                                                axisLine={false}
                                                tickFormatter={(value: any) => value.slice(0, 5)}
                                                interval={1}

                                            />
                                            :
                                            <YAxis
                                                dataKey="vendor"
                                                type="category"
                                                tickLine={false}
                                                tickMargin={5}
                                                axisLine={false}
                                                tickFormatter={(value: any) => value.slice(0, 5)}
                                                interval={0}

                                            />
                                        }


                                        <XAxis type="number" hide />

                                        <ChartTooltip
                                            cursor={false}
                                            defaultIndex={1}
                                            content={<ChartTooltipContent indicator="line" />}
                                        />

                                        <Bar dataKey="Open" layout="vertical" stackId="a" fill={`var(--color-Open)`} radius={[12 / barChartData2.length, 0, 0, 12 / barChartData2.length]} />
                                        <Bar dataKey="Close" layout="vertical" stackId="a" fill={`var(--color-Close)`} radius={[0, 12 / barChartData2.length, 12 / barChartData2.length, 0]} >
                                            <LabelList
                                                dataKey="Total"
                                                position="right"
                                                offset={8}
                                                className="fill-foreground"
                                                fontSize={12}
                                            />
                                        </Bar>


                                    </BarChart>
                                </ChartContainer>
                            }

                        </div>
                    </>
                    :
                    <div className="flex justify-center items-center h-[20vh]">
                        <div className="text-xs text-slate-700">
                            No data available for the selected period.
                        </div>
                    </div>}

            </CardContent>
        </>
    );
}