import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import dayjs from 'dayjs';
import MapChart from '@/components/total/projectMap';
import StatusBullet from '@/components/total/statusBullet';
import EventsTable from "@/components/total/eventTable";
import { ManPowerBarChart } from "@/components/total/manPowerBarChart";
import Photos from "@/components/total/photo";
import LTIRTotal from "@/components/total/ltir";
import PQITotal from "@/components/total/pqi";
import PTWTotal from "@/components/total/ptw";
import Envtotal from "@/components/total/env";

export default function TotalPage() {



  return (
    <main className="grid grid-rows-[auto,1fr] gap-3 p-3 h-screen w-full">
      {/* First container with title and horizontal cards */}
      <div className="p-4 flex flex-col  bg-muted/50 rounded-md">
        <h3 className="text-sm font-semibold mb-2">Project Information</h3>

        {/* Cards stack vertically on mobile and horizontally on larger screens */}
        <div className="flex flex-col md:flex-row flex-1 gap-4">
          <Card className="flex-1 h-full flex flex-col border-0">
            <CardHeader className="p-3">
              <CardTitle className="text-left text-xs">Project Location</CardTitle>
            </CardHeader>
            <CardContent className="p-2 flex-1 flex items-center justify-center h-[25vh] md:h-full">
              <MapChart chart_id='projectmap' />
            </CardContent>
          </Card>

          {/* 두번째 카드 */}
          <StatusBullet />

          <Card className="flex-1 h-full flex flex-col border-0">
            <EventsTable />
          </Card>
        </div>
      </div>

      {/* Second div section with title and vertical cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 h-[62vh] w-full">
        <div className="w-full h-full p-4 flex flex-col bg-muted/50 rounded-md">
          <h3 className="text-sm font-semibold mb-2">Construction</h3>
          <div className="flex flex-col gap-4 flex-1">
            <Card className="flex-1 h-full flex flex-col  border-0">
              <CardHeader className="p-3">

                <CardTitle className="flex justify-between">
                  <span className='text-xs'>인원 투입현황</span>
                  <span className="text-xs font-normal">{dayjs().add(-1, 'days').format("YYYY년 MM월 DD일")}</span>

                </CardTitle>
              </CardHeader>
              <ManPowerBarChart />

            </Card>
            <Card className="flex-1 h-full flex flex-col  border-0">
              <Photos />

            </Card>
          </div>
        </div>

        <div className="w-full h-full p-4 flex flex-col bg-muted/50 rounded-md">
          <h3 className="text-sm font-semibold mb-2">Safety</h3>
          <div className="flex flex-col gap-4 flex-1">
            <Card className="flex-1 h-full flex flex-col  border-0">
              <CardHeader className="p-3">
                <CardTitle className="text-left text-xs">LTIR</CardTitle>
              </CardHeader>
              <CardContent className="flex-1 flex items-center justify-center">
                <LTIRTotal />
              </CardContent>
            </Card>
            <Card className="flex-1 h-full flex flex-col  border-0">
              <CardHeader className="p-3">
                <CardTitle className="text-left text-xs">위험작업 현황</CardTitle>
              </CardHeader>
              <CardContent className="flex-1 flex items-center justify-center pb-2">
                <PTWTotal />
              </CardContent>
            </Card>
          </div>
        </div>

        <div className="w-full h-full p-4 flex flex-col bg-muted/50 rounded-md">
          <h3 className="text-sm font-semibold mb-2">Quality & Environment</h3>
          <div className="flex flex-col gap-4 flex-1">
            <Card className="flex-1 h-full flex flex-col  border-0">
              <PQITotal />

            </Card>
            <Card className="flex-1 h-full flex flex-col  border-0">
              <CardHeader className="p-3">
                <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                  <span className='text-xs'>Net Zero Roadmap</span>
                  <span className="ml-auto text-xs font-normal">{`${dayjs().format("YYYY년 01월")}~${dayjs().format("YYYY년 MM월")}`}</span>
                </CardTitle>
              </CardHeader>
              <CardContent className="flex-1 flex items-center justify-center pb-2">
                <Envtotal />
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </main>
  );
}