"use client"

import { ColumnDef } from "@tanstack/react-table"
import { ArrowUpDown } from "lucide-react"
import { Button } from "../ui/button"

import { DataTableColumnHeader } from "../tables-client/data-table-column-header"
import dayjs from "dayjs"

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type Activity = {
  uuu_P6ActivityId: string
  uuu_P6ActivityName: string
  Act_activity_status: string
  d_plan_progress: number
  Act_actual_progress: number
  d_forecast_qty: number
  progress_difference:number
  d_actual_qty: number
  d_remaining_qty: number
  Act_evm_plan_start: Date
  Act_evm_plan_finish: string
  d_sep_uom_spd: string
  Act_evm_actual_start: string
  Act_evm_actual_finish: string
}

export const AcitivtyColumns: ColumnDef<Activity>[] = [
  {
    // id:"ID",
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    cell: ({ row }) => <div className="w-[120px]">{row.getValue("id")}</div>,
    enableSorting: false,
    enableHiding: true,
  },
  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    cell: ({ row }) => {
      return (
        <div className="max-w-[250px] truncate font-medium" title={row.getValue("name")}>
          
          {row.getValue("name")}
        </div>
      )
    },
    enableHiding: true,


  },
  {
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row }) => <div className="w-[80px]">{row.getValue("status")}</div>,
    enableSorting: true,
    enableHiding: true,
    

  },

  {
    accessorKey:"Progress Status",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Progress Status" />
    ),
    cell: ({ row }) => <div className="w-[80px]">{row.getValue("Progress Status")}</div>,
    enableSorting: true,
    enableHiding: true,
    

  },

  {
    header: "진도율 (%)",
    columns: [
      {
        accessorKey: "d_plan_progress",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="계획" />
        ),
        cell: ({ row }) => <div className="w-[60px]">{row.getValue("d_plan_progress")}</div>,
        enableSorting: true,
        enableHiding: false,
      },
      {
        accessorKey: "Act_actual_progress",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="실적" />
        ),
        cell: ({ row }) => <div className="w-[60px]">{row.getValue("Act_actual_progress")}</div>,
        enableSorting: true,
        enableHiding: false,
      },
      {
        accessorKey: "progress_difference",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="차이" />
        ),
        cell: ({ row }) => <div className="w-[60px]">{row.getValue("progress_difference")}</div>,

        enableSorting: true,
        enableHiding: false,
      },
    ],
  },
  {
    header: "물량",
    columns: [

      {
        accessorKey: "d_forecast_qty",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="예상" />
        ),
        cell: ({ row }) => <div className="w-[60px]">{row.getValue("d_forecast_qty")}</div>,
        enableSorting: true,
        enableHiding: false,
      },
      {
        accessorKey: "d_actual_qty",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="실적" />
        ),
        cell: ({ row }) => <div className="w-[60px]">{row.getValue("d_actual_qty")}</div>,
        enableSorting: true,
        enableHiding: false,
      },
      {
        accessorKey: "d_remaining_qty",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="잔여" />
        ),
        cell: ({ row }) => <div className="w-[60px]">{row.getValue("d_remaining_qty")}</div>,
        enableSorting: true,
        enableHiding: false,
      },
      {
        accessorKey: "d_sep_uom_spd",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="단위" />
        ),
        cell: ({ row }) => <div className="w-[40px]">{row.getValue("d_sep_uom_spd")}</div>,
        enableSorting: false,
        enableHiding: false,
      },
    ],
  },

  {
    header: "계획",
    columns: [
      {
        accessorKey: "Act_evm_plan_start",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="시작일" />
        ),
        cell: ({ row }) => {
          return (
            <span className="w-[80px] truncate">
              {row.getValue("Act_evm_plan_start") ? dayjs(row.getValue("Act_evm_plan_start")).format("YYYY-MM-DD") : ''}

            </span>
          )
        },
        enableSorting: true,
        enableHiding: true,
      },
      {
        accessorKey: "Act_evm_plan_finish",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="종료일" />
        ),
        cell: ({ row }) => {
          return (
            <span className="w-[80px] truncate">
              {row.getValue("Act_evm_plan_finish") ? dayjs(row.getValue("Act_evm_plan_finish")).format("YYYY-MM-DD") : ''}
            </span>
          )
        },
        enableSorting: true,
        enableHiding: true,
      },
    ],
  },
  {
    header: "실적",
    columns: [
      {
        accessorKey: "Act_evm_actual_start",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="실적 시작일" />
        ),
        cell: ({ row }) => {
          return (
            <span className="w-[80px] truncate">
              {row.getValue("Act_evm_actual_start") ? dayjs(row.getValue("Act_evm_actual_start")).format("YYYY-MM-DD") : ''}
            </span>
          )
        },
        enableSorting: true,
        enableHiding: true,
      },
      {
        accessorKey: "Act_evm_actual_finish",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="실적 종료일" />
        ),
        cell: ({ row }) => {
          return (
            <span className="w-[80px] truncate">
              {row.getValue("Act_evm_actual_finish") ? dayjs(row.getValue("Act_evm_actual_finish")).format("YYYY-MM-DD") : ''}
            </span>
          )
        },
        enableSorting: true,
        enableHiding: true,
      },
    ],
  },
]
