import { EPgogressChartWithBullet } from "@/components/eprogress/EProgressChart";
import { ETimeLine } from "@/components/eprogress/ETimeLine";
import { EngIssueHtml } from "@/components/eprogress/issue";
import { Card} from "@/components/ui/card";


export default function EProgress() {

  return (
    <div className="flex flex-1 flex-col gap-4 p-4 w-full">
      {/* 마일스톤 */}
      <Card className="h-[39vh] w-full rounded-xl border bg-card text-card-foreground shadow">
        <ETimeLine />
      </Card>

      <EPgogressChartWithBullet/>

      <EngIssueHtml/>
    </div>
  );
}