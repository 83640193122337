

import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from "@/components/ui/button";
import { Loader2 } from 'lucide-react';
import { LoginForm } from '@/components/login-form';
import { useSetAtom } from "jotai";
import { languageState } from "@/atoms";

function Copyright() {
    return (
        <div className="text-center text-gray-500">
            <p className="text-sm">
                {("S.CON 문의")}:{" "}
                <a className="text-blue-500 hover:text-blue-700" href="mailto:sconhelp@partner.skecoengineering.com">
                    sconhelp@partner.skecoengineering.com
                </a>
                <br />
                {'Copyright © '}
                <a className="text-blue-500 hover:text-blue-700" href="https://www.skecoengineering.com/">
                    SK Ecoengineering
                </a>{" "}
                {new Date().getFullYear()}
                {'.'}
            </p>
        </div>
    );
}

export default function LoginPage() {
    const { t, i18n } = useTranslation();  
    const setLanguage = useSetAtom(languageState);

    const handleLanguageChange = (lang: string) => {
        i18n.changeLanguage(lang);
        setLanguage(lang)
    };

    return (
<div className="flex h-screen w-full flex-col items-center px-4 py-4">
    <div className="flex flex-1 flex-col items-center justify-center gap-4">


        <Suspense fallback={<div className="flex items-center justify-center h-screen">
        <Loader2 className="w-5 h-5 animate-spin" />
      </div>}>
      <LoginForm />
    </Suspense>
        <div className="flex space-x-2">
            <Button
                variant={i18n.language === 'ko' ? "default" : "outline"}
                onClick={() => handleLanguageChange('ko')}
                className="w-20 h-8 text-xs"
            >
                한국어
            </Button>
            <Button
                variant={i18n.language === 'en' ? "default" : "outline"}
                onClick={() => handleLanguageChange('en')}
                className="w-20 h-8 text-xs"
            >
                English
            </Button>
        </div>
    </div>
    <Copyright />
</div>
    );
}