import { Card} from "@/components/ui/card";
import { CTimeLine } from "@/components/cprogress/CTimeLine";
import { CPgogressChartWithBullet } from "@/components/cprogress/CProgressChart";


export default function CProgress() {

  return (
    <div className="flex flex-1 flex-col gap-4 p-4 w-full">
      {/* 마일스톤 */}
      <Card className="h-[39vh] w-full rounded-xl border bg-card text-card-foreground shadow">
        <CTimeLine />
      </Card>

      {/* S-curve 및 불렛 */}
      <CPgogressChartWithBullet/>
    </div>
  );
}