
import * as React from "react"
import { useParams } from "react-router-dom";
import api from "@/lib/axiosInstance";
import { BarChartH } from "../common/barChartHorizontal";

// ChartConfig 타입 정의 (필요한 경우)
interface ChartConfigItem {
    label: string;
    color: string;
}

interface LineItem {
    EBPQAFunctuin: string;
    EBPQAGrade: string;
    EQADicision: string;
    uuu_tab_id?: string;
    // 필요한 다른 속성들 추가
}


type ChartConfig = Record<string, ChartConfigItem>;

export function EQA() {
     const { projectCode } = useParams()

    const [barChartData, setBarChartData] = React.useState<any[] | null>(null);
    const [findingsCount, setFindingsCount] = React.useState<number>(0); // Findings 수를 저장할 상태


    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const body = {
                    bpname: "EQA Response",
                    lineitem: "yes",
                    filter_criteria: {
                        join: "AND",
                        filter: [
                            {
                                field: "status",
                                value: "Terminated",
                                condition_type: "neq",
                            },
                        ],
                    },
                };

                const response = await api.post(`/getemsbprecord?path=${projectCode}`, body);
                const unifierData = response.data.data.data;

                // lineItemPlat을 LineItem 배열로 타입 지정
                const lineItemPlat: LineItem[] = unifierData.reduce((acc: LineItem[], cur: any) => {
                    const { EBPQAFunctuin } = cur;
                    const _bp_lineitems = cur?._bp_lineitems ?? [];

                    const lineItemDataAdd: LineItem[] = _bp_lineitems
                        .filter((c: any) => c.uuu_tab_id === "Checklist")
                        .map((c: any) => {
                            return { ...c, EBPQAFunctuin };
                        });

                    return acc.concat(lineItemDataAdd);
                }, []);

                // Findings 수를 상태에 저장
                setFindingsCount(lineItemPlat.length);

                // groupedData의 타입을 명시적으로 지정
                const groupedData: Record<string, LineItem[]> = lineItemPlat.reduce((acc: Record<string, LineItem[]>, item: LineItem) => {
                    const key = item.EBPQAFunctuin;
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(item);
                    return acc;
                }, {});

                // group_disc 생성
                const group_disc = Object.entries(groupedData).map(([key, objs]: [string, LineItem[]]) => {
                    const result = {
                        disc: key,
                        "준수(S)": objs.filter((c) => c.EBPQAGrade === "S").length,
                        "시정조치(CA)": objs.filter((c) => c.EBPQAGrade === "CA").length,
                        "시정(C)": objs.filter((c) => c.EBPQAGrade === "C").length,
                        "관찰(O)": objs.filter((c) => c.EBPQAGrade === "O").length,
                        "보류": objs.filter((c) => c.EQADicision !== "Closed").length,
                        "승인": objs.filter((c) => c.EQADicision === "Closed").length,
                    };

                    return result;
                });

                setBarChartData(group_disc);
            } catch (error) {
                console.error("데이터를 가져오는 중 오류 발생:", error);
                setBarChartData(null);
                setFindingsCount(0);
            }
        };
        if (projectCode) {
            fetchData();
        }
    }, [projectCode]);

    // config 객체 생성
    const chartConfig: ChartConfig = {
        "준수(S)": {
            label: "준수(S)",
            color: "hsl(var(--chart-1))",
        },
        "시정조치(CA)": {
            label: "시정조치(CA)",
            color: "hsl(var(--chart-2))",
        },
        "시정(C)": {
            label: "시정(C)",
            color: "hsl(var(--chart-3))",
        },
        "관찰(O)": {
            label: "관찰(O)",
            color: "hsl(var(--chart-4))",
        },
    };

    return (
        barChartData && barChartData.length > 0 ? (
            <div className="flex flex-col items-center w-full">
                {/* Findings 수를 표시하는 메시지 */}
                <div className="mb-2 text-center text-sm font-semibold">
                    {`품질 점검 결과 ${findingsCount}건의 Findings 확인`}
                </div>
                
                {/* BarChartH 컴포넌트 */}
                <BarChartH
                    data={barChartData}
                    config={chartConfig}
                    dataKeys={["준수(S)", "시정조치(CA)", "시정(C)", "관찰(O)"]}
                />
            </div>
        ) : (
            <div className="flex justify-center items-center h-full text-xs text-slate-700">
                No data available.
            </div>
        )
    );
}