// dataTransform.ts
export interface OrgDataItem {
    ugenProjectName: string | null;
    uuu_creation_date: string;
    project_projectname: string;
    OrgName: string;
    orgParentBPK: string | null;
    empEmployeeIDTB50: string;
    project_projectnumber: string;
    ugenProjectNumber: string | null;
    SKRoleClass1: string;
    HiddenempEmployeeIDTB50: string;
    record_no: string;
    uuu_user_firstname: string;
    creator_id: string;
    SKRoleClass2: string;
    SKRoleClass3: string;
    SKRoleClass4: string;
    status: string;
}

export interface TreeNode {
    name: string;
    attributes: {
        position: string;
        image: string;
    };
    children?: TreeNode[];
}

export const transformData = (dataArray: OrgDataItem[]): TreeNode | null => {
    const nodeMap: { [key: string]: TreeNode } = {};
    let root: TreeNode | null = null;

    // 모든 노드를 맵에 추가
    dataArray.forEach(item => {
        nodeMap[item.record_no] = {
            name: item.OrgName,
            attributes: {
                position: item.SKRoleClass2,
                image: `http://it.skecoplant.com/image.aspx?sabun=${item.empEmployeeIDTB50}`
            },
            children: []
        };
    });

    // 부모-자식 관계 설정
    dataArray.forEach(item => {
        if (item.orgParentBPK && nodeMap[item.orgParentBPK]) {
            nodeMap[item.orgParentBPK].children!.push(nodeMap[item.record_no]);
        } else {
            // 부모가 없는 경우 루트 노드로 설정
            root = nodeMap[item.record_no];
        }
    });

    // 빈 children 배열 제거
    const pruneEmptyChildren = (node: TreeNode) => {
        if (node.children && node.children.length === 0) {
            delete node.children;
        } else if (node.children) {
            node.children.forEach(child => pruneEmptyChildren(child));
        }
    };

    if (root) {
        pruneEmptyChildren(root);
    }

    return root;
};