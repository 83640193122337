import OrgChart  from '@/components/ems/orgChart';
import { Card,CardContent,CardHeader, CardTitle} from '@/components/ui/card';


export default function EMSOrgPages() {

  return (
    <div className="flex flex-1 flex-col gap-4 p-4 w-full">
            <Card className=" w-full  rounded-xl border bg-card text-card-foreground shadow overflow-y-auto">
                <CardHeader className="text-left">
                    <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                        <span>설계 조직도</span>

                    </CardTitle>

                </CardHeader>
                <CardContent className='w-full h-[89vh]'>
                <OrgChart/>
                </CardContent>

      </Card>


    </div>
  );
}