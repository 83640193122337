
import { useGetProgressSimpleData } from "@/lib/getData";
import {
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { EPCProgressContent } from "@/components/overview/EPCProgressContent";
import dayjs from "dayjs";

export function EProgressCard() {

  const { simpleProgressData, simpleProgressDataloading } = useGetProgressSimpleData();


  const categories = [
    { title: "Overall", plan: simpleProgressData?.oprogressPlan, actual: simpleProgressData?.oprogressActual, color: "bg-blue-100" },
    { title: "Engineering", plan: simpleProgressData?.eprogressPlan, actual: simpleProgressData?.eprogressActual, color: "bg-emerald-100" },
    // { title: "Procurement", plan: simpleProgressData?.pprogressPlan, actual: simpleProgressData?.pprogressActual, color: "bg-yellow-100" },
    // { title: "Construction", plan: simpleProgressData?.cprogressPlan, actual: simpleProgressData?.cprogressActual, color: "bg-red-100" },
  ];

  return (
    <>
        <EPCProgressContent categories={categories} simpleProgressDataloading={simpleProgressDataloading} />
    </>)
}