
import { useGetPartMilestoneData } from "@/lib/getData";
import { PartTimeLine } from "../common/partTimeLine";

export function PTimeLine() {

  const { oMilestoneData, pMilestoneData, startDate, endDate } = useGetPartMilestoneData();

    return (          
      <PartTimeLine oMilestoneData={oMilestoneData} MilestoneData={pMilestoneData} startDate={startDate} endDate={endDate} partName="Procurement"/>
   
    )
    }