
import { useGetPartMilestoneData } from "@/lib/getData";
import { PartTimeLine } from "../common/partTimeLine";

export function ETimeLine() {

  const { oMilestoneData, eMilestoneData, startDate, endDate } = useGetPartMilestoneData();

    return (          
      <PartTimeLine oMilestoneData={oMilestoneData} MilestoneData={eMilestoneData} startDate={startDate} endDate={endDate} partName="Engineering"/>
   
    )
    }