
import React, { useEffect, useState } from 'react';
import api from '@/lib/axiosInstance';
import { useParams } from "react-router-dom";
import { Loader2 } from 'lucide-react';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import dayjs from 'dayjs';
import { DateRangePicker } from '@/components/ui/date-range-picker';
import { LabelList, Pie, PieChart, BarChart, XAxis, CartesianGrid, Bar } from "recharts"
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart"

export function CheckChart() {
    const [loading, setLoading] = useState(true);
     const { projectCode } = useParams()


    const [checkData, setCheckData] = useState<any>(null);
    const [dataRange, setDataRange] = useState<any>({ from: dayjs().add(-3, "months"), to: dayjs() });


    useEffect(() => {
        const fetchData = async () => {

            const from = dayjs(dataRange.from).format("YYYY-MM-DD");
            const to = dayjs(dataRange.to).format("YYYY-MM-DD");

            try {
                const res = await api.get(`/getpunch?project=${projectCode}&type=check&from=${from}&to=${to}`);
                setCheckData(res.data.Rows);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setLoading(false);
            }
        };

        if (projectCode) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [projectCode, dataRange]);

    const PieChartData = [
        { status: "Open", counts: checkData && checkData.filter((v: any) => v.qualitytype === "취소").length, fill: "var(--color-Open)" },
        { status: "Close", counts: checkData && checkData.filter((v: any) => v.qualitytype === "승인").length, fill: "var(--color-Close)" },
        { status: "Reject", counts: checkData && checkData.filter((v: any) => v.qualitytype === "불합격").length, fill: "var(--color-Reject)" },
    ]

    const transformData = (dataArray: any) => {
        // 1. Subcontractor_dpk로 그룹화하기 위한 중간 객체 생성
        if (dataArray) {
            const vendorGroups = dataArray.reduce((acc: any, item: any) => {
                if (!acc[item.Subcontractor_dpk]) {
                    acc[item.Subcontractor_dpk] = {
                        vendor: item.Subcontractor_dpk,
                        records: []
                    };
                }
                acc[item.Subcontractor_dpk].records.push(item);
                return acc;
            }, {});

            // 2. 각 그룹에 대해 open/close 카운트 계산
            const result = Object.values(vendorGroups).map((group: any) => ({
                vendor: group.vendor,
                Open: group.records.filter((record: any) => record.qualitytype === '취소').length,
                Close: group.records.filter((record: any) => record.qualitytype === '승인').length,
                Reject: group.records.filter((record: any) => record.qualitytype === '불합격').length,
                Total: group.records.length
            }))
                .filter(v => (v.Close + v.Open + v.Reject) !== 0);

            return result;
        }
    };

    const barChartData = checkData && transformData(checkData)

    const barChartConfig = {
        vendor: {
            label: "업체명",
        },
        Open: {
            label: "취소",
            color: "#FEAE65",
        },
        Close: {
            label: "승인",
            color: "#D1D1D1",
        },
        Reject: {
            label: "불합격",
            color: "#2D87BB",
        },
    } satisfies ChartConfig

    const chartConfig = {
        counts: {
            label: "counts",
        },
        Open: {
            label: "취소",
            color: "#FEAE65",
        },
        Close: {
            label: "승인",
            color: "#D1D1D1",
        },
        Reject: {
            label: "불합격",
            color: "#2D87BB",
        },

    } satisfies ChartConfig

    if (loading) {
        return (<>
            <CardHeader className="text-left">
                <CardTitle className="text-base leading-none tracking-tight">Punch 현황</CardTitle>
            </CardHeader>
            <CardContent className="flex items-center justify-center overflow-hidden">
                <Loader2 className="w-5 h-5 animate-spin" />
            </CardContent>
        </>
        );
    }

    return (
        <>
            <CardHeader className="text-left">
                <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                    <span>검사 현황</span>
                    <span className='ml-auto text-xs"'>
                        <DateRangePicker
                            initialDateFrom={dayjs().add(-3, 'month').format("YYYY-MM-DD")}
                            initialDateTo={dayjs().format("YYYY-MM-DD")}
                            locale={"ko-KR"}
                            showCompare={false}
                            onUpdate={(values) => setDataRange(values.range)} />
                    </span>

                </CardTitle>
            </CardHeader>
            <CardContent className="flex items-center justify-center overflow-hidden">
                {checkData && checkData.length > 0 ?
                    <>
                        <div className='w-1/3 h-full'>
                            {PieChartData && (
                                <ChartContainer
                                    config={chartConfig}
                                    className="mx-auto aspect-square min-h-[150px] max-h-[180px] px-0"
                                >
                                    <PieChart>
                                        <ChartTooltip
                                            content={<ChartTooltipContent nameKey="status" hideLabel />}
                                        />
                                        <Pie
                                            data={PieChartData}
                                            dataKey="counts"
                                            labelLine={false}
                                        >
                                            <LabelList
                                                dataKey="status"
                                                className="fill-background"
                                                stroke="none"
                                                fontSize={11}
                                                fontWeight={'bold'}
                                                formatter={(value: keyof typeof chartConfig, index: number) => {
                                                    const label = chartConfig[value]?.label;
                                                    const count = PieChartData.find(vf => vf.status === value)?.counts;
                                                    if (count > 0) {
                                                        return `${label}: ${count}`;
                                                    }
                                                }}
                                            />
                                        </Pie>
                                    </PieChart>
                                </ChartContainer>
                            )
                            }
                        </div>
                        <div className='w-2/3'>
                            {barChartData && barChartData.length > 0 &&
                                <ChartContainer config={barChartConfig} className="mx-auto min-h-[150px] max-h-[200px]">
                                    <BarChart
                                        data={barChartData}
                                        margin={{
                                            top: 15,
                                            bottom: 15,
                                            left: 25,
                                            right: 25
                                        }}
                                    >
                                        <CartesianGrid vertical={false} horizontal={false} />
                                        {barChartData && barChartData.length > 5 ?
                                            <XAxis
                                                dataKey="vendor"
                                                tickLine={false}
                                                tickMargin={10}
                                                axisLine={false}
                                                tick={({ x, y, payload }) => (
                                                    <g transform={`translate(${x},${y})`}>
                                                        <text
                                                            x={0}
                                                            y={0}
                                                            dy={0}
                                                            dx={6}
                                                            fontSize={10}
                                                            textAnchor="end"
                                                            fill="#666"
                                                            transform="rotate(-35)"
                                                        >
                                                            {payload.value.slice(0, 5)}
                                                        </text>
                                                    </g>
                                                )}
                                                interval={barChartData.length > 10 ? 2 : 0}

                                            /> :

                                            <XAxis
                                                dataKey="vendor"
                                                tickLine={false}
                                                tickMargin={10}
                                                axisLine={false}
                                                tickFormatter={(value: any) => value.slice(0, 5)}
                                                interval={0}

                                            />

                                        }

                                        <Bar dataKey="Open" stackId="a" fill={`var(--color-Open)`} radius={[0, 0, 12 / barChartData.length, 12 / barChartData.length]} />
                                        <Bar dataKey="Reject" stackId="a" fill={`var(--color-Reject)`} radius={[0, 0, 0, 0]} />
                                        <Bar dataKey="Close" stackId="a" fill={`var(--color-Close)`} radius={[12 / barChartData.length, 12 / barChartData.length, 0, 0]} >
                                            <LabelList
                                                dataKey="Total"
                                                position="top"
                                                offset={8}
                                                className="fill-foreground"
                                                fontSize={12}
                                            />
                                        </Bar>


                                        <ChartTooltip
                                            content={
                                                <ChartTooltipContent
                                                    className="w-[80px]"
                                                    formatter={(value, name, item, index) => (
                                                        <>
                                                            <div
                                                                className="h-2.5 w-2.5 shrink-0 rounded-[2px] bg-[--color-bg]"
                                                                style={
                                                                    {
                                                                        "--color-bg": `var(--color-${name})`,
                                                                    } as React.CSSProperties
                                                                }
                                                            />
                                                            {barChartConfig[name as keyof typeof barChartConfig]?.label ||
                                                                name}
                                                            <div className="ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground">
                                                                {value}
                                                            </div>

                                                        </>
                                                    )}
                                                />
                                            }
                                            cursor={false}
                                            defaultIndex={1}
                                        />
                                    </BarChart>
                                </ChartContainer>
                            }

                        </div>
                    </> :
                    <div className="flex justify-center items-center h-[20vh]">
                        <div className="text-xs text-slate-700">
                            No data available for the selected period.
                        </div>
                    </div>
                }
            </CardContent>
        </>
    );
}