
import { useGetProjectMilestoneData } from "@/lib/getData";
import Timeline from "@/components/common/Timeline";

export function OverviewTimeLine() {
  const { projectMilestoneData, startDate, endDate } = useGetProjectMilestoneData();

  console.log("test")
  console.log(projectMilestoneData)


    return (<Timeline           
        events={projectMilestoneData}
        startDate={startDate}
        endDate={endDate}
        lineColor="border-slate-900"
        flagPosition="alternate" />)
    }