// 

import * as React from "react"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import PQINumber from "@/components/overview/pqiNumber";
import { PQIBarChart } from "@/components/quality/monthPQI";
import { IssueHtml } from "@/components/quality/issue";
import dayjs from "dayjs";
import { PunchChart } from "@/components/quality/punch";
import { CheckChart } from "@/components/quality/check";
import { NCRChart } from "@/components/quality/ncr";


export default function QualityPage() {

  return (
    <div className="flex flex-1 flex-col w-full p-4 ">
      <div className="grid grid-cols-12 grid-rows-3  md:grid-rows-3 md:grid-cols-12 gap-4 flex-1">

        {/* 첫 번째 카드: Project PQI */}
        <Card className="h-[30vh] row-span-1 md:col-span-3 col-span-3 rounded-xl border bg-card text-card-foreground shadow">
          <CardHeader className="text-left">
            <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                    <span>PQI</span>
                    <span className="ml-auto text-xs">{dayjs().add(-1,"days").format("YYYY년 MM월 DD일")}</span>
            </CardTitle>
          </CardHeader>
          <CardContent className="grid grid-cols-1 gap-4 h-[calc(100%-theme(spacing.14))]">
            <PQINumber/>
          </CardContent>
        </Card>

        {/* 두 번째 카드: Project Welding Defect Rate */}
        <Card className="h-[30vh] row-start-2 row-span-1 md:col-span-3 col-span-3 rounded-xl border bg-card text-card-foreground shadow flex flex-col">
          <CardHeader className="text-left">
            <CardTitle className="text-base leading-none tracking-tight">Project Welding Defect Rate</CardTitle>
          </CardHeader>
          <CardContent className="flex-grow flex items-center justify-center overflow-hidden">
                <div className="flex justify-center items-center h-full text-xs text-slate-700">
                No data available
                </div>
          </CardContent>
        </Card>



        {/* 세 번째 카드: 월별 PQI*/}
        <Card className="h-[62vh] row-span-2  md:col-span-4 col-span-4 rounded-xl border bg-card text-card-foreground shadow">
          <CardHeader className="text-left">
            <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                    <span>월별 PQI</span>
                    <span className="ml-auto text-xs">{dayjs().add(-1,"days").format("YYYY년 MM월 DD일")}</span>
            </CardTitle>
          </CardHeader>
            <PQIBarChart/>
        </Card>
        <Card className="h-[30vh] row-span-1 md:col-span-5 col-span-5 rounded-xl border bg-card text-card-foreground shadow">
          <PunchChart />
        </Card>

        <Card className="h-[30vh] row-span-1 md:col-span-5 col-span-5 rounded-xl border bg-card text-card-foreground shadow">
          <CheckChart/>
        </Card>

        <Card className="h-[30vh] row-span-1 md:col-span-7 col-span-7 rounded-xl border bg-card text-card-foreground shadow">
          <CardHeader className="text-left">
            <CardTitle className="text-base leading-none tracking-tight">업체별 용접불량률</CardTitle>
          </CardHeader>
          <CardContent className="h-[20vh] flex-grow flex items-center justify-center overflow-hidden">
                <div className="flex justify-center items-center h-full text-xs text-slate-700">
                No data available
                </div>
          </CardContent>
        </Card>

        <Card className="h-[30vh] row-span-1 md:col-span-5 col-span-5 rounded-xl border bg-card text-card-foreground shadow">
          <NCRChart/>

        </Card>


            <IssueHtml/>



      </div>
    </div>
  );
}
