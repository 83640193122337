

import GaugeChart from "@/components/common/GaugeChart";
import { Skeleton } from "@/components/ui/skeleton";
import { useGetLTIRData, useGetPTWData } from "@/lib/getData";
import { useTranslation } from 'react-i18next';

interface GaugeData {
  company: number | undefined;
  target: number | undefined;
  actual: number | undefined;
}

interface SafetyTableRow {
  type: string;
  ready: number;
  complete: number;
  stop: number;
}



export function SafetyActivityContent() {
  const getRowInfo = (row: { type: string; ready: number; complete: number; stop: number }) => {
    const sum = row.ready + row.complete + row.stop;
    let colorClass = '';
    switch (row.type) {
      case '특별': colorClass = 'text-red-500'; break;
      case '중점': colorClass = 'text-emerald-600'; break;
      case '일상': colorClass = 'text-gray-500'; break;
      default: colorClass = 'text-gray-500';
    }
    return { sum, colorClass };
  };


  const { t } = useTranslation();

  const { projectLTIRData, LTIRDataloading } = useGetLTIRData();
  const { projectPTWData, } = useGetPTWData();


  const gaugeData = {
    company: projectLTIRData?.company,
    target: projectLTIRData?.target,
    actual: projectLTIRData?.actual
  };

  const getCount = (
    data: any,
    params?: any
  ): number => {
    if (!data) return 0;

    return data.filter((item: any) => {
      // asWorkStatus 속성이 있는지 확인 (타입 가드)
      if (!('asWorkStatus' in item)) return false;

      // 모든 조건 체크
      const workTypeMatch = !params?.workType || item.WorkType === params.workType;
      const statusMatch = !params?.workStatus || item.asWorkStatus === params.workStatus;

      return workTypeMatch && statusMatch;
    }).length;
  };

  const safetyTableData = [
    {
      type: t('special'),
      ready: getCount(projectPTWData, {
        workType: '특별 유해위험',
        workStatus: '점검대기'
      }),
      complete: getCount(projectPTWData, {
        workType: '특별 유해위험',
        workStatus: '작업 중'
      }),
      stop: getCount(projectPTWData, {
        workType: '특별 유해위험',
        workStatus: '작업 중지'
      })
    },
    {
      type: t('focused'), ready: getCount(projectPTWData, {
        workType: '중점 유해위험',
        workStatus: '점검대기'
      }),
      complete: getCount(projectPTWData, {
        workType: '중점 유해위험',
        workStatus: '작업 중'
      }),
      stop: getCount(projectPTWData, {
        workType: '중점 유해위험',
        workStatus: '작업 중지'
      })
    },
    {
      type: t('daily'), ready: getCount(projectPTWData, {
        workType: '일상 유해위험',
        workStatus: '점검대기'
      }),
      complete: getCount(projectPTWData, {
        workType: '일상 유해위험',
        workStatus: '작업 중'
      }),
      stop: getCount(projectPTWData, {
        workType: '일상 유해위험',
        workStatus: '작업 중지'
      })
    }
  ];



  return (
    <>
      {/* Left Side - LTIR Gauge */}
      <div className="w-1/2 pr-4">
        <h3 className="text-sm font-medium mb-6 text-center">LTIR</h3>
        <div className="flex flex-col items-center">
          <div className="flex justify-between w-full mb-0 text-sm pl-4 pr-4">
            <div className="text-center">
              <div className="text-gray-600">Company</div>
              <div>{LTIRDataloading ? <Skeleton className="h-6 w-16" /> : gaugeData.company}</div>
            </div>
            <div className="text-center">
              <div className="text-blue-600">Target</div>
              <div>{LTIRDataloading ? <Skeleton className="h-6 w-16" /> : gaugeData.target}</div>
            </div>
            <div className="text-center">
              <div className="text-emerald-600">Actual</div>
              <div>{LTIRDataloading ? <Skeleton className="h-6 w-16" /> : gaugeData.actual}</div>
            </div>
          </div>
          {LTIRDataloading ? <Skeleton className="h-28 w-64" /> :
            <GaugeChart
              chartID={"safety"}
              company={gaugeData.company}
              target={gaugeData.target}
              actual={gaugeData.actual}
              companyColor={"gray-600"}
              actualColor={"emerald-600"}
              targetColor={"blue-500"}
            />}

        </div>
      </div>

      {/* Right Side - Table */}
      <div className="w-1/2 pl-4">
        <h3 className="text-sm font-medium mb-6 text-center">{t('작업허가')}</h3>
        <div className="w-full">
          <div className="grid grid-cols-4 gap-1 mb-0">
            <div className="text-center rounded-md py-1 text-xs"></div>
            <div className="text-center bg-gray-400 rounded-md py-1 text-xs font-bold text-white">{t('pending')}</div>
            <div className="text-center bg-emerald-600 rounded-md py-1 text-xs font-bold text-white">{t('completed')}</div>
            <div className="text-center bg-red-400 rounded-md py-1 text-xs font-bold text-white">{t('stopped')}</div>
          </div>
          {safetyTableData.map((row, index) => {
            const { sum, colorClass } = getRowInfo(row);
            return (
              <div key={index} className="grid grid-cols-4 mb-0">
                <div className="flex items-center  gap-2 text-xs pr-1">
                  <span className={`font-bold ${colorClass}`}>{row.type}</span>
                  <span className="text-gray-400">({sum})</span>
                </div>
                <div className="bg-gray-100 p-2">
                  <div className="text-center rounded-full w-6 h-6 flex items-center justify-center bg-white mx-auto text-gray-500 border border-gray-500 text-xs">
                    {row.ready}
                  </div>
                </div>
                <div className="bg-emerald-100 p-2">
                  <div className="text-center rounded-full w-6 h-6 flex items-center justify-center bg-white mx-auto text-emerald-600 border border-emerald-600 text-xs">
                    {row.complete}
                  </div>
                </div>
                <div className="bg-red-100 p-2">
                  <div className="text-center rounded-full w-6 h-6 flex items-center justify-center bg-white mx-auto text-red-500 border border-red-500 text-xs">
                    {row.stop}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}