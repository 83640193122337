// hooks/useDateFormat.js
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';

export const useDateFormat = () => {
  const { i18n } = useTranslation();

  const formatDate = (date:any) => {
    const locale = i18n.language;
    return dayjs(date).locale(locale).format(locale === 'ko' ? 'YYYY년 MM월 DD일' : 'MMMM D, YYYY');
  };

  return formatDate;
};