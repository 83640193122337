import * as React from "react";
import { Check, ChevronsUpDown, ChevronRight, ChevronDown, X } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

interface TreeNode {
  label: string;
  value?: string;
  children?: TreeNode[];
}

interface ComboboxProps {
  options: TreeNode[];
  value: string;
  onValueChange: (value: string) => void;
  placeholder?: string;
  className?: string;
}

export const ComboboxTree: React.FC<ComboboxProps> = ({
  options,
  value,
  onValueChange,
  placeholder = "Select...",
  className,
}) => {
  const [open, setOpen] = React.useState(false);
  const [expandedNodes, setExpandedNodes] = React.useState<string[]>([]);
  const [filteredOptions, setFilteredOptions] = React.useState<TreeNode[]>(options);

  React.useEffect(() => {
    setFilteredOptions(options); // Set initial options
  }, [options]);

  // Toggle expanded state for a node
  const toggleNode = (nodeLabel: string) => {
    setExpandedNodes((prevExpanded) =>
      prevExpanded.includes(nodeLabel)
        ? prevExpanded.filter((label) => label !== nodeLabel)
        : [...prevExpanded, nodeLabel]
    );
  };

  // Find the label of the selected value recursively
  const findLabelByValue = (nodes: TreeNode[], value: string): string | null => {
    for (const node of nodes) {
      if (node.value === value) {
        return node.label;
      }
      if (node.children) {
        const childLabel = findLabelByValue(node.children, value);
        if (childLabel) return `${childLabel}`;
      }
    }
    return null;
  };

  const selectedLabel = value ? findLabelByValue(options, value) : placeholder;

  // Filter nodes based on search term
  const handleSearch = (searchTerm: string) => {
    if (!searchTerm) {
      setFilteredOptions(options); // Reset to original options if search is empty
      return;
    }

    // Recursive search function to match any level
    const filterNodes = (nodes: TreeNode[]): TreeNode[] =>
      nodes
        .map((node) => {
          if (node.label.toLowerCase().includes(searchTerm.toLowerCase())) {
            return node;
          } else if (node.children) {
            const filteredChildren = filterNodes(node.children);
            if (filteredChildren.length > 0) {
              return { ...node, children: filteredChildren };
            }
          }
          return null;
        })
        .filter((node): node is TreeNode => node !== null);

    setFilteredOptions(filterNodes(options));
  };

  const renderOptions = (nodes: TreeNode[], level = 0) => (
    <CommandGroup>
      {nodes.map((node) => (
        <div key={node.label} className="flex flex-col">
          <CommandItem
            className="flex items-center"
            onSelect={() => {
              if (node.value) {
                onValueChange(node.value);
                setOpen(false);
              }
            }}
          >
            {/* Render expand/collapse icon only if the node has children */}
            {node.children && node.children.length > 0 ? (
              <div
                className="mr-0 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation(); // Prevents onSelect from firing
                  toggleNode(node.label);
                }}
              >
                {expandedNodes.includes(node.label) ? (
                  <ChevronDown />
                ) : (
                  <ChevronRight />
                )}
              </div>
            ) : (
              <span className="mr-2" /> // No icon for leaf nodes
            )}
            <span className="block" style={{ marginLeft: level * 4 }}>
              {node.label}
            </span>
            <Check
              className={cn(
                "ml-auto",
                value === node.value ? "opacity-100" : "opacity-0"
              )}
            />
          </CommandItem>
          {node.children && expandedNodes.includes(node.label) && (
            <div className="pl-2">{renderOptions(node.children, level + 1)}</div>
          )}
        </div>
      ))}
    </CommandGroup>
  );

  // Clear the selection
  const handleClear = () => {
    onValueChange("");
    setOpen(false);
  };

  return (
    <div className={`relative ${className}`}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between overflow-hidden whitespace-nowrap"
          >
            <span className="truncate">{selectedLabel}</span>
            {!value && (
              <ChevronsUpDown className="opacity-50" />
            ) }
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full p-0">
          <Command>
            <CommandInput placeholder="Search ..." onValueChange={handleSearch} />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              {renderOptions(filteredOptions)}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      {/* X icon for clearing selection */}
      {value && (
        <X
          className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer opacity-50 w-4 h-4"
          onClick={handleClear}
        />
      )}
    </div>
  );
};