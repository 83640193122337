
import Timeline from "@/components/common/Timeline";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter
} from "@/components/ui/card";

// 타입 정의
interface PartTimelineProps {
  oMilestoneData: Array<any> | null; // 적절한 데이터 타입으로 교체하세요
  MilestoneData: Array<any>| null; // 적절한 데이터 타입으로 교체하세요
  startDate: string| null; // ISO 날짜 문자열로 가정
  endDate: string| null; // ISO 날짜 문자열로 가정
  partName: string| null;
}

export function PartTimeLine({
  oMilestoneData,
  MilestoneData,
  startDate,
  endDate,
  partName
}: PartTimelineProps) {
  return (
    <>
      <CardHeader className="text-left">
        <CardTitle className="text-base leading-none tracking-tight">
          {partName} Milestone
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col justify-center items-center gap-2 h-[26vh]">
        {/* 첫 번째 타임라인 */}
        <div className="w-full h-1/2 flex justify-center items-center relative" style={{ top: '25%' }}>
          <Timeline
            events={oMilestoneData}
            startDate={startDate}
            endDate={endDate}
            lineColor="border-slate-900"
            flagPosition="top"
          />
        </div>

        {/* 두 번째 타임라인 */}
        <div className="w-full h-1/2 flex justify-center items-center relative" style={{ top: '-25%' }}>
          <Timeline
            events={MilestoneData}
            startDate={startDate}
            endDate={endDate}
            lineColor="border-blue-900"
            flagPosition="bottom"
          />
        </div>
      </CardContent>
      {/* 레전드 섹션 */}
      <CardFooter className="flex justify-center items-center gap-4">
        {/* 첫 번째 타임라인 레전드 */}
        <div className="flex items-center gap-2">
          <span className="w-3 h-3 rounded-full bg-slate-900"></span>
          <span className="text-sm text-gray-700">Overall Milestone</span>
        </div>

        {/* 두 번째 타임라인 레전드 */}
        <div className="flex items-center gap-2">
          <span className="w-3 h-3 rounded-full bg-blue-900"></span>
          <span className="text-sm text-gray-700">{partName} Milestone</span>
        </div>
      </CardFooter>
    </>
  );
}