

import * as React from "react"
import {
    ChartConfig,
    ChartContainer
} from "@/components/ui/chart"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { useParams } from "react-router-dom";
import api from '@/lib/axiosInstance';
import dayjs from "dayjs";
import PieChartWithString from "../common/PieChartwithString";
import { Loader2 } from "lucide-react";

interface ChartData {
    id: string;
    value: number;
    fill:string;
}


export function RemainManPieChart() {

    const [loading, setLoading] = React.useState(true);
     const { projectCode } = useParams()
    const [rawData, setRawData] = React.useState<any>(null);

    const colors = ["#03045E", "#023E8A", "#0077B6", "#0096C7", "#00B4D8", "#48CAE4", "#90E0EF"];

    function createChartData(projectPTWData: any, criteria: string): ChartData[] {
        if (!projectPTWData) {
            return [];
        }

        const groupedData = projectPTWData.reduce((acc: { [key: string]: number }, curr: any) => {
            const key = String(curr[criteria]);
            if (!acc[key]) {
                acc[key] = 0;
            }
            acc[key] += 1;
            return acc;
        }, {} as { [key: string]: number });

        return groupedData ? Object.entries(groupedData).map(([key, totalCount], index): ChartData => ({
            id: key as string,
            value: totalCount as number,
            fill: colors[index % colors.length] // 인덱스를 직접 사용

        })) : [];
    }

    const chartData = rawData && createChartData(rawData, "d_job_title_pd");
    const tableData = rawData && createChartData(rawData, "d_company_name");


    const chartConfig = chartData && chartData.reduce((config: ChartConfig, { id, value }: ChartData, index: number) => {
        config[id] = {
            label: id,
            color: colors[index % colors.length],
        };
        return config;
    }, {} as ChartConfig);


    const totalValue = React.useMemo(() => {
        return chartData && chartData.reduce((acc:any, curr:any) => acc + curr.value, 0);
    }, [chartData]);

    React.useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                let body =
                {
                    "bpname": "출입기록",
                    "lineitem": "no",
                    "record_fields": "d_company_name;d_job_title_pd;uuu_record_last_update_date;site_in_date;j_jobtitle_bpk",
                    "filter_criteria": {
                        "join": "AND",
                        "filter": [
                            {
                                "field": "site_in_check_text",
                                "value": "OUT",
                                "condition_type": "neq"
                            },
                            {
                                "field": "status",
                                "value": "Active",
                                "condition_type": "eq"
                            },
                            {
                                "field": "uuu_record_last_update_date",
                                "value": dayjs().format("MM-DD-YYYY 00:00:00"),
                                "value2":dayjs().format("MM-DD-YYYY HH:mm:ss"),
                                "condition_type": "range"
                            },
                        ]
    
                    }
                }
                const res = await api.post(`/getemsbprecord?path=${projectCode}`, body);
                setRawData(res.data.data.data.filter((v:any)=>v.site_in_date.slice(0,10) === dayjs().format("MM-DD-YYYY"))
                .map((v:any)=>({...v,d_job_title_pd:v.d_job_title_pd === null ? v.j_jobtitle_bpk :v.d_job_title_pd }))
                );

            } catch (error) {
                console.error(`Failed to fetch data for:`, error);
            }

            setLoading(false);
        };

        if (projectCode) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [projectCode]); // viewMode 추가

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <Loader2 className="w-5 h-5 animate-spin" />
            </div>
        );
    }


    return (
        <>
            <CardContent className="flex-1 flex items-center justify-center">
            {chartData && chartData?.length > 0 ?
                    <PieChartWithString
                        chartData={chartData}
                        tableData={tableData || []}
                        totalValue={totalValue || 0}
                        chartConfig={chartConfig || {}}
                        stringLabel="명"
                        tableLabel="유형별 인원"
                        tableHeader="유형"
                    />
                    :
                    <span className="text-xs text-gray-500">No data available</span>

                }
                   
            </CardContent>

        </>
    )
}
