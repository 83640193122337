
import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { useGetProjectList } from '@/hooks/useQuery';
import { useAtom } from 'jotai';
import { divisionState } from '@/atoms';
import api from '@/lib/axiosInstance';
import dayjs from 'dayjs';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import MonthPicker from '@/components/common/monthPicker';
import { Loader2 } from 'lucide-react';


interface EventsTableProps {
}
interface DataItem {
    projectcode: string;
    plan_date: string;
    [key: string]: any;
}

interface BPRecordResponse {
    data: { data: DataItem[] };
}

interface FilterCriteria {
    field: string;
    value: string;
    condition_type: string;
}


const EventsTable: React.FC<EventsTableProps> = () => {
    const [tableData, setTableData] = useState<DataItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true); // Loading state
    const { data: projectsList } = useGetProjectList();
    const [division] = useAtom(divisionState);
    const [date, setMonthDate] = useState<Date | undefined>(new Date())

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading
            try {
                if (projectsList && projectsList?.length > 0) {

                    // Fetch initial file list
                    const fileRes = await api.get(`/getFileFolderList?path=/${process.env.REACT_APP_PATH2}/file/temp/key`);
                    const tempFileList = fileRes.data.fileList

                    const newArray: DataItem[] = [];

                    // Loop through file list to fetch JSON data
                    for (const file of tempFileList) {
                        const fileDataRes = await api.get(`/getJSONtotal?path=/${process.env.REACT_APP_PATH2}/file/temp/key/${file.name}`);
                        const dataWithProjectInfo = fileDataRes.data
                            .map((v: DataItem) => ({
                                ...v,
                                tst: dayjs(v.plan_date),
                                plan_date: dayjs(v.plan_date.slice(0, 10), "MM-DD-YYYY").format('MM-DD-YYYY'), genActualDate: v.genActualDate === 'Invalid date' ? null : dayjs(v.genActualDate.slice(0, 10), "MM-DD-YYYY").format('MM-DD-YYYY'),
                                ...projectsList.find(sp => sp.projectcode === v.projectcode),
                            }))
                            .filter((v: any) => {
                                const planDate = dayjs(v.plan_date, "MM-DD-YYYY"); // Parse plan_date back to a dayjs object with the expected format
                                return (!division || v.division === division) && planDate.isSame(dayjs(date), "month");
                            })
                        newArray.push(...dataWithProjectInfo);
                    }

                    // Prepare body for batch requests
                    const body = {
                        bpname: "Project Milestone",
                        lineitem: "no",
                        filter_criteria: {
                            join: "AND",
                            filter: [
                                { field: "status", value: "Active", condition_type: "eq" },
                                { field: "plan_date", value: dayjs(date).startOf("month").format("MM-DD-YYYY"), value2: dayjs(date).endOf("month").format("MM-DD-YYYY"), condition_type: "range" },
                            ] as FilterCriteria[],
                        },
                    };

                    // Execute batch requests for BPRecord data
                    const requestArr = projectsList
                        .filter(v => (!division || v.division === division))
                        .map(data => api.post<BPRecordResponse>(`/getemsbprecord?path=${data.projectcode}`, body));

                    const res = await Promise.all(requestArr);

                    const resList = res
                        .map(({ data }, index) => data.data.data.map((item: DataItem) => ({
                            ...item,
                            projectcode: projectsList[index].projectcode,
                            projectname: projectsList[index].projectname,
                            ...projectsList.find(vf => vf.projectcode === item.projectcode),
                            plan_date: item.plan_date.slice(0, 10),
                        })))
                        .flat();

                    setTableData([...resList, ...newArray].filter(v => v.projectcode !== undefined)
                        .sort((a, b) => dayjs(b.plan_date, "MM-DD-YYYY").unix() - dayjs(a.plan_date, "MM-DD-YYYY").unix())
                    );
                }

            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false); // End loading
            }
        };


        fetchData();
    }, [division, projectsList, date]);


    const handleMonthChange = (date: Date) => {
        setMonthDate(date)
    };



    const behindCount = tableData.filter(v => (v.genActualDate
        ? dayjs(v.genActualDate, "MM-DD-YYYY")
        : dayjs()).isAfter(dayjs(v.plan_date, "MM-DD-YYYY"))).length;

    const [selectedMonth, setSelectedMonth] = useState<Date | undefined>(undefined);

    return (
        <>
            {loading ?
                (<div className="flex justify-center items-center h-full">
                    <Loader2 className="w-5 h-5 animate-spin" />
                </div>) :
                (<>
                    <CardHeader className="p-3">
                        <CardTitle className="flex justify-between">
                            <span className='text-xs'>Key Events</span>
                            <MonthPicker
                                selectedMonth={date}
                                onMonthChange={handleMonthChange}
                                placeholder="Choose a month"
                            />
                        </CardTitle>
                    </CardHeader>
                    <CardContent className="h-[22.5vh]">
                        <div className="text-center text-md font-semibold mb-2">
                            <span className="text-slate-800">{tableData.length} <span className='text-xs text-slate-500'>Events</span> </span> &nbsp;&nbsp;
                            <span className="text-red-500"> {behindCount} <span className='text-xs text-slate-500'>Behind</span> </span>
                        </div>


                        <div className='overflow-hidden'>
                            <Table className='text-xs'>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead className="w-[160px] text-left p-2 h-8">프로젝트</TableHead>
                                        <TableHead className="w-[200px] text-left p-2 h-8">마일스톤</TableHead>
                                        <TableHead className="w-[100px] text-left p-2 h-8">계획일</TableHead>
                                        <TableHead className="w-[100px] text-left p-2 h-8">실적일</TableHead>
                                    </TableRow>
                                </TableHeader>
                            </Table>
                        </div>
                        <div className='overflow-auto h-[14.5vh]'>
                            <Table className='text-xs'>
                                <TableBody>
                                    {tableData?.map((v, index) => {
                                        const planDate = dayjs(v.plan_date, "MM-DD-YYYY");
                                        const actualDate = v.genActualDate
                                            ? dayjs(v.genActualDate, "MM-DD-YYYY")
                                            : dayjs(); // use today's date if genActualDate is null
                                        const isLate = actualDate.isAfter(planDate); // check if genActualDate is later than plan_date

                                        return (
                                            <TableRow key={index} className={isLate ? 'text-red-600' : ''}>
                                                <TableCell className="w-[160px] text-left p-2 font-medium">{v.projectname}</TableCell>
                                                <TableCell className="w-[200px] text-left p-2 font-medium">{v.genMilestoneDesc}</TableCell>
                                                <TableCell className="w-[100px] text-left p-2">{v.plan_date?.slice(0, 5)}</TableCell>
                                                <TableCell
                                                    className={`text-left p-2 ${isLate ? 'text-red-600' : ''}`}
                                                    style={{ width: tableData.length > 5 ? '80px' : '100px' }}
                                                >
                                                    {v.genActualDate ? v.genActualDate.slice(0, 5) : ''}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </CardContent>

                </>)

            }
        </>)
};

export default EventsTable;

