

import * as React from "react"

import {
    Card,
    CardContent,
    CardHeader,
    CardTitle, CardFooter
} from "@/components/ui/card";
import { useGetCProgressData } from "@/lib/getData";
import SCurveChart from "@/components/common/ScurveChart";

import dayjs from "dayjs";
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(weekOfYear);

interface ProgressItem {
    date: string;
    PV: number;
    EV: number;
    cumulativePV: number;
    cumulativeEV: number | null;
    // 필요한 다른 속성들을 추가하세요.
}

interface TransformedData {
    weekProgress: ProgressItem[];
    monthProgress: ProgressItem[];
}

interface TreeNode {
    label: string;
    value?: string;
    children?: TreeNode[];
}

interface CProgressChartProps {
    transformedDataWithCumulative: TransformedData;
    chartId:string
    ChartName:string
}


const SimpleProgressChart: React.FC<CProgressChartProps> = ({
    transformedDataWithCumulative,
    chartId,
    ChartName
}) => {


    const [checked, setChecked] = React.useState<"월간" | "주간">("월간");
    const legendRef = React.useRef<HTMLDivElement | null>(null);



    const [legendContainer, setLegendContainer] = React.useState<HTMLDivElement | null>(null);


    // const legendRef = React.useRef<HTMLElement | null>(null);
    const cardContentRef = React.useRef<HTMLDivElement>(null);
    const [chartHeight, setChartHeight] = React.useState(500); // Default height


    React.useLayoutEffect(() => {
        const updateChartHeight = () => {
            if (cardContentRef.current) {
                const cardContentHeight = cardContentRef.current.clientHeight;
                // Adjust the height as needed (e.g., subtracting padding or other elements)
                setChartHeight(cardContentHeight); // Example adjustment
            }
        };
        updateChartHeight();
        window.addEventListener('resize', updateChartHeight);
        return () => window.removeEventListener('resize', updateChartHeight);
    }, [cardContentRef]);





    const chartData =  checked === "주간" ? transformedDataWithCumulative.weekProgress
    : transformedDataWithCumulative.monthProgress


    React.useEffect(() => {
        setLegendContainer(legendRef.current);
    }, [transformedDataWithCumulative]);



    const currentDateString = checked === "주간"
        ? dayjs().format("YY년 ") + dayjs().week() + "주"
        : dayjs().format("YY년 MM월");

    // Attempt to find the data point for the current date
    let currentDataPoint = chartData && chartData.find(v => v.date === currentDateString);

    // If not found or cumulativeEV is undefined, find the data point with the maximum cumulativeEV
    if (!currentDataPoint || currentDataPoint.cumulativeEV == null) {
        // Filter out data points where cumulativeEV is null or undefined
        const validDataPoints = chartData && chartData.filter(v => v.cumulativeEV != null);
    
        if (validDataPoints && validDataPoints.length > 0) {
            // Find the data point with the maximum cumulativeEV
            currentDataPoint = validDataPoints.reduce((max: any, v: ProgressItem) => {
                return v.cumulativeEV  && v.cumulativeEV > max.cumulativeEV ? v : max;
            });
        } else {
            // If no valid data points, set currentDataPoint to undefined
            currentDataPoint = undefined;
        }
    }

    const earnedValue = currentDataPoint ? currentDataPoint.cumulativeEV : 0;
    const planValue = currentDataPoint ? currentDataPoint.cumulativePV : 0;
    const dev = earnedValue && earnedValue - planValue;



    const columnItems =
    {
        week: [{
            name: "주간 계획",
            field_name: "PV",
            color: "#FEAE65",
        },
        {
            name: "주간 실적",
            field_name: "EV",
            color: '#48CAE4'
        },]
        ,
        month: [{
            name: "월간 계획",
            field_name: "PV",
            color: "#FEAE65",
        },
        {
            name: "월간 실적",
            field_name: "EV",
            color: '#48CAE4',
        },]
    }

    const lineItems = [
        {
            name: "누적 계획",
            field_name: "cumulativePV",
            color: "#FEAE65",
        },
        {
            name: "누적 실적",
            field_name: "cumulativeEV",
            color: '#48CAE4',
        },
    ];


    return (
        <>
            <div className="w-full overflow-y-auto">

                <CardHeader className="text-left">
                    <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                        <span>{ChartName}</span>
                        <div
                            className="flex-grow text-xs flex justify-end"
                            style={{
                                zIndex: 99999,
                                height: '40px',
                                maxWidth: '550px',
                                whiteSpace: 'nowrap',
                            }}
                            ref={legendRef}
                        ></div>
                    </CardTitle>
                </CardHeader>
                {/* <CardContent className="h-[40vh] flex-grow flex items-center justify-center "> */}
                <CardContent className="grid grid-cols-1 md:grid-cols-3 gap-4 h-[30vh]" ref={cardContentRef}>

                    {/* Left Section - Full width on mobile, 1/3 on larger screens */}
                    <div className="col-span-1 h-full relative flex flex-col">
                        <div className="flex-1 flex items-center justify-center">
                            <div className="flex items-center space-x-8">
                                {/* Plan */}
                                <div className="flex flex-col items-center">
                                    <label className="text-sm mb-1 text-gray-600">Plan</label>
                                    <div className="text-2xl text-center">{planValue && planValue?.toFixed(2)}%</div>
                                </div>
                                {/* Separator */}
                                <div className="h-12 border-l border-gray-300 hidden md:block"></div>
                                {/* Actual */}
                                <div className="flex flex-col items-center">
                                    <label className="text-sm mb-1 text-gray-600">Actual</label>
                                    <div className="text-2xl text-center text-blue-800">{earnedValue && earnedValue?.toFixed(2)}%</div>
                                </div>
                                {/* Separator */}
                                <div className="h-12 border-l border-gray-300 hidden md:block"></div>
                                {/* Dev */}
                                <div className="flex flex-col items-center">
                                    <label className="text-sm mb-1  text-gray-600">Dev.</label>
                                    <div
                                        className={`text-2xl text-center ${dev && dev >= 0 ? 'text-emerald-600' : 'text-red-600'}`}
                                    >{dev && dev?.toFixed(2)}%</div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* Right Section - Full width on mobile, 2/3 on larger screens */}
                    <div className="col-span-1 md:col-span-2 grid gap-0 h-[20vh]">

                        {transformedDataWithCumulative && transformedDataWithCumulative.monthProgress &&
                            <SCurveChart
                                data={chartData}
                                columnItems={columnItems}
                                lineItems={lineItems}
                                chart_id={chartId}
                                height={chartHeight}
                                category='date'
                                lineLabel={true}
                                unit={"%"}
                                legendContainer={legendContainer}
                                maxYaxis={110}
                                checked={checked}
                                onCheckedChange={setChecked}
                            />
                        }
                    </div>
                </CardContent>
            </div>
        </>
    )
}

export default SimpleProgressChart;