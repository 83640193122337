import * as React from "react"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import dayjs from "dayjs";
import { LandscapeImg } from "@/components/photo/landscape";
import { PhotoHistroy } from "@/components/photo/photohistory";
import { WeeklyPhoto } from "@/components/photo/weeklyphoto";


export default function PhotoPage() {
  return (
<div className="flex flex-1 flex-col gap-4 p-4 h-full"> {/* h-full 추가 */}
  <div className="grid auto-rows-min gap-4 md:grid-cols-3">
    <Card className="col-span-1">
      <CardHeader className="text-left">
        <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
          <span>전경사진</span>
        </CardTitle>
      </CardHeader>
      <CardContent className="relative flex h-[55vh] overflow-hidden">
        <LandscapeImg />
      </CardContent>
    </Card>
    
    <Card className="col-span-2 h-full">
      <CardHeader className="text-left">
        <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
          <span>주요공정 Weekly</span>
        </CardTitle>
      </CardHeader>
      <CardContent className="flex h-[calc(55vh-4rem)] items-start justify-between px-6 py-2">
        <WeeklyPhoto/>
      </CardContent>
    </Card>
  </div>

  <Card className="flex-1"> {/* min-h 관련 클래스들을 제거하고 flex-1만 사용 */}
    <CardHeader className="text-left">
      <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
        <span>Photo History</span>
      </CardTitle>
    </CardHeader>
    <CardContent className="flex items-center justify-between px-6 py-2">
      <PhotoHistroy/>
    </CardContent>
  </Card>
</div>
  )
}