
import React, { useEffect, useState } from 'react';
import api from '@/lib/axiosInstance';
import { useParams } from "react-router-dom";
import { Loader2 } from 'lucide-react';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import dayjs from 'dayjs';

export function IssueHtml() {
    const [loading, setLoading] = useState(true);
const { projectCode } = useParams();


    const [issueData, setIssueData] = useState<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            let body = {
                "bpname": "품질 이슈사항",
                "lineitem": "no",
                "filter_condition": "status=Active",
            };

            try {
                const res = await api.post(`/getemsbprecord?path=${projectCode}`, body);
                setIssueData(res.data.data.data.sort((a: any, b: any) => b.record_no - a.record_no).reverse()[0]);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setLoading(false);
            }
        };

        if (projectCode) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [projectCode]);

    if (loading) {
        return (
            <Card className="row-span-1 md:col-span-12 col-span-12 rounded-xl border bg-card text-card-foreground shadow">
                <CardHeader className="text-left">
                    <CardTitle className="text-base leading-none tracking-tight">품질 Issue 사항</CardTitle>
                </CardHeader>
                <CardContent className="flex items-center justify-center overflow-hidden">
                    <Loader2 className="w-5 h-5 animate-spin" />
                </CardContent>
            </Card>
        );
    }

    return (
        <Card className="row-span-1 md:col-span-12 col-span-12 rounded-xl border bg-card text-card-foreground shadow overflow-visible">
            <CardHeader className="text-left">
                <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                    <span>품질 Issue 사항</span>
                    <span className="ml-auto text-xs">{dayjs(issueData?.genSubmitDO).format("YYYY년 MM월 DD일")}</span>
                </CardTitle>
            </CardHeader>
            <CardContent className="flex items-center justify-center overflow-hidden">
                {issueData ? (
                    <div className="w-full">
                        <div
                            className="text-sm"
                            dangerouslySetInnerHTML={{ __html: issueData.genIssueDescRT }}
                        />
                    </div>
                ) : (
                    <div className="flex justify-center items-center h-full text-xs text-slate-700">
                        No data available.
                    </div>
                )}
            </CardContent>
        </Card>
    );
}