
import * as React from "react";
import { Pie, PieChart, Label, Sector } from "recharts";
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"

interface PieChartProps {
    chartData: { id: string; value: number; fill: string }[];
    tableData: { id: string; value: number; }[];
    totalValue: number;
    chartConfig: any;
    stringLabel: string;
    tableLabel: string;
    tableHeader: string;
}

interface ViewBox {
  cx: number;
  cy: number;
  innerRadius: number;
  outerRadius: number;
  startAngle: number;
  endAngle: number;
  width: number;
  height: number;
}
interface LabelProps {
  viewBox: ViewBox;
  value?: string | number;
}


const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, id, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 20) * cos;
    const my = cy + (outerRadius + 20) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 10;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 5} y={ey} textAnchor={textAnchor} fill="#333" fontSize={"10px"}>{`${id} ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 5} y={ey} dy={10} textAnchor={textAnchor} fill="#999" fontSize={"10px"}>
                {`(${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

const PieChartWithString: React.FC<PieChartProps> = ({ chartData, tableData, totalValue, chartConfig, stringLabel, tableLabel,tableHeader }) => {
    const [activeIndex, setActiveIndex] = React.useState<number | null>(0); // 초기값을 첫 번째 항목으로 설정하고 null도 허용
    const [isHovered, setIsHovered] = React.useState(false); // hover 상태 추가
    const [open, setOpen] = React.useState(false); // State to control the dialog

    const onPieEnter = (_: any, index: number) => setActiveIndex(index);

    // chartData가 변경될 때마다 첫 번째 항목을 활성화하도록 설정
    React.useEffect(() => {
        if (chartData && chartData.length > 0) {
            setActiveIndex(0);
        }
    }, [chartData]);

    return (
        <>
          <ChartContainer
            config={chartConfig}
            // className="mx-auto min-h-[200px] min-w-[300px] [&_.recharts-pie-label-text]:fill-foreground"
            className="mx-auto aspect-square max-h-[200px] w-full"          >
            <PieChart>
            <ChartTooltip
              content={<ChartTooltipContent nameKey="id" hideLabel />}
            />
              <Pie
                data={chartData}
                dataKey="value"
                nameKey="id"
                innerRadius={50}
                outerRadius={75}
                strokeWidth={1}
                labelLine={false} 
                label={({ cx, cy, midAngle, outerRadius, value, name }) => {
                  // Calculate the position of the label
                  const RADIAN = Math.PI / 180;
                  const labelRadius = outerRadius +5; // Distance from the outer edge
                  const x = cx + labelRadius * Math.cos(-midAngle * RADIAN);
                  const y = cy -5+ labelRadius * Math.sin(-midAngle * RADIAN);

                  if (value / totalValue< 0.05) return null; // 5% 미만이면 라벨 표시하지 않음

          
                  // Calculate percentage
                  
                  const percentage = ((value / totalValue) * 100).toFixed(2); // Calculating percentage

                  
          
                  return (
          
                      <text
                          x={x}
                          y={y}
                          // fill="#fff"
                          textAnchor={x > cx ? "start" : "end"} // Anchors the text depending on its position
                          dominantBaseline="central"
                          className='text-[10px] fill-gray-800'
                      >
                          {/* <tspan x={x} dy="-0.4em">{name}</tspan> */}
                          <tspan className='text-[10px]'x={x} dy="0em">{`${name} `}</tspan>
                          <tspan className='text-[10px]' x={x} dy="1.2em">{`${value.toFixed(0)} (${percentage}%)`}</tspan>
                          {/* <tspan x={x} dy="1.2em">{`(${percentage}%)`}</tspan> */}
                      </text>

             
                  );
              }}
                // activeIndex={activeIndex as number | undefined}
                // activeShape={renderActiveShape}
                // onMouseEnter={onPieEnter}
                // onMouseLeave={() => setActiveIndex(null)}
              >
                
                <Label
                  content={({ viewBox }: any) => {
                    if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                      const fontSize = totalValue > 9999 ? "text-xl" : "text-2xl";
    
                      return (
                        <text
                          x={viewBox.cx}
                          y={viewBox.cy}
                          textAnchor="middle"
                          dominantBaseline="middle"
                          style={{
                            cursor: "pointer",
                            // textDecoration: isHovered ? "underline" : "none",
                          }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          onClick={() => setOpen(true)} // Open dialog on click
                        >
                          <tspan
                            x={viewBox.cx}
                            y={viewBox.cy}
                            className={`fill-foreground ${fontSize} font-semibold`}
                          >
                            {totalValue.toLocaleString()}
                          </tspan>
    
                          <tspan
                            x={viewBox.cx}
                            y={(viewBox.cy || 0) + 20}
                            className="fill-muted-foreground"
                          >
                            {stringLabel}
                          </tspan>
                        </text>
                      );
                    }
                    return null;
                  }}
                />
              </Pie>
            </PieChart>
          </ChartContainer>
    
          {/* Place Dialog outside of PieChart */}
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>{tableLabel} 정보</DialogTitle>
              </DialogHeader>
              <div className='overflow-hidden'>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[300px]">{tableHeader}</TableHead>
                    <TableHead className="text-right">{tableLabel}</TableHead>
                  </TableRow>
                </TableHeader>
                </Table>
                </div>
                <div className='overflow-auto h-[45vh]'>
                <Table>
                <TableBody>
                  {tableData?.map((v, index) => (
                    <TableRow key={index}>
                      <TableCell className="font-medium">{v.id}</TableCell>
                      <TableCell className="text-right">{v.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              </div>
            </DialogContent>
          </Dialog>
        </>
      );
    };
    
    export default PieChartWithString;