


import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import JSZip from 'jszip';
import PDFTronSquadLast from './viewerSquadLast';
import InfoBoxSquadLast from './infoBoxLast';
import { getSessionUsername } from '@/components/common/getName';
import api from '@/lib/axiosInstance';
import { useParams, useLocation } from 'react-router-dom';
import { useToast } from "@/hooks/use-toast";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const MyDocumentViewerModalSquadLast = ({ }) => {
  let query = useQuery();

  const { projectCode } = useParams();

  const recordNo = query.get("recordNo");
  const SQDTitle = query.get("title");


  const [username, setUsername] = useState(null);
  const { toast } = useToast();

  useEffect(() => {
    const fetchUsername = async () => {
      const name = await getSessionUsername();
      setUsername(name);
    };

    fetchUsername();
  }, []);


  const [pdftronInstance, setPdftronInstance] = useState(null);

  // PDFTron 컴포넌트에서 인스턴스를 업데이트하는 함수
  const updatePdftronInstance = (instance) => {
    setPdftronInstance(instance);
  };

  const [documentList, setDocumentList] = useState(null);
  const [loginUserName, setLoginUserName] = useState(null);
  const [selectedDocId, setSelectedDocId] = useState('');
  const [selectedDocTitle, setSelectedDocTitle] = useState('');
  const [selectedDocBlob, setSelectedDocBlob] = useState(null);



  useEffect(() => {
    async function fetchData() {
      try {
        if (projectCode && recordNo) {
          const res = await api.get(`/getrecordfilelist2?project=${projectCode}&record=${recordNo}`);
          if (res.data.success) {
            setDocumentList(res.data.data);
            setLoginUserName(res.data.userName);
            if (res.data.data.length > 0) {
              selectDocument(res.data.data[0].id, res.data.data[0].title);

            }
          }
        }
      } catch (error) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "An error occurred. Please contact the system administrator.${error}",
        })
        // Handle error appropriately
        setDocumentList([]);
      }
    }
    fetchData();
  }, [projectCode, recordNo]); // Correct dependency array


  const selectDocument = async (docId, docTitle) => {
    setSelectedDocId(docId);
    setSelectedDocTitle(docTitle);
    const docBlob = await getDocumentBlob(docId);
    setSelectedDocBlob(docBlob);
  };

  const getDocumentBlob = async (docId) => {
    const response = await api.get(`/download/${docId}`, { responseType: 'blob' });

    // ZIP 파일 압축 해제
    const jszip = new JSZip();
    const zipContent = await jszip.loadAsync(response.data); // ZIP 파일 로드

    // 첫 번째 파일의 내용을 얻음
    const fileNames = Object.keys(zipContent.files);
    if (fileNames.length === 0) {
      throw new Error('ZIP file is empty');
    }

    const firstFileName = fileNames[0];
    const firstFile = zipContent.files[firstFileName];
    return await firstFile.async('blob');
  };

  useEffect(() => {
    // Check if loginUserName matches username and alert if not
    if (loginUserName && username && loginUserName !== username) {
      toast({
        variant: "destructive",
        title: "Unauthorized",
        description: `You do not have permission to view this document.`,
      })
    }
  }, [loginUserName, username]);


  if (loginUserName && username && loginUserName === username) {
    return (
      <Box display="flex">
        <Box flex={1} mr={1} sx={{ marginRight: 0 }}>
          <PDFTronSquadLast initialDocBlob={selectedDocBlob} documenTitle={selectedDocTitle} docuID={selectedDocId} username={username}
            onInstanceReady={setPdftronInstance} />
        </Box>
        <InfoBoxSquadLast SQDTitle={SQDTitle} pdftronInstance={pdftronInstance} recordNo={recordNo} documentList={documentList} onSelectDocument={selectDocument} />
      </Box>
    );
  } else {
    return null;  // Optionally, return null or some other placeholder if not authorized
  }
};

export default MyDocumentViewerModalSquadLast;
