

import * as React from "react"
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle
} from "@/components/ui/card";
import { Combobox } from "@/components/common//comboBox";
import BulletChart from "@/components/common/BulletChart";
import dayjs from "dayjs";
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import api from "@/lib/axiosInstance";
import { useParams } from "react-router-dom";
import SCurveChartEMC from "../common/ScurveChartEMS";


dayjs.extend(weekOfYear);
dayjs.extend(isSameOrBefore);
interface ProgressItem {
    date: string;
    PV: number;
    EV: number;
    cumulativePV: number;
    cumulativeEV: number | null;
    // 필요한 다른 속성들을 추가하세요.
}

interface TransformedData {
    weekProgress: ProgressItem[];
    monthProgress: ProgressItem[];
}

interface TreeNode {
    label: string;
    value?: string;
    children?: TreeNode[];
}

interface DataItem {
    DISC_NM: string;
    CUT_OFF_DT: string;
    PLAN_PROG: number;
    ACTUAL_PROG: number;
}

interface BulletDataItem {
    공종: string;
    PV: number;
    EV: number;
    Dev: number;
}


interface ChartDataItem {
    date: string;
    cumulativePV: number;
    cumulativeEV: number;
    PV: number;
    EV: number;
}



export function ManhourChartWithBullet() {


    const [selectedDisciplineValue, setSelectedDisciplineValue] = React.useState<string>("OVERALL");
    const [optionsDiscipline, setOptionsDiscipline] = React.useState<any>(null);
    const [chartSourceData, setChartSourceData] = React.useState<any>(null);

    const legendRef = React.useRef<HTMLDivElement | null>(null);
     const { projectCode } = useParams()


    const filtedData = selectedDisciplineValue === "OVERALL" || selectedDisciplineValue === "" ? chartSourceData :
        chartSourceData && chartSourceData.filter((v: any) => v.discipline === selectedDisciplineValue)

    const sortedData = filtedData
        ? [...filtedData].sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime()).reverse()
        : [];
    



    type DataItem = {
        discipline: string;
        date: string;
        EV: number;
        PV: number;
    };

    type GroupedDataItem = {
        discipline: string;
        date: string;
        EV: number;
        PV: number;
    };

    const groupAndSum = (data: DataItem[]): GroupedDataItem[] => {
        const groupedMap: Record<string, GroupedDataItem> = {};

        data.forEach((item) => {
            const key = `${item.date}`;
            if (!groupedMap[key]) {
                groupedMap[key] = { discipline: item.discipline, date: item.date, EV: 0, PV: 0 };
            }
            groupedMap[key].EV += item.EV ? item.EV : 0;
            groupedMap[key].PV += item.PV ? item.PV : 0;
        });

        return Object.values(groupedMap);
    };

    const groupedData = sortedData && groupAndSum(sortedData)

    const groupedData2 = chartSourceData
    ? groupAndSum([...chartSourceData].sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime()).reverse())
    : [];




    const calculateCumulativeValues = (data: any) => {
        let cumulativePV = 0;
        let cumulativeEV = 0;
        return data.map((item: any) => {
            cumulativePV += item.PV;
            cumulativeEV += item.EV || 0;
            return {
                ...item,
                cumulativePV,
                cumulativeEV,
            };
        });
    };

    const chartData = groupedData && calculateCumulativeValues(groupedData)

    const bulletDataSource = groupedData2 && calculateCumulativeValues(groupedData2)

    const maxCumulativePV = chartData ? Math.max(...chartData.map((item: any) => item.cumulativePV)) : 0;
    const maxCumulativeEV = chartData ? Math.max(...chartData.map((item: any) => item.cumulativeEV)) : 0;

    const maxCumulativeValue = Math.max(maxCumulativePV, maxCumulativeEV);


    const currentDateString = dayjs().format("YYYY-MM-01");

    // Attempt to find the data point for the current date
    let currentDataPoint = chartData && chartData.find((v: any) => v.date === currentDateString);

    // If not found or cumulativeEV is undefined, find the data point with the maximum cumulativeEV
    if (!currentDataPoint || currentDataPoint.cumulativeEV == null) {
        // Filter out data points where cumulativeEV is null or undefined
        const validDataPoints = chartData && chartData.filter((v: any) => v.cumulativeEV != null);

        if (validDataPoints && validDataPoints.length > 0) {
            // Find the data point with the maximum cumulativeEV
            currentDataPoint = validDataPoints.reduce((max: any, v: ProgressItem) => {
                return v.cumulativeEV && v.cumulativeEV > max.cumulativeEV ? v : max;
            });
        } else {
            // If no valid data points, set currentDataPoint to undefined
            currentDataPoint = undefined;
        }
    }

    const earnedValue = currentDataPoint ? (currentDataPoint.cumulativeEV / maxCumulativePV) * 100 : 0;
    const planValue = currentDataPoint ? (currentDataPoint.cumulativePV / maxCumulativePV) * 100 : 0;
    const dev = earnedValue && earnedValue - planValue;


    React.useEffect(() => {
        const fetchData = async () => {

            if (projectCode) {
                const res = await api.get(`/getemsJSON?path=/${process.env.REACT_APP_PATH2}/file/emsmanhour/${projectCode}_manhour.json`);
                const emsData = res.data;

                const { act, plan, combobox } = emsData;

                const add_overall_combox = [...combobox].map((com) =>
                    com.toUpperCase()
                );
                add_overall_combox.unshift("OVERALL");
                setOptionsDiscipline(add_overall_combox.map(value => ({ value, label: value })));

                const planData = plan.map((v: any) => ({
                    discipline: v.Disc_txt,
                    date: v.months,
                    PV: v.days
                }))
                    .sort((a: any, b: any) => b.discipline - a.discipline)

                const actualData = act.map((v: any) => ({
                    discipline: v.Disc_txt,
                    date: v.emsWorkingDate_month,
                    EV: v.emsTMSActualHour
                }))
                    .sort((a: any, b: any) => b.discipline - a.discipline)

                setChartSourceData([...planData, ...actualData])

            }


        };
        fetchData();
    }, [projectCode]);




    const columnItems =
        [{
            name: "월간 계획",
            field_name: "PV",
            color: "#FEAE65",
        },
        {
            name: "월간 실적",
            field_name: "EV",
            color: '#48CAE4',
        },]


    const lineItems = [
        {
            name: "누적 계획",
            field_name: "cumulativePV",
            color: "#FEAE65",
        },
        {
            name: "누적 실적",
            field_name: "cumulativeEV",
            color: '#48CAE4',
        },
    ];
    const cardContentRef = React.useRef<HTMLDivElement>(null);
    const [chartHeight, setChartHeight] = React.useState(500); // Default height
    React.useLayoutEffect(() => {
        const updateChartHeight = () => {
            if (cardContentRef.current) {
                const cardContentHeight = cardContentRef.current.clientHeight;
                // Adjust the height as needed (e.g., subtracting padding or other elements)
                setChartHeight(cardContentHeight); // Example adjustment
            }
        };
        updateChartHeight();
        window.addEventListener('resize', updateChartHeight);
        return () => window.removeEventListener('resize', updateChartHeight);
    }, [cardContentRef]);


    const handleDisciplineValueChange = (value: string) => {
        setSelectedDisciplineValue(value);
    };

    const [legendContainer, setLegendContainer] = React.useState<HTMLDivElement | null>(null);

    React.useEffect(() => {
        setLegendContainer(legendRef.current);
    }, [chartData]);


    const find = bulletDataSource?.findIndex((v: any) => v.date === dayjs().format("YYYY-MM-01")) ?? -1;

    const lastProgressData = (find !== -1 && bulletDataSource)
        ? bulletDataSource.slice(0, find)
        : bulletDataSource; // 조건에 맞는 값이 없으면 전체 데이터 반환



    ///bulletchart data 1 
    const groupedDataforBullet = lastProgressData && lastProgressData.reduce((acc: any, item:any) => {
        const { discipline, PV, EV } = item;
        const sumWeight = lastProgressData ? lastProgressData.filter((v:any) => v.discipline === discipline).reduce((acc:any, v:any) => acc + v.PV, 0) : 0;

        if (!acc[discipline]) {
            acc[discipline] = { discipline: discipline, plan: 0, actual: 0 };
        }

        acc[discipline].plan += PV / sumWeight;
        acc[discipline].actual += EV / sumWeight;

        return acc;
    }, {});



    const itemsPerRow = 4;
    // Convert object to array using useMemo
    const bulletDataArray = React.useMemo(() => {
        return groupedDataforBullet ? Object.values(groupedDataforBullet) : [];
    }, [groupedDataforBullet]);

    // Slice the array into rows using useMemo
    const rows = React.useMemo(() => {
        if (!bulletDataArray.length) return [];
        const result = [];
        for (let i = 0; i < bulletDataArray.length; i += itemsPerRow) {
            result.push(bulletDataArray.slice(i, i + itemsPerRow));
        }
        return result;
    }, [bulletDataArray, itemsPerRow]);



    return (
        <>

            <Card className="h-[50vh] w-full rounded-xl border bg-card text-card-foreground shadow overflow-y-auto">

                <CardHeader className="text-left">
                    <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                        <span>Construction Progress</span>
                        <div
                            className="flex-grow text-xs flex justify-end"
                            style={{
                                zIndex: 99999,
                                height: '40px',
                                maxWidth: '550px',
                                whiteSpace: 'nowrap',
                            }}
                            ref={legendRef}
                        ></div>
                    </CardTitle>
                </CardHeader>
                {/* <CardContent className="h-[40vh] flex-grow flex items-center justify-center "> */}
                <CardContent className="grid grid-cols-1 md:grid-cols-3 gap-4 h-[40vh]" ref={cardContentRef}>

                    {/* Left Section - Full width on mobile, 1/3 on larger screens */}
                    <div className="col-span-1 h-full relative flex flex-col">

                        {/* Comboboxes row */}
                        <div className="flex flex-wrap md:space-x-4 md:flex-nowrap mb-4">
                            <div className="w-full">
                                <label htmlFor="combo2" className="text-xs mb-1">Discipline</label>
                                <Combobox
                                    options={optionsDiscipline ?? []}
                                    value={selectedDisciplineValue}
                                    onValueChange={handleDisciplineValueChange}
                                    placeholder="Select a Discipline"
                                    className="w-full truncate"
                                />
                            </div>

                        </div>

                        <div className="flex-1 flex items-center justify-center">
                            <div className="flex items-center space-x-8">
                                {/* Plan */}
                                <div className="flex flex-col items-center">
                                    <label className="text-sm mb-1 text-gray-600">Productivity</label>
                                    <div className="text-2xl text-center">{(earnedValue / planValue).toFixed(2)}</div>
                                </div>

                            </div>

                        </div>


                        <div className="flex-1 flex items-center justify-center">
                            <div className="flex items-center space-x-8">
                                {/* Plan */}
                                <div className="flex flex-col items-center">
                                    <label className="text-sm mb-1 text-gray-600">Plan</label>
                                    <div className="text-2xl text-center">{planValue && planValue?.toFixed(2)}%</div>
                                </div>
                                {/* Separator */}
                                <div className="h-12 border-l border-gray-300 hidden md:block"></div>
                                {/* Actual */}
                                <div className="flex flex-col items-center">
                                    <label className="text-sm mb-1 text-gray-600">Actual</label>
                                    <div className="text-2xl text-center text-blue-800">{earnedValue && earnedValue?.toFixed(2)}%</div>
                                </div>
                                {/* Separator */}
                                <div className="h-12 border-l border-gray-300 hidden md:block"></div>
                                {/* Dev */}
                                <div className="flex flex-col items-center">
                                    <label className="text-sm mb-1  text-gray-600">Dev.</label>
                                    <div
                                        className={`text-2xl text-center ${dev && dev >= 0 ? 'text-emerald-600' : 'text-red-600'}`}
                                    >{dev && dev?.toFixed(2)}%</div>
                                </div>
                            </div>

                        </div>

                    </div>

                    {/* Right Section - Full width on mobile, 2/3 on larger screens */}
                    <div className="col-span-1 md:col-span-2 grid gap-0 h-[30vh]">

                        {chartData &&
                            <SCurveChartEMC
                                data={chartData}
                                columnItems={columnItems}
                                lineItems={lineItems}
                                chart_id={"C-Scurve"}
                                height={chartHeight}
                                category='date'
                                lineLabel={true}
                                unit={"mh"}
                                legendContainer={legendContainer}
                                maxYaxis={maxCumulativeValue * 1.05}

                            />
                        }
                    </div>

                </CardContent>
            </Card>

            <Card className=" w-full  rounded-xl border bg-card text-card-foreground shadow overflow-y-auto">
                <CardHeader className="text-left">
                    <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                        <span>Construction Progress (Discipline)</span>

                    </CardTitle>

                </CardHeader>
                <CardContent className="flex-grow items-center justify-center space-y-4">
                    {rows.map((row, rowIndex) => (
                        <div key={rowIndex} className="flex justify-around w-full space-x-4">
                            {row.map((item: any, index: any) => {
                                const difference = (item.actual - item.plan) * 100;

                                return (
                                    <div key={index} className="flex flex-col items-start space-y-1 w-full">
                                        <span className="text-sm font-semibold  text-gray-700">{item.discipline}</span>
                                        <div className="flex space-x-2">
                                            <span className="text-xs text-gray-500">계획: {Math.round(item.plan * 100)}%</span>
                                            <span className="text-xs text-gray-500">실적: {Math.round(item.actual * 100)}%</span>
                                            <span className="text-xs text-gray-500">차이: {Math.round(difference)}%</span>
                                        </div>
                                        <BulletChart
                                            spark={false}
                                            maxValue={100}
                                            targetValue={item.plan * 100}
                                            actualValue={item.actual * 100}
                                            height={75}
                                            divId={`${item.discipline}`}
                                            unit="%"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </CardContent>
            </Card>
        </>
    )
}