import { useQuery } from '@tanstack/react-query';
import { fetchProjectList } from '@/lib/getData';
import { ProjectsList } from '@/types';

export function useGetProjectList() {
    return useQuery<ProjectsList[], Error>({
      queryKey: ['projectList'],
      queryFn: fetchProjectList,
      staleTime: 1000 * 60 * 5, // 5분 동안 데이터가 신선하다고 간주
      gcTime: 1000 * 60 * 30, // 30분 동안 캐시 유지
    });
  }