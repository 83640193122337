"use client"

import React from "react"
import { Bar, BarChart, LabelList, XAxis, YAxis } from "recharts"
import {
  ChartConfig as BaseChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart"
import {useAtomValue} from 'jotai'
import { sidebarCollapsedState   } from '@/atoms';

// Define types for your chart data and configuration
interface ChartDataItem {
  month: string
  [key: string]: number | string
}

interface ChartConfigItem {
  label: string
  color: string
}

type ChartConfig = Record<string, ChartConfigItem>

// Define props for the BarChartH component
interface BarChartHProps {
  data: ChartDataItem[]
  config: ChartConfig
  dataKeys: string[]
}

// Helper function to determine the border radius of the bars
function getRadius(index: number, totalBars: number): [number, number, number, number] {
  if (index === 0) return [4, 0, 0, 4] // First bar
  if (index === totalBars - 1) return [0, 4, 4, 0] // Last bar
  return [0, 0, 0, 0] // Middle bars
}

export function BarChartH({ data, config, dataKeys }: BarChartHProps) {
  const sidebarCollapsed = useAtomValue(sidebarCollapsedState) ?? undefined;

  console.log(sidebarCollapsed)

  return (
    <ChartContainer config={config as BaseChartConfig}
    className={`mx-auto aspect-square ${sidebarCollapsed ? "max-h-[220px]":"max-h-[170px]"} w-full p-2`}>
    

      <BarChart
        accessibilityLayer
        data={data}
        layout="vertical"
        margin={{ left: 30 }}
      >
        <XAxis type="number" hide />
        <YAxis
          dataKey="disc"
          type="category"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          tickFormatter={(value: string) => value}
        />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent />}
        />
        {dataKeys.map((key, index) => (
          <Bar
            key={key}
            dataKey={key}
            stackId="a"
            fill={config[key]?.color || "var(--default-color)"}
            radius={getRadius(index, dataKeys.length)}
            >
                <LabelList 
                    position="inside"
                    className="fill-white"
                    fontSize={12}
                />
            </Bar>

          
        ))}
      </BarChart>
    </ChartContainer>
  )
}