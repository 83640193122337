
import React, { useEffect, useState } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import { useGetProjectList } from '@/hooks/useQuery';
import { setKey, fromLatLng, fromAddress, setLanguage } from "react-geocode";
import { divisionState } from '@/atoms';
import { useAtom } from 'jotai';
import { Loader2 } from 'lucide-react';

am5.addLicense("AM5M363108325");
am5.addLicense("AM5C363108325");


var countrycodedict = {
    "ad": "Andorra",
    "ae": "United Arab Emirates",
    "af": "Afghanistan",
    "ag": "Antigua and Barbuda",
    "ai": "Anguilla",
    "al": "Albania",
    "am": "Armenia",
    "ao": "Angola",
    "aq": "Antarctica",
    "ar": "Argentina",
    "as": "American Samoa",
    "at": "Austria",
    "au": "Australia",
    "aw": "Aruba",
    "ax": "Åland Islands",
    "az": "Azerbaijan",
    "ba": "Bosnia and Herzegovina",
    "bb": "Barbados",
    "bd": "Bangladesh",
    "be": "Belgium",
    "bf": "Burkina Faso",
    "bg": "Bulgaria",
    "bh": "Bahrain",
    "bi": "Burundi",
    "bj": "Benin",
    "bl": "Saint Barthélemy",
    "bm": "Bermuda",
    "bn": "Brunei",
    "bo": "Bolivia",
    "bq": "Caribbean Netherlands",
    "br": "Brazil",
    "bs": "Bahamas",
    "bt": "Bhutan",
    "bv": "Bouvet Island",
    "bw": "Botswana",
    "by": "Belarus",
    "bz": "Belize",
    "ca": "Canada",
    "cc": "Cocos (Keeling) Islands",
    "cd": "DR Congo",
    "cf": "Central African Republic",
    "cg": "Republic of the Congo",
    "ch": "Switzerland",
    "ci": "Côte d'Ivoire (Ivory Coast)",
    "ck": "Cook Islands",
    "cl": "Chile",
    "cm": "Cameroon",
    "cn": "China",
    "co": "Colombia",
    "cr": "Costa Rica",
    "cu": "Cuba",
    "cv": "Cape Verde",
    "cw": "Curaçao",
    "cx": "Christmas Island",
    "cy": "Cyprus",
    "cz": "Czechia",
    "de": "Germany",
    "dj": "Djibouti",
    "dk": "Denmark",
    "dm": "Dominica",
    "do": "Dominican Republic",
    "dz": "Algeria",
    "ec": "Ecuador",
    "ee": "Estonia",
    "eg": "Egypt",
    "eh": "Western Sahara",
    "er": "Eritrea",
    "es": "Spain",
    "et": "Ethiopia",
    "eu": "European Union",
    "fi": "Finland",
    "fj": "Fiji",
    "fk": "Falkland Islands",
    "fm": "Micronesia",
    "fo": "Faroe Islands",
    "fr": "France",
    "ga": "Gabon",
    "gb": "United Kingdom",
    "gb-eng": "England",
    "gb-nir": "Northern Ireland",
    "gb-sct": "Scotland",
    "gb-wls": "Wales",
    "gd": "Grenada",
    "ge": "Georgia",
    "gf": "French Guiana",
    "gg": "Guernsey",
    "gh": "Ghana",
    "gi": "Gibraltar",
    "gl": "Greenland",
    "gm": "Gambia",
    "gn": "Guinea",
    "gp": "Guadeloupe",
    "gq": "Equatorial Guinea",
    "gr": "Greece",
    "gs": "South Georgia",
    "gt": "Guatemala",
    "gu": "Guam",
    "gw": "Guinea-Bissau",
    "gy": "Guyana",
    "hk": "Hong Kong",
    "hm": "Heard Island and McDonald Islands",
    "hn": "Honduras",
    "hr": "Croatia",
    "ht": "Haiti",
    "hu": "Hungary",
    "id": "Indonesia",
    "ie": "Ireland",
    "il": "Israel",
    "im": "Isle of Man",
    "in": "India",
    "io": "British Indian Ocean Territory",
    "iq": "Iraq",
    "ir": "Iran",
    "is": "Iceland",
    "it": "Italy",
    "je": "Jersey",
    "jm": "Jamaica",
    "jo": "Jordan",
    "jp": "Japan",
    "ke": "Kenya",
    "kg": "Kyrgyzstan",
    "kh": "Cambodia",
    "ki": "Kiribati",
    "km": "Comoros",
    "kn": "Saint Kitts and Nevis",
    "kp": "North Korea",
    "kr": "South Korea",
    "kw": "Kuwait",
    "ky": "Cayman Islands",
    "kz": "Kazakhstan",
    "la": "Laos",
    "lb": "Lebanon",
    "lc": "Saint Lucia",
    "li": "Liechtenstein",
    "lk": "Sri Lanka",
    "lr": "Liberia",
    "ls": "Lesotho",
    "lt": "Lithuania",
    "lu": "Luxembourg",
    "lv": "Latvia",
    "ly": "Libya",
    "ma": "Morocco",
    "mc": "Monaco",
    "md": "Moldova",
    "me": "Montenegro",
    "mf": "Saint Martin",
    "mg": "Madagascar",
    "mh": "Marshall Islands",
    "mk": "North Macedonia",
    "ml": "Mali",
    "mm": "Myanmar",
    "mn": "Mongolia",
    "mo": "Macau",
    "mp": "Northern Mariana Islands",
    "mq": "Martinique",
    "mr": "Mauritania",
    "ms": "Montserrat",
    "mt": "Malta",
    "mu": "Mauritius",
    "mv": "Maldives",
    "mw": "Malawi",
    "mx": "Mexico",
    "my": "Malaysia",
    "mz": "Mozambique",
    "na": "Namibia",
    "nc": "New Caledonia",
    "ne": "Niger",
    "nf": "Norfolk Island",
    "ng": "Nigeria",
    "ni": "Nicaragua",
    "nl": "Netherlands",
    "no": "Norway",
    "np": "Nepal",
    "nr": "Nauru",
    "nu": "Niue",
    "nz": "New Zealand",
    "om": "Oman",
    "pa": "Panama",
    "pe": "Peru",
    "pf": "French Polynesia",
    "pg": "Papua New Guinea",
    "ph": "Philippines",
    "pk": "Pakistan",
    "pl": "Poland",
    "pm": "Saint Pierre and Miquelon",
    "pn": "Pitcairn Islands",
    "pr": "Puerto Rico",
    "ps": "Palestine",
    "pt": "Portugal",
    "pw": "Palau",
    "py": "Paraguay",
    "qa": "Qatar",
    "re": "Réunion",
    "ro": "Romania",
    "rs": "Serbia",
    "ru": "Russia",
    "rw": "Rwanda",
    "sa": "Saudi Arabia",
    "sb": "Solomon Islands",
    "sc": "Seychelles",
    "sd": "Sudan",
    "se": "Sweden",
    "sg": "Singapore",
    "sh": "Saint Helena, Ascension and Tristan da Cunha",
    "si": "Slovenia",
    "sj": "Svalbard and Jan Mayen",
    "sk": "Slovakia",
    "sl": "Sierra Leone",
    "sm": "San Marino",
    "sn": "Senegal",
    "so": "Somalia",
    "sr": "Suriname",
    "ss": "South Sudan",
    "st": "São Tomé and Príncipe",
    "sv": "El Salvador",
    "sx": "Sint Maarten",
    "sy": "Syria",
    "sz": "Eswatini (Swaziland)",
    "tc": "Turks and Caicos Islands",
    "td": "Chad",
    "tf": "French Southern and Antarctic Lands",
    "tg": "Togo",
    "th": "Thailand",
    "tj": "Tajikistan",
    "tk": "Tokelau",
    "tl": "Timor-Leste",
    "tm": "Turkmenistan",
    "tn": "Tunisia",
    "to": "Tonga",
    "tr": "Turkey",
    "tt": "Trinidad and Tobago",
    "tv": "Tuvalu",
    "tw": "Taiwan",
    "tz": "Tanzania",
    "ua": "Ukraine",
    "ug": "Uganda",
    "um": "United States Minor Outlying Islands",
    "un": "United Nations",
    "us": "United States",
    "us-ak": "Alaska",
    "us-al": "Alabama",
    "us-ar": "Arkansas",
    "us-az": "Arizona",
    "us-ca": "California",
    "us-co": "Colorado",
    "us-ct": "Connecticut",
    "us-de": "Delaware",
    "us-fl": "Florida",
    "us-ga": "Georgia",
    "us-hi": "Hawaii",
    "us-ia": "Iowa",
    "us-id": "Idaho",
    "us-il": "Illinois",
    "us-in": "Indiana",
    "us-ks": "Kansas",
    "us-ky": "Kentucky",
    "us-la": "Louisiana",
    "us-ma": "Massachusetts",
    "us-md": "Maryland",
    "us-me": "Maine",
    "us-mi": "Michigan",
    "us-mn": "Minnesota",
    "us-mo": "Missouri",
    "us-ms": "Mississippi",
    "us-mt": "Montana",
    "us-nc": "North Carolina",
    "us-nd": "North Dakota",
    "us-ne": "Nebraska",
    "us-nh": "New Hampshire",
    "us-nj": "New Jersey",
    "us-nm": "New Mexico",
    "us-nv": "Nevada",
    "us-ny": "New York",
    "us-oh": "Ohio",
    "us-ok": "Oklahoma",
    "us-or": "Oregon",
    "us-pa": "Pennsylvania",
    "us-ri": "Rhode Island",
    "us-sc": "South Carolina",
    "us-sd": "South Dakota",
    "us-tn": "Tennessee",
    "us-tx": "Texas",
    "us-ut": "Utah",
    "us-va": "Virginia",
    "us-vt": "Vermont",
    "us-wa": "Washington",
    "us-wi": "Wisconsin",
    "us-wv": "West Virginia",
    "us-wy": "Wyoming",
    "uy": "Uruguay",
    "uz": "Uzbekistan",
    "va": "Vatican City (Holy See)",
    "vc": "Saint Vincent and the Grenadines",
    "ve": "Venezuela",
    "vg": "British Virgin Islands",
    "vi": "United States Virgin Islands",
    "vn": "Vietnam",
    "vu": "Vanuatu",
    "wf": "Wallis and Futuna",
    "ws": "Samoa",
    "xk": "Kosovo",
    "ye": "Yemen",
    "yt": "Mayotte",
    "za": "South Africa",
    "zm": "Zambia",
    "zw": "Zimbabwe"
}

interface MapChartProps {
    chart_id: string;
}

interface Project {
    project: string;
    lat: string;
    lng: string;
    uuu_location: string;
    SKONeUSEPD: string;
    country?: string;
    newloc?: { lat: number; lng: number };
}

interface FilteredProject {
    country: string;
    pjt: string;
    latlng: { lat: number; lng: number };
    nodap: string;
    latitude: number;
    longitude: number;
    icon: string;
    length: number;
}

interface Feature {
    type: string;
    geometry: { type: string; coordinates: [number, number] };
    properties: { pictureSettings: { src: string }; name: string };
}

interface FeatureCollection {
    type: string;
    features: Feature[];
}


function getKeyByValue(object: any, value: any) {
    return Object.keys(object).find(key => object[key] === value);
}


function removeTrailingNewline(text: string) {
    // return text.replace(/\n+$/, '');
    return text?.replace(/\n$/, '');
}

const MapChart: React.FC<MapChartProps> = ({ chart_id }) => {
    const { data: projectsList } = useGetProjectList();
    const [division, setDivision] = useAtom(divisionState);
    const [projectWithLocation, setProjectWithLocation] = useState<FilteredProject[]>([]);
    const [loading, setLoading] = useState<boolean>(true); // Loading state

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading
            if (projectsList && projectsList.length) {
                try {
                    const latlnglist: Project[] = projectsList
                        .filter(
                            (v: any) =>
                                v.uuu_latitude !== null &&
                                v.uuu_longitude !== null &&
                                typeof v.uuu_latitude !== "undefined" &&
                                !v.uuu_shell_location.includes("반도체") &&
                                (!division || v.division === division)
                        )
                        .map((v: any) => ({
                            project: v.projectname,
                            lat: String(v.uuu_latitude),
                            lng: String(v.uuu_longitude),
                            uuu_location: v.uuu_shell_location,
                            SKONeUSEPD: v.use,
                        }));

                    setKey("AIzaSyBP-17SYLdi3X2v-qh8k-cK8obmONubMUU");
                    setLanguage("en");

                    const newarray: Project[] = await Promise.all(
                        latlnglist.map(async (item: any) => {
                            const response = await fromLatLng(item.lat, item.lng);
                            const country = response.results.reverse()[0].formatted_address;
                            return { ...item, country };
                        })
                    );

                    const latlng_f = await Promise.all(
                        newarray.map(async (item) => {
                            const response = await fromAddress(item.country || "");
                            const newloc = response.results[0].geometry.location;
                            return { ...item, newloc };
                        })
                    );

                    const groupedProjects = Object.values(
                        latlng_f.reduce<Record<string, FilteredProject>>((acc, curr) => {
                            const countryKey = curr.country || "Unknown";

                            if (!acc[countryKey]) {
                                acc[countryKey] = {
                                    country: countryKey,
                                    pjt: "",
                                    latlng: curr.newloc!,
                                    nodap: "",
                                    latitude: curr.newloc!.lat,
                                    longitude: curr.newloc!.lng,
                                    icon: `https://flagcdn.com/${getKeyByValue(
                                        countrycodedict,
                                        countryKey
                                    )}.svg`,
                                    length: countryKey === "Poland" ? 40 : 20,
                                };
                            }

                            acc[countryKey].pjt +=
                                acc[countryKey].pjt.split("\n").length < 10
                                    ? `${curr.project}\n`
                                    : "";
                            if (acc[countryKey].pjt.split("\n").length === 10 &&
                                !acc[countryKey].pjt.includes(`...외 ${acc[countryKey].length - 10}건`)
                            ) {
                                acc[countryKey].pjt += `...외 ${acc[countryKey].length - 10}건`;
                            }

                            return acc;
                        }, {})
                    );

                    setProjectWithLocation(groupedProjects);
                } catch (error) {
                    console.error("Error fetching data:", error);
                } finally {
                    setLoading(false); // End loading
                }
            }
        };

        fetchData();
    }, [projectsList, division]);

    useEffect(() => {
        // Only run chart setup when loading is false (data has been fetched and rendered)
        if (!loading) {
            const root = am5.Root.new(chart_id);
            root.setThemes([am5themes_Animated.new(root)]);

            const chart = root.container.children.push(am5map.MapChart.new(root, {}));
            const polygonSeries = chart.series.push(
                am5map.MapPolygonSeries.new(root, {
                    geoJSON: am5geodata_worldLow,
                    exclude: ["AQ"]
                })
            );

            polygonSeries.mapPolygons.template.setAll({
                fill: am5.color(0xdadada)
            });

            polygonSeries.mapPolygons.template.states.create("hover", {
                fill: root.interfaceColors.get("primaryButtonHover")
            });

            const imageBullet: FeatureCollection = {
                type: "FeatureCollection",
                features: [],
            };

            projectWithLocation
                .map((v) => ({ ...v, pjt: removeTrailingNewline(v.pjt) }))
                .forEach((project, index) => {
                    addCity(project.longitude, project.latitude, project.pjt, project.icon, index);
                });

            function addCity(
                longitude: number,
                latitude: number,
                title: string,
                icon: string,
                index: number
            ) {
                imageBullet.features.push({
                    type: "Feature",
                    geometry: { type: "Point", coordinates: [longitude, latitude] },
                    properties: { pictureSettings: { src: icon }, name: title },
                });
            }

            var pointSeries = chart.series.push(
                am5map.MapPointSeries.new(root, {
                    geoJSON: imageBullet as any
                })
            );

            pointSeries.bullets.push(function (root, series, dataItem) {
                var container = am5.Container.new(root, {});

                container.children.push(am5.Picture.new(root, {
                    templateField: "pictureSettings",
                    width: 20,
                    centerX: am5.p50,
                    centerY: am5.p50,
                    forceInactive: true
                }));

                var circle = container.children.push(am5.Circle.new(root, {
                    radius: 0,
                    stroke: am5.color(0xB4E1FF),
                    fill: am5.color(0xB4E1FF),
                    strokeWidth: 0,
                }));

                const labelPosition = dataItem.uid % 2 === 0 ? am5.p0 : am5.p100;

                var countryLabel = container.children.push(
                    am5.Label.new(root, {
                        text: "{name}",
                        paddingLeft: 15,
                        paddingRight: 15,
                        populateText: true,
                        fontWeight: "bold",
                        fontSize: 10,
                        centerY: am5.p50,
                        centerX: labelPosition
                    })
                );

                circle.on("radius", function (radius) {
                    countryLabel.set("x", radius);
                });

                return am5.Bullet.new(root, { sprite: container });
            });

            return () => {
                root && root.dispose();
            };
        }
    }, [chart_id, projectWithLocation, loading]);

    return (
        <div className="flex flex-col w-full h-full">
            {loading ? (
                <div className="flex justify-center items-center h-full">
                    <Loader2 className="w-5 h-5 animate-spin" />
                </div>
            ) : (
                <>
                    <div className="text-center text-md font-semibold mb-4">
                        {projectWithLocation.length} <span className='text-xs text-gray-600 font-normal'>Countries</span> {projectsList?.filter((v: any) => !division || v.division === division).length} <span className='text-xs text-gray-600 font-normal'>Projects</span>
                    </div>
                    <div
                        id={chart_id}
                        className="w-full h-[20vh] md:h-[80%]"
                        style={{ position: "relative", width: "100%" }}
                    ></div>
                </>
            )}
        </div>
    );
};

export default MapChart;


