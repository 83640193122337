

import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, Button, TextField, MenuItem, Select, FormControl, InputLabel, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useParams } from "react-router-dom";
import _ from 'lodash';
import api from '@/lib/axiosInstance';
import dayjs from 'dayjs';
import { Loader2 } from 'lucide-react';
import { useToast } from "@/hooks/use-toast";
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogFooter, AlertDialogAction, AlertDialogCancel } from '@/components/ui/alert-dialog';

const InfoBox = ({ selectedRow, username, pdftronInstance, documenTitle }) => {
const { projectCode } = useParams();

    const [options, setOptions] = React.useState([]);
    const [existData, setExistData] = useState({})
    const [loading, setLoading] = useState(true);
    const { toast } = useToast();

    const [selectedOption, setSelectedOption] = useState(""); // 선택된 옵션 상태 추가
    const [comment, setComment] = useState(""); // 사용자의 코멘트를 위한 상태
    const [isButtonDisabled, setIsButtonDisabled] = useState(true); // 버튼
    const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleCommentChange = (event) => {
        setComment(event.target.value);
        setIsButtonDisabled(event.target.value.trim() === '' && selectedOption !== ""); // 코멘트가 비어있지 않은 경우에만 버튼을 활성화
    };


    useEffect(() => {
        const fetchData = async () => {
            if (projectCode) {
                setLoading(true);
                try {
                    const res = await api.get(`/gettrsimple?path=${projectCode}&record=${selectedRow.record_no}&line=${selectedRow.LineAutoSeq}`);
                    if (res.data.success) {
                        setExistData(res.data.data);
                    }
                } catch (error) {
                    console.error("Error fetching data", error);
                }
                setLoading(false);
            }
        };
        fetchData();
    }, [projectCode, selectedRow]);


    useEffect(() => {
        if (options.length > 0 && existData.DecisionBPK && options.includes(existData.DecisionBPK)) {
            setSelectedOption(existData.DecisionBPK);
        }
        if (existData.DocComment) {
            setComment(existData.DocComment);
        }
    }, [existData, options]);


    const sendData = [{
        record_no: selectedRow.record_no,
        _bp_lineitems: [{
            DecisionBPK: selectedOption,
            DocComment: comment,
            Submittedby: username,
            SubmittedDate: dayjs().format("MM-DD-YYYY"),
            LineAutoSeq: selectedRow.LineAutoSeq
        }]

    }]

    const handleSubmitReview = async () => {
        try {
            setIsAlertDialogOpen(true);
        } catch (error) {
            console.error('Error submitting review:', error);
            toast({
                variant: "destructive",
                title: "Error",
                description: `An error occurred while sending your data. ${error}`,
            })
        }
    };



    useEffect(() => {
        const fetchData = async () => {
            if (projectCode) {
                try {
                    const res = await api.get(`/getreturncode?path=${projectCode}`);
                    if (res.data.success) {
                        setOptions(res.data.data.data.map((vf) => vf.SKDMApprovalDecision));
                    }
                } catch (error) {
                    console.error("Error fetching data", error);
                }
            }
        };
        fetchData();
    }, [projectCode]);

    const handleConfirm = async () => {
        setIsAlertDialogOpen(false);
        // Proceed with the API call
        if (pdftronInstance) {
            const { documentViewer, annotationManager } = pdftronInstance.Core;
            const doc = documentViewer.getDocument();
            const xfdfString = await annotationManager.exportAnnotations();
            const data = await doc.getFileData({
                // saves the document with annotations in it
                xfdfString
            });
            const arr = new Uint8Array(data);
            const blob = new Blob([arr], { type: 'application/pdf' });


            const formData = new FormData();
            formData.append("file", blob, 'annotated-document.pdf');

            // 전체 데이터 객체를 문자열로 변환하여 추가
            const dataObject = {
                record_no: selectedRow.record_no,
                _bp_lineitems: [{
                    DecisionBPK: selectedOption,
                    DocComment: comment,
                    Submittedby: username,
                    SubmittedDate: dayjs().format("MM-DD-YYYY"),
                    LineAutoSeq: selectedRow.LineAutoSeq
                }]
            };
            formData.append("data", JSON.stringify(dataObject));

            // axios를 사용하여 백엔드로 formData를 전송합니다.
            const response = await api.post(`/updatetr?project=${projectCode}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            // 응답 처리...
            if (response.data.success) {
                toast({
                    title: "Sent!",
                    description: "Your data has been sent successfully.",
                })
                setComment('');
                setIsButtonDisabled(true);
            } else {
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: `An error occurred while sending your data.`,
                })
            }
        }
    };

    // Function to handle the cancel action
    const handleCancel = () => {
        setIsAlertDialogOpen(false);
        // Any additional logic when the user cancels
    };


    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <Loader2 className="w-5 h-5 animate-spin" />
            </div>
        );
    }

    return (
        <>
            <Paper elevation={3} style={{ padding: '16px', width: '300px', backgroundColor: '#f5f5f5' }}>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Document Properties</Typography>
                <Typography variant="body2" gutterBottom>{selectedRow.No}</Typography>
                <Typography variant="body2" gutterBottom>{selectedRow.title}</Typography>
                <Typography variant="body2" gutterBottom>{selectedRow.WBSDiscNM}</Typography>
                <Typography variant="body2" gutterBottom>Rev. {selectedRow.RevNo}</Typography>
                <Typography variant="body2" style={{ color: '#d32f2f' }} gutterBottom>{selectedRow.stageName}</Typography>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginTop: '16px' }}>Your Response</Typography>
                <Typography variant="body2" style={{ color: '#ffa726' }}>Due {dayjs(selectedRow.DueDate).format("MM/DD/YYYY")}</Typography>

                <FormControl variant="outlined" size="small" fullWidth margin="dense">
                    <InputLabel id="response-label">Decision</InputLabel>
                    <Select
                        labelId="response-label"
                        id="response-select"
                        value={selectedOption}
                        label="Decision"
                        onChange={handleChange}
                    >
                        {options.map((option, index) => (
                            <MenuItem key={index} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    label="Add a comment"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    value={comment}
                    onChange={handleCommentChange}
                    multiline
                    rows={4} // 텍스트 필드의 높이 조절
                    placeholder="Type your comment here..."
                />
                <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        style={{ margin: '8px 0' }}
                        disabled={isButtonDisabled}
                        onClick={handleSubmitReview}
                    >
                        Submit Review
                    </Button>
                </Box>
                <Box mt="auto" pt={2}>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Transmittal Properties</Typography>
                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>{selectedRow.TR_NO}</Typography>
                    <Typography variant="body2" gutterBottom>{selectedRow.TR_Title}</Typography>
                    <Typography variant="body2" style={{ color: '#d32f2f' }} gutterBottom>{selectedRow.TR_purpose}</Typography>
                </Box>
            </Paper>
            <AlertDialog open={isAlertDialogOpen} onOpenChange={setIsAlertDialogOpen}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                        <AlertDialogDescription>
                            Do you really want to send the data?
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel onClick={handleCancel}>No</AlertDialogCancel>
                        <AlertDialogAction onClick={handleConfirm}>Yes</AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
};

export default InfoBox;
