

import * as React from "react"

import {
    Card,
    CardContent,
    CardHeader,
    CardTitle
} from "@/components/ui/card";
import SCurveChart from "@/components/common/ScurveChart";
import { Combobox } from "@/components/common//comboBox";
import BulletChart from "@/components/common/BulletChart";
import dayjs from "dayjs";
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import api from "@/lib/axiosInstance";
import { useParams } from "react-router-dom";

dayjs.extend(weekOfYear);
dayjs.extend(isSameOrBefore);
interface ProgressItem {
    date: string;
    PV: number;
    EV: number;
    cumulativePV: number;
    cumulativeEV: number | null;
    // 필요한 다른 속성들을 추가하세요.
}

interface TransformedData {
    weekProgress: ProgressItem[];
    monthProgress: ProgressItem[];
}

interface TreeNode {
    label: string;
    value?: string;
    children?: TreeNode[];
}

interface DataItem {
    DISC_NM: string;
    CUT_OFF_DT: string;
    PLAN_PROG: number;
    ACTUAL_PROG: number;
  }
  
  interface BulletDataItem {
    공종: string;
    PV: number;
    EV: number;
    Dev: number;
  }
  
  
  interface ChartDataItem {
    date: string;
    cumulativePV: number;
    cumulativeEV: number;
    PV: number;
    EV: number;
  }
  


export function EPgogressChartWithBullet() {


    const [selectedDisciplineValue, setSelectedDisciplineValue] = React.useState<string>("");
    const [selectedTypeValue, setSelectedTypeValue] = React.useState<string>("");
    const [selectedCutOffValue, setSelectedCutOffValue] = React.useState<string>("");
    const [emsData, setEmsData] = React.useState<any>(null);
    const [optionsDiscipline, setOptionsDiscipline] = React.useState<any>(null);
    const [optionsType, setOptionsType] = React.useState<any>(null);
    const [optionsCutoffs, setOptionsCutoffs] = React.useState<any>(null);

    const [checked, setChecked] = React.useState<"월간" | "주간">("월간");
    const legendRef = React.useRef<HTMLDivElement | null>(null);

     const { projectCode } = useParams()


    React.useEffect(() => {
        const fetchData = async () => {

            if (projectCode) {

                const body = {
                    bpname: "Engineering Progress",
                    lineitem: "no",
                    filter_criteria: {
                        join: "AND",
                        filter: [
                            { field: "status", value: "Terminated", condition_type: "neq" },
                            { field: "PJT_CD", value: projectCode, condition_type: "eq" },
                        ],
                    },
                };

                const body2 = {
                    bpname: "Engineering Progress Ratio",
                    lineitem: "yes",
                    filter_criteria: {
                        join: "AND",
                        filter: [
                            {
                                field: "ugenProjectNumber",
                                value: projectCode,
                                condition_type: "eq",
                            },
                        ],
                    },
                };

                const res2 = await api.post(`/getemsbprecord?path=SKEC0001`, body2);
                const emsWV = res2.data.data.data[0]._bp_lineitems

                const res = await api.post(`/getemsbprecord?path=SKEC0001`, body);
                const emsData = res.data.data.data;

                const emsDatawithWV = emsData
                    .filter((v: any) => v.PROG_TYPE === "E" || v.PROG_TYPE === "R")
                    .map((v: any) => {
                        const finding = emsWV.find((vf: any) => vf.EPRDiscipline.toUpperCase() === v.DISC_NM)
                        return {
                            ...v,
                            date_week: dayjs(v.CUT_OFF_DT).format("YY년 ") + dayjs(v.CUT_OFF_DT).week() + "주",
                            date_month: dayjs(v.CUT_OFF_DT).format("YY년 MM월"),
                            PLAN_PROG_FACTOR_VALUE: v.PROG_TYPE === "E" ? v.PLAN_PROG * (finding?.EDPRRatio / 100) : v.PLAN_PROG * (finding?.EDPRRatio / 100),
                            ACTUAL_PROG_FACTOR_VALUE: v.PROG_TYPE === "E" ? v.ACTUAL_PROG * (finding?.EDPRRatio / 100) : v.ACTUAL_PROG * (finding?.EDPRRatio / 100),
                        }
                    })



                const uniqueDiscNm = Array.from(new Set(emsDatawithWV.map((v: any) => v.DISC_NM)));
                const uniqueCutoffMonth = Array.from(new Set(emsDatawithWV.map((v: any) => v.date_month))).sort((a:any, b:any) => {
                    return a.localeCompare(b);
                });
                const uniqueCutoffWeek = Array.from(new Set(emsDatawithWV.map((v: any) => v.date_week))).sort((a:any, b:any) => {
                    return a.localeCompare(b);
                });
                const uniqueType = Array.from(new Set(emsDatawithWV.map((v: any) => v.PROG_TYPE)));


                setOptionsDiscipline(uniqueDiscNm.map(value => ({ value, label: value })))
                setOptionsType(uniqueType.map(value => ({ value, label: value })))
                setOptionsCutoffs({ month: uniqueCutoffMonth.map(value => ({ value, label: value })), week: uniqueCutoffWeek.map(value => ({ value, label: value })) })
                setEmsData(emsDatawithWV);
            }
        };
        fetchData();
    }, [projectCode]);


    const progressby = emsData && emsData
        .sort((a:any, b:any) => a.DISC_NM.localeCompare(b.DISC_NM)) // DISC_NM 기준으로 정렬
        .reduce((acc:any, curr:any) => {
            // DISC_NM별 그룹화
            const group = acc[curr.DISC_NM] || [];
            acc[curr.DISC_NM] = [...group, curr];
            return acc;
        }, {} as Record<string, any[]>);

        const bulletData: BulletDataItem[] = progressby && Object.entries(progressby as Record<string, DataItem[]>)
        .map(([key, objs]) => {
          if (key === "OVERALL") return null; // "OVERALL" 제거
      
          // Actual 최대값 객체 찾기
          const maxActualObj = objs.reduce((max, obj) =>
            obj.ACTUAL_PROG > max.ACTUAL_PROG ? obj : max, objs[0]
          );
      
          const planObj = objs.find(obj => obj.CUT_OFF_DT === maxActualObj.CUT_OFF_DT);
      
          return {
            공종: key,
            PV: planObj?.PLAN_PROG || 0, // Plan 값 설정
            EV: maxActualObj.ACTUAL_PROG,
            Dev: maxActualObj.ACTUAL_PROG - (planObj?.PLAN_PROG || 0),
          };
        })
        .filter((v): v is BulletDataItem => v !== null); // null 값 필터링




    const itemsPerRow = 4;

    const rows = React.useMemo(() => {
        if (!bulletData) return [];
        const result = [];
        for (let i = 0; i < bulletData.length; i += itemsPerRow) {
            result.push(bulletData.slice(i, i + itemsPerRow));
        }
        return result;
    }, [bulletData]);





    const cutoffdates =  checked === "월간" ? optionsCutoffs &&optionsCutoffs.month : optionsCutoffs &&optionsCutoffs.week;


    const [legendContainer, setLegendContainer] = React.useState<HTMLDivElement | null>(null);


    //


    // const legendRef = React.useRef<HTMLElement | null>(null);
    const cardContentRef = React.useRef<HTMLDivElement>(null);
    const [chartHeight, setChartHeight] = React.useState(500); // Default height



    React.useLayoutEffect(() => {
        const updateChartHeight = () => {
            if (cardContentRef.current) {
                const cardContentHeight = cardContentRef.current.clientHeight;
                // Adjust the height as needed (e.g., subtracting padding or other elements)
                setChartHeight(cardContentHeight); // Example adjustment
            }
        };
        updateChartHeight();
        window.addEventListener('resize', updateChartHeight);
        return () => window.removeEventListener('resize', updateChartHeight);
    }, [cardContentRef]);



    const handleCutOffValueChange = (value: string) => {
        setSelectedCutOffValue(value);
    };

    const handleDisciplineValueChange = (value: string) => {
        setSelectedDisciplineValue(value);
    };

    const handleTypeValueChange = (value: string) => {
        setSelectedTypeValue(value);
    };

    const filteredEmsData = emsData && emsData
    .filter((v: any) => {
      const weekDate = v.date_week ? dayjs(v.date_week, "YY년 WW주") : null;
      const monthDate = v.date_month ? dayjs(v.date_month, "YY년 MM월") : null;
      const cutoffWeekDate = selectedCutOffValue && checked === "주간"
        ? dayjs(selectedCutOffValue, "YY년 WW주")
        : null;
      const cutoffMonthDate = selectedCutOffValue && checked === "월간"
        ? dayjs(selectedCutOffValue, "YY년 MM월")
        : null;
  
      return (
        (!selectedTypeValue || v.PROG_TYPE === selectedTypeValue) &&
        (!selectedCutOffValue ||
          (checked === "주간" && weekDate && cutoffWeekDate && weekDate.isSameOrBefore(cutoffWeekDate)) ||
          (checked === "월간" && monthDate && cutoffMonthDate && monthDate.isSameOrBefore(cutoffMonthDate))
        ) &&
        (!selectedDisciplineValue || v.DISC_NM === selectedDisciplineValue) &&
        (!selectedDisciplineValue  && v.DISC_NM === "OVERALL" )
      );
    })
    .map((v: any) => ({ ...v, date: checked === "주간" ? v.date_week : v.date_month }));

    const sortedData = filteredEmsData && filteredEmsData.sort((a:any, b:any) => (a.date > b.date ? 1 : -1));




    const progressby2 = sortedData && sortedData
        .reduce((acc:any, curr:any) => {
            // DISC_NM별 그룹화
            const group = acc[curr.date] || [];
            acc[curr.date] = [...group, curr];
            return acc;
        }, {} as Record<string, any[]>);

    const chartData: ChartDataItem[] = sortedData && Object.entries(progressby2 as Record<string, DataItem[]>)
    .map(([key, objs]) => {
  
      // Actual 최대값 객체 찾기
      const maxActualObj = objs.reduce((max, obj) =>
        obj.ACTUAL_PROG > max.ACTUAL_PROG ? obj : max, objs[0]
      );
  
      const planObj = objs.find(obj => obj.CUT_OFF_DT === maxActualObj.CUT_OFF_DT);
  
      return {
        date: key,
        cumulativePV: planObj?.PLAN_PROG || 0, // Plan 값 설정
        cumulativeEV: maxActualObj.ACTUAL_PROG ===0? null: maxActualObj.ACTUAL_PROG,
      };
    })
    .filter((v): v is ChartDataItem => v !== null); // null 값 필터링
    
    
    chartData && chartData.forEach((item:any, index) => {
        if (index > 0) {
            item.PV = item.cumulativePV - chartData[index - 1].cumulativePV;
            item.EV = item.cumulativeEV ? item.cumulativeEV - chartData[index - 1].cumulativeEV : null;
        } else {
            item.PV = item.cumulativePV; // 첫 주의 경우 전체가 일별 값
            item.EV = item.cumulativeEV; // 첫 주의 경우 전체가 일별 값
        }
    });



    React.useEffect(() => {
        setLegendContainer(legendRef.current);
    }, [chartData]);



    const currentDateString = checked === "주간"
        ? dayjs().format("YY년 ") + dayjs().week() + "주"
        : dayjs().format("YY년 MM월");

    // Attempt to find the data point for the current date
    let currentDataPoint = chartData && chartData.length>0&& chartData.find((v:any) => v.date === currentDateString);

    // If not found or cumulativeEV is undefined, find the data point with the maximum cumulativeEV
    if (!currentDataPoint || currentDataPoint.cumulativeEV == null) {
        // Filter out data points where cumulativeEV is null or undefined
        const validDataPoints = chartData && chartData.length>0&& chartData.filter((v:any) => v.cumulativeEV != null);

        if (validDataPoints && validDataPoints.length > 0) {
            // Find the data point with the maximum cumulativeEV
            currentDataPoint = validDataPoints.reduce((max: any, v: ProgressItem) => {
                return v.cumulativeEV && v.cumulativeEV > max.cumulativeEV ? v : max;
            });
        } else {
            // If no valid data points, set currentDataPoint to undefined
            currentDataPoint = undefined;
        }
    }

    const earnedValue = currentDataPoint ? currentDataPoint.cumulativeEV : 0;
    const planValue = currentDataPoint ? currentDataPoint.cumulativePV : 0;
    const dev = earnedValue && earnedValue - planValue;


    const columnItems =
    {
        week: [{
            name: "주간 계획",
            field_name: "PV",
            color: "#FEAE65",
        },
        {
            name: "주간 실적",
            field_name: "EV",
            color: '#48CAE4'
        },]
        ,
        month: [{
            name: "월간 계획",
            field_name: "PV",
            color: "#FEAE65",
        },
        {
            name: "월간 실적",
            field_name: "EV",
            color: '#48CAE4',
        },]
    }

    const lineItems = [
        {
            name: "누적 계획",
            field_name: "cumulativePV",
            color: "#FEAE65",
        },
        {
            name: "누적 실적",
            field_name: "cumulativeEV",
            color: '#48CAE4',
        },
    ];


    return (
        <>
            <Card className="h-[50vh] w-full rounded-xl border bg-card text-card-foreground shadow overflow-y-auto">

                <CardHeader className="text-left">
                    <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                        <span>Engineering Progress</span>
                        <div
                            className="flex-grow text-xs flex justify-end"
                            style={{
                                zIndex: 99999,
                                height: '40px',
                                maxWidth: '550px',
                                whiteSpace: 'nowrap',
                            }}
                            ref={legendRef}
                        ></div>
                    </CardTitle>
                </CardHeader>
                {/* <CardContent className="h-[40vh] flex-grow flex items-center justify-center "> */}
                <CardContent className="grid grid-cols-1 md:grid-cols-3 gap-4 h-[40vh]" ref={cardContentRef}>

                    {/* Left Section - Full width on mobile, 1/3 on larger screens */}
                    <div className="col-span-1 h-full relative flex flex-col">

                        {/* Comboboxes row */}
                        <div className="flex flex-wrap md:space-x-4 md:flex-nowrap mb-4">
                            {/* Combobox 1 */}
                            <div className="flex flex-col flex-1">
                                <label htmlFor="combo1" className="text-xs mb-1">Discipline</label>
                                <Combobox
                                    options={optionsDiscipline ?? []}
                                    value={selectedDisciplineValue}
                                    onValueChange={handleDisciplineValueChange}
                                    placeholder="Select a Discipline"
                                    className="w-full md:w-40 truncate"
                                />
                            </div>

                            {/* Combobox 2 */}
                            <div className="flex flex-col flex-1">
                                <label htmlFor="combo2" className="text-xs mb-1">Type</label>
                                <Combobox
                                    options={optionsType ?? []}
                                    value={selectedTypeValue}
                                    onValueChange={handleTypeValueChange}
                                    placeholder="Select a Discipline"
                                    className="w-full md:w-40 truncate"
                                />
                            </div>

                            {/* Combobox 3 */}
                            <div className="flex flex-col flex-1">
                                <label htmlFor="combo3" className="text-xs mb-1">Cut off</label>
                                <Combobox
                                    options={cutoffdates ?? []}
                                    value={selectedCutOffValue}
                                    onValueChange={handleCutOffValueChange}
                                    placeholder="Select a Cut off"
                                    className="w-full md:w-40 truncate"
                                />
                            </div>
                        </div>

                        <div className="flex-1 flex items-center justify-center">
                            <div className="flex items-center space-x-8">
                                {/* Plan */}
                                <div className="flex flex-col items-center">
                                    <label className="text-sm mb-1 text-gray-600">Plan</label>
                                    <div className="text-2xl text-center">{planValue && planValue?.toFixed(2)}%</div>
                                </div>
                                {/* Separator */}
                                <div className="h-12 border-l border-gray-300 hidden md:block"></div>
                                {/* Actual */}
                                <div className="flex flex-col items-center">
                                    <label className="text-sm mb-1 text-gray-600">Actual</label>
                                    <div className="text-2xl text-center text-blue-800">{earnedValue && earnedValue?.toFixed(2)}%</div>
                                </div>
                                {/* Separator */}
                                <div className="h-12 border-l border-gray-300 hidden md:block"></div>
                                {/* Dev */}
                                <div className="flex flex-col items-center">
                                    <label className="text-sm mb-1  text-gray-600">Dev.</label>
                                    <div
                                        className={`text-2xl text-center ${dev && dev >= 0 ? 'text-emerald-600' : 'text-red-600'}`}
                                    >{dev && dev?.toFixed(2)}%</div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* Right Section - Full width on mobile, 2/3 on larger screens */}
                    <div className="col-span-1 md:col-span-2 grid gap-0 h-[30vh]">

                        {chartData &&
                            <SCurveChart
                                data={chartData}
                                columnItems={columnItems}
                                lineItems={lineItems}
                                chart_id={"C-Scurve"}
                                height={chartHeight}
                                category='date'
                                lineLabel={true}
                                unit={"%"}
                                legendContainer={legendContainer}
                                maxYaxis={110}
                                checked={checked}
                                onCheckedChange={setChecked}
                            />
                        }
                    </div>
                </CardContent>
            </Card>

            <Card className=" w-full  rounded-xl border bg-card text-card-foreground shadow overflow-y-auto">
                <CardHeader className="text-left">
                    <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                        <span>Engineering Progress (Discipline)</span>
                        <span className="ml-auto flex items-center text-xs space-x-4 hidden sm:flex">
                            <div className="flex items-center space-x-1">
                                <span className="w-4 h-2 bg-red-500 inline-block"></span>
                                <span>behind (Dev. &lt; -5%)</span>
                            </div>
                            <div className="flex items-center space-x-1">
                                <span className="w-4 h-2 bg-yellow-500 inline-block"></span>
                                <span>on-schedule (-5% ≤ Dev. ≤ +5%)</span>
                            </div>
                            <div className="flex items-center space-x-1">
                                <span className="w-4 h-2 bg-emerald-500 inline-block"></span>
                                <span>ahead (Dev. &gt; +5%)</span>
                            </div>
                        </span>
                    </CardTitle>

                </CardHeader>
                <CardContent className="flex-grow items-center justify-center space-y-4">
                    {rows && rows.map((row, rowIndex) => (
                        <div key={rowIndex} className="flex justify-around w-full space-x-4">
                            {row.map((item: any, index: any) => {
                                return (
                                    <div key={index} className="flex flex-col items-start space-y-1 w-full">
                                        <span className="text-sm font-semibold  text-gray-700">{item.공종}</span>
                                        <div className="flex space-x-2">
                                            <span className="text-xs text-gray-500">계획: {Math.round(item.PV)}%</span>
                                            <span className="text-xs text-gray-500">실적: {Math.round(item.EV)}%</span>
                                            <span className="text-xs text-gray-500">차이: {Math.round(item.Dev)}%</span>
                                        </div>
                                        <BulletChart
                                            spark={false}
                                            maxValue={100}
                                            targetValue={item.PV}
                                            actualValue={item.EV}
                                            height={75}
                                            divId={`${item.공종}`}
                                            unit="%"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </CardContent>
            </Card>

        </>
    )
}