

import * as React from "react";
import api from "@/lib/axiosInstance";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { useParams } from "react-router-dom";

export function LandscapeImg() {
    const [loading, setLoading] = React.useState(true);
     const { projectCode } = useParams()
    const [landscapeImage, setLandscapeImage] = React.useState<string | null>(null);
    const [rotateImage, setRotateImage] = React.useState<boolean>(false);

    React.useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                const res = await api.get(`/getLandscape?project=${projectCode}`, {
                    responseType: "arraybuffer",
                });

                const blobUrl = window.URL.createObjectURL(new Blob([res.data]));
                const img = new Image();

                img.onload = () => {
                    // Check if the image width is greater than its height
                    if (img.width > img.height) {
                        setRotateImage(true);
                    }
                    setLandscapeImage(blobUrl);
                };

                img.src = blobUrl;
            } catch (error) {
                console.error(`Failed to fetch data for:`, error);
            }
            setLoading(false);
        };

        if (projectCode) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [projectCode]);

    return (
        <>
{landscapeImage ? (
    <div className="relative w-full h-full"> {/* 컨테이너 div */}
   
            <div className="w-full h-full"> {/* 추가된 wrapper div */}
            {/* <Zoom> */}
                <img
                    alt="전경 사진"
                    src={landscapeImage}
                    className={`w-full h-full object-cover ${
                        rotateImage ? "rotate-90" : ""
                    }`}
                    // absolute positioning 제거하고 width/height를 100%로
                />
            {/* </Zoom> */}
            </div>
    
    </div>
) : (
    <div className="flex justify-center items-center h-full text-xs text-slate-700">
        No data available
    </div>
)}
        </>
    );
}