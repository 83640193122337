import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Skeleton } from "@/components/ui/skeleton";
import { useTranslation } from 'react-i18next';

interface Category {
  title: string;
  plan: number | null | undefined;
  actual: number | null | undefined;
  color: string;
}

interface EPCProgressContentProps {
  categories: Category[];
  simpleProgressDataloading: boolean;
}

export function EPCProgressContent({ categories, simpleProgressDataloading }: EPCProgressContentProps) {
  const { t } = useTranslation();

  return (
    <Table className="min-w-full table-fixed">
      <TableHeader>
        <TableRow>
          <TableHead className="w-2/5 p-2 h-6"></TableHead>
          <TableHead className="w-1/5 text-center p-2 h-6">{t('plan')}</TableHead>
          <TableHead className="w-1/5 text-center p-2 h-6">{t('actual')}</TableHead>
          <TableHead className="w-1/5 text-center p-2 h-6">{t('difference')}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {categories.map((category, index) => {
          const difference = (category.actual ?? 0) - (category.plan ?? 0);
          return (
            <TableRow key={index}>
              <TableCell className="font-semibold text-left p-2">{simpleProgressDataloading ? <Skeleton className="h-6 w-32" /> : category.title}</TableCell>
              <TableCell className="text-center p-2">{simpleProgressDataloading ? <Skeleton className="h-6 w-14" /> : category.plan !== null ? `${category.plan}%` : "-"}</TableCell>
              <TableCell className="text-center p-2">{simpleProgressDataloading ? <Skeleton className="h-6 w-14" /> : category.actual !== null ? `${category.actual}%` : "-"}</TableCell>
              <TableCell className={`font-semibold p-2 text-center ${difference >= 0 ? "text-emerald-600" : "text-red-600"}`}>
                {simpleProgressDataloading ? <Skeleton className="h-6 w-14" /> : difference !== null ? `${difference.toFixed(2)}%` : "-"}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}