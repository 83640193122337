
import { Skeleton } from "@/components/ui/skeleton";
import { useGetProjectSummaryData } from "@/lib/getData";



export function ProjectInfo() {


  const { projectSummaryData } = useGetProjectSummaryData();

    return (
        <div className="flex-grow items-center justify-center overflow-y-auto">
       
                <div className="col-span-2 ml-2 space-y-1">
                    <div className="p-2 space-y-2">
                        <div className="grid grid-cols-[1fr_auto] text-sm font-medium border-b border-gray-200 pb-1">
                            <span>Project Name</span>
                            <span className="text-gray-500 text-right max-w-[180px] break-words">
                                {projectSummaryData?.ugenProjectName || "N/A"}
                            </span>
                        </div>
                        <div className="grid grid-cols-[1fr_auto] text-sm font-medium border-b border-gray-200 pb-1">
                            <span>Location</span>
                            <span className="text-gray-500 text-right max-w-[180px] truncate" title={projectSummaryData?.address || "N/A"}>
                                {projectSummaryData?.address || "N/A"}
                            </span>
                        </div>
                        <div className="grid grid-cols-[1fr_auto] text-sm font-medium border-b border-gray-200 pb-1">
                            <span>Contract Type</span>
                            <span className="text-gray-500 text-right max-w-[180px] truncate" title={projectSummaryData?.genContractType || "N/A"}>
                                {projectSummaryData?.genContractType || "N/A"}
                            </span>
                        </div>
                        <div className="grid grid-cols-[1fr_auto] text-sm font-medium border-b border-gray-200 pb-1">
                            <span>Client</span>
                            <span className="text-gray-500 text-right max-w-[180px] break-words">
                                {projectSummaryData?.genClient || "N/A"}
                            </span>
                        </div>
                    </div>
                </div>
    

        </div>
    );
}
