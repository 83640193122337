import React, { useEffect, useRef, useMemo, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import dayjs from "dayjs";
import { Button } from "../ui/button";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

am5.addLicense("AM5C363108325");
am5.addLicense("AM5M363108325");


interface SCurveChartProps {
    height: number ;
    // width: number;
    maxYaxis: number;
    data: any;
    chart_id: string;
    columnItems: { name: string; field_name: string; color: string }[];
    lineItems: { name: string; field_name: string; color: string }[];
    unit: string;
    lineLabel: boolean;
    category: string;
    side_unit_label?: string;
    legendContainer?: HTMLDivElement | null;

}

const SCurveChartEMC: React.FC<SCurveChartProps> = ({
    height,
    maxYaxis,
    data,
    chart_id,
    columnItems,
    lineItems,
    unit,
    category,
    lineLabel,
    side_unit_label,
    legendContainer,

}) => {
    const chartRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        if (!chartRef.current || !legendContainer) return;

        const chartRoot = am5.Root.new(chart_id);
        chartRoot.setThemes([am5themes_Animated.new(chartRoot)]);

        const chart = chartRoot.container.children.push(
            am5xy.XYChart.new(chartRoot, {
                panX: true,
                panY: false,
                wheelX: "panX",
                wheelY: "zoomX",
                layout: chartRoot.verticalLayout,
            })
        );

        chart.plotContainer.onPrivate("height", (height = 0) => {
            chart.zoomOutButton.set("dy", height - 45);
            chart.zoomOutButton.set("scale", 0.8);
        });

        const tooltip = am5.Tooltip.new(chartRoot, {});
        chart.plotContainer.set("tooltipPosition", "pointer");
        chart.plotContainer.set("tooltipText", "a");
        chart.plotContainer.set("tooltip", tooltip);

        tooltip.label.adapters.add("text", function (text) {
            let newText = "";
            let i = 0;
            chart.series.each((series) => {
                const tooltipDataItem = series.get("tooltipDataItem");
                if (tooltipDataItem) {
                    if (i === 0) newText += `[bold fontSize:14px]${dayjs(tooltipDataItem.get("categoryX")).format("YY년 MM월")}\n`;
                    if (i !== 0) newText += "\n";
                    if (tooltipDataItem.get("valueY")) {
                        const strokeColor = series.get("stroke");
                        const symbol = series instanceof am5xy.LineSeries ? "—" : "■";
                        newText += `[${strokeColor}]${symbol}[/][width:8px] [fontSize: 14px]${series.get("name")}: ${Number(
                            tooltipDataItem.get("valueY")?.toFixed(2) || 0
                        ).toLocaleString()} ${unit}`;
                    }
                }
                i++;
            });
            return newText;
        });

        if (tooltip) {
            tooltip.get("background")?.setAll({
                stroke: am5.color(0x000000),
                strokeOpacity: 0.8,
                fill: am5.color(0xffffff),
                fillOpacity: 0.8,
            });
        }

        const cursor = chart.set(
            "cursor",
            am5xy.XYCursor.new(chartRoot, {
                behavior: "none", // or "zoomX" if you want to allow zooming along the X-axis
            })
        );

        // Enable panning on the X-axis
        chart.chartContainer.set("wheelable", true);

        cursor.lineX.setAll({
            stroke: am5.color(0x525252),
            strokeWidth: 1,
            strokeDasharray: [5, 5],
        });

        cursor.lineY.setAll({
            stroke: am5.color(0x525252),
            strokeWidth: 1,
            strokeDasharray: [5, 5],
        });


        if (legendContainer) {

            var legendRoot = am5.Root.new(legendContainer);


            legendRoot.setThemes([
                am5themes_Animated.new(legendRoot)
            ]);


            var legend = legendRoot.container.children.push(
                am5.Legend.new(legendRoot, {
                    x: am5.percent(100),          // x 위치를 부모의 100%로 설정 (오른쪽 끝)
                    y: am5.percent(100),          // y 위치를 부모의 100%로 설정 (아래쪽 끝)
                    centerX: am5.percent(100),    // 중심점을 오른쪽 끝으로 설정
                    centerY: am5.percent(100),
                    layout: am5.GridLayout.new(legendRoot, {
                        maxColumns: 4,
                        fixedWidthGrid: true
                    })
                })
            );

            legend.valueLabels.template.set("forceHidden", true);


            legend.markers.template.setAll({
                width: 10,
                height: 10,
            });

            legend.labels.template.setAll({
                fontSize: 12,
                fontWeight: "500",
                fill: am5.color(0x525252),
            });

        }

        const xRenderer = am5xy.AxisRendererX.new(chartRoot, {
            minGridDistance: 30,
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
            strokeOpacity: 0,
        });

        const xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(chartRoot, {
                categoryField: category,
                renderer: xRenderer,
            })
        );

        xRenderer.grid.template.setAll({
            location: 1,
            strokeOpacity: 0,
        });

        xRenderer.labels.template.adapters.add("text", (text: any, target: any) => {
            if (target.dataItem) {
                const category: string = target.dataItem.dataContext.date;

                          // 월간 데이터 처리
                    const date = dayjs(category, "YYYY-MM-01");

                    // 데이터 아이템 목록 가져오기
                    const dataItems = target.dataItem.component._data._values as any[];
                    const categoryIndex = dataItems && dataItems.findIndex((vf) => vf.date === category);

                    const year = date.year();
                    const month = date.month() + 1;



                    if (categoryIndex === 0 || year !== dayjs(target.dataItem.component._data._values[categoryIndex - 1]?.date, "YYYY-MM-01").year()) {
                        return dayjs(date).format("YY년");
                    } else {
                        return `${month}월`;
                    }
         
                return text;
            }
        });
        xAxis.data.setAll(data);



        xAxis.get("renderer").labels.template.setAll({
            // oversizedBehavior: "fit",
            oversizedBehavior: "wrap",
            textAlign: "center",
            fill: am5.color(0x525252),
            maxWidth: 50,
            fontSize: "0.8rem",
            minScale: 0.6
        });

        let yRenderer = am5xy.AxisRendererY.new(chartRoot, {
            strokeOpacity: 1,
            strokeWidth: 1,
            stroke: am5.color("rgba(224, 224, 224, 0.5)"),
            // grid: {
            //   disabled: true,
            // },
        });

        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(chartRoot, {
                strictMinMaxSelection: true,
                extraMin: 0,
                // extraMax: 0.06,
                renderer: yRenderer,
            })
        );

        yRenderer.grid.template.setAll({
            strokeOpacity: 0,
        });

        yRenderer.labels.template.setAll({
            paddingRight: 10,
            scale: 0.7,
            fill: am5.color(0x525252),
        });

        yAxis.set("numberFormat", "#,###");


        let paretoAxisRenderer = am5xy.AxisRendererY.new(chartRoot, {
            opposite: true,
            strokeOpacity: 1,
            strokeWidth: 1,
            stroke: am5.color("rgba(224, 224, 224, 0.5)"),
        });

        let paretoAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(chartRoot, {
                strictMinMaxSelection: true,
                // extraMin: 0,
                // extraMax: 0.03,
                max: maxYaxis,
                renderer: paretoAxisRenderer,
                visible: lineLabel,
            })
        );

        paretoAxisRenderer.grid.template.setAll({
            strokeOpacity: 0,
        });

        paretoAxisRenderer.labels.template.setAll({
            paddingLeft: 10,
            scale: 0.7,
            fill: am5.color(0x525252),
            text: "asd",
        });

        chart.yAxes.autoDispose = false;
        // paretoAxisRenderer.grid.template.set("forceHidden", true);
        // paretoAxis.set("numberFormat", "#");

        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

        const makeSeries = (name: string, fieldName: string, color: any) => {
            let series = chart.series.push(
                am5xy.ColumnSeries.new(chartRoot, {
                    name: name,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: fieldName,
                    categoryXField: category,
                    fill: color,
                    stroke: color,
                    legendLabelText: name + " {valueY.formatNumber('#.## ')}",
                    // legendRangeLabelText: name,
                })
            );

            series.data.setAll(data);
            series.columns.template.setAll({
                width: am5.percent(100)
            });

            // series.columns.template.set("fillGradient", am5.LinearGradient.new(chartRoot, {
            //   stops: [{
            //     color: am5.color(color),
            //     opacity: 0.8
            //   }, {
            //     color: am5.color(color),
            //     opacity: 0
            //   }],
            //   rotation: 90
            // }));
            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear();

            series.bullets.push(function () {
                return am5.Bullet.new(chartRoot, {
                    locationY: 0,
                    sprite: am5.Label.new(chartRoot, {
                        // text: "{valueY}",
                        fill: chartRoot.interfaceColors.get("alternativeText"),
                        centerY: 0,
                        centerX: am5.p50,
                        populateText: true,
                    }),
                });
            });

            legend.data.push(series);
        };


        const makeParetoSeries = (name: string, fieldName: string, color: any) => {
            let paretoSeries = chart.series.push(
                am5xy.LineSeries.new(chartRoot, {
                    name: name,
                    xAxis: xAxis,
                    yAxis: paretoAxis,
                    valueYField: fieldName,
                    categoryXField: category,
                    stroke: color,
                    fill: color,
                    // maskBullets: false,
                    // tooltip: am5.Tooltip.new(chartRoot, {
                    //   labelText: "[bold]{name}[/]\n{valueX.formatDate()}: {valueY}",
                    // }),
                    legendLabelText: name + " {valueY.formatNumber('#,###.#')}",
                    legendRangeLabelText: name,
                })
            );

            paretoSeries.strokes.template.setAll({
                strokeWidth: 2,
            });

            paretoSeries.bullets.push(function () {
                return am5.Bullet.new(chartRoot, {
                    locationY: 1,
                    sprite: am5.Circle.new(chartRoot, {
                        radius: 3,
                        stroke: paretoSeries.get("stroke"),
                        strokeWidth: 2,
                        fill: am5.color(0xffffff),
                    }),
                });
            });

            paretoSeries.data.setAll(data);

            legend.data.push(paretoSeries);
        };

 

        columnItems.forEach((com:any) => {
            const { name, field_name, color } = com;

            makeSeries(name, field_name, color);
        });

        lineItems.forEach((com) => {
            const { name, field_name, color } = com;

            makeParetoSeries(name, field_name, color);
        });



        chart.appear(1000, 100);


        return () => {
            chartRoot && chartRoot.dispose();
            legendRoot && legendRoot.dispose();
        };
    }, [data, columnItems, lineItems, unit, category, side_unit_label, lineLabel, chartRef, legendContainer]);

    return (
        <div style={{ position: "relative", width: "100%", height }}>
            <div
                id={chart_id}
                ref={chartRef}
                style={{ position: "absolute", bottom: 0, width: "100%", height: "calc(100% - 45px)" }}
            />
        </div>
    );
};

export default SCurveChartEMC;