import ClientData from "@/components/edpr/pages/client";
import useDocumentTitle from "@/hooks/useDocumentTitle";

export default function ClientTR() {

  useDocumentTitle("S.CON Portal")

  return (
    <ClientData />

  )
}