

import React, { useLayoutEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { getTailwindColor } from '@/lib/utils';

am5.addLicense("AM5C363108325");
am5.addLicense("AM5M363108325");

interface GaugeChartProps {
  chartID: string;
  company: number | undefined;
  target: number | undefined;
  actual: number | undefined;
  companyColor: string;
  actualColor: string;
  targetColor: string;
}
const GaugeChart: React.FC<GaugeChartProps> = ({ chartID, company, target, actual, actualColor, companyColor ,targetColor}) => {
  useLayoutEffect(() => {
    // Create root element
    const root = am5.Root.new(chartID);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/radar-chart/
    var chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        startAngle: 180,
        endAngle: 360
      })
    );

    // Create axis and its renderer
    // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Axes
    var axisRenderer = am5radar.AxisRendererCircular.new(root, {
      innerRadius: -10,
      // strokeOpacity: 1,
      // strokeWidth: 15,
      // strokeGradient: am5.LinearGradient.new(root, {
      //   rotation: 0,
      //   stops: [
      //     { color: am5.color(0x19d228) },
      //     { color: am5.color(0xf4fb16) },
      //     { color: am5.color(0xf6d32b) },
      //     { color: am5.color(0xfb7116) }
      //   ]
      // })
    });



    var bandsData = [{
      color:am5.color(getTailwindColor(targetColor)) ,
      lowScore: 0,
      highScore: target
    }, {
      color: am5.color(0xf04922),
      lowScore: target,
      highScore: 1.5
    }, ];


    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        max: 1.5,
        strictMinMax: true,
        renderer: axisRenderer
      })
    );

    // Add clock hand
    // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Clock_hands
    var axisDataItem = xAxis.makeDataItem({});
    axisDataItem.set("value", 0);


    var clockHand = am5radar.ClockHand.new(root, {
      pinRadius: 5,
      radius: am5.percent(98),
      bottomWidth: 5
    });

    clockHand.pin.setAll({
      fill: am5.color(getTailwindColor(companyColor))// red
    });

    clockHand.hand.setAll({
      fill: am5.color(getTailwindColor(companyColor))// red
    });

    var bullet = axisDataItem.set("bullet", am5xy.AxisBullet.new(root, {
      sprite: clockHand
    }));


    xAxis.createAxisRange(axisDataItem);

    axisDataItem.get("grid")?.set("visible", false);


    axisDataItem.animate({
      key: "value",
      to: company,
      duration: 800,
      easing: am5.ease.out(am5.ease.cubic)
    });


    var axisDataItem2 = xAxis.makeDataItem({});
    axisDataItem2.set("value", 0);

    var clockHand2 = am5radar.ClockHand.new(root, {
      pinRadius: 5,
      radius: am5.percent(98),
      bottomWidth: 5
    });


    clockHand2.pin.setAll({
      fill: am5.color(getTailwindColor(actualColor))  // blue
    });


    clockHand2.hand.setAll({
      fill: am5.color(getTailwindColor(actualColor))  // blue
    });

    var bullet2 = axisDataItem2.set("bullet", am5xy.AxisBullet.new(root, {
      sprite: clockHand2
    }));

    xAxis.createAxisRange(axisDataItem2);

    axisDataItem2.get("grid")?.set("visible", false);


    axisDataItem2.animate({
      key: "value",
      to: actual,
      duration: 800,
      easing: am5.ease.out(am5.ease.cubic)
    });

    am5.array.each(bandsData, function (data) {
      var axisRange = xAxis.createAxisRange(xAxis.makeDataItem({}));
    
      axisRange.setAll({
        value: data.lowScore,
        endValue: data.highScore
      });
    
      axisRange.get("axisFill")?.setAll({
        visible: true,
        fill: data.color,
        fillOpacity: 0.8
      });
    
      axisRange.get("label")?.setAll({
        inside: true,
        fontSize: "0.9em",
        fill: root.interfaceColors.get("background")
      });
    });

    // axisDataItem.get("grid")?.set("visible", false);
    // axisDataItem2.get("grid")?.set("visible", false);
    axisRenderer.labels.template.set("forceHidden", true);
    axisRenderer.grid.template.set("forceHidden", true);
    
    // Clean up
    return () => {
      root.dispose();
    };
  }, [company, target, actual, chartID]);

  return (
    <div id={chartID} className="w-full h-full min-h-[135px]"></div>
  );
};

export default GaugeChart;