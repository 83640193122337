import * as React from "react"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useGetDailyReportData } from "@/lib/getData";
import dayjs from "dayjs";
import { Loader2 } from 'lucide-react';
import PieChartWithString from "@/components/common/PieChartwithString";
import {
  ChartConfig,
} from "@/components/ui/chart";
import { useTranslation } from 'react-i18next';
import { useDateFormat } from "@/hooks/useDateFormat";

interface ProjectRecord {
  job_title: string;
  d_company_name: string;
  equipment_code_name: string;
  d_day_work?: number;
  d_today_total?: number;
  d_eq_ncounter?: number;
  d_eq_xcounter?: number;
  d_eq_mcounter?: number;
  d_eq_int_tearly?: number;
  d_eq_ecounter?: number;
  d_today_etotal?: number;
  d_equip_day_irb?: string;
  date?: string;
}

export function OverviewManEquipPieChart() {

  const { projectDRData, projectDRDataloading } = useGetDailyReportData();
  const { t } = useTranslation();
  const formatDate = useDateFormat();


  ///////인원 데이터////////
  const uniqueRecords = projectDRData ? projectDRData
    .filter((item, index, self) =>
      self.findIndex(i => i.record_no === item.record_no) === index // record_no 기준으로 고유 항목 필터링
    )
    .filter(item =>
      Array.isArray(item._bp_lineitems) && item.d_reporting_date !== null && item.d_company_name !== null
    )
    .reduce((acc, item) => {
      // _bp_lineitems 배열을 평탄화하며 필요한 필드 추가
      const lineItemsWithDetails = item._bp_lineitems?.map(lineItem => ({
        ...lineItem,
        d_reporting_date: item.d_reporting_date,
        d_company_name: item.d_company_name,
        d_subcon_discipline_spd: item.d_subcon_discipline_spd,
      })) || [];
      return acc.concat(lineItemsWithDetails); // 평탄화하여 누적
    }, [] as any[])  // 초기값 타입 명시
    .filter(lineItem =>
      ['Manpower(Picker)', 'Manpower(Optional)', '투입인력'].includes(lineItem.uuu_tab_id)
    ) : [];


  const formattedRecords = uniqueRecords
    .map(record => ({
      ...record,
      date: `${record.d_reporting_date.slice(6, 10)}년 ${record.d_reporting_date.slice(0, 2)}월 ${record.d_reporting_date.slice(3, 5)}일`,
    }))
    .sort((a, b) => {
      const dateA = new Date(a.date.replace(/[년월일\s]/g, '-')).getTime();
      const dateB = new Date(b.date.replace(/[년월일\s]/g, '-')).getTime();
      return dateB - dateA; // 역순 정렬
    })
    .map(record => ({
      ...record,
      job_title: record.d_dash_LIBS_JOBTITLE ?? record.j_jobtitle_bpk, // job_title 설정
    }));


  // formattedRecords 배열이 비어있지 않은지 확인 후 마지막 date 값 추출
  const lastDate = formattedRecords.length > 0 ? formattedRecords[0].date : null;

  // lastDate와 동일한 date 값을 가진 항목들 필터링
  const latestRecords = lastDate
    ? formattedRecords.filter(record => record.date === lastDate)
    : [];

  const colors = ["#0077B6", "#0096C7", "#00B4D8", "#023E8A", "#03045E", "#48CAE4", "#90E0EF", "#ADE8F4", "#D3F7FF"]; // 원하는 색상 배열을 정의

  const chartData = Object.values(
    latestRecords.reduce((acc: Record<string, { id: string; value: number; fill: string }>, record: ProjectRecord) => {
      const key = record.job_title;
      const dayWork = record.d_day_work ?? 0;
      const todayTotal = record.d_today_total ?? 0;

      // 그룹에 job_title 키가 없으면 초기화
      if (!acc[key]) {
        acc[key] = { id: key, value: 0, fill: getColorForJobTitle(key) }; // `fill` 컬러 값 설정
      }

      // 조건에 맞는 값을 누적하여 합산
      acc[key].value += dayWork + todayTotal;

      return acc;
    }, {} as Record<string, { id: string; value: number; fill: string }>)
  ) as { id: string; value: number; fill: string }[] // 타입을 명시적으로 설정

  // getColorForJobTitle 함수를 정의하여 각 job_title에 맞는 색상을 반환
  function getColorForJobTitle(jobTitle: string): string {
    const index = jobTitle.length % colors.length;
    return colors[index];
  }


  // totalValue 계산
  const totalValue = React.useMemo(() => {
    return chartData.reduce((acc, curr) => acc + curr.value, 0);
  }, [chartData]);


  const tableData = Object.values(
    latestRecords.reduce((acc: Record<string, { id: string; value: number }>, record: ProjectRecord) => {
      const key = record.d_company_name;
      const dayWork = record.d_day_work ?? 0;
      const todayTotal = record.d_today_total ?? 0;

      // 그룹에 job_title 키가 없으면 초기화
      if (!acc[key]) {
        acc[key] = { id: key, value: 0 };
      }

      // 조건에 맞는 값을 누적하여 합산
      acc[key].value += dayWork + todayTotal;

      return acc;
    }, {} as Record<string, { id: string; value: number }>)
  ).filter((item): item is { id: string; value: number } => (item as { id: string; value: number }).value !== 0);


  const chartConfig = chartData.reduce((config, { id }, index) => {
    // `index`에 따라 색상을 지정하거나 임의의 색상을 선택
    const colors = ["#2563eb", "#60a5fa", "#34d399", "#f97316", "#a855f7"];
    config[id] = {
      label: id,
      color: colors[index % colors.length], // 색상 반복 사용
    };
    return config;
  }, {} as ChartConfig);


  function getColorForEquip(equipment_code_name: string | null | undefined): string {
    const name = equipment_code_name ?? ""; // equipment_code_name이 null 또는 undefined인 경우 빈 문자열로 대체
    const index = name.length % colors.length;
    return colors[index];
  }

  ///////장비 데이터////////
  const uniqueRecords2 = projectDRData
    ? projectDRData
      .filter((item, index, self) =>
        self.findIndex(i => i.record_no === item.record_no) === index // record_no 기준으로 고유 항목 필터링
      )
      .filter(item =>
        Array.isArray(item._bp_lineitems) && item.d_reporting_date !== null && item.d_company_name !== null
      )
      .reduce((acc, item) => {
        // _bp_lineitems 배열을 평탄화하며 필요한 필드 추가 및 필터링
        const lineItemsWithDetails = item._bp_lineitems
          ?.filter(
            lineItem =>
              (lineItem.d_eq_xcounter ?? 0 + lineItem.d_eq_ncounter ?? 0 + lineItem.d_eq_mcounter ?? 0 + lineItem.d_today_etotal ?? +lineItem.d_eq_ecounter ?? 0) !== 0
          ) // d_eq_xcounter, d_eq_ncounter, d_eq_mcounter가 null 또는 0이 아닌 항목만 필터링
          .map(lineItem => ({
            ...lineItem,
            d_reporting_date: item.d_reporting_date,
            d_company_name: item.d_company_name ? item.d_company_name : item.d_subcontractor_bpk,
          })) || [];
        return acc.concat(lineItemsWithDetails); // 평탄화하여 누적
      }, [] as any[]) // 초기값 타입 명시
      .filter(lineItem =>
        ['Equipment(Picker)', '투입장비'].includes(lineItem.uuu_tab_id)
      )
    : [];

  const formattedRecords2 = uniqueRecords2
    .map(record => ({
      ...record,
      date: `${record.d_reporting_date.slice(6, 10)}년 ${record.d_reporting_date.slice(0, 2)}월 ${record.d_reporting_date.slice(3, 5)}일`,
    }))
    .sort((a, b) => {
      const dateA = new Date(a.date.replace(/[년월일\s]/g, '-')).getTime();
      const dateB = new Date(b.date.replace(/[년월일\s]/g, '-')).getTime();
      return dateB - dateA; // 역순 정렬
    })


  const lastDate2 = formattedRecords2.length > 0 ? formattedRecords2[0].date : null;

  const latestRecords2 = lastDate2
    ? formattedRecords2.filter(record => record.date === lastDate2)
    : [];


  const chartData2 = Object.values(
    latestRecords2.reduce((acc: Record<string, { id: string; value: number; fill: string; date: string }>, record: ProjectRecord) => {
      const key = record.equipment_code_name;
      const d_eq_xcounter = record.d_eq_xcounter ?? 0;
      const d_eq_ncounter = record.d_eq_ncounter ?? 0;
      const d_eq_mcounter = record.d_eq_mcounter ?? 0;
      const d_eq_int_tearly = record.d_eq_int_tearly ?? 0;
      const d_eq_ecounter = record.d_eq_ecounter ?? 0;
      const d_today_etotal = record.d_today_etotal ?? 0;
      const d_equip_day_irb = record.d_equip_day_irb === "투입" ? 1 : 0;

      // 그룹에 job_title 키가 없으면 초기화
      if (!acc[key]) {
        acc[key] = { id: key, value: 0, fill: getColorForEquip(key), date: record.date ?? "" }; // `fill` 컬러 값 설정
      }

      // 조건에 맞는 값을 누적하여 합산
      acc[key].value += d_eq_xcounter + d_eq_ncounter + d_eq_mcounter + d_eq_int_tearly + d_eq_ecounter + d_today_etotal + d_equip_day_irb;

      return acc;
    }, {} as Record<string, { id: string; value: number; fill: string; date: string }>)
  ) as { id: string; value: number; fill: string; date: string }[] // 타입을 명시적으로 설정


  const totalValue2 = React.useMemo(() => {
    return chartData2.reduce((acc, curr) => acc + curr.value, 0);
  }, [chartData2]);

  const tableData2: { id: string; value: number }[] = Object.values(
    latestRecords2.reduce((acc: Record<string, { id: string; value: number; }>, record: ProjectRecord) => {
      const key = record.d_company_name;
      const d_eq_xcounter = record.d_eq_xcounter ?? 0;
      const d_eq_ncounter = record.d_eq_ncounter ?? 0;
      const d_eq_mcounter = record.d_eq_mcounter ?? 0;
      const d_eq_int_tearly = record.d_eq_int_tearly ?? 0;
      const d_eq_ecounter = record.d_eq_ecounter ?? 0;
      const d_today_etotal = record.d_today_etotal ?? 0;
      const d_equip_day_irb = record.d_equip_day_irb === "투입" ? 1 : 0;

      // 그룹에 job_title 키가 없으면 초기화
      if (!acc[key]) {
        acc[key] = { id: key, value: 0 }; // date 속성 생략
      }

      // 조건에 맞는 값을 누적하여 합산
      acc[key].value += d_eq_xcounter + d_eq_ncounter + d_eq_mcounter + d_eq_int_tearly + d_eq_ecounter + d_today_etotal + d_equip_day_irb;

      return acc;
    }, {} as Record<string, { id: string; value: number; }>) // date를 선택적 속성으로 설정
  )


  // 

  console.log(lastDate)


  const chartConfig2 = chartData2.reduce((config, { id }, index) => {
    // `index`에 따라 색상을 지정하거나 임의의 색상을 선택
    const colors = ["#2563eb", "#60a5fa", "#34d399", "#f97316", "#a855f7"];
    config[id] = {
      label: id,
      color: colors[index % colors.length], // 색상 반복 사용
    };
    return config;
  }, {} as ChartConfig);

  return (
    <>
      <Card className="row-span-1 md:col-span-2 col-span-8 rounded-xl border bg-card text-card-foreground shadow flex flex-col">
        <CardHeader className="text-left">
          <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
            <span>{t('manpower')}</span>
            <span className="ml-auto text-xs">{formatDate(dayjs(lastDate, "YYYY년 MM월 DD일"))}</span>
          </CardTitle>
        </CardHeader>
        <CardContent className="flex-1 flex items-center justify-center overflow-hidden pb-2">
          {projectDRDataloading ? (
            <Loader2 className="w-5 h-5 animate-spin" style={{ marginTop: '0%' }} />
          ) : chartData.length > 0 ? (
            <PieChartWithString chartData={chartData} tableData={tableData} totalValue={totalValue} chartConfig={chartConfig} stringLabel={t('person')} tableLabel={t('byCompany')} tableHeader={t('companyName')} />
          ) : (
            <span className="text-xs text-gray-500">{t('noDataAvailable')}</span>
          )}
        </CardContent>
      </Card>

      <Card className="row-span-1 md:col-span-2 col-span-8 rounded-xl border bg-card text-card-foreground shadow flex flex-col">
        <CardHeader className="text-left">
          <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
            <span>{t('equipment')}</span>
            <span className="ml-auto text-xs">{formatDate(dayjs(lastDate2, "YYYY년 MM월 DD일"))}</span>
          </CardTitle>
        </CardHeader>
        <CardContent className="flex-1 flex items-center justify-center overflow-hidden pb-0">
          {projectDRDataloading ? (
            <Loader2 className="w-5 h-5 animate-spin" style={{ marginTop: '0%' }} />
          ) : chartData2.length > 0 ? (
            <PieChartWithString chartData={chartData2} tableData={tableData2} totalValue={totalValue2} chartConfig={chartConfig2} stringLabel={t('unit')}  tableLabel={t('byCompany')} tableHeader={t('companyName')} />
          ) : (
            <span className="text-xs text-gray-500">{t('noDataAvailable')}</span>
          )}
        </CardContent>
      </Card>
    </>
  );
}