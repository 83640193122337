import React from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';

const Timeline = ({ events, startDate, endDate, lineColor = "border-slate-900" , flagPosition = "alternate" }) => {

  // 날짜 위치 계산 함수
  const getRelativePosition = (date) => {
    const totalDuration = dayjs(endDate).diff(dayjs(startDate), 'day');
    const eventDuration = dayjs(date).diff(dayjs(startDate), 'day');
    return (eventDuration / totalDuration) * 100;
  };

  // 색상 결정 함수
  const getLabelColor = (planDate, actualDate) => {
    const today = dayjs();
    const actual = actualDate ? dayjs(actualDate) : null;
    const plan = dayjs(planDate);

    if (actual) {
      if (actual.isBefore(plan)) return "green";
      else if (actual.isAfter(plan)) return "red";
    } else if (plan.isAfter(today)) {
      return "gray";
    }
    return "black";
  };

  return (
    <div
      className="relative h-40 flex items-center"
      style={{
        width: '90%',
      }}
    >
      {/* 가로선 */}
      <div  className={`absolute top-1/2 w-full border-t ${lineColor}`}style={{ transform: 'translateY(-50%)' }}></div>

      {/* 점과 깃발 */}
      {events?.map((event, index) => {
        const baseDate = event.actual_date || event.plan_date;
        const position = getRelativePosition(baseDate);
        const labelColor = getLabelColor(event.plan_date, event.actual_date);
        const borderColor = {
          green: "#16A34A",
          red: "#DC2626",
          gray: "#374151",
          black: "#000000",
        }[labelColor];

        let flagTopPosition ='6%'
        if(flagPosition === "alternate"){
        // 겹침 해결
        const previousEvent = events[index - 2];
        flagTopPosition = index % 2 === 0 ? '6%' : '44%';

        if (previousEvent && Math.abs(getRelativePosition(previousEvent.actual_date || previousEvent.plan_date) - position) < 6) {
          flagTopPosition = index % 2 === 0 ? '77%' : '-26%';
        }} 
        else if(flagPosition === "top"){
          flagTopPosition = '6%';
          const previousEvent = events[index - 1];
          if (previousEvent && Math.abs(getRelativePosition(previousEvent.actual_date || previousEvent.plan_date) - position) < 6) {
            flagTopPosition =  '-26%';
          }
        }else if(flagPosition === "bottom"){
          flagTopPosition = '44%';
        }

        return (
          <React.Fragment key={index}>
            {/* 점 */}
            <div
              className="absolute w-2 h-2 rounded-full"
              style={{
                left: `${position}%`,
                top: '50%',
                transform: 'translate(-50%, -50%)',
                borderColor: borderColor, // 동적으로 borderColor 적용
                borderWidth: '2px',
                backgroundColor: 'white',
              }}
            ></div>

            {/* 깃발 */}
            <div
              className="absolute mt-4 px-1 py-0.5 bg-white border border-gray-300 rounded shadow-sm"
              style={{
                left: `${position}%`,
                top: flagTopPosition,
                transform: 'translateX(-50%)',
              }}
            >
              <div className={clsx("text-[10px] font-medium", {
                "text-green-600": labelColor === "green",
                "text-red-600": labelColor === "red",
                "text-gray-600": labelColor === "gray",
                "text-black": labelColor === "black",
              })}>
                {event.label}
              </div>              <div className="text-[9px] text-gray-700">
                계획: {dayjs(event.plan_date).format("YYYY-MM-DD")}
              </div>
              {event.actual_date && (
                <div className="text-[9px] text-gray-700">
                  실적: {dayjs(event.actual_date).format("YYYY-MM-DD")}
                </div>
              )}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Timeline;