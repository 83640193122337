"use client"

import React, { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, XAxis } from "recharts"

import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart"
import dayjs from "dayjs";
import { DateRangePicker } from '@/components/ui/date-range-picker';
import { useParams } from "react-router-dom";
import { Loader2, RotateCw, RefreshCw } from 'lucide-react';
import api from '@/lib/axiosInstance';
import { Skeleton } from "@/components/ui/skeleton"

interface JobConfig {
    label: string;
    color: string;
    stackId: string;
}

interface ChartConfigData {
    [key: string]: JobConfig
}


interface RawDataItem {
    d_reporting_date: string;
    project_projectnumber: string;
    uuu_record_last_update_date: string;
    record_no: string;
    d_company_name: string;
    discipline: string;
    name: string;
    count: string;
    type: string;
    uniq: string;
}


export function Equipment() {

    const [loading, setLoading] = useState(true);
const { projectCode } = useParams();

    const [rawData, setRawData] = useState<any>(null);
    const [companyData, setCompanyData] = useState<any>(null);
    const [dataRange, setDataRange] = useState<any>({ from: dayjs().add(-3, "months"), to: dayjs() });
    const [viewMode, setViewMode] = useState('company'); // 'company' 또는 'job'
    const [activeChart, setActiveChart] = useState<any>(null);


    const totals = companyData && companyData.reduce((sum: any, item: any) => sum + Number(item.counts), 0)


    const toggleViewMode = () => {
        setViewMode(viewMode === 'company' ? 'job' : 'company');
        setActiveChart(null)
    };

    const chartData = rawData && processData(rawData)


    function processData(data: RawDataItem[]): any[] {
        // Group data by date and job directly
        const groupedByDate: { [date: string]: { [jobName: string]: number } } = {};

        data.forEach(item => {
            const { d_reporting_date, name, count } = item;
            if (!groupedByDate[d_reporting_date]) {
                groupedByDate[d_reporting_date] = {};
            }
            // Aggregate job counts directly under the date
            if (groupedByDate[d_reporting_date][name]) {
                groupedByDate[d_reporting_date][name] += parseFloat(count);
            } else {
                groupedByDate[d_reporting_date][name] = parseFloat(count);
            }
        });

        // Transform grouped data into the required format
        const result: any[] = [];

        Object.entries(groupedByDate).forEach(([date, jobs]) => {
            const entry: any = { date, ...jobs };
            result.push(entry);
        });

        return result;
    }


    const chartConfig = React.useMemo(() => {
        const config: ChartConfigData = {};

        const jobsSet = new Set<string>(); // 중복을 제거하기 위한 Set 생성

        chartData && chartData.forEach((data: any) => {
            Object.keys(data).forEach(key => {
                if (key !== "date") { // 'date' 키를 제외한 모든 키는 직업명
                    jobsSet.add(key); // 직업명을 Set에 추가
                }
            });
        });

        // Set에서 추출된 직업명으로 config 설정
        Array.from(jobsSet).forEach((job, index) => {
            config[job] = {
                label: job,
                color: `hsl(var(--chart-${index + 1}))`, // 동적으로 색상 할당
                stackId: "stack", // 모든 바를 같은 스택에 포함시키기
            };
        });

        return config;
    }, [chartData]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const from = dayjs(dataRange.from).format("YYYY-MM-DD");
            const to = dayjs(dataRange.to).format("YYYY-MM-DD");

            try {
                const endpoint = viewMode === 'company' ? '/getmanequiptotal' : viewMode === 'job' ? '/getmanequiptotal2':'/getmanequiptotal3';
                console.log(endpoint)
                const res = await api.get(`${endpoint}?project=${projectCode}&type=mp&from=${from}&to=${to}`);
                setCompanyData(res.data.Rows);
                if (!activeChart && res.data.Rows.length > 0) { 
                    setActiveChart(res.data.Rows[0].company);
                }
            } catch (error) {
                console.error(`Failed to fetch data for ${viewMode}:`, error);
            }

            setLoading(false);
        };

        if (projectCode) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [projectCode, dataRange, viewMode]); // viewMode 추가

    useEffect(() => {
        if (!activeChart || !projectCode) return;

        const fetchDataDetails = async () => {
            const from = dayjs(dataRange.from).format("YYYY-MM-DD");
            const to = dayjs(dataRange.to).format("YYYY-MM-DD");

            try {
                const detailEndpoint = viewMode === 'company' ? '/getmanequipwith' :  viewMode === 'job' ?'/getmanequipwith2':'/getmanequipwith3';
                const res = await api.get(`${detailEndpoint}?project=${projectCode}&type=mp&company=${activeChart}&from=${from}&to=${to}`);
                setRawData(res.data.Rows);
            } catch (error) {
                console.error(`Failed to fetch detail data for ${viewMode}:`, error);
            }
        };

        fetchDataDetails();
    }, [activeChart, projectCode, dataRange, viewMode]); // viewMode 추가


    const from = dayjs(dayjs(dataRange.from).format("YYYY-MM-DD"));
    const to = dayjs(dayjs(dataRange.to).format("YYYY-MM-DD"));
    const monthDiff = to.diff(from, "M");
    const dayDiff = to.diff(from, "d");
    const period = monthDiff < 1 ? `${dayDiff}D` : `${monthDiff}M`;

    const message = `지난 ${period}의 출입 장비는 ${totals ? totals.toLocaleString() : 0} 대입니다.`;




    return (
        <>
            <Card className="w-full h-1/2 ">
                <CardHeader className="text-left">
                    <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                        <span>장비 현황</span>
                        <span className='ml-auto text-xs"'>
                            <DateRangePicker
                                initialDateFrom={dayjs().add(-3, 'month').format("YYYY-MM-DD")}
                                initialDateTo={dayjs().add(-1, 'days').format("YYYY-MM-DD")}
                                locale={"ko-KR"}
                                showCompare={false}
                                onUpdate={(values) => setDataRange(values.range)} />
                        </span>
                    </CardTitle>
                </CardHeader>
                <CardContent className="relative z-10 [&>div]:rounded-none [&>div]:border-none [&>div]:shadow-none p-0">


                    <Card>
                        <CardHeader className="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">

                            <div className="flex max-w-[180px] flex-col justify-center gap-1 px-6 py-5 sm:py-6">
                            <CardTitle className="text-sm flex items-center justify-between">
                                    <div className="flex items-center space-x-2">
                                        <button
                                            onClick={() => { setViewMode('company'); setActiveChart(null); }}
                                            className={`${viewMode === 'company' ? 'text-black' : 'text-gray-400'}`}
                                        >
                                            협력업체
                                        </button>
                                        <span className="h-4 border-l border-gray-300"></span>
                                        <button
                                            onClick={() => { setViewMode('job'); setActiveChart(null); }}
                                            className={`${viewMode === 'job' ? 'text-black' : 'text-gray-400'}`}
                                        >
                                            직종
                                        </button>
                                        <span className="h-4 border-l border-gray-300"></span>
                                        <button
                                            onClick={() => { setViewMode('discipline'); setActiveChart(null); }}
                                            className={`${viewMode === 'discipline' ? 'text-black' : 'text-gray-400'}`}
                                        >
                                            공종
                                        </button>

                                    </div>
                                </CardTitle>
                                <CardDescription className='text-xs'>
                                {loading ?
                                        <Skeleton className="h-4 w-full" />
                                        :
                                        message
                                    }
                                </CardDescription>
                            </div>
                            <div className="flex max-w-[1400px] overflow-x-auto ml-auto">
                                {companyData && companyData.length > 0 && companyData.map((company: any) => (
                                    <button
                                        key={company.company}
                                        data-active={activeChart === company.company}
                                        className="relative z-30 flex flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l data-[active=true]:bg-muted/50 sm:border-l sm:border-t-0 sm:px-8 sm:py-6 flex-shrink-0"
                                        onClick={() => setActiveChart(company.company)}
                                        style={{ width: 140 }}  // 고정 너비 설정
                                    >
                                        <span
                                            className="text-xs text-muted-foreground overflow-hidden overflow-ellipsis whitespace-nowrap max-w-full"
                                            title={company.company}  // 툴팁으로 전체 텍스트 표시
                                        >
                                            {company.company}
                                        </span>
                                        <span className="text-lg font-bold leading-none sm:text-xl">
                                            {Number(company.counts).toLocaleString()}
                                        </span>
                                    </button>
                                ))}
                            </div>

                        </CardHeader>
                        <CardContent className="px-2 sm:p-0">
                            {loading ?
                                <Skeleton className=" h-[250px] w-full bg-white" />
                                :
                                <ChartContainer
                                    config={chartConfig}
                                    className="aspect-auto h-[250px] w-full"
                                >
                                    <BarChart
                                        accessibilityLayer
                                        data={chartData}
                                        margin={{
                                            left: 24,
                                            right: 24,
                                            top: 36
                                        }}
                                    >
                                        <CartesianGrid vertical={false} />
                                        <XAxis
                                            dataKey="date"
                                            tickLine={false}
                                            axisLine={false}
                                            tickMargin={8}
                                            minTickGap={32}
                                            tickFormatter={(value) => {
                                                const date = new Date(value);
                                                return date.toLocaleDateString("en-US", {
                                                    month: "short",
                                                    day: "numeric",
                                                });
                                            }}
                                        />
                                        <ChartTooltip
                                            content={
                                                <ChartTooltipContent
                                                    className="w-[150px]"
                                                    // nameKey="views"
                                                    labelFormatter={(value) => {
                                                        return new Date(value).toLocaleDateString("en-US", {
                                                            month: "short",
                                                            day: "numeric",
                                                            year: "numeric",
                                                        });
                                                    }}
                                                />
                                            }
                                        />
                                        {Object.keys(chartConfig).map((key) => {
                                            if (key !== "views") {
                                                return (
                                                    <Bar
                                                        key={key}
                                                        dataKey={key}
                                                        fill={chartConfig[key].color}
                                                        stackId={"a"}
                                                        isAnimationActive={false}
                                                    />
                                                );
                                            }
                                            return null;
                                        })}
                                    </BarChart>
                                </ChartContainer>
                            }
                        </CardContent>
                    </Card>

                </CardContent>
            </Card>

        </>
    )
}
