
import React, { useEffect, useState } from 'react';
import api from "@/lib/axiosInstance";
import dayjs from 'dayjs';
import SimpleProgressChart from '../simpleprogress/simplecprogressChart';
import { useGetCProgressData } from "@/lib/getData";
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useParams } from "react-router-dom";

dayjs.extend(weekOfYear);

interface ProgressItem {
    date: string;
    PV: number;
    EV: number;
    cumulativePV: number;
    cumulativeEV: number | null;
    // 필요한 다른 속성들을 추가하세요.
}

interface TransformedData {
    weekProgress: ProgressItem[];
    monthProgress: ProgressItem[];
}

export function ProgressAll() {

    const [loading, setLoading] = React.useState(true);
     const { projectCode } = useParams()
    const [emsData, setEmsData] = useState([]);
    const [epcRatio, setEPCRatio] = useState({EngineeringPCT:0,ProcurementPCT:0, ConstructionPCT:0 });

    const { cProgressData } = useGetCProgressData("전체", "전체", "전체", "전체");
    const [emsWeightValue, setEmsWeightValue] = useState([]);
    const [progressOnOff, setprogressOnOff] = useState({ E: "Off", P: "Off", C: "Off", T: "Off" });

    useEffect(() => {
        const fetchData = async () => {

            if (projectCode) {

                const body = {
                    bpname: "Engineering Progress",
                    lineitem: "no",
                    filter_criteria: {
                        join: "AND",
                        filter: [
                            { field: "status", value: "Terminated", condition_type: "neq" },
                            { field: "PJT_CD", value: projectCode, condition_type: "eq" },
                        ],
                    },
                };

                const res = await api.post(`/getemsbprecord?path=SKEC0001`, body);

                let body2 = {
                    bpname: "EPC Progress Ratio",
                    lineitem: "no",
                };

                let body3 =
                {
                    "bpname": "EPC Progress Ratio",
                    "lineitem": "no",
                }
    
                const res2 = await api.post(`/getemsbprecord?path=${projectCode}`, body2);
                const res3 = await api.post(`/getemsbprecord?path=${projectCode}`, body3);
    
                const record = res2.data.data.data[0];
                setprogressOnOff(record && {
                    "E": record["EngineeringProgressRB"],
                    "P": record["ProcurementProgressRB"],
                    "C": record["ConstructionProgressRB"],
                    "T": record["OverallProgressRB"]
                })

                setEmsData(res.data.data.data);
                setEPCRatio(res3.data.data.data[0]);
            }
        };
        fetchData();
    }, [projectCode]);



    const emsData_e = emsData.filter((v: any) => (v.PROG_TYPE === "E" || v.PROG_TYPE === "R") && v.DISC_NM === "OVERALL")
    const emsData_p = emsData.filter((v: any) => v.PROG_TYPE === "P" && v.DISC_NM === "OVERALL");


    const eDataWeek = emsData_e.filter((v: any) => v.WeekMonthCheck === "W").map((v: any) => ({
        ...v,
        date: dayjs(v.CUT_OFF_DT, "YYYYMMDD").format("YY년 ") + dayjs(v.CUT_OFF_DT, "YYYYMMDD").week() + "주",
        cumulativePV: v.PLAN_PROG,
        cumulativeEV: v.ACTUAL_PROG === 0 ? null : v.ACTUAL_PROG
    }))

    const eDataMonth = emsData_e.filter((v: any) => v.WeekMonthCheck === "M")
        .map((v: any) => ({
            ...v,
            date: dayjs(v.CUT_OFF_DT, "YYYYMMDD").format("YY년 MM월"),
            cumulativePV: v.PLAN_PROG,
            cumulativeEV: v.ACTUAL_PROG === 0 ? null : v.ACTUAL_PROG
        }))


    // 주간 데이터 정렬 및 일별 PV, EV 계산
    const sortedEDataWeek = eDataWeek.sort((a, b) => (a.date > b.date ? 1 : -1));
    sortedEDataWeek.forEach((item, index) => {
        if (index > 0) {
            item.PV = item.cumulativePV - sortedEDataWeek[index - 1].cumulativePV;
            item.EV = item.cumulativeEV ? item.cumlativeEV - sortedEDataWeek[index - 1].cumulativeEV : null;
        } else {
            item.PV = item.cumulativePV; // 첫 주의 경우 전체가 일별 값
            item.EV = item.cumulativeEV; // 첫 주의 경우 전체가 일별 값
        }
    });

    // 월간 데이터 정렬 및 일별 PV, EV 계산
    const sortedEDataMonth = eDataMonth.sort((a, b) => (a.date > b.date ? 1 : -1));
    sortedEDataMonth.forEach((item, index) => {
        if (index > 0) {
            item.PV = item.cumulativePV - sortedEDataMonth[index - 1].cumulativePV;
            item.EV = item.cumulativeEV ? item.cumulativeEV - sortedEDataMonth[index - 1].cumulativeEV : null;
        } else {
            item.PV = item.cumulativePV; // 첫 달의 경우 전체가 일별 값
            item.EV = item.cumulativeEV; // 첫 달의 경우 전체가 일별 값
        }
    });


    const pDataWeek = emsData_p.filter((v: any) => v.WeekMonthCheck === "W").map((v: any) => ({
        ...v,
        date: dayjs(v.CUT_OFF_DT, "YYYYMMDD").format("YY년 ") + dayjs(v.CUT_OFF_DT, "YYYYMMDD").week() + "주",
        cumulativePV: v.PLAN_PROG,
        cumulativeEV: v.ACTUAL_PROG === 0 ? null : v.ACTUAL_PROG
    }))

    const pDataMonth = emsData_p.filter((v: any) => v.WeekMonthCheck === "M")
        .map((v: any) => ({
            ...v,
            date: dayjs(v.CUT_OFF_DT, "YYYYMMDD").format("YY년 MM월"),
            cumulativePV: v.PLAN_PROG,
            cumulativeEV: v.ACTUAL_PROG === 0 ? null : v.ACTUAL_PROG
        }))


    // 주간 데이터 정렬 및 일별 PV, EV 계산
    const sortedPDataWeek = pDataWeek.sort((a, b) => (a.date > b.date ? 1 : -1));
    sortedPDataWeek.forEach((item, index) => {
        if (index > 0) {
            item.PV = item.cumulativePV - sortedPDataWeek[index - 1].cumulativePV;
            item.EV = item.cumulativeEV ? item.cumulativeEV - sortedPDataWeek[index - 1].cumulativeEV : null;
        } else {
            item.PV = item.cumulativePV; // 첫 주의 경우 전체가 일별 값
            item.EV = item.cumulativeEV; // 첫 주의 경우 전체가 일별 값
        }
    });

    // 월간 데이터 정렬 및 일별 PV, EV 계산
    const sortedPDataMonth = pDataMonth.sort((a, b) => (a.date > b.date ? 1 : -1));
    sortedPDataMonth.forEach((item, index) => {
        if (index > 0) {
            item.PV = item.cumulativePV - sortedPDataMonth[index - 1].cumulativePV;
            item.EV = item.cumulativeEV ? item.cumulativeEV - sortedPDataMonth[index - 1].cumulativeEV : null;
        } else {
            item.PV = item.cumulativePV; // 첫 달의 경우 전체가 일별 값
            item.EV = item.cumulativeEV; // 첫 달의 경우 전체가 일별 값
        }
    });

    const eProgressData = { weekProgress: sortedEDataWeek, monthProgress: sortedEDataMonth }
    const pProgressData = { weekProgress: sortedPDataWeek, monthProgress: sortedPDataMonth }


    // 시공 공정률 데이터
    const sumWeightValue = cProgressData ? cProgressData
        .filter((v: any) => v.monthweek === "M")
        .reduce((sum: number, item: any) => sum + item.PV_DR, 0) : 0;


    const groupData = (data: any, monthweek: string) => {
        return data && data
            .filter((item: any) => item.monthweek === monthweek)
            .reduce((acc: any, item: any) => {
                if (!acc[item.date]) {
                    acc[item.date] = { EV_DR: 0, PV_DR: 0 };
                }
                acc[item.date].EV_DR += item.EV_DR;
                acc[item.date].PV_DR += item.PV_DR;
                return acc;
            }, {});
    };

    const calculatePercentages = (groupedData: any, sumWeight: number) =>
        groupedData && Object.keys(groupedData).map(date => ({
            date,
            'EV': (groupedData[date].EV_DR / sumWeight) * 100,
            'PV': (groupedData[date].PV_DR / sumWeight) * 100,
        }));

    const groupData_W = cProgressData && cProgressData?.length > 0 && calculatePercentages(groupData(cProgressData, "W"), sumWeightValue);
    const groupData_M = cProgressData && cProgressData?.length > 0 && calculatePercentages(groupData(cProgressData, "M"), sumWeightValue);


    const calculateCumulativeValues = (data: any) => {
        let cumulativePV = 0;
        let cumulativeEV = 0;
        return data.map((item: any) => {
            cumulativePV += item.PV;
            cumulativeEV += item.EV || 0;
            return {
                ...item,
                cumulativePV,
                cumulativeEV,
            };
        });
    };

    const criteriaDate = dayjs().add(-1, 'days'); // 기준 날짜 설정 필요

    const transformedDataWithCumulative: TransformedData = {
        weekProgress: groupData_W && groupData_W.length > 0 && calculateCumulativeValues(groupData_W).map((item: any) => {
            const itemDate = dayjs(item.date, 'YY년 WW주');
            if (itemDate.isAfter(criteriaDate)) {
                return { ...item, cumulativeEV: null };
            }
            return item;
        }),
        monthProgress: groupData_M && groupData_M.length > 0 && calculateCumulativeValues(groupData_M).map((item: any) => {
            const itemDate = dayjs(item.date, 'YY년 MM월');
            if (itemDate.isAfter(criteriaDate)) {
                return { ...item, cumulativeEV: null };
            }
            return item;
        }),
    };


    const allListWeek = groupData_W && [
        ...sortedEDataWeek.map((v)=>({date:v.date, PV:v.PV*epcRatio.EngineeringPCT/100, EV:v.EV*epcRatio.EngineeringPCT/100})),
        ...sortedPDataWeek.map((v)=>({date:v.date, PV:v.PV*epcRatio.ProcurementPCT/100, EV:v.EV*epcRatio.ProcurementPCT/100})),
        ...groupData_W.map((v:any)=>({date:v.date, PV:v.PV*epcRatio.ConstructionPCT/100, EV:v.EV*epcRatio.ConstructionPCT/100}))
    ]




    const allListMonth = groupData_M && [
        ...sortedEDataMonth.map((v)=>({date:v.date, PV:v.PV*epcRatio.EngineeringPCT/100, EV:v.EV*epcRatio.EngineeringPCT/100})),
        ...sortedPDataMonth.map((v)=>({date:v.date, PV:v.PV*epcRatio.ProcurementPCT/100, EV:v.EV*epcRatio.ProcurementPCT/100})),
      ...groupData_M.map((v:any)=>({date:v.date, PV:v.PV*epcRatio.ConstructionPCT/100, EV:v.EV*epcRatio.ConstructionPCT/100}))
    ]
    const transformedDataWithCumulativeOverall: TransformedData = {
        weekProgress: allListWeek && allListWeek.map((item: any) => {
            const itemDate = dayjs(item.date, 'YY년 WW주');
            if (itemDate.isAfter(criteriaDate)) {
                return { ...item, cumulativeEV: null };
            }
            return item;
        }),
        monthProgress:allListMonth && allListMonth.map((item: any) => {
            const itemDate = dayjs(item.date, 'YY년 MM월');
            if (itemDate.isAfter(criteriaDate)) {
                return { ...item, cumulativeEV: null };
            }
            return item;
        }),
    };



    return (
        <>

            {progressOnOff && progressOnOff.E === 'On' && progressOnOff.P === 'On' &&progressOnOff.C === 'On' &&(
                <SimpleProgressChart transformedDataWithCumulative={transformedDataWithCumulativeOverall} chartId='Overall-Surve' ChartName={"Overall Progress"} />
            )}

            {progressOnOff && progressOnOff.E === 'On' && (
                <SimpleProgressChart transformedDataWithCumulative={eProgressData} chartId='E-Surve' ChartName={"Engineering Progress"} />
            )}

            {progressOnOff && progressOnOff.P === 'On' && (
                <SimpleProgressChart transformedDataWithCumulative={pProgressData} chartId='P-Surve' ChartName={"Procurement Progress"} />
            )}

            {progressOnOff && progressOnOff.C === 'On' && (
                <SimpleProgressChart transformedDataWithCumulative={transformedDataWithCumulative} chartId='C-Surve' ChartName={"Contruction Progress"} />
            )}

        </>
    )
}