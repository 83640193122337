

import * as React from "react"

import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { useParams } from "react-router-dom";
import api from '@/lib/axiosInstance';
import dayjs from "dayjs";
import { Loader2 } from "lucide-react";

interface ChartData {
    id: string;
    value: number;
}


export function ManagerCard() {

    const [loading, setLoading] = React.useState(true);
const { projectCode } = useParams();

    const [rawData, setRawData] = React.useState<any>(null);


    console.log(rawData)
    React.useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                let body = {
                    bpname: "안전보건 인력 선임현황",
                    lineitem: "no",
                    filter_condition: "status=Active",
                };
                const res = await api.post(`/getemsbprecord?path=${projectCode}`, body);
                setRawData(res.data.data.data
                    .sort((a: any, b: any) => a.record_no - b.record_no)
                    .reverse()[0]
                )

            } catch (error) {
                console.error(`Failed to fetch data for:`, error);
            }

            setLoading(false);
        };

        if (projectCode) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [projectCode]); // viewMode 추가

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <Loader2 className="w-5 h-5 animate-spin" />
            </div>
        );
    }


    return (
        <>

            <Card className="h-[31vh] row-span-1 md:col-span-4 col-span-4 rounded-xl border bg-card text-card-foreground shadow flex flex-col">
                <CardHeader className="text-left">
                    <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                        <span>안전보건 인력 선임 현황</span>
                        <span className="ml-auto text-xs">{dayjs(rawData?.uuu_record_last_update_date).format("YYYY년 MM월 DD일")}</span>
                    </CardTitle>
                </CardHeader>
                <CardContent className="flex-1 flex items-center justify-center gap-x-4">
                    <Card className="flex-1 h-full">
                        <CardHeader className="text-left">
                            <CardTitle className="text-gray-700 flex items-center justify-between text-base leading-none tracking-tight">
                                안전관리자 현황
                            </CardTitle>
                        </CardHeader>
                        <CardContent className="h-2/3 flex items-center justify-center text-center">
                            선임: {rawData?.SafetyActualDA}명 / 법적: {rawData?.SafetyLegalDA}명
                        </CardContent>
                    </Card>
                    <Card className="flex-1 h-full">
                        <CardHeader className="text-left">
                            <CardTitle className="text-gray-700 flex items-center justify-between text-base leading-none tracking-tight">
                                보건관리자 현황
                            </CardTitle>
                        </CardHeader>
                        <CardContent className="h-2/3 flex items-center justify-center text-center">
                            선임: {rawData?.HealthActualDA}명 / 법적: {rawData?.HealthLegalDA}명
                        </CardContent>
                    </Card>
                </CardContent>
            </Card>


        </>
    )
}
