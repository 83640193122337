import * as React from "react"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import dayjs from "dayjs";
import { CWPStatus } from "@/components/cwp/cwp";

export default function CWP() {
  return (
    <div className="flex flex-1 flex-col w-full h-full gap-4 p-4">
      <CWPStatus/>
   
    </div>
  )
}