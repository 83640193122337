import api from '@/lib/axiosInstance';

interface LogoutParams {
  project: string;
  client: boolean;
  tr?: boolean; // `tr`은 선택적 파라미터
}

export const logout = async ({  project, client, tr = false }: LogoutParams): Promise<void> => {
  try {
    // 서버에 로그아웃 요청
    await api.get('/logout', {
      params: {
        project,
      },
      withCredentials: true,
    });

    // 리다이렉트 경로 설정
    const redirectPath = tr
      ? `/${project}/clientTR`
      : client
      ? `/${project}/client/login`
      : `/${project}/login`;

    // 로그인 페이지로 리다이렉트
    window.location.href = redirectPath;
  } catch (error) {
    console.error('Error during logout:', error);
  }
};