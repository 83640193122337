import { Card, CardHeader, CardTitle,CardContent } from "@/components/ui/card";
import { EPCTimeLine } from "@/components/epcprogress/timeLine";
import { ProgressAll } from "@/components/epcprogress/progressAll";


export default function Progress() {

  return (
    <div className="flex flex-1 flex-col gap-4 p-4 w-full">
      {/* 마일스톤 */}
      <Card className="h-[39vh] w-full rounded-xl border bg-card text-card-foreground shadow">
        <EPCTimeLine />
      </Card>
      <Card className="min-h-[100vh] flex-1 rounded-xl md:min-h-min">
        <CardHeader className="text-left">
          <CardTitle className='text-base leading-none tracking-tight'>EPC Progress</CardTitle>
        </CardHeader>

        <CardContent className="flex flex-col justify-center items-center gap-2">
          <ProgressAll/>
        </CardContent>
      </Card>
    </div>
  );
}