
import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { useGetProjectList } from '@/hooks/useQuery';
import { useAtom } from 'jotai';
import { divisionState } from '@/atoms';
import api from '@/lib/axiosInstance';
import dayjs from 'dayjs';
import { Loader2 } from 'lucide-react';
import { Bar, BarChart, CartesianGrid, LabelList, XAxis, YAxis, Text, Pie, PieChart, Cell, Tooltip, Label } from "recharts"
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";

interface LTIRProps {
}

interface LastLTIRData {
    _bp_lineitems?: any[];
    LTIRCumulDA?: number;
    TargetLTIR?: number;
}

interface Data {
    LTICategorySPD: string;
    InjuryName: string;
    [key: string]: any;

    // 다른 속성들...
}

interface Result {
    id: string;
    value: number;
}


const LTIRTotal: React.FC<LTIRProps> = () => {
    const [loading, setLoading] = useState<boolean>(true); // Loading state
    const { data: projectsList } = useGetProjectList();
    const [division] = useAtom(divisionState);
    const [LTIRData, setLTIRData] = useState([]);
    const [lastLTIRdata, setLastLTIRdata] = useState<LastLTIRData>({});
    const [open, setOpen] = React.useState(false); // State to control the dialog


    const ltirlist = lastLTIRdata?._bp_lineitems?.map((o: any) => ({
        ...o,
        전사LITR: lastLTIRdata.LTIRCumulDA,
        타겟LTIR: lastLTIRdata.TargetLTIR
    })).filter(v => !division ? projectsList?.some(vf => vf.projectcode === v.ugenProjectNumber) : v.SKEcoDivisionSPD.replace('/', '').includes(division) && projectsList?.some(vf => vf.projectcode === v.ugenProjectNumber))
        .map(v => ({
            ...v,
            ...projectsList?.find(sp => sp.projectcode === v.ugenProjectNumber)
        })) || [];


    const ltir_group = ltirlist.reduce((acc: any, curr: any) => {
        const key = !division ? curr.SKEcoDivisionSPD : curr.projectname;
        if (!acc[key]) {
            acc[key] = { group: key, ltir: 0, totalMH: 0 };
        }
        acc[key].ltir += curr.LTICumulShRatioDA;
        acc[key].totalMH += curr.CumulTotalMH;
        return acc;
    }, {});

    const ltir = Object.values(ltir_group).map((group: any) => ({
        ...group,
        ltir: (group.ltir / group.totalMH) * 200000
    })).filter(group => group.group && group.group !== '본사');



    const ltirpoint = ((ltir.reduce((sum, item) => sum + item.ltir, 0) / ltir.length) || 0).toFixed(3);


    function countCategories(data: Data[], categoryKey: string): Result[] {
        const countMap = new Map<string, number>();

        data.forEach((item) => {
            const category = item[categoryKey];
            if (countMap.has(category)) {
                countMap.set(category, countMap.get(category)! + 1);
            } else {
                countMap.set(category, 1);
            }
        });

        const result: Result[] = Array.from(countMap, ([id, value]) => ({ id, value }));
        return result;
    }

    const colors = ["#0077B6", "#0096C7", "#00B4D8", "#023E8A", "#03045E", "#48CAE4", "#90E0EF", "#ADE8F4", "#D3F7FF"]; // 원하는 색상 배열을 정의

    const filteredData = division === null ? LTIRData : LTIRData.filter((v: any) => v.SKEcoDivisionSPD.includes(division));

    const chartData = countCategories(filteredData, "LTICategorySPD").map((v, index) => ({
        ...v,
        fill: colors[index]
    }))

    const chartConfig = chartData.reduce((config, { id }, index) => {
        // `index`에 따라 색상을 지정하거나 임의의 색상을 선택
        const colors = ["#2563eb", "#60a5fa", "#34d399", "#f97316", "#a855f7"];
        config[id] = {
            label: id,
            color: colors[index % colors.length], // 색상 반복 사용
        };
        return config;
    }, {} as ChartConfig);


    const totalValue = chartData.reduce((sum, item) => sum + item.value, 0);

    const tableData = countCategories(filteredData, "InjuryName")



    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading

            try {

                let body = {
                    "bpname": "LTI 리스트",
                    "lineitem": "no",
                    "filter_criteria": {
                        "join": "AND",
                        "filter": [
                            {
                                "field": "YearText",
                                "value": dayjs().format("YYYY년"),
                                "condition_type": "eq"
                            },
                            {
                                "field": "status",
                                "value": "Active",
                                "condition_type": "eq"
                            },
                        ]
                    }
                }
                const res = await api.post(`/getbprecordtotal?path=SKEC0001`, body);
                setLTIRData(res.data.data.data);

                let body2 = {
                    "bpname": "LTIR 현황",
                    "lineitem": "yes",
                    "filter_criteria": {
                        "join": "AND",
                        "filter": [
                            {
                                "field": "YearText",
                                "value": dayjs().format("YYYY년"),
                                "condition_type": "eq"
                            },
                            {
                                "field": "status",
                                "value": "Active",
                                "condition_type": "eq"
                            },
                        ]
                    }
                }


                const res2 = await api.post(`/getbprecordtotal?path=SKEC0001`, body2);
                const last_data = res2.data.data.data.sort((a: any, b: any) => b.record_no - a.record_no)[0];
                setLastLTIRdata(last_data);

            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false); // End loading
            }
        };


        fetchData();
    }, []);


    return (
        <>
            {loading ?

                (<div className="flex justify-center items-center h-full">
                    <Loader2 className="w-5 h-5 animate-spin" />
                </div>) :

                <div className="flex flex-col w-full h-full">
                    <div className="grid grid-cols-2 gap-4 flex-1">
                        {/* Left Section */}
                        <div className="flex flex-col justify-center text-center">
                            <div className="p-4">
                                <span className="text-xl">
                                    {ltirpoint}
                                    <span className="text-xs">LTIR</span>
                                </span>
                            </div>
                            <div className="overflow-auto h-[14.5vh]">
                                <Table className="text-xs text-center">
                                    <TableBody>
                                        {ltir.map((v, index) => (
                                            <TableRow key={index}>
                                                <TableCell className="p-2">{v.group}</TableCell>
                                                <TableCell className="p-2">{v.ltir?.toFixed(3)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>

                        {/* Right Section */}
                        <div className="flex flex-col text-center h-[14.5vh]">
                            <div className="text-xs">유형별 LTI</div>
                            <ChartContainer
                                config={chartConfig}
                                className="mx-auto aspect-square max-h-[200px] w-full -mt-4"       
                            >
                                <PieChart>
                                    <ChartTooltip
                                        content={<ChartTooltipContent nameKey="id" />}
                                    />
                                    <Pie
                                        data={chartData}
                                        dataKey="value"
                                        nameKey="id"
                                        strokeWidth={1}
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={45}
                                        innerRadius={30}
                                        labelLine={false}
                                        label={({ cx, cy, midAngle, outerRadius, value, name }) => {
                                            const RADIAN = Math.PI / 180;
                                            const labelRadius = outerRadius + 8;
                                            const x = cx + labelRadius * Math.cos(-midAngle * RADIAN);
                                            const y = cy + labelRadius * Math.sin(-midAngle * RADIAN);
                                            const percentage = ((value / totalValue) * 100).toFixed(2);

                                            return (
                                                <text
                                                    x={x}
                                                    y={y}
                                                    fill="#666"
                                                    textAnchor={x > cx ? "start" : "end"}
                                                    dominantBaseline="central"
                                                    className="text-[10px] fill-foreground"
                                                >
                                                    <tspan x={x} dy="0em">{`${name} ${value.toFixed(0)}`}</tspan>
                                                    <tspan className="text-[8px]" x={x} dy="1.2em">({`${percentage}`})%</tspan>
                                                </text>
                                            );
                                        }}
                                    >
                                        <Label
                                            content={({ viewBox }) => {
                                                if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                                                    // Calculate the starting Y position to center the entire text block

                                                    const startY = viewBox.cy ? viewBox.cy - 4 : 0;  
                                                    return (
                                                        <text
                                                            x={viewBox.cx}
                                                            y={startY}
                                                            textAnchor="middle"
                                                            dominantBaseline="middle"
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => setOpen(true)}
                                                        >
                                                            <tspan
                                                                x={viewBox.cx}
                                                                y={startY}
                                                                className="fill-foreground text-md font-bold"
                                                            >
                                                                {totalValue}
                                                            </tspan>
                                                            <tspan
                                                                x={viewBox.cx}
                                                                y={startY + 12}
                                                                className="fill-muted-foreground text-[9px]"
                                                            >
                                                                건
                                                            </tspan>
                                                        </text>
                                                    );
                                                }
                                            }}
                                        />
                                    </Pie>
                                    <Tooltip />
                                </PieChart>
                            </ChartContainer>
                        </div>

                        {/* Dialog */}
                        <Dialog open={open} onOpenChange={setOpen}>
                            <DialogContent>
                                <DialogHeader>
                                    <DialogTitle>사고유형별 정보</DialogTitle>
                                </DialogHeader>
                                <div className="overflow-hidden">
                                    <Table>
                                        <TableHeader>
                                            <TableRow>
                                                <TableHead className="w-[300px]">사고유형</TableHead>
                                                <TableHead className="text-right">건</TableHead>
                                            </TableRow>
                                        </TableHeader>
                                    </Table>
                                </div>
                                <div className="overflow-auto h-[45vh]">
                                    <Table>
                                        <TableBody>
                                            {tableData?.map((v, index) => (
                                                <TableRow key={index}>
                                                    <TableCell className="font-medium">{v.id}</TableCell>
                                                    <TableCell className="text-right">{v.value}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </div>
                </div>
            }
        </>
    )
};
export default LTIRTotal;