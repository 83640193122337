import { SidebarProvider, SidebarRail } from "@/components/ui/sidebar";
import React, { useEffect } from "react";
import { useSetAtom } from "jotai";
import { sidebarCollapsedState } from "@/atoms";
import { Outlet } from 'react-router-dom';
import { TotalSidebar } from "@/pages/layouts/total-sidebar";


export default function TotalLayout() {
  const [open, setOpen] = React.useState(true);
  const setSidebarCollapsed = useSetAtom(sidebarCollapsedState);


  useEffect(() => {
    setSidebarCollapsed(!open);
  }, [open]);


    return (
      <>

          <SidebarProvider open={open} onOpenChange={setOpen}>
            <TotalSidebar />
            <main className="flex-1 antialiased">
              <SidebarRail />
              <Outlet />
            </main>
          </SidebarProvider>
   
      </>
    );
  }

