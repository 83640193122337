
import React, { useEffect, useState } from 'react';
import { TrendingUp, TrendingDown, MoveRight } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useGetPQIdata } from '@/lib/getData';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import dayjs from 'dayjs';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';

interface PQIData {
    PQIYearMonth: string;
    d_company_name: string;
    PQIPoint: number;
}

interface CompanyData {
    name: string;
    total: number;
    count: number;
    average: string;
    [key: string]: number | string; // 모든 월별 데이터를 숫자로 처리하고 없는 경우 0을 사용
}

const PQINumber = () => {

    const [selectedValue, setSelectedValue] = useState('SKEE');
    const { projectPQIVendorData, projectPQIData, projectPQIDataloading } = useGetPQIdata();
    const [open, setOpen] = useState(false); // State to control the dialog


    const currentYear = dayjs().format("YY년")
    const currentYear2 = dayjs().format("YYYY년")
    const months = Array.from({ length: 12 }, (_, i) => `${currentYear} ${String(i + 1).padStart(2, '0')}월`);

    const reduceDataForTable = (data: PQIData[]): CompanyData[] => {
        const companyData: { [key: string]: CompanyData } = {};

        data.forEach(({ PQIYearMonth, d_company_name, PQIPoint }) => {
            // 월 포맷을 '01', '02' 등으로 변경
            const formattedMonth = PQIYearMonth.replace(/(\d+)월/, (_, m) => `${m.padStart(2, '0')}월`);

            if (!companyData[d_company_name]) {
                companyData[d_company_name] = {
                    name: d_company_name,
                    total: 0,
                    count: 0,
                    average: '0',
                };
                months.forEach(month => companyData[d_company_name][month] = '-');
            }
            companyData[d_company_name][formattedMonth.replace(`${currentYear2} `, `${currentYear} `)] = PQIPoint.toFixed(2);
            companyData[d_company_name].total += PQIPoint;
            companyData[d_company_name].count += 1;
        });

        Object.values(companyData).forEach(company => {
            company.average = (company.total / company.count).toFixed(2);
        });

        return Object.values(companyData);
    };

    const tableProjectPQIVendorData = reduceDataForTable(projectPQIVendorData ? projectPQIVendorData : []);


    const yearMonthValues: number[] = projectPQIData?.[0]
        ? Object.keys(projectPQIData[0])
            .filter(key => key.includes('년') && key.includes('월'))
            .map(key => Number(projectPQIData[0][key]))
            .sort((a, b) => b - a)
            .slice(0, 2)
        : [];


    // 날짜 문자열을 비교 가능한 형식으로 변환하는 함수
    const convertToComparable = (dateStr: string): number => {
        const [year, month] = dateStr.replace(/[년월\s]/g, '-').split('-');
        return parseInt(year) * 100 + parseInt(month);
    };


    const uniqueMonths = Array.from(new Set(projectPQIVendorData?.map(item => item.PQIYearMonth)));

    // 상위 2개의 날짜 찾기
    const topTwoMonths = uniqueMonths
        .sort((a, b) => convertToComparable(b) - convertToComparable(a))
        .slice(0, 2);

    // 각 날짜에 해당하는 데이터 필터링
    const firstMonthData = projectPQIVendorData?.filter(item => item.PQIYearMonth === topTwoMonths[0]);
    const secondMonthData = projectPQIVendorData?.filter(item => item.PQIYearMonth === topTwoMonths[1]);

    const firstVendorPQI = firstMonthData &&
        firstMonthData.reduce((sum, item) => sum + item.PQIPoint, 0) / firstMonthData.length;

    const secondVendorPQI = secondMonthData &&
        secondMonthData.reduce((sum, item) => sum + item.PQIPoint, 0) / secondMonthData.length;


    const [firstMaxPQI, secondMaxPQI] = yearMonthValues;

    const presentPQI = selectedValue === "SKEE" ? (firstMaxPQI ? firstMaxPQI.toFixed(1) : '0') : (firstVendorPQI ? firstVendorPQI.toFixed(1) : '0');
    const pastPQI = selectedValue === "SKEE" ? (secondMaxPQI ? secondMaxPQI.toFixed(1) : '0') : (secondVendorPQI ? secondVendorPQI.toFixed(1) : '0');


    const getColor = (value: string) => {
        if (parseFloat(value) >= 80) return 'text-green-500';
        if (parseFloat(value) >= 70) return 'text-amber-500';
        return 'text-red-500';
    };

    const getColorForValue = (value: number, max: number, min: number) => {
        if (value === max) return 'text-green-600'; // 최대값은 초록색
        if (value === min) return 'text-red-600'; // 최소값은 빨간색
        return ''; // 
    };

    const renderComparisonIcon = (currentValue: string, previousValue: string) => {

        const difference = parseFloat(currentValue) - parseFloat(previousValue);
        if (difference > 0) {
            return (
                <div className="flex items-center text-gray-500">
                    <TrendingUp className="w-4 h-4 mr-1" />
                    <span className="text-sm">{difference.toFixed(1)}</span>
                </div>
            );
        } else if (difference < 0) {
            return (
                <div className="flex items-center text-gray-500">
                    <TrendingDown className="w-4 h-4 mr-1" />
                    <span className="text-sm">{Math.abs(difference).toFixed(1)}</span>
                </div>
            );
        } else {
            return (
                <div className="flex items-center text-gray-500">
                    <MoveRight className="w-4 h-4 mr-1" />
                    <span className="text-sm">0</span>
                </div>
            );
        }
    };



    return (
        <>
            <div className="col-span-1 h-full relative">
                <div className="absolute top-0 left-0 right-0 flex justify-between">
                    <Button size="sm" className='h-8' variant="outline" onClick={() => setOpen(true)} >
                        <span className="text-xs">연간 PQI</span>
                    </Button>

                    <ToggleGroup className='gap-0' size="sm" type="single" value={selectedValue} onValueChange={setSelectedValue}>
                        <ToggleGroupItem className='h-6 rounded-[0px]' value="SKEE" aria-label="SKEE">
                            <span className="text-xs">SKEE</span>
                        </ToggleGroupItem>
                        <ToggleGroupItem className='h-6 rounded-[0px]' value="BizP" aria-label="Biz.P">
                            <span className="text-xs">Biz.P</span>
                        </ToggleGroupItem>
                    </ToggleGroup>
                </div>
                <div className="h-full pt-10 pb-4 flex flex-col">
                    <div className="flex-1 flex items-center justify-center">
                        <div className="flex items-baseline">
                            <div className={`text-4xl ${getColor(presentPQI)} text-center mr-2`}>
                                {presentPQI}
                            </div>
                            {renderComparisonIcon(presentPQI, pastPQI)}
                        </div>
                    </div>
                    <div className="mx-auto flex items-center space-x-8">
                        <div className="flex flex-col items-center space-y-1">
                            <div className="flex items-center space-x-1">
                                <div className="w-3 h-3 bg-green-500 rounded-full"></div>
                                <div className="text-xs">Good</div>
                            </div>
                            <div className="text-xs text-center text-muted-foreground">PQI &gt; 80</div>
                        </div>
                        <div className="flex flex-col items-center space-y-1">
                            <div className="flex items-center space-x-1">
                                <div className="w-3 h-3 bg-amber-500 rounded-full"></div>
                                <div className="text-xs">Avg</div>
                            </div>
                            <div className="text-xs text-center text-muted-foreground">70 ≤ PQI ≤ 80</div>
                        </div>
                        <div className="flex flex-col items-center space-y-1">
                            <div className="flex items-center space-x-1">
                                <div className="w-3 h-3 bg-red-500 rounded-full"></div>
                                <div className="text-xs">Bad</div>
                            </div>
                            <div className="text-xs text-center text-muted-foreground">PQI &lt; 70</div>
                        </div>
                    </div>
                </div>
            </div>
        
            <Dialog open={open} onOpenChange={setOpen} >
                <DialogContent className="w-full max-w-7xl max-h-[65vh]">
                    <DialogHeader>
                        <DialogTitle>연간 PQI</DialogTitle>
                    </DialogHeader>

                    <Tabs defaultValue="project" className="w-full">
                        <TabsList className="grid w-full grid-cols-2">
                            <TabsTrigger value="project">Project 연간 월 PQI</TabsTrigger>
                            <TabsTrigger value="vendor">업체별 연간  월 PQI</TabsTrigger>
                        </TabsList>
                        <TabsContent value="project" className='relative'>
                            <div className='overflow-hidden'>
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead className="w-[150px] text-xs p-2">프로젝트명</TableHead>
                                            {months.map(month => <TableHead className="w-[100px] text-xs p-2" key={month}>{month}</TableHead>)}
                                            <TableHead className="w-[80px] text-xs p-2">평균</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                </Table>
                            </div>
                            <div className='overflow-auto h-[45vh]'>
                                <Table>
                                    <TableBody>
                                        {projectPQIData?.map((row) => {

                                            const values = months.map(month => Number(row[month]) || 0).filter(value => value !== 0);
                                            const max = Math.max(...values);
                                            const min = Math.min(...values);
                                            return (
                                                <TableRow key={row.projectname}>
                                                    <TableCell className="w-[150px] font-medium text-xs p-2">{row.projectname}</TableCell>
                                                    {months.map(month => (
                                                        <TableCell className={`w-[100px] text-xs p-2 ${getColorForValue(Number(row[month]), max, min)}`} key={month}>
                                                            {row[month] !== undefined ? (row[month] as number).toFixed(2) : '-'}
                                                        </TableCell>
                                                    ))}
                                                    <TableCell className='w-[0px] text-xs p-2'>{row.average}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </TabsContent>
                        <TabsContent value="vendor" className='relative'>
                            <div className='overflow-hidden'>
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead className="w-[150px] text-xs p-2">업체명</TableHead>
                                            {months.map(month => <TableHead className="w-[100px] text-xs p-2" key={month}>{month}</TableHead>)}
                                            <TableHead className="w-[80px] text-xs p-2">평균</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                </Table>
                            </div>
                            <div className='overflow-auto h-[45vh]'>
                                <Table>
                                    <TableBody>
                                        {tableProjectPQIVendorData.map((row) => {
                                            const values = months.map(month => Number(row[month]) || 0).filter(value => value !== 0);
                                            const max = Math.max(...values);
                                            const min = Math.min(...values);
                                            return (
                                                <TableRow key={row.name}>
                                                    <TableCell className="w-[150px] font-medium text-xs p-2">{row.name}</TableCell>
                                                    {months.map(month => (
                                                        <TableCell className={`w-[100px] text-xs p-2 ${getColorForValue(Number(row[month]), max, min)}`} key={month}>
                                                            {row[month] || '-'}
                                                        </TableCell>
                                                    ))}
                                                    <TableCell className='w-[0px] text-xs p-2'>{row.average}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </TabsContent>
                    </Tabs>

                </DialogContent>
            </Dialog>
        </>
    )

}

export default PQINumber;
