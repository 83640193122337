
import React, { useEffect, useState } from 'react';
import api from '@/lib/axiosInstance';
import { useParams } from "react-router-dom";
import { Loader2 } from 'lucide-react';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import dayjs from 'dayjs';
import { ScrollArea } from '../ui/scroll-area';
import { Separator } from '../ui/separator';

export function EnvUdpate() {
    const [loading, setLoading] = useState(true);
const { projectCode } = useParams();

    const [issueData, setIssueData] = useState<any>(null);

    console.log(issueData)

    useEffect(() => {
        const fetchData = async () => {
            let body = {
                bpname: "Environment Manage Update Log",
                lineitem: "no",
                record_fields: "record_no;EMULCreationDate;EMULBPName;EMULCreator;",
                filter_criteria: {
                    join: "AND",
                    filter: [
                        {
                            field: "status",
                            value: "Terminated",
                            condition_type: "neq",
                        },
                        {
                            field: "status",
                            value: "Inactive",
                            condition_type: "neq",
                        },
                    ],
                },
            };

            try {
                const res = await api.post(`/getemsbprecord?path=${projectCode}`, body);
                setIssueData(res.data.data.data
                    .sort((a: any, b: any) => b.record_no - a.record_no).reverse()
                    .slice(0, 5)
                );
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setLoading(false);
            }
        };

        if (projectCode) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [projectCode]);

    if (loading) {
        return (
            <Card className="col-span-12 md:col-span-3 h-full">
                <CardHeader className="text-left">
                    <CardTitle className="text-base leading-none tracking-tight">Update 사항</CardTitle>
                </CardHeader>
                <CardContent className="flex items-center justify-center overflow-hidden">
                    <Loader2 className="w-5 h-5 animate-spin" />
                </CardContent>
            </Card>
        );
    }

    return (
        <Card className="col-span-12 md:col-span-3 h-[48vh]">
            <CardHeader className="text-left">
                <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                    <span>Update 사항</span>
                    {/* <span className="ml-auto text-xs">{dayjs(issueData?.genSubmitDO).format("YYYY년 MM월 DD일")}</span> */}
                </CardTitle>
            </CardHeader>
            <CardContent className="flex items-center justify-center overflow-hidden">
                <ScrollArea className="w-full h-full">
                    {issueData && issueData.length > 0 ? (
                        issueData.map((data: any, index: number) => (
                            <React.Fragment key={index}> {/* React.Fragment에 key 추가 */}
                                <div className="text-sm">
                                    {dayjs(data.EMULCreationDate).format("YYYY년 MM월 DD일")} {data.EMULBPName}
                                </div>
                                <Separator className="my-2" />
                            </React.Fragment>
                        ))
                    ) : (
                        <div className="flex justify-center items-center h-full text-xs text-slate-700">
                            No data available.
                        </div>
                    )}
                </ScrollArea>

            </CardContent>
        </Card>
    );
}