// components/CustomNode.tsx
import React, { useState } from 'react';
import { TreeNode } from './dataTransform';

interface CustomNodeProps {
    nodeDatum: any;
    toggleNode: () => void;
}

const CustomNode: React.FC<CustomNodeProps> = ({ nodeDatum, toggleNode }) => {
    const [hover, setHover] = useState(false);
    const [imgError, setImgError] = useState(false);

    const handleClick = () => {
        toggleNode();
    };

    // 노드 크기 및 배치 설정
    const rectWidth = 150;
    const rectHeight = 60;
    const imageWidth = 60;
    const imageHeight = 60;
    const padding = 8;


    const clickableRadius = 1;
    const clickableX = rectWidth/2;
    const clickableY = rectHeight + 0; // 직사각형 아래쪽
    // Leaf 노드 수를 계산하는 함수
    const countLeaves = (node: TreeNode): number => {
        if (!node.children || node.children.length === 0) {
            return 1;
        }
        return node.children.reduce((sum, child) => sum + countLeaves(child), 0);
    };

    const leafCount = countLeaves(nodeDatum);

    return (
        <g
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            aria-label={nodeDatum.name}
        >

{/* <rect  width="20" height="20"  onClick={handleClick} x="-10"/> */}


            {/* 메인 직사각형 */}
            <rect
                x={-rectWidth/2}
                y={0}
                width={rectWidth}
                height={rectHeight}
                fill={hover ? 'rgb(229 229 229)' : '#fff'}
                stroke="rgb(64 64 64)"
                strokeWidth={1}
                rx={5} // 모서리 라운드
                ry={5}
                onClick={handleClick}
                style={{ cursor: 'pointer' }}
            />

            {/* 이미지 섹션 */}
            <image
                href={imgError ? '/fallback.png' : nodeDatum.attributes.image}
                x={padding-rectWidth/2}
                y={padding}
                width={imageWidth - padding * 2}
                height={imageHeight - padding * 2}
                onClick={handleClick}
                onError={() => setImgError(true)}
                style={{ cursor: 'pointer', borderRadius: '5px' }}
            />

            {/* Position 정보 텍스트 */}
            <text
                x={imageWidth + padding-rectWidth/2}
                y={rectHeight / 3}
                textAnchor="start"
                alignmentBaseline="middle"
                fill="#fff"
                fontSize="12px"
                onClick={handleClick}
                style={{ cursor: 'pointer' }}
            >
                {nodeDatum.attributes.position}
            </text>

            {/* 이름 텍스트 */}
            <text
                x={imageWidth + padding-rectWidth/2}
                y={(rectHeight / 3) * 2} // 약 65px (조금 아래로 이동)
                textAnchor="start"
                alignmentBaseline="middle"
                fontSize="14px"
                onClick={handleClick}
                className='font-bold'
                style={{ cursor: 'pointer' }}
            >
                {nodeDatum.name} {nodeDatum.children && `(${leafCount})`}
            </text>
        </g>
    );
};

export default CustomNode;
