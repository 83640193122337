
import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { useAtom } from 'jotai';
import { divisionState } from '@/atoms';
import api from '@/lib/axiosInstance';
import dayjs from 'dayjs';
import { Loader2 } from 'lucide-react';
import { Bar, BarChart, CartesianGrid, LabelList, XAxis, YAxis, Text, Pie, PieChart, Cell, Tooltip, Label } from "recharts"
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart"

interface EnvProps {
}
interface ENVDataType {
    c1: string;
    c2: string;
    c3: string;
    c4: string;
    c5: string;
    c6: string;
    c7: string;
    c8: string;
    c9: string;
    c10: string;
    c11: string;
    c12: string;
    c13: string;
    c14: string;
    c15: string;
    c16: string;
    c17: string;
    c18: string;
    c19: string;
    c20: string;
    c21: string;
    c22: string;
}

const Envtotal: React.FC<EnvProps> = () => {
    const [loading, setLoading] = useState<boolean>(true); // Loading state
    const [division] = useAtom(divisionState);
    const [ENVata, setENVData] = useState<ENVDataType>({
        c1: '',
        c2: '',
        c3: '',
        c4: '',
        c5: '',
        c6: '',
        c7: '',
        c8: '',
        c9: '',
        c10: '',
        c11: '',
        c12: '',
        c13: '',
        c14: '',
        c15: '',
        c16: '',
        c17: '',
        c18: '',
        c19: '',
        c20: '',
        c21: '',
        c22: '',
    });


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading

            const div = division === null ? "" : division

            try {
                const res = await api.get(`/getEnvData?div=${div}`);

                setENVData(res.data.sendData)


            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false); // End loading
            }
        }

        fetchData();
    }, [division]);

    const chartData = [
        { type: "온실가스(Scope 1+2)", number: parseFloat(ENVata.c21), unit: 'tCo2-eq', label: parseFloat(ENVata.c21) + " tCo2-eq" },
        { type: "온실가스(Scope 3, Biz.P 건설기계)", number: parseFloat(ENVata.c22), unit: 'tCo2-eq', label: parseFloat(ENVata.c22) + " tCo2-eq" },
        { type: "전력", number: parseFloat(ENVata.c15), unit: 'MWh', label: parseFloat(ENVata.c15) + " MWh" },
        { type: "용수", number: parseFloat(ENVata.c16), unit: 'ton', label: parseFloat(ENVata.c16) + " ton" },
        { type: "폐기물", number: parseFloat(ENVata.c17), unit: 'ton', label: parseFloat(ENVata.c17) + " ton" },
    ]

    const chartPieData = [

    ]

    const chartConfigPie = {
        Scope1: {
            label: "Scope1",
            color: "hsl(var(--chart-1))",
        },
        Scope2: {
            label: "Scope2",
            color: "hsl(var(--chart-2))",
        },
    } satisfies ChartConfig


    const chartConfig = {
        number: {
            label: "number",
            color: "hsl(var(--chart-1))",
        },
    } satisfies ChartConfig

    const CustomLabel = (props: any) => {
        const { x, y, width, stroke, value } = props;
        return (
            <Text
                x={width + 95}
                y={y + 8}
                fill={"black"}
                fontSize={8}
                textAnchor="end"
                width={120} // Set a fixed width to manage spacing
                style={{ whiteSpace: 'nowrap' }}
            >
                {value}
            </Text>
        );
    };

    return (
        <>
            {loading ? (
                <div className="flex justify-center items-center h-full">
                    <Loader2 className="w-5 h-5 animate-spin" />
                </div>
            ) : (
                <div className="flex flex-col w-full h-full">
                    <div className="grid grid-cols-5 gap-4 flex-1">
                        <div className="col-span-2 text-center">
                            <div className="flex flex-col h-full justify-between">
                                <span className="text-xs font-semibold text-emerald-600 pt-4">Reduction Rate</span>
                                <span className="text-lg font-semibold text-emerald-600">{ENVata.c1}%</span>
                                <span className="text-xs text-slate-700">감축목표 {ENVata.c2}%</span>
                                <ChartContainer
                                    config={chartConfigPie}
                                    className="aspect-square max-h-[120px] min-h-[120px] -mb-4"
                                >
                                    <PieChart>
                                        <ChartTooltip
                                            content={<ChartTooltipContent nameKey="name" />}
                                        />
                                        <Pie
                                            data={[
                                                { name: "Scope1", value: parseFloat(ENVata.c3.replace(",", "")), fill: "hsl(var(--chart-1))" },
                                                { name: "Scope2", value: parseFloat(ENVata.c4.replace(",", "")), fill: "hsl(var(--chart-2))" }
                                            ]}
                                            dataKey="value"
                                            nameKey="name"
                                            strokeWidth={1}
                                            cx="50%"
                                            cy="50%"
                                            outerRadius={42} // Adjust this value to match your design
                                            innerRadius={28} // This creates the donut shape
                                            labelLine={false} // Enable label lines for better label placement visualization
                                            label={({ cx, cy, midAngle, outerRadius, value, name }) => {
                                                // Calculate the position of the label
                                                const RADIAN = Math.PI / 180;
                                                const labelRadius = outerRadius +5; // Distance from the outer edge
                                                const x = cx + labelRadius * Math.cos(-midAngle * RADIAN);
                                                const y = cy -5+ labelRadius * Math.sin(-midAngle * RADIAN);
                                        
                                                // Calculate percentage
                                                const total = parseFloat(ENVata.c5.replace(",", ""));
                                                const percentage = ((value / total) * 100).toFixed(2); // Calculating percentage
                                        
                                                return (
                                                    <text
                                                        x={x}
                                                        y={y}
                                                        fill="#666"
                                                        textAnchor={x > cx ? "start" : "end"} // Anchors the text depending on its position
                                                        dominantBaseline="central"
                                                        className='text-[10px] fill-foreground '
                                                    >
                                                        {/* <tspan x={x} dy="-0.4em">{name}</tspan> */}
                                                        <tspan x={x} dy="0em">{`${value.toFixed(0)}`}</tspan>
                                                        <tspan className='text-[8px]' x={x} dy="1em">({`${percentage}`})%</tspan>
                                                        {/* <tspan x={x} dy="1.2em">{`(${percentage}%)`}</tspan> */}
                                                    </text>
                                                );
                                            }}
                                        >
                    
                                        <Label
                                            content={({ viewBox }) => {
                                                if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                                                    // Calculate the starting Y position to center the entire text block

                                                    const startY = viewBox.cy ? viewBox.cy - 12 : 0;  // Moves the starting point up to accommodate additional lines below

                                                    return (
                                                        <text
                                                            x={viewBox.cx}
                                                            y={startY}
                                                            textAnchor="middle"
                                                            dominantBaseline="central"
                                                        >
                                                            <tspan
                                                                x={viewBox.cx}
                                                                y={startY}
                                                                className="fill-foreground text-md font-bold"
                                                            >
                                                                {parseFloat(ENVata.c5.replace(",", "")).toFixed(0)}
                                                            </tspan>
                                                            <tspan
                                                                x={viewBox.cx}
                                                                y={startY + 12}
                                                                className="fill-muted-foreground text-[9px]"
                                                            >
                                                                예상배출량
                                                            </tspan>
                                                            <tspan
                                                                x={viewBox.cx}
                                                                y={startY + 24}
                                                                className="fill-muted-foreground text-xs"
                                                            >
                                                                {parseFloat(ENVata.c6.replace(",", "")).toFixed(0)}
                                                            </tspan>
                                                        </text>
                                                    )
                                                }
                                            }}
                                        />

                                    </Pie>
                                    <Tooltip />
                                </PieChart>
                            </ChartContainer>
                        </div>
                    </div>

              
                    <div className="flex flex-col col-span-3">
                        <span className="text-xs font-semibold">환경법규 위반</span>

                        <div className="w-full h-1/2 flex items-start justify-start">

                            <div className="grid grid-cols-10 gap-1 flex-1 h-full">
                                {/* <span className="text-xs font-semibold">환경법규 위반</span> */}
                                <div className="flex flex-col col-span-2 justify-center items-center">
                                    <span className='text-lg text-orange-600'>{ENVata && ENVata?.c7 && parseFloat(ENVata?.c7).toFixed(0)}<span className='text-[10px]'>건</span></span>

                                </div>
                                <div className="flex flex-col col-span-8 h-full justify-center items-center">
                                    <Table className='text-xs'>
                                        <TableHeader>
                                            <TableRow className='leading-3 h-1'>
                                                <TableHead className='text-[10px] p-1 leading-3 h-1'></TableHead>
                                                <TableHead className='text-[10px] p-1 leading-3 h-1 text-center'>위반건수<span className='text-[8px]'>(건)</span></TableHead>
                                                <TableHead className='text-[10px] p-1 leading-3 h-1 text-center'>벌금/과태료<span className='text-[8px]'>(만원)</span></TableHead>
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className='text-[10px] p-0 text-right'>Gas&Power</TableCell>
                                                <TableCell className='text-[10px] p-0 text-center'>{ENVata && parseFloat(ENVata.c8).toFixed(0)}</TableCell>
                                                <TableCell className='text-[10px] p-0 text-center'>{ENVata && parseFloat(ENVata.c9).toFixed(0)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className='text-[10px] p-0 text-right'>Industrial</TableCell>
                                                <TableCell className='text-[10px] p-0 text-center'>{ENVata && parseFloat(ENVata.c10).toFixed(0)}</TableCell>
                                                <TableCell className='text-[10px] p-0 text-center'>{ENVata && parseFloat(ENVata.c11).toFixed(0)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className='text-[10px] p-0 text-right'>H2/Recycling</TableCell>
                                                <TableCell className='text-[10px] p-0 text-center'>{ENVata && parseFloat(ENVata.c12).toFixed(0)}</TableCell>
                                                <TableCell className='text-[10px] p-0 text-center'>{ENVata && parseFloat(ENVata.c13).toFixed(0)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>

                                </div>
                            </div>
                        </div >
                        <div className="w-full h-1/2 flex items-start justify-start">
                            <div className="flex-1 relative">
                                <span className="text-xs font-semibold">누적 소비 규모</span>

                                <div className='right-0 w-3/5 flex items-center justify-between relative float-end text-center pt-2'>
                                    <div>
                                        <div className="text-[10px] font-semibold">재생에너지 사용률</div>
                                        <div className="text-[12px] text-emerald-600">{ENVata.c18} %</div> {/* Assuming c18 contains the renewable energy usage rate */}
                                    </div>
                                    <div>
                                        <div className="text-[10px] font-semibold">폐기물 재활용률</div>
                                        <div className="text-[12px] text-emerald-600">{ENVata.c19} %</div> {/* Assuming c19 contains the waste recycling rate */}
                                    </div>
                                </div>

                                <ChartContainer config={chartConfig} className='mx-auto h-[55px] m-0 w-full'>
                                    <BarChart
                                        accessibilityLayer
                                        data={chartData}
                                        layout="vertical"
                                        margin={{
                                            right: 32,
                                        }}
                                    >
                                        <CartesianGrid horizontal={false} vertical={false} />
                                        <YAxis
                                            dataKey="type"
                                            type="category"
                                            tickLine={false}
                                            tickMargin={2}
                                            axisLine={false}
                                            tickFormatter={(value) => value.slice(0, 4)}
                                            fontSize={10}
                                            interval={0} // Show every label

                                        />
                                        <XAxis dataKey="number" type="number" hide tickLine={false} />

                                        <ChartTooltip
                                            cursor={false}
                                            content={<ChartTooltipContent indicator="line" />}
                                        />
                                        <Bar
                                            dataKey="number"
                                            layout="vertical"
                                            fill="var(--color-number)"
                                            radius={4}
                                        >
                                            {/* <LabelList
                                                    dataKey="type"
                                                    position="left"
                                                    offset={8}
                                                    className="fill-[--color-label]"
                                                    fontSize={10}
                                                /> */}
                                            <LabelList
                                                dataKey="label"
                                                position="insideRight"
                                                offset={8}
                                                className="fill-foreground"
                                                content={<CustomLabel />}
                                            />
                                        </Bar>
                                    </BarChart>
                                </ChartContainer>

                            </div>
                        </div>
                    </div >
                </div >
                </div >
            )}
        </>
    )

};
export default Envtotal;