
import * as React from "react"
import { useParams } from "react-router-dom";
import api from "@/lib/axiosInstance";
import { Pie, PieChart, Cell, Tooltip, Label } from "recharts"
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart"

// ChartConfig 타입 정의 (필요한 경우)
interface ChartConfigItem {
    label: string;
    color: string;
}

interface LineItem {
    EBPQAFunctuin: string;
    EBPQAGrade: string;
    EQADicision: string;
    uuu_tab_id?: string;
    // 필요한 다른 속성들 추가
}


export function LessonLearned() {
     const { projectCode } = useParams()

    const [pieChartData, setPieChartData] = React.useState<any[] | null>(null);
    const [findingsCount, setFindingsCount] = React.useState<number>(0); // Findings 수를 저장할 상태


    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const body = {
                    bpname: "LL Application",
                    lineitem: "no",
                };


                const response = await api.post(`/getemsbprecord?path=${projectCode}`, body);
                const unifierData = response.data.data.data;

                const pieData = [
                    {
                        id: "보류",
                        value: unifierData.filter((v: any) => v.status !== "Closed").length,
                        fill:"#0077B6"
                    },
                    {
                        id: "승인",
                        value: unifierData.filter((v: any) => v.status === "Closed").length,
                        fill:"#0096C7"
                    }
                ]



                // Findings 수를 상태에 저장
                setFindingsCount(unifierData.length);
                setPieChartData(pieData);
            } catch (error) {
                console.error("데이터를 가져오는 중 오류 발생:", error);
                setPieChartData(null);
                setFindingsCount(0);
            }
        };
        if (projectCode) {
            fetchData();
        }
    }, [projectCode]);

    const chartConfig = pieChartData && pieChartData.reduce((config, { id }, index) => {
        // `index`에 따라 색상을 지정하거나 임의의 색상을 선택
        const colors = ["#2563eb", "#60a5fa", "#34d399", "#f97316", "#a855f7"];
        config[id] = {
            label: id,
            color: colors[index % colors.length], // 색상 반복 사용
        };
        return config;
    }, {} as ChartConfig);



    return (
        pieChartData && pieChartData.length>0?
        <div className="flex flex-col text-center">
            <div className="text-xs">LL 적용 현황</div>
            <ChartContainer
                config={chartConfig}
                className="mx-auto aspect-square max-h-[140px] w-full -mt-4"
            >
                <PieChart>
                    <ChartTooltip
                        content={<ChartTooltipContent nameKey="id" />}
                    />
                    <Pie
                        data={pieChartData}
                        dataKey="value"
                        nameKey="id"
                        strokeWidth={1}
                        cx="50%"
                        cy="50%"
                        outerRadius={45}
                        innerRadius={30}
                        labelLine={false}
                        label={({ cx, cy, midAngle, outerRadius, value, name }) => {
                            const RADIAN = Math.PI / 180;
                            const labelRadius = outerRadius + 8;
                            const x = cx + labelRadius * Math.cos(-midAngle * RADIAN);
                            const y = cy + labelRadius * Math.sin(-midAngle * RADIAN);
                            const percentage = ((value / findingsCount) * 100).toFixed(2);

                            return (
                                <text
                                    x={x}
                                    y={y}
                                    fill="#666"
                                    textAnchor={x > cx ? "start" : "end"}
                                    dominantBaseline="central"
                                    className="text-[10px] fill-foreground"
                                >
                                    <tspan x={x} dy="0em">{`${name} ${value.toFixed(0)}`}</tspan>
                                    <tspan className="text-[8px]" x={x} dy="1.2em">({`${percentage}`})%</tspan>
                                </text>
                            );
                        }}
                    >
                        <Label
                            content={({ viewBox }) => {
                                if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                                    // Calculate the starting Y position to center the entire text block

                                    const startY = viewBox.cy ? viewBox.cy - 4 : 0;
                                    return (
                                        <text
                                            x={viewBox.cx}
                                            y={startY}
                                            textAnchor="middle"
                                            dominantBaseline="middle"
                                            style={{
                                                cursor: "pointer",
                                            }}
                                           
                                        >
                                            <tspan
                                                x={viewBox.cx}
                                                y={startY}
                                                className="fill-foreground text-md font-bold"
                                            >
                                                {findingsCount}
                                            </tspan>
                                            <tspan
                                                x={viewBox.cx}
                                                y={startY + 12}
                                                className="fill-muted-foreground text-[9px]"
                                            >
                                                건
                                            </tspan>
                                        </text>
                                    );
                                }
                            }}
                        />
                    </Pie>
                    <Tooltip />
                </PieChart>
            </ChartContainer>
        </div>
     : (
        <div className="flex justify-center items-center h-full text-xs text-slate-700">
            No data available.
        </div>
    )
    );
}