

import React, { useEffect, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import { Button } from "@/components/ui/button";
import {
  DataGridPremium,
  GridToolbarContainer, useGridApiRef,
  GridActionsCellItem,
} from '@mui/x-data-grid-premium';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import dayjs from 'dayjs';
import { useToast } from "@/hooks/use-toast";
import { sortBy } from 'lodash';
import api from '@/lib/axiosInstance';
import { Dialog, DialogContent, DialogClose, DialogHeader, DialogFooter, DialogTitle } from "@/components/ui/dialog";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDropzone } from 'react-dropzone';
import JSZip from 'jszip';
import { darken } from '@mui/material/styles';
import MyDocumentViewerModalDR from '../viewers/modalforDocumentRegister';
import { IconButton } from '@mui/material';
import { getSessionUsername } from '@/components/common/getName';
import { useNavigate, useLocation } from 'react-router-dom';
import { CircleXIcon, HistoryIcon, Plus, ArrowBigDownDash, Loader2, Trash} from 'lucide-react';
import { SaveAll } from 'lucide-react';
import { Paperclip } from 'lucide-react';

dayjs.extend(isSameOrBefore);


export const DocumentRegisterTable = ({  tabeldata, projectCode, revData, drawingData, returnStatus }) => {

  const [rows, setRows] = React.useState(tabeldata.map((v, index) => ({ ...v, id: randomId() })));
  const [rowModesModel, setCellModesModel] = React.useState({});
  const [openFileUpload, setOpenFileUpload] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [editingRowParams, setEditingRowParams] = React.useState([]);
  const apiRef = useGridApiRef();
  const [isSaving, setIsSaving] = useState(false);
  const [username, setUsername] = useState(null);
  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  const [isProcessing, setIsProcessing] = useState(false);


  useEffect(() => {
    const fetchUsername = async () => {
      const name = await getSessionUsername();
      setUsername(name);
    };

    fetchUsername();
  }, []);

  const [hasUnsavedRows, setHasUnsavedRows] = useState(false);
  const unsavedChangesRef = React.useRef({
    unsavedRows: {},
    rowsBeforeChange: {},
  });


  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const newFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));
      setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);
    }
  });

  const handleRemoveFile = (filePath) => {
    setSelectedFiles(prevFiles => prevFiles.filter(file => file.path !== filePath));
  };


  useEffect(() => {
    setRows(sortBy((tabeldata.map((v, index) => ({
      ...v,
      id: randomId(),
    }))), "record_no").reverse())
  }, [tabeldata])



  const handleOpenFileUpload = (rowId) => {
    setOpenFileUpload(rowId); // 열린 파일 업로드 대화상자의 rowId를 설정
  };

  const handleCloseFileUpload = () => {setOpenFileUpload(false); setSelectedFiles([])};

  const handleUploadFiles = async () => {
    const newRows = []; // 새로운 행을 저장할 빈 배열을 초기화합니다.

    for (const file of selectedFiles) {
      let fileNameWithoutExtension = file.name;
      const lastDotIndex = fileNameWithoutExtension.lastIndexOf('.');
      if (lastDotIndex > 0) {
        fileNameWithoutExtension = fileNameWithoutExtension.substring(0, lastDotIndex);
      }

      const splitChars = [' ', '.', '_'];
      let minIndex = fileNameWithoutExtension.length; // 파일 이름의 길이를 초기 최소 인덱스 값으로 설정

      for (const char of splitChars) {
        const index = fileNameWithoutExtension.indexOf(char);
        if (index > -1 && index < minIndex) {
          minIndex = index; // 가장 작은 인덱스 업데이트
        }
      }

      if (minIndex !== fileNameWithoutExtension.length) { // 구분자가 존재하는 경우
        fileNameWithoutExtension = fileNameWithoutExtension.substring(0, minIndex);
      }


      const drawing = drawingData.find(v => v.Doc_No === fileNameWithoutExtension);

      // drawingData에서 파일 이름을 찾지 못한 경우
      if (!drawing) {
        toast({
          variant: "destructive",
          title: "에러",
          description: `첨부된 파일의 도서 번호 ${fileNameWithoutExtension}를 "Deliverable & MR List"에서 찾을 수 없습니다.`,
        })

        continue; // 현재 파일 처리를 건너뛰고 다음 파일로 넘어갑니다.
      }



      const doc_title = drawing.Doc_Title;
      const emsManhourDA = drawing.EDPRPRManhour;
      const DOC_TYPE_NM = drawing.WBSDiscNM;
      // const DocType_spd = drawing.PMSClassKindCD === "E"?"400 Engineering":"600 Procurement";
      const DOC_TYPE_NM2 = drawing.DOC_TYPE_NM;
      const newId = randomId();
      const RevNo_bpk = drawingData.find(v => v.Doc_No === drawing.Doc_No).RevNo

      const foundRow = rows.find(v => v.Doc_No === fileNameWithoutExtension);
      const currentDocNoDP = foundRow?.EDPRDocNoDP;

      // currentDocNoDP가 undefined일 경우, sameDocNoDPRows는 빈 배열이 됩니다.
      const sameDocNoDPRows = currentDocNoDP ? rows.filter(row => row.EDPRDocNoDP === currentDocNoDP) : [];

      // sameDocNoDPRows가 빈 배열일 경우, Math.max(...)는 -Infinity를 반환하므로, 이 경우 0을 사용합니다.
      // 여기서 Math.max(...)의 결과가 -Infinity일 경우 0을 반환하도록 수정합니다.
      const maxVersion = sameDocNoDPRows.length > 0 ? Math.max(...sameDocNoDPRows.map(row => parseInt(row.DocRegVersion, 10) || 0)) : 0;
      const safeMaxVersion = isFinite(maxVersion) ? maxVersion : 0;


      newRows.push({
        id: newId,
        EDPRDocNoDP: fileNameWithoutExtension + "_" + projectCode,
        isNew: true,
        isFileAttached: true,
        Doc_Title: doc_title,
        DOC_TYPE_NM: DOC_TYPE_NM2,
        DocType_spd: "400 Engineering",
        emsManhourDA: emsManhourDA,
        WBSDiscNM: DOC_TYPE_NM,
        file: file,
        RevNo_bpk: RevNo_bpk,
        DocRegVersion: safeMaxVersion + 1,
      });

      // setRowModesModel(oldModel => ({ ...oldModel, [newId]: { mode: GridRowModes.Edit } }));
    }

    // setRows 함수 내에서 기존 행들과 신규 행을 병합한 뒤, 정렬을 수행
    setRows((oldRows) => {
      const mergedRows = [...newRows, ...oldRows];
      // 신규 행을 배열의 앞쪽으로 이동
      mergedRows.sort((a, b) => {
        if (a.isNew && !b.isNew) {
          return -1; // a가 신규 행이면 앞으로 이동
        } else if (!a.isNew && b.isNew) {
          return 1; // b가 신규 행이면 a를 뒤로 이동
        } else {
          // 두 행 모두 신규이거나 기존 행인 경우, 기존의 레코드 번호 등 다른 기준으로 정렬
          return a.record_no - b.record_no;
        }
      });
      return mergedRows;
    });

    handleCloseFileUpload();
  };


  const handlerequest = async () => {

    setIsProcessing(true); // 처리 시작 시 isProcessing을 true로 설정

    let hasError = false; // 오류 추적용 변수
    let errorMessage = ''; // 오류 메시지 저장용 변수

    const changedRows = rows.filter(row => {
      const correspondingData = tabeldata.find(data => data.record_no === row.record_no);

      if (correspondingData) {
        return Object.keys(correspondingData).some(key => {
          return row[key] !== correspondingData[key];
        });
      }

      return false;
    });

    const transferData = changedRows.map((v) => ({
      ...v,
      Doc_No: v.EDPRDocNoDP.split("_")[0],
      status: "Active", LatestDoc: "Yes",

    }))


    
    if (transferData.length === 0) {
      setIsProcessing(false)
      toast({
        title: "전송할 데이터 없음",
        description: "전송할 데이터가 없습니다.",
      });
      return; // Add this line to exit the function
    }
    
    if (transferData.length > 0) {
      try {
        const response = await api.post(`/documentregisterupdate?path=${projectCode}`, transferData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.data.success) {
          hasError = true;
          errorMessage = response.data.data; // 오류 메시지 저장
        }
      } catch (error) {
        hasError = true;
        errorMessage = error.response ? error.response.data.message : `${transferData}} 데이터 전송 중 오류가 발생했습니다.`;
        console.error(`Error sending data to the server for ${transferData}:`, error);
      }
    }


    for (const row of rows.filter(v => v.record_no === undefined)) {
      const zip = new JSZip();

      // 해당 row의 파일을 ZIP 파일에 추가(있는 경우)
      if (row.file) {
        zip.file(row.file.name, row.file);
      }

      // ZIP 파일 생성 및 Base64 인코딩
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      const zipBase64 = await toBase64(zipBlob);

      // ZIP 파일 정보와 해당 row 데이터를 포함하는 요청 본문 생성
      const requestBody = {
        options: {
          bpname: "Documents Register",
        },
        data: [{
          WBSDiscNM: row.WBSDiscNM,
          DecisionBPK: row.DecisionBPK,
          UploadBy: row.UploadBy,
          DocType_spd: row.DocType_spd,
          EDPRDocNoDP: row.EDPRDocNoDP,
          EDPRStageLIDP: row.EDPRStageLIDP,
          RevNo_bpk: row.RevNo_bpk,
          Doc_Title: row.Doc_Title,
          Doc_No: row.EDPRDocNoDP.split("_")[0],
          DocRegVersion: row.DocRegVersion,
          status: "Active",
          LatestDoc: "Yes",
          _attachment: row.file ? [{
            file_name: row.file.name,
            title: row.Doc_Title,
            issue_date: dayjs().format('DD/MM/YYYY'),
            revision_no: "000"
          }] : []
        }],
        _attachment: {
          zipped_file_name: "create.zip",
          zipped_file_size: zipBlob.size.toString(),
          zipped_file_content: zipBase64,
        }
      };

      try {
        const response = await api.post(`/documentregister?path=${projectCode}`, requestBody, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.data.success) {
          hasError = true;
          errorMessage = response.data.data; // 오류 메시지 저장
          break; // 에러가 발생하면 더 이상 진행하지 않음
        }
      } catch (error) {
        hasError = true;
        errorMessage = error.response ? error.response.data.message : `${row.Doc_Title} 데이터 전송 중 오류가 발생했습니다.`;
        console.error(`Error sending data to the server for ${row.Doc_Title}:`, error);
        break; // 에러가 발생하면 더 이상 진행하지 않음
      }
    }

    // 모든 요청이 완료된 후 결과에 따라 팝업 표시
    if (!hasError) {

      toast({
        title: "전송 성공",
        description: '모든 데이터가 성공적으로 전송되었습니다.',
      })


      setTimeout(() => {
      navigate(location.pathname, { replace: true });
      }, 2000);


    } else {
      toast({
        variant: "destructive",
        title: '오류 발생',
        description: errorMessage,
      })

    }

    setIsProcessing(false); // 처리 완료 시 isProcessing을 false로 설정

  };

  const [open, setOpen] = useState(false);
  const [documentUrl, setDocumentUrl] = useState('');
  const [documenTitle, setDocumenTitle] = useState('');
  const [docuID, setdocuID] = useState('');



  const handleOpenDocumentViewer = async (record_no) => {
    try {

      const getFileID =  await api.get(`/getFileID?record=${record_no}&project=${projectCode}`)
      const fileID = getFileID.data.fileID

      if(fileID){

      const response = await api.get(`/download/${fileID}`,
        {
          responseType: 'blob'
        }

      );

      // ZIP 파일 압축 해제
      const jszip = new JSZip();
      const zipContent = await jszip.loadAsync(response.data); // ZIP 파일 로드

      // 첫 번째 파일의 내용을 얻음
      const fileNames = Object.keys(zipContent.files);
      if (fileNames.length === 0) {
        throw new Error('ZIP file is empty');
      }

      const firstFileName = fileNames[0];
      const firstFile = zipContent.files[firstFileName];
      const fileData = await firstFile.async('blob');


      // 파일 이름을 상태로 설정
      setDocumenTitle(firstFileName);
      setdocuID(fileID)
      // Blob URL 생성 및 상태 설정
      setDocumentUrl(URL.createObjectURL(fileData));
      setOpen(true);
    }

    } catch (error) {
      console.error('Error fetching or unzipping the document:', error);
    }
  };



  function EditToolbar() {
    return (
      <GridToolbarContainer>
        <div
          className="relative flex items-center justify-between w-full"
          style={{ right: "8px" }}
        >

          <div className='pl-2 text-xs'>
            <Button className="bg-primary text-white text-xs p-4 h-8 font-semibold" onClick={handleOpenFileUpload}>
              <Plus className="h-4 w-4" />
              Add records
            </Button>
          </div>

          <div className="flex items-center gap-4">
            <Button
              variant="outline"
              className='text-xs p-4 h-8'
              disabled={!hasUnsavedRows}
              onClick={saveChanges}
            >
              {isSaving ? <Loader2 className="h-4 w-4 animate-spin" />:   <SaveAll className='h-4 w-4' />}
              Save All
            </Button>

            <Button
              disabled={!hasUnsavedRows || isSaving}
              onClick={discardChanges}
              className='text-xs p-4 h-8'
            >
              <HistoryIcon className='h-4 w-4'/>
              Discard all changes
            </Button>

            <Button  className='text-xs p-4 h-8'  onClick={applyValuesToAll}>
              <ArrowBigDownDash className='h-4 w-4 '/>
              Apply Values
            </Button>

              <Button
                variant="secondary"
                style={{ display: rows.length === 0 || hasUnsavedRows ? "none" : "block" }}
                onClick={handlerequest}
                disabled={isProcessing} // isProcessing이 true일 때 버튼 비활성화
                className='text-xs h-8 font-semibold'
              >
                
                {isProcessing ? '처리 중...' : '프로젝트 적용'}
              </Button>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }


  const columns = React.useMemo(() => {
    return [
      {
        field: 'actions',
        type: 'actions',
        width: 50,
        getActions: ({ id, row }) => {
          return [

            <GridActionsCellItem
              icon={<Trash className='h-4 w-4' />}
              label="Delete"
              onClick={() => {
                unsavedChangesRef.current.unsavedRows[id] = {
                  ...row,
                  _action: 'delete',
                };
                if (!unsavedChangesRef.current.rowsBeforeChange[id]) {
                  unsavedChangesRef.current.rowsBeforeChange[id] = row;
                }
                setHasUnsavedRows(true);
                apiRef.current.updateRows([row]); // to trigger row render
              }}
            />,
          ];
        },
      },

      {
        field: 'viewDocument',
        headerName: '문서 보기',
        width: 80,
        renderCell: (params) => (
          <GridActionsCellItem
            icon={<Paperclip className='h-4 -w-4' />}
            label="문서 보기"
            onClick={() => handleOpenDocumentViewer(params.row.record_no)}
          // onClick={() => handleOpenDocumentViewer("353372")}
          />
        ),
      },
      { field: 'record_no', headerName: "레코드 번호", width: 100, editable: false },
      { field: 'UploadBy', headerName: "작성자", width: 100, editable: true },

      { field: 'WBSDiscNM', headerName: "Discipline", width: 100, editable: false },
      { field: 'DocType_spd', headerName: "Doc. Type", width: 150, editable: false, },
      { field: 'DOC_TYPE_NM', headerName: "Doc. Type Name", width: 100, editable: false },
      {
        field: 'EDPRDocNoDP',
        headerName: "Doc. No.",
        width: 200,
        editable: false,
        renderCell: (params) => {
          // params.value를 projectCode로 분할
          const parts = params.value.split("_" + projectCode);
          // 첫 번째 부분 반환 (projectCode 이전의 문자열)
          return parts[0];
        },
      },
      { field: 'Doc_Title', headerName: "Doc. Title", width: 450, editable: false },
      {
        field: 'EDPRStageLIDP', headerName: "Stage Name*", width: 150, editable: true, type: 'singleSelect', valueOptions: ({ row }) =>
          drawingData.find(v => v.Doc_No === row.EDPRDocNoDP.split("_")[0])?._bp_lineitems.filter(f => f.FormatType !== "Not issue").map(vf => vf.PMSStageNM) || []

      },
      { field: 'emsManhourDA', headerName: "M/H", width: 100, editable: false },
      {
        field: 'RevNo_bpk',
        headerName: "Rev*",
        width: 100,
        editable: true,
        type: 'singleSelect',
        valueOptions: ({ row }) => {
          // 해당 row에 대한 drawingData 항목 찾기
          const drawingItem = drawingData.find(v => v.Doc_No === row.EDPRDocNoDP.split("_")[0]);

          // drawingItem이 유효한지 확인
          if (!drawingItem) {
            console.error('No drawing item found for Doc_No:', row.EDPRDocNoDP);
            return []; // 또는 적절한 기본값 반환
          }

          // 필터링된 revData 항목으로부터 valueOptions 생성
          const valueOptions =
            revData.map(v => v.udrawRevNoBP);

          return valueOptions;
        }
      },

      { field: 'DocRegVersion', headerName: "Version", width: 100, editable: false },
      {
        field: 'DecisionBPK', headerName: "Return Status", width: 150, editable: true, type: 'singleSelect', valueOptions: returnStatus
      },

    ];
  }, [unsavedChangesRef, apiRef]);


  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const dropzoneStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
    // width: '400px', // 너비 고정
    boxSizing: 'border-box', // 패딩과 보더가 너비에 포함되도록 설정

  };

  const activeStyle = {
    borderColor: '#2196f3',
  };


  const applyValuesToAll = useCallback(() => {
    const editableRows = rows.filter(row => row.isNew);

    if (editableRows.length > 0) {
      const firstRow = rows.find(row => row.isNew && row.DocType_spd && row.EDPRStageLIDP && row.RevNo_bpk);
      if (!firstRow) return; // 첫 번째 행이 없으면 함수 종료

      const { DocType_spd, RevNo_bpk, EDPRStageLIDP, UploadBy } = firstRow;

      let updatedRows = rows.map(row => {
        if (row.isNew) {
          // 첫 번째 편집 가능한 행의 값을 기준으로 다른 편집 중인 행을 업데이트합니다.
          return { ...row, DocType_spd, RevNo_bpk, EDPRStageLIDP, UploadBy };
        }
        return row;
      });

      // 업데이트된 행 데이터를 `rows` 상태에 반영합니다.
      setRows(updatedRows);
    }
  }, [rows, setRows]);


  const processRowUpdate = (newRow, oldRow) => {
    const rowId = newRow.id;

    const requiredFields =
      [
        // { field: 'UploadBy', message: "작성자는 필수값입니다." },
        { field: 'EDPRStageLIDP', message: "Stage Name은 필수값입니다." },
        { field: 'RevNo_bpk', message: "Rev은 필수값입니다." },
      ];

    let allFieldsValid = true; // 모든 필드가 유효한지 여부를 추적하는 변수

    for (const { field, message } of requiredFields) {
      if (!newRow[field] || newRow[field] === "" || newRow[field] === undefined) {
        allFieldsValid = false; // 하나라도 조건을 만족하지 못하면 false로 설정
        break; // 더 이상 검사할 필요 없으므로 반복 중단
      }
    }

    setHasUnsavedRows(allFieldsValid); // 모든 필드가 유효한지에 따라 상태 설정

    if (allFieldsValid) {
      unsavedChangesRef.current.unsavedRows[rowId] = newRow;
      const updatedRow = { ...newRow };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    }

    return newRow;
  };


  const saveChanges = async () => {
    try {
      const rowsToDelete = Object.values(
        unsavedChangesRef.current.unsavedRows,
      ).filter((row) => row._action === 'delete');
      if (rowsToDelete.length > 0) {
        apiRef.current.updateRows(rowsToDelete);
        setRows(
          currentRows => currentRows.filter(row => !rowsToDelete.some(deletedRow => deletedRow.id === row.id))
            .map((v) => ({ ...v, isNew: false }))

        );
      }
      setHasUnsavedRows(false);
      unsavedChangesRef.current = {
        unsavedRows: {},
        rowsBeforeChange: {},
      };

      // setRows(rows .map((v)=>({...v, isNew:false})))

    } catch (error) {
      setIsSaving(false);
    }
  };

  const discardChanges = () => {
    setHasUnsavedRows(false);
    apiRef.current.updateRows(
      Object.values(unsavedChangesRef.current.rowsBeforeChange),
    );
    unsavedChangesRef.current = {
      unsavedRows: {},
      rowsBeforeChange: {},
    };
  };

  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);


  const handleSelectionChange = (selectionModel) => {

    setRowSelectionModel(selectionModel);

    // 선택된 행의 ID를 사용하여 해당 행의 데이터를 찾습니다.
    const selectedRowsData = selectionModel.map((id) =>
      rows.find((row) => row.id === id)
    );

    // 콘솔에 출력
    console.log("Selected Rows:", selectedRowsData);
  };

  return (
    <>
      <Box
        sx={{
          height: "76vh",
          width: '100%',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold', // 헤더 셀의 글자를 진하게 설정
          },
          '& .MuiDataGrid-cell': {
            fontSize: 12,
          },
          '& .notmatched': {
            backgroundColor: '#f2f3f3',
          },
          '& .notmatched.MuiDataGrid-cell--editing': {
            backgroundColor: '#f2f3f3',
            color: '#f2f3f3'
          },
          '& .notmatched input': {
            // backgroundColor: '#F0EDE5', 
            fontSize: 0
          },
          '& .notmatched.MuiDataGrid-cell': {
            backgroundColor: '#f2f3f3',
          },
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >

        <DataGridPremium
          checkboxSelection
          rows={rows}
          apiRef={apiRef}
          initialState={{ pinnedColumns: { left: ['actions'] }, }}
          onRowSelectionModelChange={handleSelectionChange}
          rowSelectionModel={rowSelectionModel}
          columns={columns}
          density="compact"
          // editMode="row"
          // rowModesModel={rowModesModel}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: {
              cellModesModel: rowModesModel,
              setCellModesModel,
              editingCellParams: editingRowParams,
              setEditingCellParams: setEditingRowParams,
            },
          }}
          pagination
          disableRowSelectionOnClick
          unstable_cellSelection
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => console.log(error)}
          experimentalFeatures={{ clipboardPaste: true }}
          unstable_ignoreValueFormatterDuringExport
          sx={{
            '& .MuiDataGrid-row.row--removed': {
              backgroundColor: (theme) => {
                if (theme.palette.mode === 'light') {
                  return 'rgba(255, 170, 170, 0.3)';
                }
                return darken('rgba(255, 170, 170, 1)', 0.7);
              },
            },
            '& .MuiDataGrid-row.row--new': {
              backgroundColor: (theme) => {
                if (theme.palette.mode === 'light') {
                  return 'rgba(0, 255, 0, 0.3)';
                }
                return darken('rgba(0, 255, 0, 0.3)', 0.7);
              },
            },
            '& .MuiDataGrid-row.row--edited': {
              backgroundColor: (theme) => {
                if (theme.palette.mode === 'light') {
                  return 'rgba(255, 254, 176, 0.3)';
                }
                return darken('rgba(255, 254, 176, 1)', 0.6);
              },
            },
          }}

          loading={isSaving}
          getRowClassName={({ id, row }) => {
            // 삭제될 행에 대한 클래스
            if (unsavedChangesRef.current.unsavedRows[id]?._action === 'delete') {
              return 'row--removed';
            }
            // 편집된 행에 대한 클래스
            else if (unsavedChangesRef.current.unsavedRows[id]) {
              return 'row--edited';
            }
            // 새로 추가된 행에 대한 클래스
            else if (row.isNew) {
              return 'row--new';
            }
            return '';
          }}
        />
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Box>


      <MyDocumentViewerModalDR open={open} onClose={() => setOpen(false)} username={username} docuID={docuID} documenTitle={documenTitle} documentUrl={documentUrl} />


      <Dialog open={openFileUpload} onOpenChange={setOpenFileUpload}>
        <DialogContent className='w-full'>
          <DialogHeader>
            <DialogTitle>파일 업로드</DialogTitle>
          </DialogHeader>
          <div className='w-full'>
            <div {...getRootProps()} style={{ ...dropzoneStyle, ...(isDragActive ? activeStyle : {}) }}>
              <input {...getInputProps()} />
              {
                isDragActive ?
                  <p>Drop the files here ...</p> :
                  <p>여기로 드래그 & 드랍을 하거나, 파일을 고르기 위해 여기를 클릭하세요.</p>
              }
            </div>
            <ul className='w-full text-xs p-2'>
              {selectedFiles.map(file => (
                <li key={file.path}>
                  {file.path} - {file.size} bytes&nbsp;
                  <IconButton sx={{ p: 0 }} onClick={() => handleRemoveFile(file.path)}> <CircleXIcon className='h-4 w-4' /> </IconButton>
                </li>
              ))}
            </ul>
          </div>
          <DialogFooter className="sm:justify-start">
            {/* <DialogClose asChild> */}
              <Button variant="secondary"  onClick={handleCloseFileUpload}>
                Close
              </Button>
            {/* </DialogClose> */}
            <Button onClick={handleUploadFiles}>업로드</Button>

          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

const toBase64 = (blob) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onload = () => resolve(reader.result.split(',')[1]); // 결과에서 Base64 문자열만 추출
  reader.onerror = error => reject(error);
});