
import { useGetProgressSimpleData } from "@/lib/getData";
import {
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { EPCProgressContent } from "@/components/overview/EPCProgressContent";
import { useDateFormat } from "@/hooks/useDateFormat";

export function OverviewProgressCard() {

  const { simpleProgressData, simpleProgressDataloading } = useGetProgressSimpleData();
  const formatDate = useDateFormat();


  const categories = [
    { title: "Overall", plan: simpleProgressData?.oprogressPlan, actual: simpleProgressData?.oprogressActual, color: "bg-blue-100" },
    { title: "Engineering", plan: simpleProgressData?.eprogressPlan, actual: simpleProgressData?.eprogressActual, color: "bg-emerald-100" },
    { title: "Procurement", plan: simpleProgressData?.pprogressPlan, actual: simpleProgressData?.pprogressActual, color: "bg-yellow-100" },
    { title: "Construction", plan: simpleProgressData?.cprogressPlan, actual: simpleProgressData?.cprogressActual, color: "bg-red-100" },
  ];

  return (
    <>
      <CardHeader className="text-left">
        <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
          <span>EPC Progress</span>
          <span className="ml-auto text-xs">{formatDate(simpleProgressData?.lastDate)}</span>
        </CardTitle>
      </CardHeader>
      <CardContent className="flex items-center justify-center overflow-hidden">
        <EPCProgressContent categories={categories} simpleProgressDataloading={simpleProgressDataloading} />
      </CardContent>
    </>)
}