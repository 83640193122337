

import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import PDFTronSquad from './viewerSquad';
import InfoBoxSquad from './infoBoxSquad';
import JSZip from 'jszip';
import { getSessionUsername } from '@/components/common/getName';
import api from '@/lib/axiosInstance';
import { useParams, useLocation } from 'react-router-dom';
import { useToast } from "@/hooks/use-toast";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const MyDocumentViewerModalSquad = ({ }) => {
  let query = useQuery();
  const { projectCode } = useParams();

  const recordNo = query.get("recordNo");
  const group = query.get("group");
  const duedate = query.get("duedate");
  const autoseq = query.get("autoseq");
  const SQDTitle = query.get("title");

  const [username, setUsername] = useState(null);
  const { toast } = useToast();

  useEffect(() => {
    const fetchUsername = async () => {
      const name = await getSessionUsername();
      setUsername(name);
    };

    fetchUsername();
  }, []);

  const [pdftronInstance, setPdftronInstance] = useState(null);

  // PDFTron 컴포넌트에서 인스턴스를 업데이트하는 함수
  const updatePdftronInstance = (instance) => {
    setPdftronInstance(instance);
  };

  const [documentList, setDocumentList] = useState(null);
  const [selectedDocId, setSelectedDocId] = useState('');
  const [selectedDocTitle, setSelectedDocTitle] = useState('');
  const [selectedDocBlob, setSelectedDocBlob] = useState(null);


  useEffect(() => {
    async function fetchData() {
      try {
        if (projectCode && recordNo) {
          const res = await api.get(`/getrecordfilelist?project=${projectCode}&record=${recordNo}&group=${group}`);
          if (res.data.success) {
            setDocumentList(res.data.data);
            if (res.data.data.length > 0) {
              selectDocument(res.data.data[0].id, res.data.data[0].title);
            }
          }
        }
      } catch (error) {

        toast({
          variant: "destructive",
          title: "Error",
          description: "An error occurred. Please contact the system administrator.${error}",
        })


        // Handle error appropriately
        setDocumentList([]);
      }
    }
    fetchData();
  }, [projectCode, recordNo, group]); // Correct dependency array

  const selectDocument = async (docId, docTitle) => {
    setSelectedDocId(docId);
    setSelectedDocTitle(docTitle);
    const docBlob = await getDocumentBlob(docId);
    setSelectedDocBlob(docBlob);
  };

  const getDocumentBlob = async (docId) => {
    const response = await api.get(`${process.env.REACT_APP_URI}/download/${docId}`, { responseType: 'blob' });

    // ZIP 파일 압축 해제
    const jszip = new JSZip();
    const zipContent = await jszip.loadAsync(response.data); // ZIP 파일 로드

    // 첫 번째 파일의 내용을 얻음
    const fileNames = Object.keys(zipContent.files);
    if (fileNames.length === 0) {
      throw new Error('ZIP file is empty');
    }

    const firstFileName = fileNames[0];
    const firstFile = zipContent.files[firstFileName];
    return await firstFile.async('blob');
  };

  return (
      <Box display="flex" >
        <Box flex={1} mr={1} sx={{marginRight:0}}> 
          {/* {selectedDocBlob && */}
          <PDFTronSquad initialDocBlob={selectedDocBlob} documenTitle={selectedDocTitle} docuID={selectedDocId} username={username}
            onInstanceReady={setPdftronInstance} />
            {/* } */}
        </Box>
        <InfoBoxSquad recordNo={recordNo} SQDTitle={SQDTitle} autoseq={autoseq} group={group} duedate={duedate} documentList={documentList} onSelectDocument={selectDocument} />
      </Box>

  );
};
export default MyDocumentViewerModalSquad;
