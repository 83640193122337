import { useNavigate, useLocation, Link } from "react-router-dom";
import { useState } from "react";
import api from '@/lib/axiosInstance';
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {  Loader2 } from "lucide-react"; // Import Loader2 for loading indicator
import { useToast } from "@/hooks/use-toast";
import { useTranslation } from 'react-i18next';

export function SignUpForm() {
  const { t, i18n } = useTranslation();  
  const [username, setUsername] = useState("");
  const [checkresult, setCheckResult] = useState("");
  const [error, setError] = useState("");
  const { toast } = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true); // Start loading

    try {
      const response = await api.post('/forgot-password', { email: checkresult, language: i18n.language });

      if (response.data.success) {
        toast({
          variant: 'default',
          title: '비밀번호 재설정 요청 성공',
          description: response.data.message || '비밀번호 재설정 이메일을 확인해주세요.',
        });
        // Reset state
        setCheckResult("");
        setUsername("");
        // Redirect to /login after a short delay to allow users to read the toast
        setTimeout(() => {
          navigate('/login');
        }, 2000); // 2-second delay
      } else {
        toast({
          variant: 'destructive',
          title: '비밀번호 재설정 요청 실패',
          description: response.data.message || '비밀번호 재설정 요청에 실패했습니다.',
        });
      }
    } catch (err: any) {
      console.error(err);
      const errorMessage = err.response?.data?.error || '비밀번호 재설정 요청에 문제가 발생했습니다. 시스템 관리자에게 연락하시기 바랍니다.';
      toast({
        variant: 'destructive',
        title: '비밀번호 재설정 요청 실패',
        description: errorMessage,
      });
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const checkID = async () => {
    setCheckResult("");
    setError(""); // Clear previous errors
    if (username) {
      try {
        const res = await api.get(`/getuser?user=${username}`);

        const email = res?.data[0]?.uuu_user_email;

        if (email) {
          setError(`User Info check. ${email}`);
          setCheckResult(email);
        } else {
          setError('해당 사용자 이름에 대한 이메일을 찾을 수 없습니다.');
        }
      } catch (err) {
        console.error(err);
        setError('사용자 이름을 확인하는 동안 오류가 발생했습니다.');
      }
    } else {
      setError('S.CON 로그인 이름을 입력해주세요.');
    }
  };

  return (
    <Card className="mx-auto max-w-sm">
      <CardHeader>
        <CardTitle className="flex items-center justify-center">
          <img src="/images/sconlogoH.png" alt="Logo" className="w-2/3 pb-2" />
        </CardTitle>
        <CardTitle className="text-xl">{t('passwordReset')}</CardTitle>
        <CardDescription className="flex items-center justify-center text-xs">
          {t('passwordResetDescription')}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleResetPassword}>
          <div className="grid gap-4">
            <div className="grid gap-2">
              <div className="flex items-center">
                <Label htmlFor="username">{t('login.username')}</Label>
              </div>
              <Input
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />

              <span className="ml-auto inline-block text-sm underline cursor-pointer" onClick={checkID}>
              {t('checkUsername')}
              </span>
            </div>

            {error && <p className="text-red-500 text-sm">{error}</p>}
            <Button
              disabled={!username || !checkresult || isLoading}
              type="submit"
              className="w-full flex items-center justify-center"
            >
            {isLoading ? (
                <>
                  <Loader2 className="animate-spin mr-2" />
                  {t('pleaseWait')}
                </>
              ) : (
                t('resetPassword')
              )}
            </Button>
          </div>
        </form>
        <div className="mt-4 text-center text-sm">
          {t('alreadyHaveAccount')}{' '}
          <Link to="/login" className="underline">
            {t('loginlogin')}
          </Link>
        </div>
      </CardContent>
    </Card>
  );
}