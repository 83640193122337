
import React, { useEffect, useState ,useRef} from 'react';
import { useGetProjectList } from '@/hooks/useQuery';
import { useAtom } from 'jotai';
import { divisionState } from '@/atoms';
import api from '@/lib/axiosInstance';
import { CardContent } from "@/components/ui/card";
import { ChevronLeft, ChevronRight, Loader2 } from 'lucide-react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { Button } from "../ui/button";
import { Bar, BarChart, CartesianGrid, XAxis, LabelList } from "recharts"
import {
    ChartConfig,
    ChartContainer,
    ChartLegend,
    ChartLegendContent,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart"
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(weekOfYear);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);


interface DataItem {
    projectcode: string;
    d_reporting_date: string;
    인원: number;
    [key: string]: any;
}

interface AggregatedData {
    [category: string]: {
        [key: string]: number; // 여기서 number는 '인원' 값을 합산한 것을 의미
    };
}



export function ManPowerBarChart() {

    const { data: projectsList } = useGetProjectList();
    const [manData, setMandata] = useState<DataItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true); // Loading state
    const [division] = useAtom(divisionState);

    const [checked, setChecked] = React.useState<"월간" | "주간" | "일간">("일간");

    useEffect(() => {
        const fetchData = async () => {

            setLoading(true); // Start loading
            try {
                if (projectsList && projectsList?.length > 0) {
                    const res = await api.get(`/getFileFolderList?path=/${process.env.REACT_APP_PATH2}/file/temp/man`);

                    // After file list is set, fetch the other data and set manData.
                    const res1 = await api.get(`/getJSONtotal?path=/${process.env.REACT_APP_PATH2}/file/datafiles/drmandata.json`);
                    const res2 = await api.get(`/getJSONtotal?path=/${process.env.REACT_APP_PATH2}/file/datafiles/manmanualdata.json`);

                    const newarray = [];
                    for (let i of res.data.fileList) {
                        const res = await api.get(`/getJSONtotal?path=/${process.env.REACT_APP_PATH2}/file/temp/man/${i.name}`);
                        const data_with_pjtinfo = res.data.map((v: any) => ({ ...v, d_reporting_date: dayjs(v.d_reporting_date).format('MM-DD-YYYY') }))
                            .map((v: any) => ({ ...v, ...projectsList.find(sp => sp.projectcode === v.projectcode) }));
                        newarray.push(...data_with_pjtinfo);
                    }


                    setMandata(
                        [...res1.data, ...res2.data, ...newarray].map(v => ({ ...v, ...projectsList.find(sp => sp.projectcode === v.projectcode) }))
                            .filter(v => typeof v.division !== 'undefined' && typeof v.projectcode !== 'undefined'&& typeof v.projectname !== 'undefined')
                            .filter(v => (!division || v.division === division))

                            .map((v) => ({
                                ...v,
                                uniq: v.division + "//" + v.d_reporting_date.slice(0, 10),
                                uniq2: v.projectname + "//" + v.d_reporting_date.slice(0, 10)
                            })).sort((a, b) => {
                                // Parse `category` as a date if necessary or customize this sort function 
                                // depending on `category` format.
                                const dateA = dayjs(a.d_reporting_date, "MM-DD-YYYY");  // Adjust format based on `category`
                                const dateB = dayjs(b.d_reporting_date, "MM-DD-YYYY");  // Adjust format based on `category`
                        
                                return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
                            }) 
                    );
                };

            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false); // End loading
            }
        };


        fetchData();
    }, [projectsList, division]);



    const yestdayManCount = manData
        .filter((v: any) => {
            const reportingDate = dayjs(v.d_reporting_date, "MM-DD-YYYY");
            const current = dayjs().add(-1, 'days').format("MM-DD-YYYY")
            return (!division || v.division === division) && reportingDate.isSame(current, "day");
        })
        .reduce((sum, v) => sum + (v.인원 || 0), 0);

    const weekManCount = manData
        .filter((v: any) => {
            const reportingDate = dayjs(v.d_reporting_date, "MM-DD-YYYY");
            const current = dayjs().add(-2, 'years').format("MM-DD-YYYY")
            return (!division || v.division === division) && reportingDate.isSame(current, "week");
        })
        .reduce((sum, v) => sum + (v.인원 || 0), 0);

    const monthManCount = manData
        .filter((v: any) => {
            const reportingDate = dayjs(v.d_reporting_date, "MM-DD-YYYY");
            const current = dayjs().add(-2, 'years').format("MM-DD-YYYY")
            return (!division || v.division === division) && reportingDate.isSame(current, "month");
        })
        .reduce((sum, v) => sum + (v.인원 || 0), 0);



    function processManData(manData: any, checked: any, division: any) {
        const result = [];
        const aggregatedData: AggregatedData = {}; // 인덱스 서명이 포함된 타입 사

        // Helper to format category based on 'checked' value
        const getCategory = (date: any) => {
            if (checked === "일간") {
                return dayjs(date, "MM-DD-YYYY").format("MM월 DD일");
            } else if (checked === "주간") {
                return dayjs(date, "MM-DD-YYYY").format("YY년 ") + dayjs(date, "MM-DD-YYYY").week() + "주";
            } else if (checked === "월간") {
                return dayjs(date, "MM-DD-YYYY").format("YY년 MM월");
            }
            // 기본값 설정
            return dayjs(date, "MM-DD-YYYY").format("YYYY-MM-DD"); // 기본 포맷 설정
        };

        // Aggregate data
        manData.forEach((item: any) => {
            const category = getCategory(item.d_reporting_date);
            if (!category) return;

            const key = division ? item.projectname.replace(/[&+ ]/g, '') : item.division.replace(/[&+ ]/g, '');
            if (!key) return;

            if (!aggregatedData[category]) {
                aggregatedData[category] = {};
            }
            if (!aggregatedData[category][key]) {
                aggregatedData[category][key] = 0;
            }

            aggregatedData[category][key] += item.인원 || 0;
        });

        for (const category in aggregatedData) {
            const data = { category, ...aggregatedData[category] };
            result.push(data);
        }

        return result;
    }

    const chartData = manData && manData.length > 0 
    ? processManData(manData, checked, division).sort((a, b) => {
        // Parse `category` as a date if necessary or customize this sort function 
        // depending on `category` format.
        const dateA = dayjs(a.category, "MM-DD-YYYY");  // Adjust format based on `category`
        const dateB = dayjs(b.category, "MM-DD-YYYY");  // Adjust format based on `category`

        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
    }) 
    : [];

    const chartKey = !division
        ? [...new Set(manData.map(item => item.division.replace(/[&+ ]/g, '')))]
        : [...new Set(manData.map(item => item.projectname.replace(/[&+ ]/g, '')))].filter(v=>v!==undefined);

    const chartConfig = chartKey.reduce((config, key, index) => {
        config[key] = {
            label: key,
            color: `hsl(var(--chart-${index + 1}))`,
        };
        return config;
    }, {}) satisfies ChartConfig;


    const [startIndex, setStartIndex] = useState(0);
    const itemsPerPage = 6;
    
    // Ref to track if the startIndex has been initialized
// Track the previous length of chartData to determine significant changes
const prevDataLength = useRef(chartData?.length);

useEffect(() => {
    if (chartData && chartData.length > 0) {
        // Only update startIndex if this is the initial load 
        // or if the data length has changed significantly
        if (prevDataLength.current === undefined || Math.abs(chartData.length - prevDataLength.current) > itemsPerPage) {
            setStartIndex(Math.max(chartData.length - itemsPerPage, 0));
        }
        // Update the ref to the current length after handling
        prevDataLength.current = chartData.length;
    }
}, [chartData, itemsPerPage]);

    const visibleChartData = chartData
        ? chartData.slice(startIndex, startIndex + itemsPerPage).map((item: any) => ({
            ...item,
            total: chartKey.reduce((acc, key) => acc + (item[key] || 0), 0)
        }))
        : [];
    
    // Handler functions for panning
    const handlePrevious = () => {
        if (startIndex > 0) {
            setStartIndex(Math.max(startIndex - itemsPerPage, 0));
        }
    };
    
    const handleNext = () => {
        if (Array.isArray(chartData) && startIndex + itemsPerPage < chartData.length) {
            setStartIndex(startIndex + itemsPerPage);
        }
    };

 
    return (
        <>
            {loading ? (
                <div className="flex justify-center items-center h-full">
                    <Loader2 className="w-5 h-5 animate-spin" />
                </div>
            ) : (
                <CardContent className="flex flex-col items-end justify-end">
                    <div className="flex items-center gap-4 mb-2 w-full">
                        {/* Left elements with flex-grow to push right-aligned buttons */}
                        <div className="flex gap-1">
                            {/* Chevron buttons tightly aligned */}
                            <Button onClick={handlePrevious} variant="ghost" size="icon" className="h-5 w-5">
                                <ChevronLeft />
                            </Button>
                            <Button onClick={handleNext} variant="ghost" size="icon" className="h-5 w-5 -ml-2">
                                <ChevronRight />
                            </Button>
                        </div>

                        {/* Display counts based on the selected view */}
                        <div className="flex-grow text-sm font-semibold text-gray-700">
                            {checked === "일간" && (
                                <>
                                    {yestdayManCount}
                                    <span className="text-[10px] font-normal text-gray-500"> 명(어제)</span>
                                </>
                            )}
                            {checked === "주간" && (
                                <>
                                    {weekManCount}
                                    <span className="text-[10px] font-normal text-gray-500"> 명(이번 주)</span>
                                </>
                            )}
                            {checked === "월간" && (
                                <>
                                    {monthManCount}
                                    <span className="text-[10px] font-normal text-gray-500"> 명(이번 달)</span>
                                </>
                            )}
                        </div>

                        {/* Right-aligned buttons for switching views */}
                        <div className="flex gap-2">
                            <Button
                                className={`
                w-12 h-6 px-2 rounded-md font-medium inline-flex items-center justify-center gap-2
                transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2
                disabled:cursor-not-allowed disabled:opacity-100
                ${checked !== "월간"
                                        ? "bg-transparent text-gray-600 border border-[#F2F2F2] hover:bg-[#F2F2F2] hover:text-black"
                                        : "bg-[#062653] text-white border-none hover:bg-[#051E4B]"
                                    }
            `}
                                size="sm"
                                onClick={() => {
                                    setChecked("월간");
                                    setStartIndex(0);
                                }}
                                disabled={checked === "월간"}
                                variant={checked !== "월간" ? "outline" : "default"}
                            >
                                <span className="text-[8px] font-bold">MONTHLY</span>
                            </Button>

                            <Button
                                className={`
                w-12 h-6 px-2 rounded-md font-medium inline-flex items-center justify-center gap-2
                transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2
                disabled:cursor-not-allowed disabled:opacity-100
                ${checked !== "주간"
                                        ? "bg-transparent text-gray-600 border border-[#F2F2F2] hover:bg-[#F2F2F2] hover:text-black"
                                        : "bg-[#062653] text-white border-none hover:bg-[#051E4B]"
                                    }
            `}
                                size="sm"
                                onClick={() => {setChecked("주간");    setStartIndex(0);}}
                                disabled={checked === "주간"}
                                variant={checked !== "주간" ? "outline" : "default"}
                            >
                                <span className="text-[8px] font-bold">WEEKLY</span>
                            </Button>

                            <Button
                                className={`
                w-12 h-6 px-2 rounded-md font-medium inline-flex items-center justify-center gap-2
                transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2
                disabled:cursor-not-allowed disabled:opacity-100
                ${checked !== "일간"
                                        ? "bg-transparent text-gray-600 border border-[#F2F2F2] hover:bg-[#F2F2F2] hover:text-black"
                                        : "bg-[#062653] text-white border-none hover:bg-[#051E4B]"
                                    }
            `}
                                size="sm"
                                onClick={() => {setChecked("일간");    setStartIndex(0);}}
                                disabled={checked === "일간"}
                                variant={checked !== "일간" ? "outline" : "default"}
                            >
                                <span className="text-[8px] font-bold">DAILY</span>
                            </Button>
                        </div>
                    </div>

                    {/* Chart below buttons */}
                    <div
                        className="overflow-hidden w-full cursor-grab active:cursor-grabbing"

                    >

                        <ChartContainer config={chartConfig} className="mx-auto h-[160px] w-full">
                            <BarChart
                                data={visibleChartData}
                                margin={{
                                    top: 15,
                                }}
                            >
                                <CartesianGrid vertical={false} />
                                <XAxis
                                    dataKey="category"
                                    tickLine={false}
                                    tickMargin={10}
                                    axisLine={false}
                                    tickFormatter={(value) => value}
                                />
                                <ChartTooltip content={<ChartTooltipContent hideLabel />} />
                                <ChartLegend content={<ChartLegendContent />} />
                                {chartKey.map((bar, index) => {
                                    let radius: [number, number, number, number];
                                    if (index === 0) {
                                        radius = [0, 0, 4, 4]; // First item
                                    } else if (index === chartKey.length - 1) {
                                        radius = [4, 4, 0, 0]; // Last item
                                    } else {
                                        radius = [0, 0, 0, 0]; // Middle items
                                    }

                                    return (
                                        <Bar
                                            key={bar}
                                            dataKey={bar}
                                            stackId="a"
                                            fill={`var(--color-${bar})`}
                                            radius={radius}
                                        >
                                            {index === chartKey.length - 1 && (
                                                <LabelList dataKey="total" position="top" fontSize={12} fill="#000" />
                                            )}
                                        </Bar>
                                    );
                                })}
                            </BarChart>
                        </ChartContainer>
                    </div>
                </CardContent>
            )}
        </>
    )
}
