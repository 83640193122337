

import GaugeChart from "@/components/common/GaugeChart";
import { Skeleton } from "@/components/ui/skeleton";
import { useGetLTIRData } from "@/lib/getData";

interface GaugeData {
    company: number | undefined;
    target: number | undefined;
    actual: number | undefined;
}


export function SafetyGaugeChart() {

    const { projectLTIRData, LTIRDataloading } = useGetLTIRData();


    const gaugeData = {
        company: projectLTIRData?.company,
        target: projectLTIRData?.target,
        actual: projectLTIRData?.actual
    };


    return (
        <>
                {/* <h3 className="text-sm font-medium mb-6 text-center">LTIR</h3> */}
                <div className="flex flex-col items-center">
                    <div className="flex justify-between w-4/5 mb-4 text-sm pl-4 pr-4">
                        <div className="text-center">
                            <div className="text-gray-600">Company</div>
                            <div>{LTIRDataloading ? <Skeleton className="h-6 w-16" /> : gaugeData.company}</div>
                        </div>
                        <div className="text-center">
                            <div className="text-blue-600">Target</div>
                            <div>{LTIRDataloading ? <Skeleton className="h-6 w-16" /> : gaugeData.target}</div>
                        </div>
                        <div className="text-center">
                            <div className="text-emerald-600">Actual</div>
                            <div>{LTIRDataloading ? <Skeleton className="h-6 w-16" /> : gaugeData.actual}</div>
                        </div>
                    </div>
                    {LTIRDataloading ? <Skeleton className="h-28 w-64" /> :
                        <GaugeChart
                            chartID={"safety"}
                            company={gaugeData.company}
                            target={gaugeData.target}
                            actual={gaugeData.actual}
                            companyColor={"gray-600"}
                            actualColor={"emerald-600"}
                            targetColor={"blue-500"}
                        />}
                </div>
        </>
    );
}