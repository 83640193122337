import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/index.css';
import reportWebVitals from './reportWebVitals';
import '@/i18n'; // 반드시 초기화 파일을 import
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from '@/pages/login';
import { LicenseInfo } from '@mui/x-license';
import ProjectLayout from '@/pages/layouts/projectLayout';
import RootLayout from '@/pages/layouts/rootLayout';
import ProjectOverview from '@/pages/project/overview';
import ProjectConcern from '@/pages/project/concern';
import EMSOrgPages from '@/pages/project/emsorg';
import EProgress from '@/pages/project/eprogress';
import ProcurementProgress from '@/pages/project/ProcurementProgress';
import CProgress from '@/pages/project/cprogress';
import Progress from '@/pages/project/progress';
import EMSManhour from '@/pages/project/emsManhour';
import EMSQuality from '@/pages/project/emsQuality';
import CWP from '@/pages/project/cwp';
import ManPowerEquip from '@/pages/project/mp';
import SafeyPage from '@/pages/project/safety';
import QualityPage from '@/pages/project/quality';
import EnvPages from '@/pages/project/envrionment';
import PhotoPage from '@/pages/project/photo';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@/queryClient';
import TotalPage from '@/pages/total';
import TotalLayout from '@//pages/layouts/totalLayout';
import ClientTR from './pages/project/clientTR';
import DrawingRegister from './pages/project/drawingRegister';
import MRPages from './pages/project/mrRegister';
import SquadCheck from './pages/project/documentViewer';
import SquadCheckLast from './pages/project/documentViewerLast';
import ProtectedRoute from './components/privateRoute';
import SignUpPage from './pages/signup';
import ResetPasswordPage from './pages/resetPassword';
import ProjectOverviewPlatform from './pages/project/overviewPlatform';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY as string);
const isNotIframe = window.self === window.top;


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route element={<RootLayout />}>

            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            {/* <Route path="/:projectCode/platform" element={<ProjectOverviewPlatform />} /> */}

            <Route element={<ProtectedRoute />}>
              <Route element={isNotIframe ? <ProjectLayout /> : null}>

                <Route path="/:projectCode" element={<ProjectOverview />} />
                <Route path="/:projectCode/overview" element={<ProjectOverview />} />
                <Route path="/:projectCode/emsorg" element={<EMSOrgPages />} />

                <Route path="/:projectCode/eprogress" element={<EProgress />} />
                <Route path="/:projectCode/pprogress" element={<ProcurementProgress />} />
                <Route path="/:projectCode/cprogress" element={<CProgress />} />
                <Route path="/:projectCode/progress" element={<Progress />} />

                <Route path="/:projectCode/emsmanhour" element={<EMSManhour />} />
                <Route path="/:projectCode/qualityp" element={<EMSQuality />} />

                <Route path="/:projectCode/cwp" element={<CWP />} />
                <Route path="/:projectCode/mp" element={<ManPowerEquip />} />
                <Route path="/:projectCode/safety" element={<SafeyPage />} />
                <Route path="/:projectCode/quality" element={<QualityPage />} />
                <Route path="/:projectCode/environment" element={<EnvPages />} />
                <Route path="/:projectCode/photo" element={<PhotoPage />} />
                <Route path="/:projectCode/concern" element={<ProjectConcern />} />

              </Route>

              <Route path="/:projectCode/platform" element={<ProjectOverviewPlatform />} />

              <Route path="/:projectCode/clientTR" element={<ClientTR />} />
              <Route path="/:project_code/documentViewer" element={<SquadCheck />} />
              <Route path="/:project_code/documentViewerLast" element={<SquadCheckLast />} />



              <Route path="/:project_code/drawingregister" element={<DrawingRegister />} />
              <Route path="/:project_code/mrregister" element={<MRPages />} />

              <Route element={<TotalLayout />}>
                <Route path="/total" element={<TotalPage />} />
              </Route>
            </Route>

          </Route>


        </Routes>
      </Router>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
