// validationSchema.ts
import { z } from 'zod';
import { TFunction } from 'i18next';

export const getResetPasswordSchema = (t: TFunction) =>
  z
    .object({
      password: z
        .string()
        .min(8, t('validation.password_min'))
        .regex(/[A-Z]/, t('validation.password_uppercase'))
        .regex(/[a-z]/, t('validation.password_lowercase'))
        .regex(/[0-9]/, t('validation.password_number'))
        .regex(/[^A-Za-z0-9]/, t('validation.password_special_char')),
      passwordConfirmation: z.string(),
    })
    .refine((data) => data.password === data.passwordConfirmation, {
      message: t('validation.password_mismatch'),
      path: ['passwordConfirmation'], // 에러의 위치를 지정
    });