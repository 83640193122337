import { TopIssue } from "@/components/concern/topIssue";
import { Concern } from "@/components/concern/concern";
import { Report } from "@/components/concern/report";


export default function ProjectConcern() {
    return (
        <div className="flex flex-1 flex-col w-full  min-h-screen  gap-4 p-4">
            <div className="grid  grid-rows-2 gap-4 md:grid-cols-12 md:grid-rows-2 overflow-y-hidden">
                <TopIssue />
                <Concern />
                <Report />

            </div>


        </div>
    )
}