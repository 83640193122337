import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { ProjectSummaryContent } from "@/components/overview/ProjectSummaryContent";
import { OverviewTimeLine } from "@/components/overview/OverviewTimeLine";
import { OverviewProgressCard } from "@/components/overview/ProgressCard";
import { OverviewManEquipPieChart } from "@/components/overview/ManEquipPieChart";
import { useGetCProgressData } from "@/lib/getData";
import dayjs from "dayjs";
import SimpleProgressChartP from "@/components/simpleprogress/simplecprogressChart2";
import useDocumentTitle from "@/hooks/useDocumentTitle";


interface ProgressItem {
  date: string;
  PV: number;
  EV: number;
  cumulativePV: number;
  cumulativeEV: number | null;
  // 필요한 다른 속성들을 추가하세요.
}

interface TransformedData {
  weekProgress: ProgressItem[];
  monthProgress: ProgressItem[];
}

export default function ProjectOverviewPlatform() {

  useDocumentTitle("S.CON")

  const { cProgressData } = useGetCProgressData("전체", "전체", "전체", "전체");
  const calculateCumulativeValues = (data: any) => {
    let cumulativePV = 0;
    let cumulativeEV = 0;
    return data.map((item: any) => {
        cumulativePV += item.PV;
        cumulativeEV += item.EV || 0;
        return {
            ...item,
            cumulativePV,
            cumulativeEV,
        };
    });
};


const sumWeightValue = cProgressData ? cProgressData
.filter((v: any) => v.monthweek === "M")
.reduce((sum: number, item: any) => sum + item.PV_DR, 0) : 0;


const groupData = (data: any, monthweek: string) => {
return data && data
    .filter((item: any) => item.monthweek === monthweek)
    .reduce((acc: any, item: any) => {
        if (!acc[item.date]) {
            acc[item.date] = { EV_DR: 0, PV_DR: 0 };
        }
        acc[item.date].EV_DR += item.EV_DR;
        acc[item.date].PV_DR += item.PV_DR;
        return acc;
    }, {});
};

const calculatePercentages = (groupedData: any, sumWeight: number) =>
groupedData && Object.keys(groupedData).map(date => ({
    date,
    'EV': (groupedData[date].EV_DR / sumWeight) * 100,
    'PV': (groupedData[date].PV_DR / sumWeight) * 100,
}));

const groupData_W = cProgressData && cProgressData?.length > 0 && calculatePercentages(groupData(cProgressData, "W"), sumWeightValue);
const groupData_M = cProgressData && cProgressData?.length > 0 && calculatePercentages(groupData(cProgressData, "M"), sumWeightValue);

const criteriaDate = dayjs().add(-1, 'days'); // 기준 날짜 설정 필요

  const transformedDataWithCumulative: TransformedData = {
    weekProgress: groupData_W && groupData_W.length > 0 && calculateCumulativeValues(groupData_W).map((item: any) => {
        const itemDate = dayjs(item.date, 'YY년 WW주');
        if (itemDate.isAfter(criteriaDate)) {
            return { ...item, cumulativeEV: null };
        }
        return item;
    }),
    monthProgress: groupData_M && groupData_M.length > 0 && calculateCumulativeValues(groupData_M).map((item: any) => {
        const itemDate = dayjs(item.date, 'YY년 MM월');
        if (itemDate.isAfter(criteriaDate)) {
            return { ...item, cumulativeEV: null };
        }
        return item;
    }),
};


  return (
    <div className="flex h-screen w-full p-4 overflow-hidden">
      <div className="grid grid-cols-1 md:grid-rows-3 md:grid-cols-8 gap-4 flex-1">

        {/* 첫 번째 카드: Project 개요 */}
        <Card className="row-span-3 md:col-span-2 col-span-8 rounded-xl border bg-card text-card-foreground shadow">
          <CardHeader className="text-left">
            <CardTitle className="text-base leading-none tracking-tight">Project 개요</CardTitle>
          </CardHeader>
          <CardContent className="flex-grow items-center justify-center overflow-y-auto">
            <ProjectSummaryContent />
          </CardContent>
        </Card>

        {/* 두 번째 카드: Project Milestone */}
        <Card className="row-span-1 md:col-span-6 col-span-8 rounded-xl border bg-card text-card-foreground shadow flex flex-col">
          <CardHeader className="text-left">
            <CardTitle className="text-base leading-none tracking-tight">Project Milestone</CardTitle>
          </CardHeader>
          <CardContent className="h-full flex-grow flex items-center justify-center overflow-hidden">
            <OverviewTimeLine />
          </CardContent>
        </Card>

        {/* 세 번째 카드: EPC Progress */}
        <Card className="row-span-1 md:col-span-2 col-span-8 rounded-xl border bg-card text-card-foreground shadow">
          <OverviewProgressCard />
        </Card>

        {/* 네 번째 카드: 인원   다섯 번째 카드: 장비*/}
        <OverviewManEquipPieChart />

        <Card className="row-span-1 md:col-span-6 col-span-8 rounded-xl border bg-card text-card-foreground shadow">

        <SimpleProgressChartP transformedDataWithCumulative={transformedDataWithCumulative} chartId='C-Surve' ChartName={"Contruction Progress"} />

        </Card>





      </div>
    </div>
  );
}