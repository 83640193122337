import React, { useEffect, useState } from 'react';
import { divisionState } from '@/atoms';
import { useAtom } from 'jotai';
import { Loader2 } from 'lucide-react';
import api from '@/lib/axiosInstance';
import { useGetProjectList } from '@/hooks/useQuery';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import BulletChart from '@/components/common/BulletChart';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import DatePicker from '@/components/common/datePicker';

dayjs.extend(isSameOrBefore);

interface StatusBulletProps {
}

interface Project {
    ugenProjectNumber: string;
    division?: string;
    [key: string]: any;
}

interface File {
    name: string;
}

interface DataItem {
    projectcode: string;
    Epc_reportdate: string;
    [key: string]: any;
}

interface BPRecordResponse {
    data: { data: DataItem[] };
}

interface FilterCriteria {
    field: string;
    value: string;
    condition_type: string;
}


const StatusBullet: React.FC<StatusBulletProps> = () => {
    const [statusData, setStatusData] = useState<DataItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true); // Loading state
    const { data: projectsList } = useGetProjectList();
    const [division] = useAtom(divisionState);

    const [date, setDate] = useState<Date | undefined>(new Date())


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading
            try {
            if (projectsList && projectsList?.length > 0) {
        
                    // Fetch initial file list
                    const fileRes = await api.get(`/getFileFolderList?path=/${process.env.REACT_APP_PATH2}/file/temp/status`);
                    const tempFileList = fileRes.data.fileList

                    const newArray: DataItem[] = [];

                    // Loop through file list to fetch JSON data
                    for (const file of tempFileList) {
                        const fileDataRes = await api.get(`/getJSONtotal?path=/${process.env.REACT_APP_PATH2}/file/temp/status/${file.name}`);
                        const dataWithProjectInfo = fileDataRes.data
                            .map((v: DataItem) => ({
                                ...v,
                                ...projectsList.find(sp => sp.projectcode === v.projectcode),
                                record_no: `${v.projectcode}${v.Epc_reportdate}`,
                            }))
                            .filter((v: any) => (!division || v.division === division) && dayjs(v.Epc_reportdate).isSame(dayjs(date).format("YYYY-MM-DD"), "week"))
                        newArray.push(...dataWithProjectInfo);
                    }

                    // Prepare body for batch requests
                    const body = {
                        bpname: "트랜잭션_EPC Progress",
                        lineitem: "no",
                        filter_criteria: {
                            join: "AND",
                            filter: [
                                { field: "status", value: "Active", condition_type: "eq" },
                                { field: "epc_category_pd", value: "Overall", condition_type: "eq" },
                                { field: "epc_category_pd", value: "Overall", condition_type: "eq" },
                                { field: "Epc_reportdate", value: dayjs(date).startOf("week").format("MM-DD-YYYY"), value2: dayjs(date).endOf("week").format("MM-DD-YYYY"), condition_type: "range" },
                            ] as FilterCriteria[],
                        },
                    };

                    // Execute batch requests for BPRecord data
                    const requestArr = projectsList
                        .filter(v => (!division || v.division === division))
                        .map(data => api.post<BPRecordResponse>(`/getemsbprecord?path=${data.projectcode}`, body));

                    const res = await Promise.all(requestArr);

                    const resList = res
                        .map(({ data }, index) => data.data.data.map((item: DataItem) => ({
                            ...item,
                            projectcode: projectsList[index].projectcode,
                            projectname: projectsList[index].projectname,
                            ...projectsList.find(vf => vf.projectcode === item.projectcode),
                            Epc_reportdate: item.Epc_reportdate.slice(0, 10),
                        })))
                        .flat();

                    setStatusData([...resList, ...newArray].filter(v=>v.projectcode !== undefined));
                }

                } catch (error) {
                    console.error("Error fetching data:", error);
                } finally {
                    setLoading(false); // End loading
                }
            };
        

        fetchData();
    }, [division, projectsList, date]);

    const aheadCount = statusData.filter(item => item.epc_Actual - item.epc_plan > 5).length;
    const behindCount = statusData.filter(item => item.epc_Actual - item.epc_plan < -5).length;
    const onScheduleCount = statusData.length - aheadCount - behindCount;




    return (
        <Card className="flex-1 h-full flex flex-col border-0">

        <CardHeader className="p-3">
        <CardTitle className="flex justify-between">
          <span className='text-xs'>Project Status</span>
          <DatePicker
            placeholder="Select..."
            dateFormat="yyyy년 MM월 dd일"
            initialDate={new Date()}
            onDateChange={(date) => setDate(date)}
          />
        </CardTitle>
      </CardHeader>
        <CardContent className="h-[22vh]">
        <div className="flex flex-col w-full h-full h-[22vh] md:h-[100%]">
            {loading ?
                (<div className="flex justify-center items-center h-full">
                    <Loader2 className="w-5 h-5 animate-spin" />
                </div>) : (
                    statusData.length === 0 ?
                        <div className="flex justify-center items-center h-full text-center text-gray-500 text-xs"> No data available</div> :
                        <>
                            <div className="text-center text-md font-semibold mb-2">
                                <span className="text-emerald-600">{aheadCount} <span className='text-xs text-slate-500'>Ahead</span> </span> &nbsp;&nbsp;
                                <span className="text-yellow-500"> {onScheduleCount} <span className='text-xs text-slate-500'>On-Schedule</span> </span> &nbsp;&nbsp;
                                <span className="text-red-500"> {behindCount} <span className='text-xs text-slate-500'>Behind</span> </span>
                            </div>

                            <div className='overflow-y-auto'>

                                {statusData.map((item: DataItem, index: number) => {
                                    const difference = item.epc_Actual - item.epc_plan;

                                    return (
                                        <div key={index} className="flex items-center justify-between w-full">
                                            {/* Project Name */}
                                            <span className="text-xs text-right font-semibold text-gray-700 w-1/4 truncate pr-1">
                                                {item.projectname}
                                            </span>

                                            {/* Bullet Chart Wrapper */}
                                            <div className="flex-shrink-0 w-2/4">
                                                <BulletChart
                                                    spark={true}
                                                    maxValue={100}
                                                    targetValue={item.epc_plan}
                                                    actualValue={item.epc_Actual}
                                                    height={22}
                                                    divId={`${item.projectname}`}
                                                    unit="%"
                                                />
                                            </div>

                                            {/* Actual and Difference */}
                                            <span className="text-xs text-gray-500 w-1/4 text-left pl-1">
                                                {Math.round(item.epc_Actual)}% ({Math.round(difference)}%)
                                            </span>
                                        </div>
                                    );
                                })
                                }
                            </div>
                        </>


                )


            }
        </div>
        </CardContent>
      </Card>

    )


};
export default StatusBullet;