import { useParams } from "react-router-dom";
import api from './axiosInstance';
import { useState, useEffect } from 'react';
import { ProjectsList,ProjectLevelData,ProjectProgressData,projectPQIData, projectPQIVendorData, ProjectPTWData, ProjectData, ProgressData, projectSummaryData, projectMilestoneData, projectDRData, SimpleProgressData, ProjectLTIRData } from '@/types';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);


export const fetchProjectList = async (): Promise<ProjectsList[]> => {
  const body = {
    bpname: 'ESG 대시보드 프로젝트 관리',
    lineitem: 'no',
    filter_condition: 'status=Active',
  };

  try {
    const res = await api.post(`/getemsbprecord?path=SKEC0001`, body);

    const projectsList = res.data.data.data
      .map((v: any) => ({
        uuu_shell_location: v.uuu_shell_location,
        uuu_latitude: v.uuu_latitude,
        uuu_longitude: v.uuu_longitude,
        division:
          v.uuu_shell_location &&
          v.uuu_shell_location
            .split(v.ugenProjectName)[0]
            .split('/SK 에코엔지니어링/')[1]
            .slice(0, -1),
        use: v.SKONeUSEPD && v.SKONeUSEPD,
        projectname:
          v.ShortPJTNM && v.ugenProjectName && v.ShortPJTNM !== null
            ? v.ShortPJTNM
            : v.ugenProjectName,
        projectcode: v.ugenProjectNumber && v.ugenProjectNumber,
      }))
      .filter(
        (v: any) =>
          v.division !== null &&
          !v.projectname.includes('TEST') &&
          v.use !== '제외'
      )
      .map((v: any) => ({
        ...v,
        order:
          v.division === 'Industrial'
            ? 1
            : v.division === 'Gas&Power'
            ? 2
            : v.division === '수소친환경'
            ? 3
            : 4,
      }))
      .sort((a: any, b: any) => a.projectname.localeCompare(b.projectname))
      .reverse()
      .sort((a: any, b: any) => a.order - b.order);

    return projectsList; // Return the data array directly
  } catch (err) {
    throw err; // Throw the error so React Query can handle it
  }
};

export function useGetProjectData() {
  
    const { projectCode } = useParams();

  const [projectData, setProjectData] = useState<ProjectData | null>(null);
  const [progressData, setProgressData] = useState<ProgressData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      if (projectCode) {
        try {
          const projectBody = {
            bpname: "ESG 대시보드 프로젝트 관리",
            lineitem: "no",
            filter_criteria: {
              join: "AND",
              filter: [
                {
                  field: "ugenProjectNumber",
                  value: projectCode,
                  condition_type: "eq"
                },
                {
                  field: "status",
                  value: "Active",
                  condition_type: "eq"
                }
              ]
            }
          };
          const progressBody = {
            bpname: "EPC Progress Ratio",
            lineitem: "no"
          };

          const [projectResponse, progressResponse] = await Promise.all([
            api.post(`/getemsbprecord?path=SKEC0001`, projectBody),
            api.post(`/getemsbprecord?path=${projectCode}`, progressBody)
          ]);

          setProjectData(projectResponse.data.data.data[0]);
          setProgressData(progressResponse.data.data.data[0]);
        } catch (err) {
          setError(err as Error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [projectCode]);

  return { projectData, progressData, loading, error };
}

export function useGetProjectSummaryData() {
  
    const { projectCode } = useParams();

  const [projectSummaryData, setProjectSummaryData] = useState<projectSummaryData | null>(null);
  const [projectSummaryDataloading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      if (projectCode) {
        try {
          const body = {
            bpname: "프로젝트 개요",
            lineitem: "yes",
          };

          const res = await api.post(`/getemsbprecord?path=${projectCode}`, body);
          const activeData = res.data.data.data
            .filter((v: projectSummaryData) => v.status === 'Active') // 'Active' 상태만 필터링
            .sort((a: projectSummaryData, b: projectSummaryData) => {
              if (a.record_no < b.record_no) return -1;
              if (a.record_no > b.record_no) return 1;
              return 0;
            });

          // 마지막 데이터만 선택하여 설정
          setProjectSummaryData(activeData[activeData.length - 1] || null);
        } catch (err) {
          setError(err as Error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [projectCode]);

  return { projectSummaryData, projectSummaryDataloading, error };
}

export function useGetProjectMilestoneData() {
  
    const { projectCode } = useParams();

    console.log(projectCode)

  const [projectMilestoneData, setProjectMilestoneData] = useState<projectMilestoneData[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      if (projectCode) {
        try {
          const body = {
            bpname: "Project Milestone",
            lineitem: "no",
            filter_condition: "status=Active",
          };

          const res = await api.post(`/getemsbprecord?path=${projectCode}`, body);
          const activeData = res.data.data.data
            .filter((item: any) => item.ActMilSRB === "Milestone" && item.plan_date !== null)
            .map((item: any) => ({
              label: item.genMilestoneDesc,
              plan_date: dayjs(item.plan_date).format("YYYY-MM-DD"),
              actual_date: item.genActualDate ? dayjs(item.genActualDate).format("YYYY-MM-DD") : undefined,
            }))
            .sort((a: projectMilestoneData, b: projectMilestoneData) => {
              const dateA = new Date(a.plan_date);
              const dateB = new Date(b.plan_date);
              return dateA.getTime() - dateB.getTime();
            });

          setProjectMilestoneData(activeData || null);
          // Calculate start and end dates
          const dates = activeData
            .map((milestone: any) => milestone.actual_date || milestone.plan_date)
            .filter(Boolean) as string[]; // Filter out undefined

          const earliestDate = dates.reduce((a, b) => (a < b ? a : b), dates[0]);
          const latestDate = dates.reduce((a, b) => (a > b ? a : b), dates[0]);

          setStartDate(earliestDate);
          setEndDate(latestDate);

        } catch (err) {
          setError(err as Error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [projectCode]);

  return { projectMilestoneData, startDate, endDate, loading, error };
}

export function useGetPartMilestoneData() {
  
    const { projectCode } = useParams();

  const [oMilestoneData, setOMilestoneData] = useState<projectMilestoneData[] | null>(null);
  const [eMilestoneData, setEMilestoneData] = useState<projectMilestoneData[] | null>(null);
  const [pMilestoneData, setPMilestoneData] = useState<projectMilestoneData[] | null>(null);
  const [cMilestoneData, setCMilestoneData] = useState<projectMilestoneData[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      if (projectCode) {
        try {
          const body = {
            bpname: "Project Milestone",
            lineitem: "no",
            filter_condition: "status=Active",
          };

          const res = await api.post(`/getemsbprecord?path=${projectCode}`, body);
          const activeDataOverall = res.data.data.data
            .filter((item: any) => item.ActMilSRB === "Milestone" && item.plan_date !== null && item.OverallCB === "Yes")
            .map((item: any) => ({
              label: item.genMilestoneDesc,
              plan_date: dayjs(item.plan_date).format("YYYY-MM-DD"),
              actual_date: item.genActualDate ? dayjs(item.genActualDate).format("YYYY-MM-DD") : undefined,
            }))
            .sort((a: projectMilestoneData, b: projectMilestoneData) => {
              const dateA = new Date(a.plan_date);
              const dateB = new Date(b.plan_date);
              return dateA.getTime() - dateB.getTime();
            });

            const activeDataE = res.data.data.data
            .filter((item: any) => item.ActMilSRB === "Milestone" && item.plan_date !== null && item.MilestonePhase === "Engineeirng")
            .map((item: any) => ({
              label: item.genMilestoneDesc,
              plan_date: dayjs(item.plan_date).format("YYYY-MM-DD"),
              actual_date: item.genActualDate ? dayjs(item.genActualDate).format("YYYY-MM-DD") : undefined,
            }))
            .sort((a: projectMilestoneData, b: projectMilestoneData) => {
              const dateA = new Date(a.plan_date);
              const dateB = new Date(b.plan_date);
              return dateA.getTime() - dateB.getTime();
            });

            const activeDataP = res.data.data.data
            .filter((item: any) => item.ActMilSRB === "Milestone" && item.plan_date !== null && item.MilestonePhase === "Procurement")
            .map((item: any) => ({
              label: item.genMilestoneDesc,
              plan_date: dayjs(item.plan_date).format("YYYY-MM-DD"),
              actual_date: item.genActualDate ? dayjs(item.genActualDate).format("YYYY-MM-DD") : undefined,
            }))
            .sort((a: projectMilestoneData, b: projectMilestoneData) => {
              const dateA = new Date(a.plan_date);
              const dateB = new Date(b.plan_date);
              return dateA.getTime() - dateB.getTime();
            });

            const activeDataC = res.data.data.data
            .filter((item: any) => item.ActMilSRB === "Milestone" && item.plan_date !== null && item.MilestonePhase === "Construction")
            .map((item: any) => ({
              label: item.genMilestoneDesc,
              plan_date: dayjs(item.plan_date).format("YYYY-MM-DD"),
              actual_date: item.genActualDate ? dayjs(item.genActualDate).format("YYYY-MM-DD") : undefined,
            }))
            .sort((a: projectMilestoneData, b: projectMilestoneData) => {
              const dateA = new Date(a.plan_date);
              const dateB = new Date(b.plan_date);
              return dateA.getTime() - dateB.getTime();
            });

            setOMilestoneData(activeDataOverall || null);
            setEMilestoneData(activeDataE || null);
            setPMilestoneData(activeDataP || null);
            setCMilestoneData(activeDataC || null);
          // Calculate start and end dates
          const dates = activeDataOverall
            .map((milestone: any) => milestone.actual_date || milestone.plan_date)
            .filter(Boolean) as string[]; // Filter out undefined

          const earliestDate = dates.reduce((a, b) => (a < b ? a : b), dates[0]);
          const latestDate = dates.reduce((a, b) => (a > b ? a : b), dates[0]);

          setStartDate(earliestDate);
          setEndDate(latestDate);

        } catch (err) {
          setError(err as Error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [projectCode]);

  return { oMilestoneData,pMilestoneData,cMilestoneData,eMilestoneData, startDate, endDate, loading, error };
}

export function useGetDailyReportData() {
  
    const { projectCode } = useParams();

  const [projectDRData, setProjectDRData] = useState<projectDRData[] | null>(null);
  const [projectDRDataloading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
  
      if (projectCode) {
        try {
          const res = await api.get(`/getFileFolderList?path=/${process.env.REACT_APP_PATH2}/file/drhistory/${projectCode}/`);
          const files: { name: string }[] = res.data.fileList || [];
  
          if (files.length === 0) {
            // 파일 목록이 비어 있으면 바로 로딩 상태 종료
            setProjectDRData([]); // 비어있는 데이터 배열로 상태 설정
            setLoading(false);
            return; // 추가 로직 실행 방지
          }
  
          const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
          const sortedFiles = files.sort((a, b) => collator.compare(a.name, b.name));
  
          const fileRequests = sortedFiles
            .slice(-3)
            .map(fileName => api.get(`/getemsJSON?path=/${process.env.REACT_APP_PATH2}/file/drhistory/${projectCode}/${fileName.name}`));
  
          const drResponses = await Promise.all(fileRequests);
          const drDataList = drResponses.map((response:any) => response.data);
  
          const drRes2 = await api.get(`/getemsJSON?path=/${process.env.REACT_APP_PATH2}/file/dailyreport/${projectCode}_dr.json`);
  
          const allData = [...drDataList.flat(), drRes2.data];
          setProjectDRData(allData || null);
        } catch (err) {
          setError(err as Error);
        } finally {
          setLoading(false);
        }
      }
    };
  
    fetchData();
  }, [projectCode]);

  return { projectDRData, projectDRDataloading, error };
}

export function useGetProgressSimpleData() {
  
    const { projectCode } = useParams();

  const [simpleProgressData, setSimpleProgressData] = useState<SimpleProgressData | null>(null);
  const [simpleProgressDataloading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      if (projectCode) {
        try {
          const body = {
            bpname: "트랜잭션_EPC Progress",
            lineitem: "no",
            filter_condition: "status=Active",
            record_fields: "record_no;Epc_reportdate;epc_category_pd;epc_plan;epc_Actual;epc_Dev"
          };

          const res = await api.post(`/getemsbprecord?path=${projectCode}`, body);
          const activeData = res.data.data.data;

          // Find the record with the largest record_no
          const maxRecord = activeData.reduce((max: any, item: any) =>
            item.record_no > max.record_no ? item : max, activeData[0]
          );

          // Filter data with the same Epc_reportdate as the maxRecord's Epc_reportdate
          const filteredData = activeData.filter(
            (item: any) => item.Epc_reportdate === maxRecord.Epc_reportdate
          );

          // Extract specific progress values for each category
          const eprogressActual = filteredData.find((v: any) => v.epc_category_pd === "Engineering")?.epc_Actual || null;
          const eprogressPlan = filteredData.find((v: any) => v.epc_category_pd === "Engineering")?.epc_plan || null;
          const pprogressActual = filteredData.find((v: any) => v.epc_category_pd === "Procurement")?.epc_Actual || null;
          const pprogressPlan = filteredData.find((v: any) => v.epc_category_pd === "Procurement")?.epc_plan || null;
          const cprogressActual = filteredData.find((v: any) => v.epc_category_pd === "Construction")?.epc_Actual || null;
          const cprogressPlan = filteredData.find((v: any) => v.epc_category_pd === "Construction")?.epc_plan || null;
          const oprogressActual = filteredData.find((v: any) => v.epc_category_pd === "Overall")?.epc_Actual || null;
          const oprogressPlan = filteredData.find((v: any) => v.epc_category_pd === "Overall")?.epc_plan || null;

          const lastDate = maxRecord.Epc_reportdate

          setSimpleProgressData({
            lastDate,
            eprogressActual,
            eprogressPlan,
            pprogressActual,
            pprogressPlan,
            cprogressActual,
            cprogressPlan,
            oprogressActual,
            oprogressPlan
          });
        } catch (err) {
          setError(err as Error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [projectCode]);

  return { simpleProgressData, simpleProgressDataloading, error };
}

export function useGetLTIRData() {
  
    const { projectCode } = useParams();

  const [projectLTIRData, setProjectLTIRData] = useState<ProjectLTIRData | null>(null);
  const [LTIRDataloading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      if (projectCode) {
        try {

          const res = await api.get(`/getltir?path=${projectCode}`);
          const activeData = res.data.data;

          setProjectLTIRData({
            actual: activeData.actulval,
            target: activeData.targetval,
            company: activeData.companyval
          })


        } catch (err) {
          setError(err as Error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [projectCode]);

  return { projectLTIRData, LTIRDataloading, error };
}

export function useGetPTWData() {
  
    const { projectCode } = useParams();

  const [projectPTWData, setProjectPTWData] = useState<ProjectPTWData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      if (projectCode) {
        try {

          const res = await api.get(`/getemsJSON?path=/${process.env.REACT_APP_PATH2}/file/safety/${projectCode}_safety.json`,)
          const activeData = res.data;
          if (projectCode === '22105D' || projectCode === '22106D') {
            setProjectPTWData(
              activeData.map((data: any) => {
                return {
                  WorkType: data.d_cwp_check_list_dpk,
                  IssueDate: data.d_work_start.slice(6, 10) + '년 ' + data.d_work_start.slice(0, 2) + '월 ' + data.d_work_start.slice(3, 5) + '일',
                  record_no: data.record_no,
                  count: 1,
                  workercount:Number(data.d_number_of_person)

                }
              })
            )
          }

          else {
            setProjectPTWData(
              activeData.map((data: any) => {
                return {
                  WorkType: data.asWorkType,
                  WorkType2: data.asTargetHazardNM,
                  IssueDate: data.asWorkDate.slice(6, 10) + '년 ' + data.asWorkDate.slice(0, 2) + '월 ' + data.asWorkDate.slice(3, 5) + '일',
                  record_no: data.record_no,
                  asWorkStatus: data.asWorkStatus,
                  count: 1,
                  workercount:Number(data.asWorkNo)

                }
              })
            )
          }


        } catch (err) {
          setError(err as Error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [projectCode]);

  return { projectPTWData, error };
}


export function useGetPQIdata() {
  
    const { projectCode } = useParams();

  const [projectPQIData, setProjectPQIData] = useState<projectPQIData[] | null>(null);
  const [projectPQIVendorData, setProjectPQIVendorData] = useState<projectPQIVendorData[] | null>(null);
  const [projectPQIDataloading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      if (projectCode) {
        try {

          const pqi = await api.get(`/getemsJSON?path=/${process.env.REACT_APP_PATH2}/file/pqi/project_all_pqi.json`);
          const filteredData = pqi.data.filter((v:any)=>v.projectcode===projectCode);

          const body = {
            bpname: "BP PQI",
            lineitem: "no",
            "record_fields" :"PQIYearMonth;d_company_name;PQIPoint",
            "filter_criteria":{
              "join":"AND",
              "filter":[
                {
                  "field":"ugenProjectNumber",
                  "value":projectCode,
                  "condition_type":"eq"
                  },
              ]
            }

          }

          const res = await api.post(`/getemsbprecord?path=SKEC0001`, body);
          const activeData = res.data.data.data

          setProjectPQIData( filteredData|| null);
          setProjectPQIVendorData( activeData|| null);
        } catch (err) {
          setError(err as Error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [projectCode]);

  return { projectPQIData, projectPQIVendorData,projectPQIDataloading };
}


export function useGetCProgressData(level1="전체",level2="전체",level3="전체",level4="전체") {
  
    const { projectCode } = useParams();

  const [cProgressData, setCProgressData] = useState<ProjectProgressData[] | null>(null);  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      if (projectCode) {
        try {

          const res = await api.get(`/getSelectedData?project=${projectCode}&level1=${level1}&level2=${level2}&level3=${level3}&level4=${level4}`);
          
          if(res.data.success){
            setCProgressData(res.data.data)
          }



        } catch (err) {
          setError(err as Error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [projectCode, level1,level2, level3, level4]);

  return { cProgressData, loading, error };
}

export function useGetCLevelData(monthweek="W") {
  
    const { projectCode } = useParams();

  const [cLevelData, setCLevelData] = useState<ProjectLevelData[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      if (projectCode) {
        try {

          const res = await api.get(`/getLevels?project=${projectCode}&monthweek=${monthweek}`);
          if(res.data.success){
            setCLevelData(res.data.levelRows)
          }
        } catch (err) {
          setError(err as Error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [projectCode, monthweek]);

  return { cLevelData, loading, error };
}