
import React, { useEffect, useState, useRef } from 'react';
import api from '@/lib/axiosInstance';
import { ChevronLeft, ChevronRight, Loader2, TrendingUp, TrendingDown, X } from 'lucide-react';
import dayjs from 'dayjs';
import { Bar, BarChart, CartesianGrid, XAxis, LabelList, YAxis } from "recharts"
import {
    ChartConfig,
    ChartContainer,
    ChartLegend,
    ChartLegendContent,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart"
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { useParams } from "react-router-dom";
import { Combobox } from '../common/comboBox';



export function PQIBarChart() {

    const [loading, setLoading] = useState<boolean>(false); // Loading state
    const [selectedValue, setSelectedValue] = useState('SKEE');
    const [projectPQIData, setPQIData] = useState<[] | null>(null);
    const [vendorPQIData, setPQIVData] = useState<[] | null>(null);
const { projectCode } = useParams();
    const [selectedVendor, setSelectedVendor] = useState<null>(null);



    const selectedVendorPQI = vendorPQIData && prepareVendorChartData(vendorPQIData?.filter((v: any) => v.d_company_name === selectedVendor).
        map((v: any) => ({
            ...v, month: v.PQIYearMonth,
            pqi: v.PQIPoint
        }))
    )


    function prepareChartData(data: any[]): any[] {
        const keyMap: Record<string, string> = {
            "자재검사 신청": "PQIPoint01",
            "자재검사 실패율": "PQIPoint02",
            "자재검사 완료": "PQIPoint03",
            "시공검사 신청": "PQIPoint04",
            "시공검사 실패율": "PQIPoint05",
            "시공검사 완료": "PQIPoint06",
            "NCR/Punch 발행건수": "PQIPoint07",
            "NCR 지연": "PQIPoint08",
            "CAR 발행건수": "PQIPoint09",
            "PAR 발행건수": "PQIPoint10"
        };

        return data.map(item => {
            const newItem: Record<string, any> = {};
            Object.keys(item).forEach(key => {
                // 매핑된 새 키를 찾거나 기존 키 사용
                const newKey = keyMap[key] || key;
                const value = item[key];
                // 숫자로 변환 가능한 경우 숫자로 변환, 아니면 0
                newItem[newKey] = !isNaN(Number(value)) ? Number(value) : 0;
                // 원본 값 저장
                newItem[`original_${newKey}`] = value;
            });
            return newItem;
        });
    }


    function prepareVendorChartData(data: any[]): any[] {
        return data.map(item => {
            const newItem: Record<string, any> = {}; // 여기에서 Record<string, any> 타입 사용
            Object.keys(item).forEach(key => {
                const value = item[key];
                newItem[key] = !isNaN(Number(value)) ? Number(value) : 0; // 기본값으로 0 사용
                newItem[`original_${key}`] = value; // 원본 값 저장
            });
            return newItem;
        });
    }
    const filteredPQIData = projectPQIData?.filter(v => v['project'] === projectCode).slice(-3);
    const formattedData = filteredPQIData && prepareChartData(filteredPQIData);

    const filteredVendorData = vendorPQIData?.filter((v: any) => v.ugenProjectNumber === projectCode);
    const companyList = filteredVendorData?.map((v: any) => v.d_company_name)
    const uniqueCompanyList = Array.from(new Set(companyList)).map(company => ({
        label: company,
        value: company
    }));


    const averagePQIVendor = prepareVendorChartData(updatePQIScores(formatGroupedData(calculateGroupedAveragePQI(filteredVendorData))))



    function calculateGroupedAveragePQI(data: any) {
        const groupedData: Record<string, any> = {};
        const averages: Record<string, any> = {};

        // 데이터 그룹화
        data && data.forEach((item: any) => {
            const month = item.PQIYearMonth;
            if (!groupedData[month]) {
                groupedData[month] = [];
            }
            groupedData[month].push(item);
        });

        // 각 그룹별로 평균 계산
        Object.keys(groupedData).forEach(month => {
            const group = groupedData[month];
            const totals: Record<string, any> = {};
            let validCounts: Record<string, any> = {};

            // 각 PQIPoint 초기화
            for (let i = 1; i <= 10; i++) {
                const pointKey = `PQIPoint${i < 10 ? '0' + i : i}`;
                totals[pointKey] = 0;
                validCounts[pointKey] = 0;
            }

            // 점수 합산 및 유효 카운트
            group.forEach((item: any) => {
                Object.keys(totals).forEach(key => {
                    const value = parseFloat(item[key]);
                    if (!isNaN(value)) {
                        totals[key] += value;
                        validCounts[key]++;
                    }
                });
            });

            // 평균 계산
            averages[month] = {};
            Object.keys(totals).forEach(key => {
                averages[month][key] = validCounts[key] > 0 ? (totals[key] / validCounts[key]).toFixed(2) : 'N/A';
            });
        });

        return averages;
    }

    function formatGroupedData(groupedAverages: any) {
        const formattedData = Object.keys(groupedAverages).map(month => {
            const entries = groupedAverages[month];
            const formattedEntries: Record<string, any> = {};

            Object.keys(entries).forEach(key => {
                formattedEntries[key] = entries[key] !== 'N/A' ? parseFloat(entries[key]) : 'N/A';
            });

            return { month, ...formattedEntries };
        });

        return formattedData;
    }

    function updatePQIScores(data: any) {
        if (!Array.isArray(data)) {
            console.error('Invalid data: Expected an array, got:', data);
            return []; // 또는 적절한 기본값 반환
        }

        return data.map((v: any) => {
            let pqi = parseFloat(v.PQIPoint01) + parseFloat(v.PQIPoint02) + parseFloat(v.PQIPoint03) +
                parseFloat(v.PQIPoint04) + parseFloat(v.PQIPoint05) + parseFloat(v.PQIPoint06) +
                parseFloat(v.PQIPoint07) + (isNaN(parseFloat(v.PQIPoint08)) ? 0 : parseFloat(v.PQIPoint08)) +
                parseFloat(v.PQIPoint09) + (isNaN(parseFloat(v.PQIPoint10)) ? 0 : parseFloat(v.PQIPoint10));

            // 조정 계수 적용
            if (v.PQIPoint08 === "N/A" && v.PQIPoint10 === "N/A") {
                pqi *= 100 / 80; // 80%만 점수로 환산
            } else if (v.PQIPoint08 === "N/A" || v.PQIPoint10 === "N/A") {
                pqi *= 100 / 90; // 90%만 점수로 환산
            }

            return { ...v, pqi: pqi.toFixed(2) }; // 계산된 PQI 점수를 소수점 두 자리로 반올림하여 추가
        });
    }


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading
            try {

                const body = {
                    bpname: "BP PQI",
                    lineitem: "no",
                    // "filter_condition" : `ugenProjectNumber=${projectCode}`,
                    "record_fields": "ugenProjectNumber;PQIYearMonth;d_company_name;PQIPoint;PQIPoint01;PQIPoint02;PQIPoint03;PQIPoint04;PQIPoint05;PQIPoint06;PQIPoint07;PQIPoint08;PQIPoint09;PQIPoint10;",
                    "filter_criteria": {
                        "join": "OR",
                        "filter": [
                            {
                                "field": "PQIYearMonth",
                                "value": dayjs().format("YYYY년 MM월"),
                                "condition_type": "eq"
                            },
                            {
                                "field": "PQIYearMonth",
                                "value": dayjs().add(-1, 'M').format("YYYY년 MM월"),
                                "condition_type": "eq"
                            },
                            {
                                "field": "PQIYearMonth",
                                "value": dayjs().add(-2, 'M').format("YYYY년 MM월"),
                                "condition_type": "eq"
                            },
                        ]
                    }

                }
                const res = await api.get(`/getemsJSON?path=/${process.env.REACT_APP_PATH2}/file/pqi/project_pqi.json`);
                const resVendor = await api.post(`/getemsbprecord?path=SKEC0001`, body);
                setPQIData(res.data)
                setPQIVData(resVendor.data.data.data)

            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false); // End loading
            }
        }

        fetchData();
    }, []);



    const chartData = selectedValue === "SKEE" ? formattedData : selectedVendor === null ? averagePQIVendor : selectedVendorPQI;

    // Function to calculate the number of decreased PQIPoints
    const getDecreasedCount = (currentData: any, previousData: any) => {
        let decreasedCount = 0;
        for (let i = 1; i <= 10; i++) {
            const key = `PQIPoint${i < 10 ? '0' + i : i}`; // Adjust key for two digits
            const original_key = `original_PQIPoint${i < 10 ? '0' + i : i}`; // Adjust key for two digits
            if (previousData && currentData[key] < previousData[key] && currentData[original_key] !== "N/A") {
                decreasedCount++;
            }
        }
        return decreasedCount;
    };

    // Custom label renderer to display pqi and decreased count
    const renderCustomLabel = (props: any) => {
        const { x, y, width, value, index, payload } = props;

        let decreasedCount = 0;

        if (index > 0) {
            const currentData = chartData && chartData[index];
            const previousData = chartData && chartData[index - 1] || {}; // Handling the first item case
            decreasedCount = getDecreasedCount(currentData, previousData); // Calculate decreased count
        }

        return (
            <g>
                <text x={x + width / 2} y={y - 6} fill="#000" fontSize={14} fontWeight="bold" textAnchor="middle">
                    {value} {/* pqi value */}
                </text>
                {decreasedCount > 0 && (
                    <text x={x + width / 2 + 20} y={y - 6} fill="#ff4500" fontSize={12} fontWeight="bold" textAnchor="start">
                        ↓{decreasedCount}
                    </text>
                )}
            </g>
        )

    };

    const chartConfig = {
        "PQIPoint01": {
            label: "자재검사 신청",
            color: "hsl(var(--pastelchart-1))",
        },
        "PQIPoint02": {
            label: "자재검사 실패율",
            color: "hsl(var(--pastelchart-2))",
        },
        "PQIPoint03": {
            label: "자재검사 완료",
            color: "hsl(var(--pastelchart-3))",
        },
        "PQIPoint04": {
            label: "시공검사 신청",
            color: "hsl(var(--pastelchart-4))",
        },
        "PQIPoint05": {
            label: "시공검사 실패율",
            color: "hsl(var(--pastelchart-5))",
        },
        "PQIPoint06": {
            label: "시공검사 완료",
            color: "hsl(var(--pastelchart-6))",
        },
        "PQIPoint07": {
            label: selectedValue === "SKEE" ? "NCR/Punch 월평균 발행건수" : "NCR 발행건수",
            color: "hsl(var(--pastelchart-7))",
        },
        "PQIPoint08": {
            label: "NCR 지연",
            color: "hsl(var(--pastelchart-8))",
        },
        "PQIPoint09": {
            label: "CAR 발행건수",
            color: "hsl(var(--pastelchart-9))",
        },
        "PQIPoint10": {
            label: selectedValue === "SKEE" ? "PAR 발행건수" : "CAR 지연",
            color: "hsl(var(--pastelchart-10))",
        },

    } satisfies ChartConfig


    function getPreviousMonthTrend(index: number, data: any, dataKey: any) {
        if (index > 0) {  // 첫 번째 월은 비교할 이전 데이터가 없습니다.
            const previousValue = parseFloat(data[index - 1] && data[index - 1][dataKey]);
            const currentValue = parseFloat(data[index] && data[index][dataKey]);

            return currentValue - previousValue

            // if (currentValue > previousValue) {
            //     return 'TrendingUp';
            // } else if (currentValue < previousValue) {
            //     return 'TrendingDown';
            // }
        }
        return null; // 첫 번째 데이터이거나 비교 불가능
    }

    return (
        <>
            {loading ? (
                <div className="flex justify-center items-center h-full">
                    <Loader2 className="w-5 h-5 animate-spin" />
                </div>
            ) : (
                <>
                    <div className="flex items-center gap-4 w-full pr-4 pl-4">
                        {/* 왼쪽에 Select 컴포넌트 배치 */}
                        {selectedValue !== "SKEE" &&
                            <Combobox
                                options={uniqueCompanyList ?? []}
                                value={selectedVendor || ''}
                                onValueChange={(value: any) => setSelectedVendor(value)}
                                placeholder="Select a Company..."
                                className="w-full md:w-45 truncate"
                            />

                        }

                        {/* 오른쪽에 ToggleGroup 컴포넌트 배치 */}
                        <div className="ml-auto flex gap-2 pr-6">
                            <ToggleGroup className='gap-0' size="sm" type="single" value={selectedValue} onValueChange={setSelectedValue}>
                                <ToggleGroupItem value="SKEE" aria-label="SKEE">
                                    <span className="text-xs">SKEE</span>
                                </ToggleGroupItem>
                                <ToggleGroupItem value="BizP" aria-label="Biz.P">
                                    <span className="text-xs">Biz.P</span>
                                </ToggleGroupItem>
                            </ToggleGroup>
                        </div>
                    </div>


                    {/* Chart below buttons */}
                    <div className="chartContainer overflow-hidden w-full">

                        {chartData && chartData.length > 0 ?
                            <ChartContainer config={chartConfig} className="mx-auto h-[480px] w-5/6 p-2">

                                <BarChart
                                    data={chartData}
                                    margin={{
                                        top: 35,
                                    }}
                                >
                                    <CartesianGrid vertical={false} horizontal={false} />
                                    <XAxis
                                        dataKey="original_month"
                                        tickLine={false}
                                        tickMargin={10}
                                        axisLine={false}
                                        tickFormatter={(value) => value}

                                    />

                                    <Bar dataKey="PQIPoint01" stackId="a" fill={`var(--color-PQIPoint01)`} radius={[0, 0, 8, 8]} />
                                    <Bar dataKey="PQIPoint02" stackId="a" fill={`var(--color-PQIPoint02)`} radius={[0, 0, 0, 0]} />
                                    <Bar dataKey="PQIPoint03" stackId="a" fill={`var(--color-PQIPoint03)`} radius={[0, 0, 0, 0]} />
                                    <Bar dataKey="PQIPoint04" stackId="a" fill={`var(--color-PQIPoint04)`} radius={[0, 0, 0, 0]} />
                                    <Bar dataKey="PQIPoint05" stackId="a" fill={`var(--color-PQIPoint05)`} radius={[0, 0, 0, 0]} />
                                    <Bar dataKey="PQIPoint06" stackId="a" fill={`var(--color-PQIPoint06)`} radius={[0, 0, 0, 0]} />
                                    <Bar dataKey="PQIPoint07" stackId="a" fill={`var(--color-PQIPoint07)`} radius={[0, 0, 0, 0]} />
                                    <Bar dataKey="PQIPoint08" stackId="a" fill={`var(--color-PQIPoint08)`} radius={[0, 0, 0, 0]} />
                                    <Bar dataKey="PQIPoint09" stackId="a" fill={`var(--color-PQIPoint09)`} radius={[0, 0, 0, 0]} />

                                    <Bar
                                        dataKey="PQIPoint10"
                                        stackId="a"
                                        fill={`var(--color-PQIPoint10)`}
                                        radius={[0, 0, 8, 8]}
                                    >
                                        <LabelList dataKey="pqi" content={renderCustomLabel} />

                                    </Bar>
                                    <ChartTooltip
                                        content={
                                            <ChartTooltipContent
                                                // hideLabel
                                                className="w-[260px]"
                                                formatter={(value, name, item, index) => {
                                                    const formattedName = typeof name === "string" ? name.replace(/[\s\/]/g, '') : name;
                                                    const chartLabel = chartConfig[formattedName as keyof typeof chartConfig]?.label || name;
                                                    const formattedValue = typeof value === 'number' ? value.toFixed(1) : value;
                                                    const trend = chartData && getPreviousMonthTrend(chartData.findIndex(v => v.original_month === item.payload.original_month), chartData, formattedName);
                                                    const trendPQI = chartData && getPreviousMonthTrend(chartData.findIndex(v => v.original_month === item.payload.original_month), chartData, "pqi");

                                                    const displayValue = item.payload[`original_${formattedName}`] === 'N/A' ? 'N/A' : formattedValue; // 숫자가 0이면 'N/A'로 표시


                                                    const currentWeekIssuePointValue = chartData.find(v => v.original_month === dayjs().format("YY년 MM월"))?.original_currentWeekIssuePoint !== null ?
                                                        (chartData.find(v => v.original_month === dayjs().format("YY년 MM월"))?.currentWeekIssuePoint * 0.15).toFixed(2) : "N/A";


                                                    return (
                                                        <>
                                                            <div
                                                                className="h-2.5 w-2.5 shrink-0 rounded-[2px] bg-[--color-bg]"
                                                                style={{
                                                                    "--color-bg": `var(--color-${formattedName})`,
                                                                } as React.CSSProperties}
                                                            />
                                                            {chartLabel}
                                                            <div className="ml-auto flex items-baseline gap-0.5 font-medium tabular-nums text-foreground">
                                                                {displayValue}
                                                                {displayValue !== "N/A" && trend && trend > 0 ?
                                                                    <><span className='text-emerald-500 text-[10px]'>+{trend.toFixed(1)}</span><TrendingUp className="h-3 w-3 text-emerald-500" /></> :
                                                                    displayValue !== "N/A" && trend && trend < 0 ? <><span className='text-red-500 text-[10px]'>{trend.toFixed(1)}</span><TrendingDown className="h-3 w-3 text-red-500" /></> : null}

                                                            </div>

                                                            {formattedName === "PQIPoint07" && selectedValue === "SKEE" && item.payload.original_month === dayjs().format("YY년 MM월") &&


                                                                <div className="flex basis-full items-center text-xs font-medium text-foreground">
                                                                    NCR/Punch 1주간 발행점수
                                                                    <div className="ml-auto flex items-baseline gap-0.5 font-medium tabular-nums text-foreground">
                                                                        {currentWeekIssuePointValue}
                                                                    </div>
                                                                </div>
                                                            }

                                                            {/* Add this after the last item */}
                                                            {index === 9 && (
                                                                <div className="mt-1.5 flex basis-full border-t items-center pt-1.5 text-xs font-medium text-foreground">
                                                                    PQI:
                                                                    <div className="ml-auto flex items-baseline gap-0.5 font-medium tabular-nums text-foreground">
                                                                        {item.payload.pqi}

                                                                        {trendPQI && trendPQI > 0 ?
                                                                            <><span className='text-emerald-500 text-[10px]'>+{trendPQI.toFixed(1)}</span><TrendingUp className="h-3 w-3 text-emerald-500" /></> :
                                                                            trendPQI && trendPQI < 0 ? <><span className='text-red-500 text-[10px]'>{trendPQI.toFixed(1)}</span><TrendingDown className="h-3 w-3 text-red-500" /></> : null}

                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    );
                                                }}
                                            />
                                        }
                                        cursor={false}
                                    // defaultIndex={2}
                                    />
                                </BarChart>

                            </ChartContainer>
                            :
                            <div className="flex justify-center items-center h-full text-xs text-slate-700">
                                No data available
                            </div>
                        }
                    </div>
                </>
            )
            }
        </>
    )

}