import { Sidebar, SidebarContent, SidebarGroup, SidebarGroupContent, SidebarGroupLabel, SidebarMenu, SidebarMenuSub, SidebarMenuButton, SidebarMenuSubItem, SidebarMenuItem, SidebarHeader, SidebarFooter } from "@/components/ui/sidebar";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { Home, ChevronDown, LogOut, Factory, Heading2, Zap } from "lucide-react";
import { useAtom } from 'jotai';
import { languageState, divisionState } from '@/atoms';
import { useNavigate, useLocation, Link } from 'react-router-dom'; 
import { useTranslation } from 'react-i18next';
import { Button } from "@/components/ui/button";
import { projectCodeState } from '@/atoms';
import { useGetProjectList } from '@/hooks/useQuery';
import api from '@/lib/axiosInstance';
import { logout } from '@/components/common/logout';
import useDocumentTitle from "@/hooks/useDocumentTitle";

interface SubItem {
    title: string;
    url: string;
    use: string;
}

interface Item {
    title: string;
    icon: React.ComponentType;
    subItems: SubItem[];
}

export function TotalSidebar() {


    const { t, i18n } = useTranslation();  
    const [language, setLanguage] = useAtom(languageState);
    const [division, setDivision] = useAtom(divisionState);
    const navigate = useNavigate(); // useNavigate 사용
    const location = useLocation(); // useLocation 사용
    const [, setProjectCode] = useAtom(projectCodeState);
    const { data: projectsList } = useGetProjectList();

    useDocumentTitle(i18n.language ==="ko"? "S.CON - 통합 Dashboard":"S.CON Company Dashboard")

    const pathname = location.pathname;

    const handleLanguageChange = (lang: string) => {
        i18n.changeLanguage(lang);
    };

    const handleNavigation = (path: string) => {
        window.open(path, "_blank"); // Open in a new tab
    };

    const handleNavigation2 = (path: string) => {
        navigate(path); // navigate 사용하여 내부 이동
    };

    const handleDivisionSelect = (path: string) => {
        const value = path === "SK 에코엔지니어링" ? null : path;
        setDivision(value);
        navigate("/total"); // navigate 사용하여 이동
    };



    const handleLogout = async() => {

        const res = await api.get(`/getcheckowner?project=SKEC0001`);
        if (res.data.success ) {
            const client = res.data.client; // 예를 들어, API 응답에서 'client' 데이터를 기대한다고 가정
            logout({
              project: "total",
              client: client
            });

          } else {

          }

          navigate("/login"); // 로그아웃 후 로그인 페이지로 이동
    };


    const items = (t: (key: string) => string) => {
        // Icon mapping for each division
        const iconsMap: Record<string, React.ComponentType> = {
            "Industrial": Factory,
            "Gas&Power": Zap,
            "수소친환경": Heading2,
            // "ESG": ESGIcon, // Using an SVG or PNG image
            // Add additional divisions and icons here as needed
        };

        // Static first item
        const staticItem: Item = {
            title: "SK 에코엔지니어링",
            icon: Home,
            subItems: [], // Add an empty subItems array
        };
        // Group projects by division
        const groupedItems: Record<string, Item> = {};
        projectsList && projectsList.forEach(project => {
            const { division, projectname, projectcode, use } = project;

            if (!groupedItems[division]) {
                groupedItems[division] = {
                    title: division,
                    icon: iconsMap[division] || Home, // Default to Home if no icon found
                    subItems: []
                };
            }

            groupedItems[division].subItems.push({
                title: projectname,
                url: `/${projectcode}`,
                use: use
            });
        });

        // Convert groupedItems into an array and add staticItem as the first element
        return [staticItem, ...Object.values(groupedItems)];
    };
    return (
        <Sidebar>
            <SidebarHeader className="flex items-center justify-center p-8">
                <img src="/images/S.CON black.svg" alt="Logo" width={120} height={50} />
                {/* <span className="font-bold mt-4">
                   통합 대시보드
                </span> */}
            </SidebarHeader>
            <SidebarContent>
                <SidebarGroup>
                    <SidebarGroupLabel>통합 대시보드</SidebarGroupLabel>
                    <SidebarGroupContent>
                        <SidebarMenu>
                            {items(t).map((item, index) => {

                                const isActive = (pathname ==="/total"&& division === item.title) || (pathname ==="/total"&&division === null && item.title === "SK 에코엔지니어링");
                                return (

                                    <Collapsible key={index} className="group/collapsible">
                                        <SidebarMenuItem>
                                            <SidebarMenuButton >
                                                <item.icon />
                                                <span onClick={() => handleDivisionSelect(item.title)} className={isActive ? 'text-blue-600' : ''}>{item.title}</span>
                                                {item.subItems && item.subItems.length > 0 && (
                                                    <CollapsibleTrigger asChild>
                                                        <ChevronDown className="ml-auto transition-transform group-data-[state=open]/collapsible:rotate-180" />
                                                    </CollapsibleTrigger>
                                                )}
                                            </SidebarMenuButton>

                                            <CollapsibleContent>
                                                <SidebarMenuSub>
                                                    {item.subItems.map((subItem, subIndex) => {
                                                        const isOperable = subItem.use === "운용"; // Check if the item is "운용"
                                                        console.log("isOperable status for", subItem.title, ":", isOperable);
                                                        return (
                                                            <SidebarMenuSubItem
                                                                key={subIndex}
                                                                onClick={isOperable ? () => handleNavigation(subItem.url) : undefined} // Only clickable if "운용"
                                                                className={`cursor-pointer px-2 py-1 rounded-md transition-colors duration-150 
                    ${isOperable ? 'hover:bg-gray-100 hover:text-blue-600' : 'text-gray-400 cursor-not-allowed'}`
                                                                } // Muted color and no hover if "미운용"
                                                            >
                                                                <span>{subItem.title}</span>
                                                            </SidebarMenuSubItem>
                                                        );
                                                    })}
                                                </SidebarMenuSub>
                                            </CollapsibleContent>
                                        </SidebarMenuItem>
                                    </Collapsible>
                                )
                            })}
                        </SidebarMenu>
                    </SidebarGroupContent>
                </SidebarGroup>


            </SidebarContent>
            <SidebarFooter>
                <div className="flex justify-center items-center text-sm mb-0">
                    <div>
                        <button
                            className={`px-2 ${language === 'ko' ? 'font-bold' : ''}`}
                            onClick={() => handleLanguageChange('ko')}
                        >
                            한국어
                        </button>
                        <span className="text-gray-400">|</span>
                        <button
                            className={`px-2 ${language === 'en' ? 'font-bold' : ''}`}
                            onClick={() => handleLanguageChange('en')}
                        >
                            English
                        </button>
                    </div>
                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={handleLogout}
                        className="text-red-500 ml-auto"
                        aria-label="로그아웃"
                    >
                        <LogOut className="w-5 h-5" />
                    </Button>
                </div>
            </SidebarFooter>
        </Sidebar>
    );
}