"use client"

import React, { useEffect, useState, useMemo } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { RefreshCw } from 'lucide-react';
import api from '@/lib/axiosInstance';
import { ProgressWithValue } from '../ui/progressbar';
import { projectCodeState, constructionEarnedValueAtom, constructionPlanValueAtom } from '@/atoms';
import { useAtomValue } from 'jotai';
import { AcitivtyColumns } from './activity-colmuns';
import { DataTable } from '../tables-client/data-tables';
import { useParams } from "react-router-dom";

interface DataItem {
    name: string;   // assuming name is always a string
    actual: number; // assuming actual is always a number
    plan: number;   // assuming plan is always a number
}

export function CWPStatus() {

    // State to track selected items for each list
    const [selectedWbs1, setSelectedWbs1] = useState(null);
    const [selectedWbs2, setSelectedWbs2] = useState(null);
    const [selectedWbs3, setSelectedWbs3] = useState(null);
    const [loading, setLoading] = useState(true);
     const { projectCode } = useParams()
    const constructionEarnedValue = useAtomValue(constructionEarnedValueAtom) ?? undefined;
    const constructionPlanValue = useAtomValue(constructionPlanValueAtom) ?? undefined;
    const [progressData, setProgressData] = useState<any>(null);


    // Updated to use specific setters for each list
    const handleSelect = (item: any, listType: any) => {
        switch (listType) {
            case 'wbs1':
                setSelectedWbs1(item);
                setSelectedWbs2(null);
                setSelectedWbs3(null);

                break;
            case 'wbs2':
                setSelectedWbs2(item);
                setSelectedWbs3(null);

                break;
            case 'wbs3':
                setSelectedWbs3(item);
                break;
            default:
                break;
        }
    };

    const wbs1List = useMemo(() => {
        return progressData ? [...new Set(progressData.map((item: any) => item.Lv_1_Name))] : [];
    }, [progressData]);

    const wbs2List = useMemo(() => {
        return progressData && selectedWbs1
            ? [...new Set(progressData.filter((v: any) => v.Lv_1_Name === selectedWbs1).map((item: any) => item.Lv_2_Name))]
            : [];
    }, [progressData, selectedWbs1]);

    const wbs3List = useMemo(() => {
        return progressData && selectedWbs1 && selectedWbs2
            ? [...new Set(progressData.filter((v: any) => v.Lv_1_Name === selectedWbs1 && v.Lv_2_Name === selectedWbs2).map((item: any) => item.Lv_3_Name))]
            : [];
    }, [progressData, selectedWbs1, selectedWbs2]);

    const disciplineList = useMemo(() => {
        return progressData ? [...new Set(progressData.map((item: any) => item.Lv_4_Name))] : [];
    }, [progressData]);


    const filteredActivityList = useMemo(() => {
        return progressData
            ? progressData.filter((v: any) => {
                return (!selectedWbs1 || v.Lv_1_Name === selectedWbs1) &&
                    (!selectedWbs2 || v.Lv_2_Name === selectedWbs2) &&
                    (!selectedWbs3 || v.Lv_3_Name === selectedWbs3);
            })
            : [];
    }, [progressData, selectedWbs1, selectedWbs2, selectedWbs3]);


    const tableActivityList = filteredActivityList.map((v:any)=> {

        const variance = v.Act_actual_progress -v.d_plan_progress

        return {

        id:v.uuu_P6ActivityId,
        name:v.uuu_P6ActivityName,
        status:v.Act_activity_status,
        d_plan_progress:v.d_plan_progress,
        Act_actual_progress:v.Act_actual_progress,
        progress_difference:Number(variance.toFixed(0)),
        d_forecast_qty:v.d_forecast_qty,
        d_actual_qty:v.d_actual_qty,
        Act_evm_plan_start:v.Act_evm_plan_start,
        Act_evm_plan_finish:v.Act_evm_plan_finish,
        Act_evm_actual_start:v.Act_evm_actual_start,
        Act_evm_actual_finish:v.Act_evm_actual_finish,
        d_remaining_qty:v.d_remaining_qty,
        "Progress Status":variance<-5?"Behind":variance>5? "Ahead":"On Schedule"
        }

    })

    const plannedValue = filteredActivityList && filteredActivityList.reduce((sum: any, item: any) => sum + Number(item.d_weight_value * item.d_plan_progress), 0)
    const earnedValue = filteredActivityList && filteredActivityList.reduce((sum: any, item: any) => sum + Number(item.d_weight_value * item.Act_actual_progress), 0)
    const weightValue = filteredActivityList && filteredActivityList.reduce((sum: any, item: any) => sum + Number(item.d_weight_value), 0)


    const planPercent = selectedWbs1 !== null ? ((plannedValue / weightValue)) : constructionPlanValue;
    const actualPercent = selectedWbs1 !== null ? ((earnedValue / weightValue)) : constructionEarnedValue;




    const calculateWeightedProgress = (data: any, levelName: string, groupName: string) => {
        const groupedData = data.reduce((acc: any, cur: any) => {
            const key = `${cur[levelName]} - ${cur[groupName]}`;
            if (!acc[key]) {
                acc[key] = { totalAssignedWV: 0, actualSum: 0, planSum: 0 };
            }
            acc[key].totalAssignedWV += cur.d_weight_value;
            acc[key].actualSum += cur.d_weight_value * (cur.Act_actual_progress / 100);
            acc[key].planSum += cur.d_weight_value * (cur.d_plan_progress / 100);
            return acc;
        }, {});

        return Object.keys(groupedData).map(key => ({
            name: key,
            actual: groupedData[key].totalAssignedWV ? groupedData[key].actualSum / groupedData[key].totalAssignedWV : 0,
            plan: groupedData[key].totalAssignedWV ? groupedData[key].planSum / groupedData[key].totalAssignedWV : 0
        }));
    };

    const tableData = useMemo(() => {
        if (!progressData) return [];

        if (!selectedWbs1) {
            // Group by Lv_1_Name and Lv_4_Name
            return calculateWeightedProgress(progressData, 'Lv_1_Name', 'Lv_4_Name');
        } else if (selectedWbs1 && !selectedWbs2) {
            // Filter by selectedWbs1 and group by Lv_2_Name and Lv_4_Name
            const filteredData = progressData.filter((item: any) => item.Lv_1_Name === selectedWbs1);
            return calculateWeightedProgress(filteredData, 'Lv_2_Name', 'Lv_4_Name');
        } 
        else if (selectedWbs1 && selectedWbs2 ) {
            // Filter by selectedWbs1 and selectedWbs2, then group by Lv_3_Name and Lv_4_Name
            const filteredData = progressData.filter((item: any) => item.Lv_1_Name === selectedWbs1 && item.Lv_2_Name === selectedWbs2);
            return calculateWeightedProgress(filteredData, 'Lv_3_Name', 'Lv_4_Name');
        }
        return [];
    }, [progressData, selectedWbs1, selectedWbs2]);



    function transformData(data: DataItem[]) {
        if (!data) return {};

        return data.reduce((acc: Record<string, Record<string, { actual: number, plan: number }>>, item: DataItem) => {
            // Destructure properties from the item with proper typing
            const { name, actual, plan } = item;

            // Split the name to separate the building and the discipline
            const [building, discipline] = name.split(" - ");

            // Initialize the building if it doesn't already exist
            if (!acc[building]) {
                acc[building] = {};
            }

            // Set the discipline data under the building
            acc[building][discipline] = { actual, plan };

            return acc;
        }, {});
    }

    const transformedData = transformData(tableData);

    console.log(transformedData)


    const resetAll = () => {
        setSelectedWbs1(null);
        setSelectedWbs2(null);
        setSelectedWbs3(null);
    };

    const resetWbs2and3 = () => {
        setSelectedWbs2(null);
        setSelectedWbs3(null);
    };

    const resetWbs3 = () => {
        setSelectedWbs3(null);
    };


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {

                
                const res = await api.get(`/getactivities?project=${projectCode}`)
                setProgressData(res.data.Rows);

            } catch (error) {
                console.error(`Failed to fetch data`, error);
            }

            setLoading(false);
        };

        if (projectCode) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [projectCode]); // viewMode 추가

  
    const statusCounts ={"Not Started":1, "In progress":2, "Completed":4}


    return (
        <>
            <Card className="w-full">
                <CardHeader className="text-left">
                    <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                        <span>CWP Status</span>
                        <span className="ml-auto flex items-center text-xs space-x-4 hidden sm:flex">
                            <div className="flex items-center space-x-1">
                                <span className="w-4 h-2 bg-red-500 inline-block"></span>
                                <span>behind (Dev. &lt; -5%)</span>
                            </div>
                            <div className="flex items-center space-x-1">
                                <span className="w-4 h-2 bg-yellow-500 inline-block"></span>
                                <span>on-schedule (-5% ≤ Dev. ≤ +5%)</span>
                            </div>
                            <div className="flex items-center space-x-1">
                                <span className="w-4 h-2 bg-emerald-500 inline-block"></span>
                                <span>ahead (Dev. &gt; +5%)</span>
                            </div>
                        </span>
                    </CardTitle>

                </CardHeader>
                <CardContent>
                    <div className='grid grid-cols-1 md:grid-cols-4 gap-4 w-full'>
                        <div className="justify-self-center self-center">
                            <div className="flex flex-col md:flex-row items-center space-x-0 md:space-x-8">
                                {/* Plan */}
                                <div className="flex flex-col items-center">
                                    <label className="text-sm mb-1 text-gray-600">Plan</label>
                                    <div className="text-xl text-center font-semibold">{planPercent.toFixed(2)}%</div>
                                </div>
                                {/* Separator */}
                                <div className="h-12 border-l border-gray-300 hidden md:block"></div>
                                {/* Actual */}
                                <div className="flex flex-col items-center">
                                    <label className="text-sm mb-1 text-gray-600">Actual</label>
                                    <div className="text-xl text-center text-blue-800 font-semibold">{actualPercent.toFixed(2)}%</div>
                                </div>
                                {/* Separator */}
                                <div className="h-12 border-l border-gray-300 hidden md:block"></div>
                                {/* Dev */}
                                <div className="flex flex-col items-center">
                                    <label className="text-sm mb-1  text-gray-600">Dev.</label>
                                    <div
                                        className={`text-xl text-center font-semibold ${actualPercent - planPercent < -5 ? 'text-red-500' : actualPercent - planPercent > 5 ? 'text-emerald-500' : 'text-yellow-500'}`}
                                    >{(actualPercent - planPercent).toFixed(2)}%</div>
                                </div>
                            </div>
                        </div>
                        <div className='text-xs'>
                            <div className='font-semibold flex items-center'>
                                CWA (WBS Lv.1)
                                <RefreshCw className='ml-1 h-3 w-3 cursor-pointer' onClick={resetAll} />
                            </div>
                            <div className='border-solid border-2 w-full h-[140px] rounded-md overflow-y-auto'>
                                {wbs1List && wbs1List
                                .sort((a:any,b)=> a.localeCompare(b))
                                .map((wbs: any, index: number) => (
                                    <button
                                        key={index}
                                        className={`text-black w-full text-left p-2 ${selectedWbs1 === wbs ? 'bg-gray-200' : 'hover:bg-gray-200'}`}
                                        onClick={() => handleSelect(wbs, 'wbs1')}
                                    >
                                        {wbs}
                                    </button>
                                ))}
                            </div>

                        </div>
                        <div className='text-xs'>
                            <div className='font-semibold flex items-center'>
                                Sub-CWA1 (WBS Lv.2)
                                <RefreshCw className='ml-1 h-3 w-3 cursor-pointer' onClick={resetWbs2and3} />
                            </div>
                            <div className='border-solid border-2 w-full h-[140px] rounded-md overflow-y-auto'>
                                {wbs2List && wbs2List
                                .sort((a:any,b)=> a.localeCompare(b))
                                .map((wbs: any, index: number) => (
                                    <button
                                        key={index}
                                        className={`text-black w-full text-left p-2 ${selectedWbs2 === wbs ? 'bg-gray-200' : 'hover:bg-gray-200'}`}
                                        onClick={() => handleSelect(wbs, 'wbs2')}
                                    >
                                        {wbs}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className='text-xs'>
                            <div className='font-semibold flex items-center'>
                                Sub-CWA2 (WBS Lv.3)
                                <RefreshCw className='ml-1 h-3 w-3 cursor-pointer' onClick={resetWbs3} />
                            </div>
                            <div className='border-solid border-2 w-full h-[140px] rounded-md overflow-y-auto'>
                                {wbs3List && wbs3List
                                .sort((a:any,b)=> a.localeCompare(b))
                                .map((wbs: any, index: number) => (
                                    <button
                                        key={index}
                                        className={`text-black w-full text-left p-2 ${selectedWbs3 === wbs ? 'bg-gray-200' : 'hover:bg-gray-200'}`}
                                        onClick={() => handleSelect(wbs, 'wbs3')}
                                    >
                                        {wbs}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='w-full overflow-x-auto p-2'>
                        <div className='overflow-hidden'>
                            <Table className='w-full'>
                                <TableHeader className='w-full'>
                                    <TableRow>
                                        <TableHead className="text-xs p-2 h-1 text-center"></TableHead>
                                        {disciplineList.map((discipline: any, index) => (
                                            <TableHead key={index} className="w-[100px] text-xs p-2 h-1 text-center">{discipline}</TableHead>
                                        ))}
                                    </TableRow>
                                </TableHeader>
                            </Table>
                        </div>
                        <div className='overflow-y-auto h-[18vh]'>
                            <Table className='w-full'>
                                <TableBody>
                                    {Object.entries(transformedData)
                                        .sort(([buildingA], [buildingB]) => buildingA.localeCompare(buildingB)) // Sorting buildings alphabetically
                                        .map(([building, disciplines], index) => (
                                            <TableRow key={index}>
                                                <TableCell className="font-medium text-xs p-2">{building}</TableCell>
                                                {disciplineList.map((discipline:any, idx) => {
                                                    const disciplineData = disciplines[discipline];
                                                    const dev = disciplineData && (disciplineData.actual * 100 - disciplineData.plan * 100);

                                                    return (
                                                        <TableCell key={idx} className="w-[100px] text-xs p-2">
                                                            {disciplineData ? (
                                                                <ProgressWithValue
                                                                    position='start'
                                                                    value={Number(((disciplineData.actual) * 100).toFixed(2))}
                                                                    indicatorColor={dev < -5 ? 'bg-red-500' : dev > 5 ? 'bg-emerald-500' : 'bg-yellow-500'}
                                                                />
                                                            ) : (
                                                                <div className='text-center'>-</div>
                                                            )}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </CardContent>


            </Card>
            <Card className="w-full h-1/2 ">
                <CardHeader className="text-left">
                    <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                        Activity Status
                    </CardTitle>
                </CardHeader>

                <CardContent>
                    <DataTable columns={AcitivtyColumns} data={tableActivityList}/>
                </CardContent>
            </Card>
        </>
    )

}
