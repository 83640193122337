
import React, { useEffect, useState } from 'react';
import api from '@/lib/axiosInstance';
import { Loader2 } from 'lucide-react';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import dayjs from 'dayjs';
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { useParams } from "react-router-dom";

export function Concern() {
    const [loading, setLoading] = useState(true);
const { projectCode } = useParams();
    const [issueData, setIssueData] = useState<any>(null);


    const tableData = issueData && issueData._bp_lineitems

    useEffect(() => {
        const fetchData = async () => {
            let body = {
                bpname: "Areas of Concern",
                lineitem: "yes",
                filter_condition: "status=Active"
            };

            try {
                const res = await api.post(`/getemsbprecord?path=${projectCode}`, body);
                setIssueData(res.data.data.data.sort((a: any, b: any) => b.record_no - a.record_no).reverse()[0]);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setLoading(false);
            }
        };

        if (projectCode) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [projectCode]);

    if (loading) {
        return (
            <Card className="col-span-12 md:col-span-8  md:row-span-1 md:h-[47.5vh]">
                <CardHeader className="text-left">
                    <CardTitle className="text-base leading-none tracking-tight">Area of Concern</CardTitle>
                </CardHeader>
                <CardContent className="flex items-center justify-center overflow-hidden">
                    <Loader2 className="w-5 h-5 animate-spin" />
                </CardContent>
            </Card>
        );
    }

    return (
        <Card className="row-start-2 col-span-12 md:col-span-8 md:h-[47.5vh]">
            <CardHeader className="text-left">
                <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                    <span>Area of Concern</span>
                    <span className="ml-auto text-xs">{dayjs(issueData?.d_reporting_date).format("YYYY년 MM월 DD일")}</span>
                </CardTitle>
            </CardHeader>
            <CardContent className="flex items-center justify-center overflow-y-auto">
                {issueData ? (
                    <div className="w-full md:max-h-[39vh] overflow-y-auto">

                        <div className='overflow-hidden'>
                            <Table className='text-sm'>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead className="w-[50px] text-left p-2 h-8">No.</TableHead>
                                        <TableHead className="w-[150px] text-left p-2 h-8">Discipline</TableHead>
                                        <TableHead className="text-left p-2 h-8">Description</TableHead>
                                    </TableRow>
                                </TableHeader>
                            </Table>
                        </div>
                        <div className='overflow-auto md:h-[35vh]'>
                            <Table className='text-sm'>
                                <TableBody>
                                    {tableData?.map((v:any, index:number) => {
                                        const planDate = dayjs(v.plan_date, "MM-DD-YYYY");
                                        const actualDate = v.genActualDate
                                            ? dayjs(v.genActualDate, "MM-DD-YYYY")
                                            : dayjs(); // use today's date if genActualDate is null
                                        const isLate = actualDate.isAfter(planDate); // check if genActualDate is later than plan_date

                                        return (
                                            <TableRow key={index}>
                                                <TableCell className="w-[50px] text-left p-2 ">{v.li_num}</TableCell>
                                                <TableCell className="w-[150px] text-left p-2 ">{v.genDisciplineText}</TableCell>
                                                <TableCell className=" text-left p-2 ">

                                                    <div
                                                        className="text-sm"
                                                        dangerouslySetInnerHTML={{ __html: v.genAoCMTL.replaceAll("\\n", "<br>") }}
                                                    />
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </div>

                    </div>
                ) : (
                    <div className="flex justify-center items-center h-full text-xs text-slate-700">
                        No data available.
                    </div>
                )}
            </CardContent>
        </Card>
    );
}