

import React, { useState, useEffect } from 'react';
import api from '@/lib/axiosInstance';
import { Loader2 } from 'lucide-react';
import { useParams } from "react-router-dom";
import ClientTable from '../tables/clientTable';


const ClientData = () => {
const { projectCode } = useParams();
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (projectCode) {
                const res = await api.get(`/getTR?path=${projectCode}`);
                const res2 = await api.get(`/getreturncode?path=${projectCode}`);
                if (res.data.success && res2.data.success) {
                    setTableData(
                        res.data.data.map((vf) => ({
                            ...vf,
                            title: vf.uuu_name !== undefined && vf.uuu_name && (vf.title === "" || vf.title === null || vf.title === undefined) ? (vf.uuu_name.lastIndexOf('.') !== -1 ? vf.uuu_name.substring(0, vf.uuu_name.lastIndexOf('.')) : vf.uuu_name) : vf.title,
                            DueDate: vf.DueDate ? new Date(vf.DueDate.slice(0, 10)) : null,
                            ReceivedDate: vf.ReceivedDate ? new Date(vf.ReceivedDate.slice(0, 10)) : null,
                            SubmittedDate: vf.SubmittedDate ? new Date(vf.SubmittedDate.slice(0, 10)) : null,

                        }))
                    )
                    setOptions(res2.data.data.data.map((vf) => vf.SKDMApprovalDecision));
                    setLoading(false);

                }
            }
        }
        fetchData()
    }, [projectCode])

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <Loader2 className="w-5 h-5 animate-spin" />
            </div>
        );
    }

    return (
        <>

            {tableData.length > 0 &&
                <ClientTable projectCode={projectCode} options={options} tableData={tableData} newkey={'record_no'} />
            }

        </>
    );
};
export default ClientData;