import { Toaster } from "@/components/ui/toaster";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import { Outlet } from 'react-router-dom';

export default function RootLayout() {
    useDocumentTitle("S.CON")
    return (
        <>

                <Outlet />
                <Toaster />

            </>
    );
}