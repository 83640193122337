

import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useParams } from "react-router-dom";
import api from '@/lib/axiosInstance';
import { useToast } from "@/hooks/use-toast";
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogFooter, AlertDialogAction, AlertDialogCancel } from '@/components/ui/alert-dialog';

const InfoBoxSquadLast = ({ documentList, SQDTitle, onSelectDocument, recordNo, pdftronInstance }) => {
  const [selectedDocument, setSelectedDocument] = useState('');
  const [selectedStatus, setSelectedStatus] = useState({});
  const [codeList, setCodeList] = useState([]);
  const [submissionStatus, setSubmissionStatus] = useState({});
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
const { projectCode } = useParams();;
  const { toast } = useToast();

  useEffect(() => {
    if (documentList && documentList.length > 0 && !selectedDocument) {
      const firstDocument = documentList[0];
      setSelectedDocument(firstDocument.id);
      onSelectDocument(firstDocument.id, firstDocument.title);
    }
  }, [documentList, selectedDocument, onSelectDocument]);

  useEffect(() => {
    const fetchCodeList = async () => {
      try {
        if (projectCode) {
          const res = await api.get(`/getsquadcode?path=${projectCode}`);
          if (res.data.success) {
            setCodeList(res.data.data.data.map(v => v.SKDMApprovalDecision));
          }
        }
      } catch (error) {
        console.error('Error fetching code list:', error);
      }
    };
    fetchCodeList();
  }, [projectCode]);

  const handleListItemClick = (id, title) => {
    setSelectedDocument(id);
    onSelectDocument(id, title);
  };

  const handleStatusChange = (event, id) => {
    setSelectedStatus(prev => ({ ...prev, [id]: event.target.value }));
  };

  const handleSubmitReview = (documentId) => {
    setIsAlertDialogOpen(true);
  };

  const handleConfirm = async () => {
    setIsAlertDialogOpen(false);

    const document = documentList.find(doc => doc.id === selectedDocument);
    if (!document) return;

    try {
      const { documentViewer, annotationManager } = pdftronInstance.Core;
      const doc = documentViewer.getDocument();
      const xfdfString = await annotationManager.exportAnnotations();
      const data = await doc.getFileData({ xfdfString });
      const arr = new Uint8Array(data);
      const blob = new Blob([arr], { type: 'application/pdf' });

      const formData = new FormData();
      formData.append("file", blob, 'annotated-document.pdf');
      formData.append("data", JSON.stringify({
        record_no: recordNo,
        SKDMApprovalDecision: selectedStatus[selectedDocument],
        document_titles: document.title,
      }));

      const response = await api.post(`/updatesqdlast?project=${projectCode}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.data.success) {

        toast({
          title: "Success",
          description: `${document.title} has been successfully submitted.`,
      })
        setSubmissionStatus(prev => ({ ...prev, [selectedDocument]: 'sent' }));
      } else {
        toast({
          variant: "destructive",
          title: "Error",
          description: `An error occurred while sending your data.`,
      })      }
    } catch (error) {
      console.error('Submission error:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: `An error occurred while sending your data. ${error}`,
    })    }
  };

  const handleCancel = () => {
    setIsAlertDialogOpen(false);
  };

  return (
    <>
      <Paper elevation={3} sx={{ padding: 2, width: 500, height: '100%', backgroundColor: '#f5f5f5', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box mb={2}>
          <Typography variant="h6" fontWeight="bold">{SQDTitle}</Typography>
        </Box>
        <List sx={{ height: '73vh', overflowY: 'auto' }}>
          {documentList.map(doc => (
            <ListItem
              key={doc.id}
              selected={selectedDocument === doc.id}
              onClick={() => handleListItemClick(doc.id, doc.title)}
              sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <ListItemText
                  primary={
                    <>
                      <Typography variant="body2" color="text.primary">No: {doc.docuNo}</Typography>
                      <Typography variant="body2" color="text.primary">Title: {doc.docuTitle}</Typography>
                      <Typography variant="body2" color="text.secondary">Rev: {doc.docuRev}</Typography>
                    </>
                  }
                  secondary={selectedStatus[doc.id] || 'No selection'}
                />
                {selectedDocument === doc.id && (
                  <FormControl sx={{ width: 150 }}>
                    <Select
                      value={selectedStatus[doc.id] || ''}
                      onChange={(e) => handleStatusChange(e, doc.id)}
                      size="small"
                      displayEmpty
                    >
                      {codeList.map((code, index) => (
                        <MenuItem key={index} value={code}>{code}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => handleSubmitReview(doc.id)}
                  disabled={submissionStatus[doc.id] === 'sent' || !selectedStatus[doc.id]}
                >
                  Submit and Save Document
                </Button>
              </Box>
            </ListItem>
          ))}
        </List>
      </Paper>
      <AlertDialog open={isAlertDialogOpen} onOpenChange={setIsAlertDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              Do you really want to submit the document?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleCancel}>No</AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirm}>Yes</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default InfoBoxSquadLast;