import React, { useState, useEffect } from 'react';
import {sortBy} from 'lodash';
import api from '@/lib/axiosInstance';
import { Loader2 } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { DocumentRegisterTable } from '../tables/registertable';

const DRscon = () => {
  const { project_code } = useParams();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [revData, setRevData] = useState([]);
  const [drawingData, setDrawingData] = useState([]);
  const [returnStatus, setReturnStatus] = useState([]);
  const [PMSdata, setPMSdata] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      if (project_code) {
        const res = await api.get(`/getDrawings?path=${project_code}`);
        const res2 = await api.get(`/getrev?path=${project_code}`);
        const res3 = await api.get(`/getalldrawings?path=${project_code}&type=E`);
        const res4 = await api.get(`/getreturncode?path=${project_code}`);
        const res5 = await api.get(`/getPMSData?path=${project_code}&code=E`);


        if (res.data.success&& res2.data.success && res3.data.success&& res4.data.success&& res5.data.success) {

          const drawinData = res.data.data;
          const revData = sortBy(res2.data.data, "RevSeqNo")
          const rowData = res3.data.data.filter(v=>v._bp_lineitems !== undefined);
          const PMSdata = res5.data.data.data.map((v)=>({key:v.PMSClassNM+v.PMSStageNM,FormatType:v.FormatType }))

 
          setRevData(revData);
          setTableData(drawinData);
          setDrawingData(rowData);
          setReturnStatus(res4.data.data.data.map(v=>v.SKDMApprovalDecision))
          setLoading(false);
          setPMSdata(PMSdata)

        }
      }
    }
    fetchData()
  }, [project_code])


  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="w-5 h-5 animate-spin" />
      </div>
    );
  }


  return (
    <>
      <DocumentRegisterTable PMSdata={PMSdata} projectCode={project_code} tabeldata={tableData} revData={revData} drawingData={drawingData} returnStatus={returnStatus}/>
    </>
  );
};
export default DRscon;
