import * as React from "react"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import dayjs from "dayjs";
import { ManPower } from "@/components/mp/manpower";
import { Equipment } from "@/components/mp/equipemnt";

export default function ManPowerEquip() {
  return (
    <div className="flex flex-1 flex-col w-full h-full gap-4 p-4">

          <ManPower/>

          <Equipment/>

    </div>
  )
}