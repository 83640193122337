import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, Outlet,useNavigate } from 'react-router-dom';
import api from '@/lib/axiosInstance';
import { Loader2 } from 'lucide-react';


const ProtectedRoute = () => {
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const validateSessionAndProject = async () => {
      const url = new URL(window.location.href);
      const pathSegments = url.pathname.split('/').filter((segment) => segment !== '');
      const ownerSegment = pathSegments[1];
      let projectCodeSegment;

      if (location.pathname === '/total') {
        projectCodeSegment = 'total';
      } else {
        projectCodeSegment = pathSegments[0];
      }


      try {
        // 서버에 세션 및 프로젝트 유효성 요청
        const response = await api.post('/checkproject', {
          projectCodeSegment,
          ownerSegment,
        });

        const { isValidSession, isValidProject } = response.data;

        if (!isValidSession || !isValidProject) {
          // 세션 또는 프로젝트가 유효하지 않으면 로그인 페이지로 리다이렉트
          navigate(`/login?redirect=${location.pathname}`, { replace: true });
        }
      } catch (error) {
        console.error('Error validating session and project:', error);
        navigate(`/login?redirect=${location.pathname}`, { replace: true });
      } finally {
        setLoading(false);
      }
    };

    validateSessionAndProject();
  }, [location.pathname, navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="w-5 h-5 animate-spin" />
      </div>
    );
  }
  

  // 유효하면 페이지 표시
  return <Outlet />;
};

export default ProtectedRoute;