import { useState } from "react";
import {
  add,
  eachMonthOfInterval,
  endOfYear,
  format,
  isEqual,
  isFuture,
  startOfYear,
  parse,
  startOfMonth,
  startOfToday,
} from "date-fns";
import { Button, buttonVariants } from "@/components/ui/button";
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/popover";
import { CalendarIcon, ChevronLeft, ChevronRight } from "lucide-react";
import { cn } from "@/lib/utils"; // Utility function for conditional class names

interface MonthPickerProps {
  placeholder?: string;
  selectedMonth?: Date;
  onMonthChange?: (month: Date) => void;
}

export default function MonthPicker({
  placeholder = "Pick a month",
  selectedMonth,
  onMonthChange,
}: MonthPickerProps) {
  const [open, setOpen] = useState(false);
  const [currentYear, setCurrentYear] = useState(
    selectedMonth ? selectedMonth.getFullYear() : new Date().getFullYear()
  );

  // Get the first day of the current year
  const firstDayCurrentYear = startOfYear(new Date(currentYear, 0, 1));

  // Generate all months for the current year
  const months = eachMonthOfInterval({
    start: firstDayCurrentYear,
    end: endOfYear(firstDayCurrentYear),
  });

  // Function to go to the previous year
  function previousYear() {
    setCurrentYear(currentYear - 1);
  }

  // Function to go to the next year
  function nextYear() {
    setCurrentYear(currentYear + 1);
  }

  // Handle month selection
  const handleMonthSelect = (month: Date) => {
    if (onMonthChange) onMonthChange(month);
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          onClick={() => setOpen(true)}
          className="p-0 h-6 text-left font-normal ml-auto text-xs font-medium flex items-center"
        >
          <span className="font-normal">
            {selectedMonth ? format(selectedMonth, "yyyy년 MM월") : placeholder}
          </span>
          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-3">
        <div className="flex flex-col space-y-2">
          {/* Year Navigation */}
          <div className="relative flex items-center justify-center">
            <div
              className="text-sm font-medium"
              aria-live="polite"
              role="presentation"
              id="month-picker"
            >
              {currentYear}
            </div>
            <div className="flex items-center space-x-1">
              <button
                name="previous-year"
                aria-label="Go to previous year"
                className={cn(
                  buttonVariants({ variant: "outline" }),
                  "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 absolute left-1"
                )}
                type="button"
                onClick={previousYear}
              >
                <ChevronLeft className="h-4 w-4" />
              </button>
              <button
                name="next-year"
                aria-label="Go to next year"
                className={cn(
                  buttonVariants({ variant: "outline" }),
                  "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 absolute right-1"
                )}
                type="button"
                onClick={nextYear}
              >
                <ChevronRight className="h-4 w-4" />
              </button>
            </div>
          </div>
          {/* Month Grid */}
          <div
            className="grid w-full grid-cols-3 gap-1"
            role="grid"
            aria-labelledby="month-picker"
          >
            {months.map((month) => (
              <div
                key={month.toString()}
                className="relative  p-0 text-center text-xs"
                role="presentation"
              >
                <button
                  name="month"
                  className={cn(
                    "inline-flex h-7 w-full items-center justify-center rounded-md p-0 text-xs font-normal ring-offset-white transition-colors hover:bg-slate-100 hover:text-slate-900 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
                    selectedMonth && isEqual(month, selectedMonth)
                      ? "bg-[#062653] text-white"
                      : "bg-white text-black"
                  )}
                  disabled={isFuture(month)}
                  role="gridcell"
                  tabIndex={-1}
                  type="button"
                  onClick={() => handleMonthSelect(month)}
                >
                  <time dateTime={format(month, "yyyy-MM-dd")}>
                    {format(month, "MMM")}
                  </time>
                </button>
              </div>
            ))}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}