import React, { useEffect, useMemo } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Micro from "@amcharts/amcharts5/themes/Micro";


interface BulletChartProps {
    maxValue: number;
    divId: string;
    height: string | number;
    targetValue: number;
    actualValue: number;
    unit: string;
    spark:boolean;
}

interface DataContext {
    type: string;
    // other properties if needed
}

const BulletChart: React.FC<BulletChartProps> = ({
    maxValue,
    divId,
    height,
    targetValue,
    actualValue,
    unit,
    spark = false
}) => {
    const difference = actualValue - targetValue;
    const formattedMaxValue = maxValue >= 1000 ? maxValue / 10000 : maxValue;
    const formattedActualValue = actualValue >= 1000 ? actualValue / 10000 : actualValue;
    const formattedTargetValue = targetValue >= 1000 ? targetValue / 10000 : targetValue;

    useEffect(() => {
        const root = am5.Root.new(divId);
        if(!spark){
            root.setThemes([am5themes_Animated.new(root)]);} 
        else{
            root.setThemes([am5themes_Micro.new(root)]);
        }

        const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "none",
                wheelY: "none",
                arrangeTooltips: false
            })
        );

        const tooltip = am5.Tooltip.new(root, {});
        chart.plotContainer.set("tooltipPosition", "pointer");
        chart.plotContainer.set("tooltipText", "a");
        chart.plotContainer.set("tooltip", tooltip);

        if(spark){

            tooltip.label.adapters.add("text", (text) => {
                text = "";
                let i = 0;
                chart.series.each((series) => {
                    const tooltipDataItem = series.get("tooltipDataItem");
                    const dataContext = tooltipDataItem?.dataContext as DataContext;
                    if (i === 0) text += `[bold fontSize: 9px]${divId} `;
                    if (tooltipDataItem && tooltipDataItem.get("valueX")) {
                        if (i !== 0) text += "";
                        text +=
                            `[fontSize: 10px]${dataContext.type}: ` +
                            `${Number((tooltipDataItem.get("valueX") ?? 0).toFixed(2)).toLocaleString()}` +
                            `${maxValue >= 1000 ? "만" : ""}${unit} `;
                    }
                    i++;
                });
                return text;
            });
    

        }else{

        tooltip.label.adapters.add("text", (text) => {
            text = "";
            let i = 0;
            chart.series.each((series) => {
                const tooltipDataItem = series.get("tooltipDataItem");
                const dataContext = tooltipDataItem?.dataContext as DataContext;
                if (i === 0) text += `[bold fontSize: 12px]${divId}\n`;
                if (tooltipDataItem && tooltipDataItem.get("valueX")) {
                    if (i !== 0) text += "\n";
                    text +=
                        `[${series.get("stroke")}]■[/] [width:8px][fontSize: 12px]${dataContext.type}: ` +
                        `${Number((tooltipDataItem.get("valueX") ?? 0).toFixed(2)).toLocaleString()}` +
                        `${maxValue >= 1000 ? "만" : ""} ${unit}`;
                }
                i++;
            });
            return text;
        });

    }


    if(!spark){
        tooltip.get("background")?.setAll({
            stroke: am5.color(0x000000),
            strokeOpacity: 0.8,
            fill: am5.color(0xffffff),
            fillOpacity: 0.8
        });

    } else{
        tooltip.get("background")?.setAll({
            // stroke: am5.color(0x000000),
            strokeOpacity: 0,
            // fill: am5.color(0xffffff),
            // fillOpacity: 0.8
        });
    }

        const yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: "category",
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );

        yAxis.data.setAll([{ category: "" }]);

        const xRenderer = am5xy.AxisRendererX.new(root, {
            // minGridDistance: width / 5
        });
        xRenderer.grid.template.set("forceHidden", true);

        const xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: xRenderer,
                min: 0,
                max: formattedMaxValue,
                strictMinMax: true,
                numberFormat: maxValue >= 1000 ? `#,###.0 '${unit}'` : `#,###.'${unit}'`,
            })
        );

   
            xAxis.get("renderer").labels.template.setAll({
                fontSize: '10px'
            });

        xAxis.get("renderer").grid.template.setAll({
            strokeOpacity: 1,
            strokeDasharray: [3, 3],
            stroke: am5.color(0x000000)
        });

        const rangeDataItem = xAxis.makeDataItem({ value: 0, endValue: formattedMaxValue });
        const range = xAxis.createAxisRange(rangeDataItem);

        range.get("axisFill")?.setAll({
            visible: true,
            fill:  am5.color(0xF5F5F5),
            stroke: am5.color(0xF5F5F5),
            fillOpacity: 1
        });

        const series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: "value",
                categoryYField: "category",
                stroke:  difference > 5 ?am5.color(0x0583F2) : difference < -5?am5.color(0xef4444): am5.color(0xeab308),
                fill: difference > 5 ?am5.color(0x0583F2) : difference < -5?am5.color(0xef4444): am5.color(0xeab308)
            })
        );

        series.columns.template.setAll({
            height: am5.p50
        });

        series.data.setAll([{ category: "", value: formattedActualValue, type: "실적" }]);

        const stepSeries = chart.series.push(
            am5xy.StepLineSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: "value",
                categoryYField: "category",
                stroke: am5.color(0xFEAE65),
                fill: am5.color(0xFEAE65),
                noRisers: true,
                stepWidth: am5.p100
            })
        );

        stepSeries.strokes.template.set("strokeWidth", 3);
        stepSeries.data.setAll([{ category: "", value: formattedTargetValue, type: "계획" }]);

        const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "none"
        }));
        cursor.lineY.set("visible", false);
        cursor.lineX.set("visible", false);

        chart.appear(1000, 100);

    //    if(!spark){
        series.events.once("datavalidated", () => {
            const remainingLabel = chart.plotContainer.children.push(
                am5.Label.new(root, {
                    text: `${(formattedMaxValue - formattedActualValue).toFixed(1)}${maxValue >= 1000 ? "만" : ""} ${unit}`,
                    fontSize: 11,
                    // fontWeight: "bold",
                    fill: am5.color(0x000000),
                    centerY: am5.p0,
                    centerX: am5.p100,
                    opacity: 0.7
                })
            );
            remainingLabel.set("x", am5.percent(100));
        });
    // }

        return () => {
            root && root.dispose();
        };
    }, [formattedMaxValue, divId, formattedTargetValue, formattedActualValue, spark]);

    return <div id={divId} style={{ width: "100%", height }}></div>;
};

export default BulletChart;