import React, { useRef, useEffect, useState, useCallback } from 'react';
import WebViewer from '@pdftron/webviewer';
import api from '@/lib/axiosInstance';

const PDFTronSquad = ({ initialDocBlob, documentTitle, docuID, username, onInstanceReady }) => {
  const viewer = useRef(null);
  const connection = useRef(null);
  const serializer = useRef(new XMLSerializer());

  const [instanceCreated, setInstanceCreated] = useState(false);
  const [currentDocId, setCurrentDocId] = useState(docuID);
  const [isInitialized, setIsInitialized] = useState(false);

  // WebSocket 메시지 처리 로직
  const processWebSocketMessage = async (annotationManager, data) => {
    try {
      // data가 문자열인지 확인하고, 문자열이면 JSON으로 파싱
      const annotation = typeof data === 'string' ? JSON.parse(data) : data;

      // 인자로 받은 annotationManager를 사용하여 어노테이션 커맨드를 임포트하고, 어노테이션을 그립니다.
      const annotations = await annotationManager.importAnnotationCommand(annotation.xfdfString);
      await annotationManager.drawAnnotationsFromList(annotations);
    } catch (error) {
      console.error('Error processing WebSocket message:', error);
    }
  };
  // XFDF 문자열을 변환하는 함수
  const convertToXfdf = (changedAnnotation, action) => {
    let xfdfString = `<?xml version="1.0" encoding="UTF-8" ?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><fields />`;
    xfdfString += `<${action}>${changedAnnotation}</${action}><add /><modify /><delete />`.replace(`<${action}></${action}>`, '');
    xfdfString += `</xfdf>`;
    return xfdfString;
  };
  const currentDocIdRef = useRef(currentDocId);
  useEffect(() => {
    currentDocIdRef.current = currentDocId;
  }, [currentDocId]);
  
  const sendAnnotationChange = useCallback((annotation, action, annotationId) => {
    const annotationString = serializer.current.serializeToString(annotation);
    const tableName = 'annotationsSquad'; 

    const message = JSON.stringify({
      documentId: currentDocIdRef.current,  // Ref를 통해 항상 최신 상태를 참조
      annotationId: annotationId,
      xfdfString: convertToXfdf(annotationString, action),
      tableName: tableName

    });
    console.log(message);
    connection.current.send(message);
  }, [convertToXfdf]);

  const fetchDocumentXFDFFromServer = async (documentId) => {
    try {
      const response = await api.get(`https://edashboard.skecoengineering.com/serverapi/annotationHandlerproxy.js?documentId=${documentId}&tableName=annotationsSquad`);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to fetch XFDF data');
      }
    } catch (error) {
      console.error('Error fetching XFDF data:', error);
      throw error;
    }
  };

  const handleAnnotationChange = (xfdfString) => {
    const parser = new DOMParser();
    const commandData = parser.parseFromString(xfdfString, 'text/xml');

    ['add', 'modify', 'delete'].forEach(action => {
      const annotsCollection = commandData.getElementsByTagName(action);
      if (annotsCollection.length > 0) {
        const annots = annotsCollection[0];
        if (annots) {
          annots.childNodes.forEach(child => {
            let annotationId;
            if (action === 'delete') {
              annotationId = child.textContent;
            } else {
              annotationId = child.getAttribute('name');
            }
            sendAnnotationChange(child, action, annotationId);
          });
        }
      }
    });
  };

  useEffect(() => {
    // docuID가 변경될 때마다 currentDocId 업데이트
    setCurrentDocId(docuID);

    if (!instanceCreated && initialDocBlob) {
      initializeWebViewer(currentDocId);
    } 

    // instanceCreated가 true일 때 실행
    if (instanceCreated && viewer.current && viewer.current.UI) {
      viewer.current.Core.documentViewer.setDocumentXFDFRetriever(async () => {
        const documentXFDFString = await fetchDocumentXFDFFromServer(currentDocId);
        return documentXFDFString.map(row => row.xfdfString);
      });

      const blobUrl = URL.createObjectURL(initialDocBlob);
      viewer.current.UI.loadDocument(blobUrl);

      const usernameR = username ? username : 'defaultUser';
      const annotationManager = viewer.current.Core.documentViewer.getAnnotationManager();
      annotationManager.setCurrentUser(usernameR);

      annotationManager.addEventListener('annotationChanged', async (e) => {
        if (e.imported || !connection.current || connection.current.readyState !== WebSocket.OPEN) {
          return;
        }
        const xfdfString = await annotationManager.exportAnnotationCommand();
        handleAnnotationChange(xfdfString, annotationManager);
      });

      connection.current.onmessage = async (message) => {
        processWebSocketMessage(annotationManager, message);
      };
    }
  }, [initialDocBlob, username, instanceCreated, currentDocId,sendAnnotationChange]); // 의존성 배열 갱신

  const initializeWebViewer = useCallback((docuID) => {
    if (isInitialized) return; // 이미 초기화가 완료된 경우 더 이상 실행하지 않음

    const url = `wss://dashboard.skecoengineering.com:8080`;
    connection.current = new WebSocket(url);
    connection.current.onerror = (event) => console.log(event);
    WebViewer({
      path: '/webviewer/lib',
      licenseKey: process.env.REACT_APP_PDF_KEY,
    }, viewer.current).then(instance => {
      viewer.current = instance;
      setInstanceCreated(true);
      if (onInstanceReady) {
        onInstanceReady(instance);
      }
      setIsInitialized(true); // 초기화 완료 플래그 설정

    }).catch(error => console.error('Failed to initialize WebViewer:', error));
  }, [docuID,sendAnnotationChange]);



  return (
    <div className="App">
      <div className="webviewer" style={{ height: "100vh" }} ref={viewer}></div>
    </div>
  );
};

export default PDFTronSquad;
