// src/atoms.ts
import { atom } from 'jotai';

export const languageState = atom('ko'); // 기본값 한국어

export const projectCodeState = atom<string | null>(null);

export const divisionState = atom<string | null>(null);

export const constructionEarnedValueAtom = atom(0);  // Initial value set to 0
export const constructionPlanValueAtom = atom(0);    // Initial value set to 0

export const sidebarCollapsedState = atom(false); // 기본값은 false

export const usernameState = atom<string | null>(null);
export const clientState = atom<string | null>(null);