'use client'

import React, { useEffect, useState } from 'react';
import { useGetProjectList } from '@/hooks/useQuery';
import { useAtom } from 'jotai';
import { divisionState } from '@/atoms';
import api from '@/lib/axiosInstance';
import dayjs from 'dayjs';
import { Loader2 } from 'lucide-react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { projectPQIData, projectPQIVendorData } from '@/types';
import { Button } from '@/components/ui/button';
import { CartesianGrid, Dot, Line, LineChart, XAxis } from "recharts"
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart"

interface PQIProps {
}
interface PQIData {
    PQIYearMonth: string;
    d_company_name: string;
    PQIPoint: number;
}

interface CompanyData {
    name: string;
    total: number;
    count: number;
    average: string;
    [key: string]: number | string; // 모든 월별 데이터를 숫자로 처리하고 없는 경우 0을 사용
}



const PQITotal: React.FC<PQIProps> = () => {
    const [loading, setLoading] = useState<boolean>(true); // Loading state
    const { data: projectsList } = useGetProjectList();
    const [division] = useAtom(divisionState);
    const [open, setOpen] = useState(false); // State to control the dialog
    const [projectPQIData, setProjectPQIData] = useState<projectPQIData[] | null>(null);
    const [divisionPQI, setDivisionPQI] = useState([]);
    const [allPQI, setAllPQI] = useState([]);
    const [skPQI, setSKPQI] = useState(0);
    const [projectPQIVendorData, setProjectPQIVendorData] = useState<projectPQIVendorData[] | null>(null);

    const currentYear = dayjs().format("YY년");
    const currentYear2 = dayjs().format("YYYY년")

    const months = Array.from({ length: 12 }, (_, i) => `${currentYear} ${String(i + 1).padStart(2, '0')}월`);

    const monthList = Array.from({ length: 12 }, (_, i) => ({ month: `${currentYear} ${String(i + 1).padStart(2, '0')}월` }));



    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {

                const pqi = await api.get(`/getemsJSON?path=/${process.env.REACT_APP_PATH2}/file/pqi/project_all_pqi.json`);
                const pqiDivision = await api.get(`/getemsJSON?path=/${process.env.REACT_APP_PATH2}/file/pqi/division_pqi.json`);
                const pqiAll = await api.get(`/getemsJSON?path=/${process.env.REACT_APP_PATH2}/file/pqi/all_pqi.json`);




                setDivisionPQI(pqiDivision.data)
                setSKPQI(pqiAll.data.find((v: any) => v.month === dayjs().format("YY년 MM월"))?.pqi)
                setAllPQI(pqiAll.data)
                const body = {
                    bpname: "BP PQI",
                    lineitem: "no",
                    "record_fields": "PQIYearMonth;d_company_name;PQIPoint;ugenProjectNumber",
                }

                const res = await api.post(`/getemsbprecord?path=SKEC0001`, body);


                setProjectPQIData(pqi.data || null);
                setProjectPQIVendorData(res.data.data.data || null);

            } catch (err) {
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    const filteredprojectPQIData = division ? projectPQIData?.filter(v => v.division === division) : projectPQIData;
    const filteredprojectPQIVendorData = Array.isArray(division)
        ? projectPQIVendorData?.map((v) => ({
            ...v,
            division: division.find((vf: any) => vf.projectcode === v.ugenProjectNumber),
        })).filter((v) => v.division === division)
        : projectPQIVendorData;

    const reduceDataForTable = (data: PQIData[]): CompanyData[] => {
        const companyData: { [key: string]: CompanyData } = {};

        data.forEach(({ PQIYearMonth, d_company_name, PQIPoint }) => {
            // 월 포맷을 '01', '02' 등으로 변경
            const formattedMonth = PQIYearMonth.replace(/(\d+)월/, (_, m) => `${m.padStart(2, '0')}월`);

            if (!companyData[d_company_name]) {
                companyData[d_company_name] = {
                    name: d_company_name,
                    total: 0,
                    count: 0,
                    average: '0',
                };
                months.forEach(month => companyData[d_company_name][month] = '-');
            }
            companyData[d_company_name][formattedMonth.replace(`${currentYear2} `, `${currentYear} `)] = PQIPoint.toFixed(2);
            companyData[d_company_name].total += PQIPoint;
            companyData[d_company_name].count += 1;
        });

        Object.values(companyData).forEach(company => {
            company.average = (company.total / company.count).toFixed(2);
        });

        return Object.values(companyData);
    };

    const tableProjectPQIVendorData = reduceDataForTable(filteredprojectPQIVendorData ? filteredprojectPQIVendorData : []);

    const getColorForValue = (value: number, max: number, min: number) => {
        if (value === max) return 'text-green-600'; // 최대값은 초록색
        if (value === min) return 'text-red-600'; // 최소값은 빨간색
        return ''; // 
    };


    const tablePQI = division === null ?
        divisionPQI.filter((v: any) => v.month === dayjs().format("YY년 MM월")).map((v: any) => ({ category: v.division, pqi: v.pqi })) :
        filteredprojectPQIData?.map((v: any) => ({ category: v.projectname, pqi: v.pqi.toFixed(1) }))

    const titlePQI = division === null
        ? skPQI
        : (divisionPQI.filter((v: any) => v.month === dayjs().format("YY년 MM월"))
            .find((v: any) => v.division === division) as any)?.pqi ?? 0;

    const chartData = division === null
        ? allPQI.filter((v: any) => v.month.includes(dayjs().format("YY년")))
        : divisionPQI.filter((v: any) => v.month.includes(dayjs().format("YY년")) && v.division === division);

    const chartDatawithMonths = Array.isArray(chartData)
        ? monthList.map((v) => ({
            ...v,
            ...(chartData.find((vf: any) => vf.month === v.month) ?? {}),
        }))
        : monthList;


    const chartConfig = {
        pqi: {
            label: "PQI",
            color: "hsl(var(--chart-1))",
        },

    } satisfies ChartConfig

    const getColor = (value: string) => {
        if (parseFloat(value) > 90) return 'text-emerald-500';
        if (parseFloat(value) < 70) return 'text-red-500';
        return 'text-yellow-500';
    };



    return (
        <>
            {loading ?
                (<div className="flex justify-center items-center h-full">
                    <Loader2 className="w-5 h-5 animate-spin" />
                </div>) :
                (<>
                    <CardHeader className="p-3">
                        <CardTitle className="flex justify-between">
                            <span className='text-xs'>PQI</span>
                            <Button className='p-0 h-7' size="sm" variant="ghost" onClick={() => setOpen(true)} >
                                <span className="text-xs">연간 PQI</span>
                            </Button>
                        </CardTitle>
                    </CardHeader>
                    <CardContent className={`flex-grow items-center justify-center space-y-2 p-4 pt-0 text-center`}>
                        <span className={`font-semibold ${getColor(titlePQI)}`}>{titlePQI}</span>

                        <div className="w-full">
                            <ChartContainer config={chartConfig} className='mx-auto max-h-[5vh] w-full'>
                                <LineChart
                                    accessibilityLayer
                                    data={chartDatawithMonths}
                                    margin={{
                                        top: 0,
                                        left: 12,
                                        right: 12,
                                    }}
                                >
                                    <CartesianGrid vertical={false} />
                                    <XAxis
                                        style={{
                                            fontSize: '10px',
                                        }}
                                        dataKey="month"
                                        tickLine={false}
                                        axisLine={false}
                                        //   tickMargin={8}
                                        tickFormatter={(value) => value.slice(4, 7)}
                                    />
                                    <ChartTooltip
                                        cursor={false}
                                        content={
                                            <ChartTooltipContent
                                                indicator="line"
                                                nameKey="month"
                                                hideLabel
                                            />
                                        }
                                    />
                                    <Line
                                        dataKey="pqi"
                                        type="natural"
                                        stroke="hsl(var(--chart-2))"
                                        strokeWidth={2}
                                        dot={({ payload, index, ...props }) => {

                                            const color = Number(payload.pqi) > 90 ? "#10b981" : Number(payload.pqi) < 70 ? "#ef4444" : "#facc15"

                                            return (
                                                <Dot
                                                    key={index}
                                                    r={3}
                                                    cx={props.cx}
                                                    cy={props.cy}
                                                    fill={color}
                                                    stroke={color}
                                                />
                                            )
                                        }}
                                    />
                                </LineChart>
                            </ChartContainer>

                        </div>

                        <div className="flex justify-center">
                            <div className="w-3/4 overflow-auto h-[8vh]">
                                {tablePQI && tablePQI.length > 0 ?
                                    <Table className="text-xs text-center">
                                        <TableBody>
                                            {tablePQI && tablePQI.map((v, index) => (
                                                <TableRow key={index}>
                                                    <TableCell className="p-1">{v.category}</TableCell>
                                                    <TableCell className={`p-1 ${getColor(v.pqi)}`}>{v.pqi}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    :
                                    <div className="flex justify-center items-center h-full text-center text-gray-500 text-xs"> No data available</div> 

                                        }


                            </div>

                        </div>

                    </CardContent>
                    <CardFooter className="flex-col gap-2 text-sm pb-3">
                        <div className="flex items-center text-xs space-x-4">
                            <div className="flex items-center space-x-1">
                                <span className="w-4 h-2 bg-red-500 inline-block"></span>
                                <span>Bad (PQI &lt; 70)</span>
                            </div>
                            <div className="flex items-center space-x-1">
                                <span className="w-4 h-2 bg-yellow-500 inline-block"></span>
                                <span>Avg. (70 ≤ PQI ≤ 80)</span>
                            </div>
                            <div className="flex items-center space-x-1">
                                <span className="w-4 h-2 bg-emerald-500 inline-block"></span>
                                <span>Good (PQI &gt; 90)</span>
                            </div>
                        </div>
                    </CardFooter>

                    <Dialog open={open} onOpenChange={setOpen} >
                        <DialogContent className="w-full max-w-7xl max-h-[65vh]">
                            <DialogHeader>
                                <DialogTitle>연간 PQI</DialogTitle>
                            </DialogHeader>

                            <Tabs defaultValue="project" className="w-full">
                                <TabsList className="grid w-full grid-cols-2">
                                    <TabsTrigger value="project">Project 연간 월 PQI</TabsTrigger>
                                    <TabsTrigger value="vendor">업체별 연간  월 PQI</TabsTrigger>
                                </TabsList>
                                <TabsContent value="project" className='relative'>
                                    <div className='overflow-hidden'>
                                        <Table>
                                            <TableHeader>
                                                <TableRow>
                                                    <TableHead className="w-[150px] text-xs p-2">프로젝트명</TableHead>
                                                    {months.map(month => <TableHead className="w-[100px] text-xs p-2" key={month}>{month}</TableHead>)}
                                                    <TableHead className="w-[80px] text-xs p-2">평균</TableHead>
                                                </TableRow>
                                            </TableHeader>
                                        </Table>
                                    </div>
                                    <div className='overflow-auto h-[45vh]'>
                                        <Table>
                                            <TableBody>
                                                {filteredprojectPQIData?.map((row) => {

                                                    const values = months.map(month => Number(row[month]) || 0).filter(value => value !== 0);
                                                    const max = Math.max(...values);
                                                    const min = Math.min(...values);
                                                    return (
                                                        <TableRow key={row.projectname}>
                                                            <TableCell className="w-[150px] font-medium text-xs p-2">{row.projectname}</TableCell>
                                                            {months.map(month => (
                                                                <TableCell className={`w-[100px] text-xs p-2 ${getColorForValue(Number(row[month]), max, min)}`} key={month}>
                                                                    {row[month] !== undefined ? (row[month] as number).toFixed(2) : '-'}
                                                                </TableCell>
                                                            ))}
                                                            <TableCell className='w-[0px] text-xs p-2'>{row.average}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </TabsContent>
                                <TabsContent value="vendor" className='relative'>
                                    <div className='overflow-hidden'>
                                        <Table>
                                            <TableHeader>
                                                <TableRow>
                                                    <TableHead className="w-[150px] text-xs p-2">업체명</TableHead>
                                                    {months.map(month => <TableHead className="w-[100px] text-xs p-2" key={month}>{month}</TableHead>)}
                                                    <TableHead className="w-[80px] text-xs p-2">평균</TableHead>
                                                </TableRow>
                                            </TableHeader>
                                        </Table>
                                    </div>
                                    <div className='overflow-auto h-[45vh]'>
                                        <Table>
                                            <TableBody>
                                                {tableProjectPQIVendorData.map((row) => {
                                                    const values = months.map(month => Number(row[month]) || 0).filter(value => value !== 0);
                                                    const max = Math.max(...values);
                                                    const min = Math.min(...values);
                                                    return (
                                                        <TableRow key={row.name}>
                                                            <TableCell className="w-[150px] font-medium text-xs p-2">{row.name}</TableCell>
                                                            {months.map(month => (
                                                                <TableCell className={`w-[100px] text-xs p-2 ${getColorForValue(Number(row[month]), max, min)}`} key={month}>
                                                                    {row[month] || '-'}
                                                                </TableCell>
                                                            ))}
                                                            <TableCell className='w-[0px] text-xs p-2'>{row.average}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </TabsContent>
                            </Tabs>

                        </DialogContent>
                    </Dialog>

                </>)




            }
        </>)

};
export default PQITotal;