

import * as React from "react"
import { Bar, BarChart, CartesianGrid, XAxis, LabelList } from "recharts"
import {
    ChartConfig,
    ChartContainer,
    ChartLegend,
    ChartLegendContent,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { useParams } from "react-router-dom";
import api from '@/lib/axiosInstance';
import { Loader2 } from "lucide-react";

interface ChartData {
    id: string;
    value: number;
}


export function SinmungoBarChart() {

    const [loading, setLoading] = React.useState(true);
const { projectCode } = useParams();



    const [rawData, setRawData] = React.useState<any>(null);


    function createChartData(projectPTWData: any, criteria: string): ChartData[] {
        if (!projectPTWData) {
            return [];
        }

        const groupedData = projectPTWData.reduce((acc: { [key: string]: number }, curr: any) => {
            const key = String(curr[criteria]);
            if (!acc[key]) {
                acc[key] = 0;
            }
            acc[key] += 1;
            return acc;
        }, {} as { [key: string]: number });

        return groupedData ? Object.entries(groupedData).map(([key, totalCount], index): ChartData => ({
            id: key as string,
            value: totalCount as number,
        })) : [];
    }

    const chartData = rawData && createChartData(rawData, "asBPName");

    console.log(chartData)
    const totals = chartData && chartData.reduce((sum: any, item: any) => sum + Number(item.value), 0)


    React.useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                let body =
                {
                    "bpname": "안전신문고",
                    "lineitem": "no",
                    "record_fields": "asBPName;",
                    "filter_criteria": {
                        "join": "AND",
                        "filter": [

                            {
                                "field": "status",
                                "value": "Active",
                                "condition_type": "eq"
                            },

                        ]

                    }
                }
                const res = await api.post(`/getemsbprecord?path=${projectCode}`, body);
                setRawData(res.data.data.data.filter((v:any)=>v.asBPName !== null));

            } catch (error) {
                console.error(`Failed to fetch data for:`, error);
            }

            setLoading(false);
        };

        if (projectCode) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [projectCode]); // viewMode 추가

    const chartConfig = {
        value: {
            label: "신문고",
            color: "hsl(var(--chart-1))",
        },
    } satisfies ChartConfig

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <Loader2 className="w-5 h-5 animate-spin" />
            </div>
        );
    }

    return (
        <>
            <CardContent className="flex-1 flex items-center justify-center">
                <ChartContainer config={chartConfig} className="mx-auto h-[160px] w-full">
                    <BarChart
                        accessibilityLayer
                        data={chartData}
                        margin={{
                            top: 20,
                        }}
                    >
                        <CartesianGrid vertical={false} />
                        <XAxis
                            dataKey="id"
                            tickLine={false}
                            tickMargin={8}
                            axisLine={false}
                            tickFormatter={(value) => value.slice(0, 6)}
                        />
                        <ChartTooltip
                            cursor={false}
                            content={<ChartTooltipContent  />}
                        />
                        <Bar dataKey="value" fill="var(--color-value)" radius={4}>
                            <LabelList
                                position="top"
                                offset={8}
                                className="fill-foreground"
                                fontSize={12}
                            />
                        </Bar>
                    </BarChart>
                </ChartContainer>
            </CardContent>
            <CardFooter className="text-sm">
                <div className="font-medium leading-none">
                    {totals}건
                </div>
            </CardFooter>
        </>
    )
}
