import { useEffect } from 'react';
import {
    Sidebar,
    SidebarContent,
    SidebarGroup,
    SidebarGroupContent,
    SidebarGroupLabel,
    SidebarMenu, SidebarMenuSub,
    SidebarMenuButton, SidebarMenuSubItem,
    SidebarMenuItem, SidebarHeader, SidebarFooter
} from "@/components/ui/sidebar";
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Home, ChevronDown, Building, Layout, Activity, LogOut } from "lucide-react";
import { useTranslation } from 'react-i18next';
import { Button } from "@/components/ui/button";
import { useGetProjectData } from '@/lib/getData'; 
import { Skeleton } from "@/components/ui/skeleton";
import { useLocation, useNavigate, useParams } from 'react-router-dom';  // react-router-dom에서 필요한 훅 임포트
import { logout } from '@/components/common/logout';
import api from '@/lib/axiosInstance';
import useDocumentTitle from '@/hooks/useDocumentTitle';

interface SubItem {
    title: string;
    url: string;
}

interface Item {
    title: string;
    icon: React.ComponentType;
    subItems: SubItem[];
}

export function ProjectSidebar() {
    const { t, i18n } = useTranslation();  
    
    const navigate = useNavigate();
    const { projectCode } = useParams();
    const { projectData, progressData, loading, error } = useGetProjectData();
    const location = useLocation();  // 현재 경로 가져오기

  
    useDocumentTitle("S.CON - " + projectData?.ugenProjectName + " Dashboard")

    useEffect(() => {
        if (projectData && !loading) {
            i18n.changeLanguage(projectData.DashboardLanguagePD === "Korean" ? "ko" : "en");
        }  
    }, [projectData, loading,]);

    const handleLanguageChange = (lang: string) => {
        i18n.changeLanguage(lang);
    };

    const handleNavigation = (path: string) => {
        navigate(`/${projectCode}${path}`);
    };

    const handleLogout =async () => {
        if(projectCode){

        const res = await api.get(`/getcheckowner?project=${projectCode}`);

        if (res.data.success) {
            const client = res.data.client; // 예를 들어, API 응답에서 'client' 데이터를 기대한다고 가정
            logout({
              project: projectCode,
              client: client
            });
          } else {
            logout({
              project: projectCode,
              client: false
            });
          }
        }
    };

    const items = (t: (key: string) => string) => [
        {
            title: t("overview"),
            icon: Home,
            subItems: [
                { title: t("project_overview"), url: "/overview" },
                { title: t("organization"), url: "/emsorg" },
            ],
        },
        {
            title: t("progress"),
            icon: Activity,
            subItems: [
                progressData?.EngineeringProgressRB === "On" && {
                    title: t("design_progress"),
                    url: "/eprogress"
                },
                progressData?.ProcurementProgressRB === "On" && {
                    title: t("procurement_progress"),
                    url: "/pprogress"
                },
                progressData?.ConstructionProgressRB === "On" && {
                    title: t("construction_progress"),
                    url: "/cprogress"
                },
                progressData?.OverallProgressRB === "On" && {
                    title: t("epc_progress"),
                    url: "/progress"
                },
            ].filter(Boolean) as SubItem[], // 강제 타입 단언
        },
        {
            title: t("design"),
            icon: Layout,
            subItems: [
                { title: t("mh_status"), url: "/emsmanhour" },
                { title: t("quality"), url: "/qualityp" },
            ],
        },
        {
            title: t("construction"),
            icon: Building,
            subItems: [
                { title: t("cwp_status"), url: "/cwp" },
                { title: t("personnel_equipment"), url: "/mp" },
                { title: t("safety"), url: "/safety" },
                { title: t("quality"), url: "/quality" },
                { title: t("environment"), url: "/environment" },
                { title: t("site_photos"), url: "/photo" },
                { title: t("area_of_concern"), url: "/concern" },
            ],
        },
    ];

    return (
        <Sidebar>
            <SidebarHeader className="flex items-center justify-center p-8">
                <img src="/images/S.CON black.svg" alt="Logo" width={120} height={50} />
                <span className="font-bold mt-4">
                    {loading ? (
                        <Skeleton className="h-6 w-32" /> // 로딩 중일 때 Skeleton 표시
                    ) : (
                        projectData?.ugenProjectName // 로딩이 끝나면 프로젝트 이름 표시
                    )}
                </span>
            </SidebarHeader>
            <SidebarContent>
                <SidebarGroup>
                    <SidebarGroupLabel>{t('project_dashboard')}</SidebarGroupLabel>
                    <SidebarGroupContent>
                        <SidebarMenu>
                            {items(t).map((item, index) => (
                                <Collapsible key={index} defaultOpen className="group/collapsible">
                                    <SidebarMenuItem>
                                        <CollapsibleTrigger asChild>
                                            <SidebarMenuButton>
                                                <item.icon className="mr-2" />
                                                {item.title}
                                                <ChevronDown className="ml-auto transition-transform group-data-[state=open]/collapsible:rotate-180" />
                                            </SidebarMenuButton>
                                        </CollapsibleTrigger>
                                        <CollapsibleContent>
                                        <SidebarMenuSub>
                                                {item.subItems.map((subItem, subIndex) => {
                                                    const isActive = location.pathname === `/${projectCode}${subItem.url}`;
                                                    return (
                                                        <SidebarMenuSubItem
                                                            key={subIndex}
                                                            onClick={() => handleNavigation(subItem.url)}
                                                            className={`cursor-pointer hover:bg-gray-100 hover:text-blue-600 transition-colors duration-150 px-2 py-1 rounded-md ${
                                                                isActive ? 'text-blue-600' : ''
                                                            }`}
                                                        >
                                                            <span>{subItem.title}</span>
                                                        </SidebarMenuSubItem>
                                                    );
                                                })}
                                            </SidebarMenuSub>
                                        </CollapsibleContent>
                                    </SidebarMenuItem>
                                </Collapsible>
                            ))}
                        </SidebarMenu>
                    </SidebarGroupContent>
                </SidebarGroup>
            </SidebarContent>
            <SidebarFooter>
                <div className="flex justify-center items-center text-sm mb-0">
                    <div>
                        <button
                            className={`px-2 ${i18n.language === 'ko' ? 'font-bold' : ''}`}
                            onClick={() => handleLanguageChange('ko')}
                        >
                            한국어
                        </button>
                        <span className="text-gray-400">|</span>
                        <button
                            className={`px-2 ${i18n.language === 'en' ? 'font-bold' : ''}`}
                            onClick={() => handleLanguageChange('en')}
                        >
                            English
                        </button>
                    </div>
                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={handleLogout}
                        className="text-red-500 ml-auto"
                        aria-label="로그아웃"
                    >
                        <LogOut className="w-5 h-5" />
                    </Button>
                </div>
            </SidebarFooter>
        </Sidebar>
    );
}