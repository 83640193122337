
import * as React from "react"
import { useParams } from "react-router-dom";
import api from "@/lib/axiosInstance";
import { BarChartH } from "../common/barChartHorizontal";
import { BarChartHPercent } from "../common/barChartHorizontalP";

// ChartConfig 타입 정의 (필요한 경우)
interface ChartConfigItem {
    label: string;
    color: string;
}

interface LineItem {
    EQCDisc_spd: string;
    EBPQAGrade: string;
    QCConfirm_srb: string;
    uuu_tab_id?: string;
    // 필요한 다른 속성들 추가
}


type ChartConfig = Record<string, ChartConfigItem>;

export function EQC() {
     const { projectCode } = useParams()

    const [barChartData, setBarChartData] = React.useState<any[] | null>(null);
    const [findingsCount, setFindingsCount] = React.useState<number>(0); // Findings 수를 저장할 상태

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const body = {
                    bpname: "EQC",
                    lineitem: "yes",
                    filter_criteria: {
                      join: "AND",
            
                      filter: [
                        {
                          field: "status",
                          value: "Terminated",
                          condition_type: "neq",
                        },
                      ],
                    },
                  };
            

                const response = await api.post(`/getemsbprecord?path=${projectCode}`, body);
                const unifierData = response.data.data.data;

                // lineItemPlat을 LineItem 배열로 타입 지정
                const lineItemPlat: LineItem[] = unifierData.reduce((acc: LineItem[], cur: any) => {
                    const { EQCDisc_spd } = cur;
                    const _bp_lineitems = cur?._bp_lineitems ?? [];

                    const lineItemDataAdd: LineItem[] = _bp_lineitems
                        .filter((c: any) => c.uuu_tab_id === "Findings")
                        .map((c: any) => {
                            return { ...c, EQCDisc_spd };
                        });

                    return acc.concat(lineItemDataAdd);
                }, []);

                // Findings 수를 상태에 저장
                setFindingsCount(lineItemPlat.length);

                // groupedData의 타입을 명시적으로 지정
                const groupedData: Record<string, LineItem[]> = lineItemPlat.reduce((acc: Record<string, LineItem[]>, item: LineItem) => {
                    const key = item.EQCDisc_spd;
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(item);
                    return acc;
                }, {});
                // group_disc 생성
                const group_disc = Object.entries(groupedData).map(([key, objs]: [string, LineItem[]]) => {
                    const result = {
                        disc: key,
                        "보류": objs.filter((c) => c.QCConfirm_srb !== "Confirmed").length,
                        "승인": objs.filter((c) => c.QCConfirm_srb === "Confirmed").length,
                    };

                    return result;
                });

                setBarChartData(group_disc);
            } catch (error) {
                console.error("데이터를 가져오는 중 오류 발생:", error);
                setBarChartData(null);
                setFindingsCount(0);
            }
        };
        if (projectCode) {
            fetchData();
        }
    }, [projectCode]);

    // config 객체 생성
    const chartConfig: ChartConfig = {
        "보류": {
            label: "보류",
            color: "hsl(var(--chart-1))",
        },
        "승인": {
            label: "승인",
            color: "hsl(var(--chart-2))",
        },

    };

    return (
        barChartData && barChartData.length > 0 ? (

                <BarChartHPercent
                    data={barChartData}
                    config={chartConfig}
                    dataKeys={["보류", "승인"]}
                />
        ) : (
            <div className="flex justify-center items-center h-full text-xs text-slate-700">
                No data available.
            </div>
        )
    );
}