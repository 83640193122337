
import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import PQINumber from './pqiNumber';
import { useParams } from 'react-router-dom';
import api from '@/lib/axiosInstance';
import { useTranslation } from 'react-i18next';

interface PQIData {
    PQIYearMonth: string;
    d_company_name: string;
    PQIPoint: number;
}

interface CompanyData {
    name: string;
    total: number;
    count: number;
    average: string;
    [key: string]: number | string; // 모든 월별 데이터를 숫자로 처리하고 없는 경우 0을 사용
}

interface QualityDataType {
    c1: string;
    c2: string;
    c3: string;
    c4: string;
    c5: string;
    c6: string;
    c7: string;
    c8: string;
    c9: string;
    c10: string;

}

const QualityActivitiesCard = () => {

    const [loading, setLoading] = useState(true);
    const { projectCode } = useParams();
    const [punchData, setPunchData] = useState<any>(null);
    const { t } = useTranslation();


    useEffect(() => {
        const fetchData = async () => {

            try {
                const res = await api.get(`/getPunchSummary?project=${projectCode}`);
                setPunchData(res.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setLoading(false);
            }
        };

        if (projectCode) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [projectCode]);

    return (
        <>
          <Card className="row-span-1 md:col-span-3 col-span-8 rounded-xl border bg-card text-card-foreground shadow">
            <CardHeader className="text-left">
              <CardTitle className='text-base leading-none tracking-tight'>{t('qualityActivities')}</CardTitle>
            </CardHeader>
            <CardContent className="grid grid-cols-2 gap-4 h-[calc(100%-theme(spacing.14))]">
              <PQINumber />
              <div className="col-span-1 grid grid-cols-2 gap-3 h-full">
                <Card className="h-full p-2">
                  <div className="flex flex-col items-center justify-center h-full">
                    <div className="text-xs font-medium mb-1">{t('ncrActionTotal')}</div>
                    <div className="text-sm font-bold">{punchData?.find((vf: any) => vf.type === "ncr").count} / {punchData?.find((vf: any) => vf.type === "ncr").total}</div>
                  </div>
                </Card>
                <Card className="h-full p-2">
                  <div className="flex flex-col items-center justify-center h-full">
                    <div className="text-xs font-medium mb-1">{t('failedTotalInspection')}</div>
                    <div className="text-sm font-bold">{punchData?.find((vf: any) => vf.type === "check").count} / {punchData?.find((vf: any) => vf.type === "check").total}</div>
                  </div>
                </Card>
                <Card className="h-full p-2">
                  <div className="flex flex-col items-center justify-center h-full">
                    <div className="text-xs font-medium mb-1">{t('weldingDefectRate')}</div>
                    <div className="text-xs font-normal">-</div>
                  </div>
                </Card>
                <Card className="h-full p-2">
                  <div className="flex flex-col items-center justify-center h-full">
                    <div className="text-xs font-medium mb-1">{t('punchActionTotal')}</div>
                    <div className="text-sm font-bold">{punchData?.find((vf: any) => vf.type === "Punch").count} / {punchData?.find((vf: any) => vf.type === "Punch").total}</div>
                  </div>
                </Card>
              </div>
            </CardContent>
          </Card>
        </>
      );
    };

export default QualityActivitiesCard;