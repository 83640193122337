"use client"

import React from "react"
import { Bar, BarChart, XAxis, YAxis, Tooltip, LabelList } from "recharts"
import {
  ChartConfig as BaseChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart"
import { sidebarCollapsedState } from '@/atoms';
import { useAtomValue } from 'jotai'

// 데이터 타입 정의
interface ChartDataItem {
  disc: string
  [key: string]: number | string
}

interface ChartConfigItem {
  label: string
  color: string
}

type ChartConfig = Record<string, ChartConfigItem>

// props 타입 정의
interface BarChartHProps {
  data: ChartDataItem[]
  config: ChartConfig
  dataKeys: string[]
}

function processChartData(data: ChartDataItem[], dataKeys: string[]): ChartDataItem[] {
  return data.map((item) => {
    // 각 항목의 합계 계산
    const total = dataKeys.reduce((sum, key) => sum + (item[key] as number), 0)

    // 합계가 0인 경우 처리
    if (total === 0) {
      return {
        ...item,
        ...dataKeys.reduce((acc, key) => ({ ...acc, [key]: 0, [`original_${key}`]: 0 }), {}),
      }
    }

    // 백분율 데이터와 실제 데이터 모두 포함
    const newItem: ChartDataItem = { disc: item.disc }
    dataKeys.forEach((key) => {
      newItem[key] = ((item[key] as number) / total) * 100 // 백분율
      newItem[`original_${key}`] = item[key] // 실제 숫자
    })
    return newItem
  })
}

export function BarChartHPercent({ data, config, dataKeys }: BarChartHProps) {


  const processedData = processChartData(data, dataKeys)

  const sidebarCollapsed = useAtomValue(sidebarCollapsedState) ?? undefined;



  return (
    <ChartContainer config={config as BaseChartConfig}
      className={`mx-auto aspect-square ${sidebarCollapsed ? "max-h-[185px]" : "max-h-[155px]"} w-full p-2`}>

      <BarChart
        data={processedData}
        layout="vertical"
        margin={{ left: 20 }}
      >
        <XAxis type="number" domain={[0, 100]} tickFormatter={(value) => `${value}%`}   tick={{ fontSize: 10 }} 
        />
        <YAxis
          dataKey="disc"
          type="category"
          tickLine={false}
          tickMargin={4}
          axisLine={false}
          tickFormatter={(value: string) => value}
          interval={0}
        />
            <ChartTooltip
              content={
                <ChartTooltipContent
                  
                  className="w-[80px]"
                  formatter={(value, name, item, index) => (
                    <>
                      <div
                        className="h-2.5 w-2.5 shrink-0 rounded-[2px] bg-[--color-bg]"
                        style={
                          {
                            "--color-bg": `var(--color-${name})`,
                          } as React.CSSProperties
                        }
                      />
                      {name }
                        
                      <div className="ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground">
                        {item.payload[`original_${name}`]}
                          </div>

                    </>
                  )}
                />
              }
              cursor={false}
              // defaultIndex={1}
            />
        {dataKeys.map((key, index) => (
          <Bar
            key={key}
            dataKey={key}
            stackId="a"
            fill={config[key]?.color || "var(--default-color)"}
          >
            <LabelList
              dataKey={`original_${key}`} // 원래 값을 표시
              position="inside"
              className="fill-white"
              fontSize={12}
            />
                
          </Bar>
        ))}
      </BarChart>
    </ChartContainer>
  )
}