import {ManhourChartWithBullet} from '@/components/ems/manhourChart'


export default function EMSManhour() {

  return (
    <div className="flex flex-1 flex-col gap-4 p-4 w-full">
      <ManhourChartWithBullet/>

    </div>
  );
}