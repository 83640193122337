

import * as React from "react";
import { projectCodeState } from "@/atoms";
import { useAtomValue } from "jotai";
import api from "@/lib/axiosInstance";
import {  Navigation, Thumbs } from "swiper/modules";
import { Loader2 } from "lucide-react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import './photo.css'
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'
import { sidebarCollapsedState } from "@/atoms";
import { useParams } from "react-router-dom";




export function WeeklyPhoto() {
    const [loading, setLoading] = React.useState(true);
     const { projectCode } = useParams()
    const [imageMetaDataList, setImageMetaDataList] = React.useState<any>([]);
    const [thumBlobList, setThumBlobList] = React.useState<(string | null)[]>([]);
    const [imageBlobList, setImageBlobList] = React.useState<(string | null)[]>([]);
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [thumbsSwiper, setThumbsSwiper] = React.useState<null>(null);
    const [initialLoad, setInitialLoad] = React.useState(true);
    const [currentPage, setCurrentPage] = React.useState(0);
    const imagesPerPage = 6;
    const isSidebarCollapsed = useAtomValue(sidebarCollapsedState) ?? false;



    React.useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                // Fetch image metadata
                const res = await api.get(`/getWeeklyPhotos?project=${projectCode}`);
                const imageDataList = res.data.Rows;
                setImageMetaDataList(imageDataList)
                // 초기 로드 시 6장의 사진만 가져오기
                const initialImageDataList = imageDataList.slice(0, imagesPerPage);
                await fetchThumbnailsAndImages(initialImageDataList);
                setInitialLoad(false);
            } catch (error) {
                console.error("Failed to fetch data:", error);
            } finally {
                setLoading(false);
            }
        };

        if (projectCode) fetchData();
    }, [projectCode]);


    const fetchThumbnailsAndImages = async (imageDataList: any[]) => {
        const thumbUrls: (string | null)[] = [];
        const imageUrls: (string | null)[] = [];


        for (const image of imageDataList) {
            // Fetch thumbnail
            try {
                const thumbResponse = await api.get(
                    `/gethistorythumnail?fileid=${image.fileid}`,
                    { responseType: "arraybuffer" }
                );
                const thumbBlob = window.URL.createObjectURL(new Blob([thumbResponse.data]));
                thumbUrls.push(thumbBlob);
            } catch (thumbError) {
                console.error(`Failed to fetch thumbnail for fileid: ${image.fileid}`, thumbError);
                thumbUrls.push(null);
            }

            // Fetch full image
            try {
                const imageResponse = await api.get(
                    `/getPhotohistroyFile?fileid=${image.fileid}`,
                    { responseType: "arraybuffer" }
                );
                const imageBlob = window.URL.createObjectURL(new Blob([imageResponse.data]));
                imageUrls.push(imageBlob);
            } catch (imageError) {
                console.error(`Failed to fetch image for fileid: ${image.fileid}`, imageError);
                imageUrls.push(null);
            }
        }


        // Filter out entries where thumbnail or image is null
        const validEntries = imageDataList
            .map((meta: any, index: number) => ({
                ...meta,
                thumbnail: thumbUrls[index],
                image: imageUrls[index],
            }))
            .filter((entry: any) => entry.thumbnail !== null && entry.image !== null);

        // Update state
        // setImageMetaDataList((prevList: any[]) => [...prevList, ...validEntries]);
        setThumBlobList((prevList: (string | null)[]) => [...prevList, ...validEntries.map((entry: any) => entry.thumbnail)]);
        setImageBlobList((prevList: (string | null)[]) => [...prevList, ...validEntries.map((entry: any) => entry.image)]);
    };

    const handleSlideChange = async (swiper: any) => {

        setCurrentIndex(swiper.activeIndex);
        if (swiper.activeIndex >= currentPage * imagesPerPage + imagesPerPage - 1 && !initialLoad) {
            console.log("슬라이드 작동")
            setCurrentPage((prevPage) => prevPage + 1);
            const nextImageDataList = imageMetaDataList.slice(
                (currentPage + 1) * imagesPerPage,
                (currentPage + 1) * imagesPerPage + imagesPerPage
            );

            await fetchThumbnailsAndImages(nextImageDataList);
        }
    };



    const [swiperWidth, setSwiperWidth] = React.useState<number>(0);
    const [slidesPerView, setSlidesPerView] = React.useState<number>(6);

    // Calculate Swiper width and slidesPerView
    React.useEffect(() => {
        const calculateSwiperWidth = () => {
            const sidebarWidth = isSidebarCollapsed ? 0: 16 * 16; // 접힌 경우 4rem, 기본 16rem
            const screenWidth = window.innerWidth;
            const availableWidth = (screenWidth - sidebarWidth - 90) * 2 / 3;
            setSwiperWidth(availableWidth);

            // Calculate slidesPerView (min 1 slide, max as fits)
            const slideWidth = 200; // Each slide is 200px wide
            setSlidesPerView(Math.max(1, Math.floor(availableWidth / slideWidth)));
        };

        calculateSwiperWidth(); // Initial calculation
        window.addEventListener("resize", calculateSwiperWidth); // Update on resize
        return () => window.removeEventListener("resize", calculateSwiperWidth);
    }, [isSidebarCollapsed]);


    return (
        <>
            {loading ? (
                <div className="flex justify-center items-center h-full">
                    <Loader2 className="w-5 h-5 animate-spin" />
                </div>

            ) :


                thumBlobList.length > 0 ?
                    <div style={{ width: swiperWidth, height: "60vh" }}>

                        <Swiper
                            spaceBetween={10}
                            navigation={true}
                            mousewheel={true}
                            direction="horizontal"
                            thumbs={{ swiper: thumbsSwiper }}
                            modules={[Navigation, Thumbs]}
                            onSlideChange={handleSlideChange} // Add slide change handler
                        >
                            {imageBlobList.map((v, index) => (
                                v &&
                                <SwiperSlide key={index}>
                                    <div className="flex flex-col md:flex-row gap-4 items-start md:items-center">
                                        {/* Image Section */}
                                        <Zoom>
                                            <img
                                                src={v}
                                                className="max-h-[360px] object-contain rounded-md shadow-md"
                                                alt={String(index)}
                                            />
                                        </Zoom>

                                        {/* Metadata Section */}
                                        <div className="flex flex-col w-full md:w-1/2 lg:w-1/3 text-left p-8">
                                            <h2 className="text-lg font-semibold text-gray-800 mb-2">
                                                {imageMetaDataList[index]?.phototitle || "Untitled"}
                                            </h2>
                                            <p className="text-sm text-gray-500 mb-4">
                                                {imageMetaDataList[index]?.photodate?.slice(0, 10) || "No Date"}
                                            </p>
                                            <div
                                                className="text-sm text-gray-700 whitespace-pre-line leading-relaxed"
                                                dangerouslySetInnerHTML={{
                                                    __html: imageMetaDataList[index].photodesc
                                                        ? imageMetaDataList[index].photodesc.replaceAll("\\n", "<br/>")
                                                        : "",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                        <div className="slider__thumbs">

                            <Swiper
                                onSwiper={(swiper: any) => setThumbsSwiper(swiper)}
                                spaceBetween={10}
                                slidesPerView={6}
                                watchSlidesProgress={true}
                                modules={[Navigation, Thumbs]}
                                mousewheel={true}

                            // navigation={true}
                            >
                                {thumBlobList && thumBlobList.map((v, index) => (
                                    v &&
                                    <SwiperSlide key={index}>
                                        <img src={v}
                                            className="cursor-pointer rounded-md object-cover"
                                            style={{ height: 150, objectFit: 'contain' }} alt={String(index)} onClick={() => setCurrentIndex(index)} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>

                    </div>

                    : (
                        <div className="flex justify-center items-center h-full text-gray-500">
                            No data available
                        </div>
                    )}


        </>
    );
}