

import * as React from "react"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import dayjs from "dayjs";
import { SaeftyIssueHtml } from "@/components/safety/issue";
import { SafetyGaugeChart } from "@/components/safety/ltir";
import { PTW } from "@/components/safety/ptw";
import { SinmungoBarChart } from "@/components/safety/sinmungo";
import { ManagerCard } from "@/components/safety/safetyManger";
import { EmergencyTable } from "@/components/safety/emergency";
import { RemainManPieChart } from "@/components/safety/remainManpower";

export default function SafeyPage() {
  return (
    <div className="flex flex-1 flex-col w-full p-4 ">
      <div className="grid grid-cols-12 grid-rows-3  md:grid-rows-3 md:grid-cols-12 gap-4 flex-1">
        <Card className="h-[31vh] row-span-1 md:col-span-5 col-span-5 rounded-xl border bg-card text-card-foreground shadow">

          <CardHeader className="text-left">
            <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
              <span>작업허가현황</span>
              <span className="ml-auto text-xs">{dayjs().add(-1, "days").format("YYYY년 MM월 DD일")}</span>
            </CardTitle>
          </CardHeader>
          <CardContent className="flex items-start justify-between px-6 py-2">
            <PTW />
          </CardContent>
        </Card>
        <Card className="h-[31vh] row-span-1 md:col-span-3 col-span-3 rounded-xl border bg-card text-card-foreground shadow flex flex-col">
          <CardHeader className="text-left">
            <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
              <span>LTIR</span>
              {/* <span className="ml-auto text-xs">{dayjs().add(-1,"days").format("YYYY년 MM월 DD일")}</span> */}
            </CardTitle>

          </CardHeader>
          <CardContent className="h-full w-full p-4">
              <SafetyGaugeChart/>
            </CardContent>

        </Card>
        <Card className="h-[31vh] row-span-1 md:col-span-4 col-span-4 rounded-xl border bg-card text-card-foreground shadow flex flex-col">
          <CardHeader className="text-left">
            <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
              <span>안전신문고</span>
              <span className="ml-auto text-xs">{dayjs().add(-1, "days").format("YYYY년 MM월 DD일")}</span>
            </CardTitle>
          </CardHeader>
            <SinmungoBarChart/>
        </Card>

          <ManagerCard/>

        <Card className="h-[31vh] row-span-1 md:col-span-4 col-span-4 rounded-xl border bg-card text-card-foreground shadow flex flex-col">
          <CardHeader className="text-left">
            <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
              <span>분기별 비상대응 실시 현황</span>
              {/* <span className="ml-auto text-xs">{dayjs().add(-1, "days").format("YYYY년 MM월 DD일")}</span> */}
            </CardTitle>
          </CardHeader>
          <EmergencyTable/>
        </Card>
        <Card className="h-[31vh] row-span-1 md:col-span-4 col-span-4 rounded-xl border bg-card text-card-foreground shadow flex flex-col">
          <CardHeader className="text-left">
            <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
              <span>인원잔류현황</span>
              <span className="ml-auto text-xs">{dayjs().add(-1, "days").format("YYYY년 MM월 DD일")}</span>
            </CardTitle>
          </CardHeader>
          <RemainManPieChart/>
        </Card>

        <SaeftyIssueHtml/>
      </div>
    </div>
  )
}