

import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/popover";
import { CalendarIcon } from "lucide-react";
import { Calendar } from "@/components/ui/calendar";
import dayjs from "dayjs";

interface DatePickerProps {
  placeholder?: string;
  dateFormat?: string;
  initialDate?: Date | undefined;
  onDateChange?: (date: Date | undefined) => void;
}

export default function DatePicker({
  placeholder = "Pick a date",
  initialDate = undefined,
  onDateChange,
}: DatePickerProps) {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(initialDate);
  const [open, setOpen] = useState(false);

  const handleDateChange = (date: Date | undefined) => {
    setSelectedDate(date);
    if (onDateChange) onDateChange(date);
    setOpen(false); // Close the calendar popover upon selecting a date
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          onClick={() => setOpen(true)}
          className="p-0 h-6 text-left font-normal ml-auto text-xs font-medium flex items-center"
        >
          <span className="font-normal">{selectedDate ? dayjs(selectedDate).format("YYYY년 MM월 DD일"): placeholder}</span>
          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-2">
        <Calendar
          mode="single"
          selected={selectedDate}
          onSelect={handleDateChange} // Compatible with Date | undefined
          className="rounded-md border"
        />
      </PopoverContent>
    </Popover>
  );
}