
import React, { useRef, useEffect, useState } from 'react';
import WebViewer from '@pdftron/webviewer';

const PDFTronDR = ({ initialDocUrl, documenTitle, username }) => {
  const viewer = useRef(null);
  const usernameR = username ? username : 'defaultUser'
  const [vw, setVW] = useState();



  useEffect(() => {
    const initializeWebViewer = async () => {
      try {
        const webviewerPromise =await WebViewer(
          {
            path: '/webviewer/lib',
            initialDoc: initialDocUrl,
            licenseKey: process.env.REACT_APP_PDF_KEY,
            enableMeasurement: true,
          },
          viewer.current,
        );
  
  
        webviewerPromise
          .then((instance) => {

          })
          .catch((error) => {
            console.error('Error initializing WebViewer (inside .then.catch):', error);
          });
      } catch (error) {
        console.error('Error importing WebViewer (outer catch):', error);
      }
    };
  
    initializeWebViewer();
  }, [initialDocUrl]);
  return (
    <div className="">
      <div className="bg-sky-400 text-white p-4 shadow-md">
        <h1 className="text-xl font-bold">{documenTitle}</h1>
      </div>

      <div className="h-[70vh]"
        ref={viewer}
      >
        {/* Webviewer 콘텐츠 */}
      </div>
    </div>
  );
};

export default PDFTronDR;