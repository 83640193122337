

import * as React from "react"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import {
    CardContent,
} from "@/components/ui/card"
import { useParams } from "react-router-dom";
import api from '@/lib/axiosInstance';
import { Loader2 } from "lucide-react";


export function EmergencyTable() {

    const [loading, setLoading] = React.useState(true);
const { projectCode } = useParams();

    const [rawData, setRawData] = React.useState<any>(null);


    React.useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                let body = {
                    bpname: "분기별 비상대응 실시 현황",
                    lineitem: "no",
                    filter_condition: "status=Active",
                };

                const res = await api.post(`/getemsbprecord?path=${projectCode}`, body);
                setRawData(res.data.data.data
                    .sort((a: any, b: any) => a.record_no - b.record_no)
                    .reverse()
                )

            } catch (error) {
                console.error(`Failed to fetch data for:`, error);
            }

            setLoading(false);
        };

        if (projectCode) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [projectCode]); // viewMode 추가


    const tableData = rawData;

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <Loader2 className="w-5 h-5 animate-spin" />
            </div>
        );
    }


    return (
        <>


            <CardContent className="h-[22.5vh]">

                <div className='overflow-hidden'>
                    <Table className='text-xs'>
                        <TableHeader>
                            <TableRow>
                                <TableHead className="w-[100px] text-left p-2 h-8">실시년도</TableHead>
                                <TableHead className="w-[100px] text-left p-2 h-8">실시분기</TableHead>
                                <TableHead className="text-left p-2 h-8">훈련내용</TableHead>
                                <TableHead className="w-[100px] text-left p-2 h-8">실시여부</TableHead>
                            </TableRow>
                        </TableHeader>
                    </Table>
                </div>
                <div className='overflow-auto h-[14.5vh]'>
                    <Table className='text-xs'>
                        <TableBody>
                            {tableData?.map((v:any, index:number) => {
      

                                return (
                                    <TableRow key={index}>
                                        <TableCell className="w-[100px] text-left p-2 font-medium">{v.ueffectyearPD}</TableCell>
                                        <TableCell className="w-[100px] text-left p-2 font-medium">{v.quarter_pd}</TableCell>
                                        <TableCell className="text-left p-2 font-medium">{v.d_tr_detail.replaceAll("\\n",'\n')}</TableCell>
                                        <TableCell className="w-[100px] text-left p-2 font-medium">{v.d_action_yn}</TableCell>

                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </CardContent>



        </>
    )
}
