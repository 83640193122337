
import React, { useEffect, useState } from 'react';
import api from '@/lib/axiosInstance';
import { useParams } from "react-router-dom";
import { Loader2 } from 'lucide-react';
import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart";
import { Bar, BarChart, XAxis } from "recharts";
import { Select, SelectItem, SelectTrigger, SelectValue, SelectContent } from "@/components/ui/select";
import dayjs from 'dayjs';

interface EnvActionData {
    month: string;
    constructionwaste: number;
    businesswaste: number;
    designatedwaste: number;
}

interface EnvActionState {
    chartData: EnvActionData[];
    lastyearRate: any;
    projectRate: any;
}

export function EnvWaste() {
    const [loading, setLoading] = useState(true);
const { projectCode } = useParams();

    const [year, setYear] = useState(dayjs().year().toString()); // 현재 연도로 초기화
    const [envAction, setEnvAction] = useState<EnvActionState | null>(null);

     // 현재 연도를 기반으로 지난 5년(포함) 생성
     const years = Array.from({ length: 5 }, (_, i) =>
     dayjs().year() - i
 ).reverse(); // 최신 연도가 마지막으로 오도록 정렬

 // X축 고정 데이터 (1부터 12까지)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const xAxisData = Array.from({ length: 12 }, (_, i) => ({ month: `${String(i + 1).padStart(2, '0')}` }));

            try {
                const res = await api.get(`/getwasteData?project=${projectCode}&year=${year}`);
                setEnvAction({
                    chartData: xAxisData.map((v)=>({
                        ...v,
                        ...res.data.monthData.find((vf:any)=>vf.month === v.month),
                    })),
                    lastyearRate: (res.data.rate.lastyearRate*100).toFixed(0),
                    projectRate: (res.data.rate.projectRate*100).toFixed(0),
                });
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setLoading(false);
            }
        };

        if (projectCode) fetchData();
        else setLoading(false);
    }, [projectCode, year]); // year가 변경되면 데이터 다시 로드

    const chartConfig = {
        constructionwaste: {
            label: "건설",
            color: "hsl(var(--chart-1))",
        },
        businesswaste: {
            label: "사업장",
            color: "hsl(var(--chart-2))",
        },
        designatedwaste: {
            label: "지정",
            color: "hsl(var(--chart-3))",
        },
    } satisfies ChartConfig;


    const chartData = envAction && envAction.chartData

    console.log(chartData)

    const recycling = chartData?.reduce(
        (sum: number, item: any) => sum + (item.recycling ?? 0),
        0 // 초기값
    );
    
    const landfill = chartData?.reduce(
        (sum: number, item: any) => sum + (item.landfill ?? 0),
        0 // 초기값
    );
    
    const incineration = chartData?.reduce(
        (sum: number, item: any) => sum + (item.incineration ?? 0),
        0 // 초기값
    );

    if (loading) {
        return (
            <Card className="col-span-12 md:col-span-6 h-[47vh]">
            <CardHeader className="text-left">
                    <CardTitle className="text-base leading-none tracking-tight">폐기물 배출 현황</CardTitle>
                </CardHeader>
                <CardContent className="flex items-center justify-center overflow-hidden">
                    <Loader2 className="w-5 h-5 animate-spin" />
                </CardContent>
            </Card>
        );
    }

    if (!envAction) {
        return (
            <Card className="col-span-12 md:col-span-6 h-[47vh]">
            <CardHeader className="text-left">
                    <CardTitle className="text-base leading-none tracking-tight">폐기물 배출 현황</CardTitle>
                </CardHeader>
                <CardContent className="flex items-center justify-center overflow-hidden">
                    <p className="text-sm text-slate-500">No data available</p>
                </CardContent>
            </Card>
        );
    }

    return (
        <Card className="col-span-12 md:col-span-6 h-[47vh]">
        <CardHeader className="text-left">
        <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
            <span >폐기물 배출 현황</span>
                    <Select
                    value={year}
                    onValueChange={(value) => setYear(value)}
                    
                >
                    <SelectTrigger className="w-[100px] text-xs">
                        <SelectValue placeholder="연도 선택" />
                    </SelectTrigger>
                    <SelectContent>
                        {years.map((y) => (
                            <SelectItem key={y} value={y.toString()}>
                                {y}년
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
                </CardTitle>

            </CardHeader>
            <CardContent className="flex-grow flex flex-col h-[31vh]">
            <Table className="text-xs">
                    <TableHeader>
                        <TableRow>
                            <TableHead className=' p-1 h-6'></TableHead>
                            <TableHead className=' p-1 h-6'>전년 대비</TableHead>
                            <TableHead className=' p-1 h-6'>전사 대비</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell className=' p-1 h-6'>Emission Rate</TableCell>
                            <TableCell className=' p-1 h-6 font-bold text-blue-700'>{envAction.lastyearRate}%</TableCell>
                            <TableCell className=' p-1 h-6 font-bold text-blue-700'>{envAction.projectRate}%</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <ChartContainer config={chartConfig} className='max-h-[26vh]'>
                    <BarChart
                        data={envAction.chartData}
                    >
                        <XAxis
                            dataKey="month"
                            tickLine={false}
                            tickMargin={10}
                            axisLine={false}
                            tickFormatter={(value) => {
                                const date = new Date(Number(dayjs().format('YYYY')) ,value-1, 1);
                                return date.toLocaleDateString("ko-KR", {
                                    month: "short",
                                });
                            }}
                        />
                        <Bar dataKey="constructionwaste" stackId="a" fill="var(--color-constructionwaste)" />
                        <Bar dataKey="businesswaste" stackId="a" fill="var(--color-businesswaste)" />
                        <Bar dataKey="designatedwaste" stackId="a" fill="var(--color-designatedwaste)" />
                        <ChartTooltip
                                         content={
                                            <ChartTooltipContent
                                              labelFormatter={(value) => {
                                                const date = new Date(Number(dayjs().format('YYYY')) ,value-1, 1);
                                                return date.toLocaleDateString("ko-KR", {
                                                    month: "short",
                                                });
                                              }}
                                            />
                                          }
                            cursor={false}
                            defaultIndex={Number(dayjs().format("MM"))-1}
                        />
                    </BarChart>
                </ChartContainer>
            </CardContent>
            <CardFooter>
            <Table className="text-xs">
                    <TableHeader>
                        <TableRow>
                            <TableHead className=' p-1 h-6'></TableHead>
                            <TableHead className=' p-1 h-6'>매립</TableHead>
                            <TableHead className=' p-1 h-6'>소각</TableHead>
                            <TableHead className=' p-1 h-6'>재활용</TableHead>
                            <TableHead className=' p-1 h-6'>Recycle Rate</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell className=' p-1 h-6'>폐기물 처리현황</TableCell>
                            <TableCell className=' p-1 h-6 font-semibold'>{landfill}ton</TableCell>
                            <TableCell className=' p-1 h-6 font-semibold'>{incineration}ton</TableCell>
                            <TableCell className=' p-1 h-6 font-semibold '>{recycling}ton</TableCell>
                            <TableCell className=' p-1 h-6 font-semibold text-blue-700'>{(((recycling||0)/((landfill||0)+(incineration||0)+(recycling||0)))*100).toFixed(0)} %</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardFooter>
        </Card>
    );
}