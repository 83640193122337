import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '@/locales/en/translation.json'
import translationKO from '@//locales/ko/translation.json'

const resources = {
  en: {
    translation: translationEN
  },
  ko: {
    translation: translationKO
  }
};

i18n
  .use(initReactI18next) // React와 연결
  .init({
    fallbackLng: 'en', // 기본 언어
    supportedLngs: ['en', 'ko'], // 지원 언어
    lng: 'ko', // 초기 언어
    resources:resources,
    ns: ['translation'], // 'translation' 네임스페이스 사용
    defaultNS: 'translation', // 기본 네임스페이스 설정
    interpolation: {
      escapeValue: false, // React가 자동으로 escaping 처리
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator'], // 언어 감지 순서
      caches: ['cookie'], // 언어를 쿠키에 저장
    },
  });

export default i18n;