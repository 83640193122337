// ResetPassword.tsx
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { PasswordInput } from "@/components/password-input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import api from '@/lib/axiosInstance';
import { Loader2 } from "lucide-react";
import { languageState } from '@/atoms';
import { useToast } from "@/hooks/use-toast";
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { getResetPasswordSchema } from './validationSchema'; // 변경된 스키마 함수 임포트

const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const { toast } = useToast();
    const { t } = useTranslation();  // i18n 객체에서 t 함수만 가져오기

    const [language, setLanguage] = useAtom(languageState);
    const [isLoading, setIsLoading] = useState(false); // Loading state

    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');

    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    // 현재 언어에 맞는 스키마를 생성
    const schema = useMemo(() => getResetPasswordSchema(t), [t]);

    // validateForm 함수 업데이트: 동적 스키마 사용
    const validateForm = useCallback(() => {
        const formData = { password, passwordConfirmation };
        const validation = schema.safeParse(formData);
        if (!validation.success) {
            const fieldErrors: { [key: string]: string } = {};
            validation.error.errors.forEach((err) => {
                if (err.path.length > 0) {
                    fieldErrors[err.path[0]] = err.message;
                }
            });
            setErrors(fieldErrors);
            return false;
        }
        setErrors({});
        return true;
    }, [password, passwordConfirmation, schema]);

    // 입력값이 변경될 때마다 유효성 검사 실행
    useEffect(() => {
        validateForm();
    }, [password, passwordConfirmation, validateForm]);

    const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // 최종 유효성 검사
        if (!validateForm()) {
            toast({
                variant: 'destructive',
                title: t('toast.inputErrorTitle'), // 국제화된 타이틀 사용
                description: t('toast.inputErrorDescription'), // 국제화된 설명 사용
            });
            return;
        }

        setIsLoading(true); // 로딩 시작

        try {
            const response = await api.post('/reset-password', { token, password });

            if (response.data.success) {
                toast({
                    variant: 'default',
                    title: t('toast.resetSuccessTitle'), // 국제화된 타이틀 사용
                    description: t('toast.resetSuccessDescription'), // 국제화된 설명 사용
                });
                // 성공 시 로그인 페이지로 리디렉션할 수 있습니다.
            } else {
                toast({
                    variant: 'destructive',
                    title: t('toast.resetFailureTitle'), // 국제화된 타이틀 사용
                    description: t('toast.resetFailureDescription'), // 국제화된 설명 사용
                });
            }
        } catch (err: any) {
            console.error(err);
            const errorMessage = err.response?.data?.error || t('toast.resetErrorDefault');
            toast({
                variant: 'destructive',
                title: t('toast.resetFailureTitle'), // 국제화된 타이틀 사용
                description: errorMessage,
            });
        } finally {
            setIsLoading(false); // 로딩 종료
        }
    };

    return (
        <Card className="mx-auto max-w-sm">
          <CardHeader>
            <CardTitle className="flex items-center justify-center">
              <img src="/images/sconlogoH.png" alt="Logo" className="w-2/3 pb-2" />
            </CardTitle>
            <CardTitle className="text-xl">{t('resetPasswordPage.title')}</CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleResetPassword}>
              <div className="space-y-4">
                <div>
                  <Label htmlFor="password">{t('resetPasswordPage.newPassword')}</Label>
                  <PasswordInput
                    id="password"
                    value={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                    autoComplete="new-password"
                  />
                  {errors.password && (
                    <p className="text-red-500 text-sm mt-1">{errors.password}</p>
                  )}
                </div>
                <div>
                  <Label htmlFor="password_confirmation">{t('resetPasswordPage.confirmPassword')}</Label>
                  <PasswordInput
                    id="password_confirmation"
                    value={passwordConfirmation}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPasswordConfirmation(e.target.value)}
                    autoComplete="new-password"
                  />
                  {errors.passwordConfirmation && (
                    <p className="text-red-500 text-sm mt-1">{errors.passwordConfirmation}</p>
                  )}
                </div>
                <Button
                  disabled={!password || !passwordConfirmation || Object.keys(errors).length > 0 || isLoading}
                  type="submit"
                  className="w-full flex items-center justify-center"
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="animate-spin mr-2" />
                      {t('resetPasswordPage.setPassword')}
                    </>
                  ) : (
                    t('resetPasswordPage.setPassword')
                  )}
                </Button>
              </div>
            </form>
            <div className="mt-4 text-center text-sm">
              {t('link.backToLogin')}{' '}
              <Link to="/login" className="underline">
                {t('link.login')}
              </Link>
            </div>
          </CardContent>
        </Card>
      );
    };
    
export default ResetPassword;