
import * as React from "react"
import { useParams } from "react-router-dom";
import api from "@/lib/axiosInstance";
import CustomNode from "./org/CustomNode";
import { transformData,  TreeNode as MyTreeNode, OrgDataItem} from "./org/dataTransform";
import Tree from 'react-d3-tree';


const OrgChart: React.FC = () => {
     const { projectCode } = useParams()
    const [orgChartData, setOrgChartData] = React.useState<MyTreeNode | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const body = {
                    bpname: "조직도",
                    lineitem: "yes",
                    filter_condition: "status=Active",
                };

                const response = await api.post(`/getemsbprecord?path=${projectCode}`, body);
                const unifierData: OrgDataItem[] = response.data.data.data;

                const transformedData = transformData(unifierData);
                setOrgChartData(transformedData);
            } catch (error) {
                console.error("데이터를 가져오는 중 오류 발생:", error);
                setError("데이터를 가져오는 중 오류가 발생했습니다.");
                setOrgChartData(null);
            } finally {
                setLoading(false);
            }
        };

        if (projectCode) {
            fetchData();
        }
    }, [projectCode]);

    // 트리의 위치를 중앙에 맞추기 위한 ref와 state
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [dimensions, setDimensions] = React.useState<{ width: number, height: number }>({ width: 800, height: 600 });

    React.useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setDimensions({
                    width: containerRef.current.offsetWidth,
                    height: containerRef.current.offsetHeight,
                });
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

   // pathClassFunc 함수 정의 (타입 오류 해결)
   const getDynamicPathClass = (link: any, orientation: string): string => {
    const { target } = link;
    if (!target.children || target.children.length === 0) {
        // Leaf 노드로 연결되는 링크
        return 'link__to-leaf';
    }

    // Branch 노드로 연결되는 링크
    return 'link__to-branch';
};

const customPathFunc = (link: any, orientation: string): string => {
    const { source, target } = link;

    const startX = source.x;
    const startY = source.y + 50; // rectHeight 만큼 아래로 이동
    const endX = target.x;
    const endY = target.y;

    // 엘보우 형태 경로 정의
    return `M ${startX} ${startY} L ${startX} ${(startY + endY) / 2} L ${endX} ${(startY + endY) / 2} L ${endX} ${endY}`;
};


    return (
        <div ref={containerRef} className="w-full h-full">
            {loading && <p>Loading...</p>}
            {error && <p>{error}</p>}
            {orgChartData && (
                <Tree
                    data={orgChartData}
                    translate={{ x: dimensions.width / 2 , y: 100 }}
                    renderCustomNodeElement={(rd3tProps) => (
                        <CustomNode {...rd3tProps} />
                    )}
                    rootNodeClassName="node__root"
                    orientation="vertical"
                    zoomable
                    scaleExtent={{ min: 0.1, max: 2 }}
                    nodeSize={{ x: 140, y: 120 }} // 노드 간격 조정
                    separation={{
                        siblings: 1.2, // 형제 노드 간 간격
                        nonSiblings: 1.2, // 비형제 노드 간 간격
                    }}
                    pathClassFunc={getDynamicPathClass}
                    pathFunc={customPathFunc}

                />
            )}
        </div>
    );
};

export default OrgChart;