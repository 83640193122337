
import React, { useEffect, useState, useCallback, useRef, MutableRefObject } from 'react';
import { useAtom } from 'jotai';
import { divisionState } from '@/atoms';
import api from '@/lib/axiosInstance';
import dayjs from 'dayjs';
import { CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import MonthPicker from '@/components/common/monthPicker';
import { Loader2 } from 'lucide-react';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { Checkbox } from "@/components/ui/checkbox"
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, Mousewheel } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode';
import './photo.css'
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'


interface PhotoProps {
}
interface DataItem {
    projectcode: string;
    plan_date: string;
    [key: string]: any;
}

interface BPRecordResponse {
    data: { data: DataItem[] };
}

interface FilterCriteria {
    field: string;
    value: string;
    condition_type: string;
}
interface Project {
    project_projectname: string;
    project_projectnumber: string;
    division: string;
    month: string;
}

interface ImageData {
    filename: string;
    blob: string;
    order: number;
    // 기타 필요한 필드 추가
}

interface ImageItem {
    blob: string;
    projectname: string;
    title: string;
    date: string;
    description: string;
    // 필요한 다른 속성들 추가
}

const Photos: React.FC<PhotoProps> = () => {
    // const { projectsList } = useGetProjectList();
    const [division] = useAtom(divisionState);
    const [date, setDate] = useState<Date | undefined>(new Date())

    const [newProjectList, setNewProjectList] = useState<Project[]>([]);
    const [selectedProject, setSelectedProject] = useState<Project | null>(null);
    const [loading, setLoading] = React.useState(true);

    const [checkValue, setCheckValue] = useState(true);

    ///
    const [imageMetaDataList, setImageMetaDataList] = React.useState<any>([]);
    const [thumBlobList, setThumBlobList] = React.useState<(string | null)[]>([]);
    const [imageBlobList, setImageBlobList] = React.useState<(string | null)[]>([]);
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [thumbsSwiper, setThumbsSwiper] = React.useState<null>(null);
    const [initialLoad, setInitialLoad] = React.useState(true);
    const [currentPage, setCurrentPage] = React.useState(0);
    const imagesPerPage = 6;
    const [validDates, setValidDates] = useState<string[]>([]);
    const navSwiperRef = useRef<any>(null);



    useEffect(() => {
        // 프로젝트나 날짜가 변경될 때 Swiper 상태 리셋
        setThumbsSwiper(null);
        // setMainSwiper(null);
    }, [selectedProject, date]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await api.get(`/getphotoprojectsList`);
                const projectListfromserver = res.data.data;
    
                if (projectListfromserver.length > 0) {
                    // Extract valid months (YYYY-MM-01 format) from photodate
                    const sortedProjects = projectListfromserver
                        .filter((item: any) => item.month) // Ensure photodate exists
                        .sort((a: any, b: any) => dayjs(b.month).diff(dayjs(a.month))); // Sort by photodate descending
    
                    // Set the project with the largest month
                    const latestProject = sortedProjects[0];
                    const latestMonth = latestProject ? dayjs(latestProject.month, "YYYY-MM").format("YYYY-MM-01") : null;

                    console.log()
    
                    setNewProjectList(projectListfromserver);
                    setSelectedProject(latestProject);
    
                    if (latestMonth) {
                        setDate(new Date(latestMonth));
                    }
                } else {
                    setNewProjectList([]);
                    setSelectedProject(null);
                    setDate(undefined);
                }
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {

        const filtered = newProjectList.filter(v=>v.project_projectnumber === selectedProject?.project_projectnumber)
        .sort((a: any, b: any) => dayjs(b.month).diff(dayjs(a.month))); // Sort by photodate descending

        const latestProject = filtered[0];
        const latestMonth = latestProject ? dayjs(latestProject.month, "YYYY-MM").format("YYYY-MM-01") : null;

        if (latestMonth) {
            setDate(new Date(latestMonth));
        }

    }, [newProjectList, selectedProject]);


    const projectList = Array.from(
        new Map(
            newProjectList.map((item: any) => [
                `${item.project_projectnumber}-${item.project_projectname}-${item.division}`,
                item
            ])
        ).values()
    );


    const filteredProjects = projectList?.filter((v: any) => (!division || v.division === division))
        .reduce((group: any, project) => {
            const { division } = project;
            group[division] = group[division] || [];
            group[division].push(project);
            return group;
        }, {});

    const findProjectByCode = (code: string): Project | null => {
        for (const division in filteredProjects) {
            const project = filteredProjects[division].find((p: any) => p.project_projectnumber === code);
            if (project) {
                return project;
            }
        }
        return null;
    };

    const handleMonthChange = (newDate: Date) => {
        // Validate the selected month against validDates

        setDate(newDate);
        setImageBlobList([]);
        setThumBlobList([]);
        setImageMetaDataList([]);

    };


    React.useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // 데이터 로딩 시작

            const projectCode = selectedProject?.project_projectnumber; // 선택된 프로젝트의 코드
            const monthDate = dayjs(date).format("YYYY-MM-01"); // 날짜를 YYYY-MM-01 형식으로 포맷

            try {
                // 이미지 메타데이터 가져오기
                const res = await api.get(`/getWeeklyPhotosTotal?project=${projectCode}&month=${monthDate}`);
                if (res.data.success && res.data.Rows.length > 0) {
                    const imageDataList = res.data.Rows;
                    setImageMetaDataList(imageDataList); // 메타데이터 상태 업데이트

                    // 초기 페이지의 썸네일과 이미지를 로드
                    const initialImageDataList = imageDataList.slice(0, imagesPerPage);
                    await fetchThumbnailsAndImages(initialImageDataList);
                } else {
                    // 데이터가 없을 때 처리
                    setImageMetaDataList([]); // 빈 배열로 초기화
                    setThumBlobList([]); // 썸네일도 초기화
                    setImageBlobList([]); // 이미지도 초기화
                }
            } catch (error) {
                console.error("Failed to fetch data:", error);
            } finally {
                setLoading(false); // 로딩 종료
            }
        };

        if (selectedProject && date) fetchData(); // 선택된 프로젝트와 날짜가 있을 때만 호출
    }, [selectedProject, date]);

    const fetchThumbnailsAndImages = async (imageDataList: any[]) => {
        const thumbUrls: (string | null)[] = [];
        const imageUrls: (string | null)[] = [];


        for (const image of imageDataList) {
            // Fetch thumbnail
            try {
                const thumbResponse = await api.get(
                    `/gethistorythumnail?fileid=${image.fileid}`,
                    { responseType: "arraybuffer" }
                );
                const thumbBlob = window.URL.createObjectURL(new Blob([thumbResponse.data]));
                thumbUrls.push(thumbBlob);
            } catch (thumbError) {
                console.error(`Failed to fetch thumbnail for fileid: ${image.fileid}`, thumbError);
                thumbUrls.push(null);
            }

            // Fetch full image
            try {
                const imageResponse = await api.get(
                    `/getPhotohistroyFile?fileid=${image.fileid}`,
                    { responseType: "arraybuffer" }
                );
                const imageBlob = window.URL.createObjectURL(new Blob([imageResponse.data]));
                imageUrls.push(imageBlob);
            } catch (imageError) {
                console.error(`Failed to fetch image for fileid: ${image.fileid}`, imageError);
                imageUrls.push(null);
            }
        }


        // Filter out entries where thumbnail or image is null
        const validEntries = imageDataList
            .map((meta: any, index: number) => ({
                ...meta,
                thumbnail: thumbUrls[index],
                image: imageUrls[index],
            }))
            .filter((entry: any) => entry.thumbnail !== null && entry.image !== null);

        // Update state
        // setImageMetaDataList((prevList: any[]) => [...prevList, ...validEntries]);
        setThumBlobList((prevList: (string | null)[]) => [...prevList, ...validEntries.map((entry: any) => entry.thumbnail)]);
        setImageBlobList((prevList: (string | null)[]) => [...prevList, ...validEntries.map((entry: any) => entry.image)]);
    };

    const handleSlideChange = async (swiper: any) => {

        setCurrentIndex(swiper.activeIndex);
        if (swiper.activeIndex >= currentPage * imagesPerPage + imagesPerPage - 1 && !initialLoad) {
            console.log("슬라이드 작동")
            setCurrentPage((prevPage) => prevPage + 1);
            const nextImageDataList = imageMetaDataList.slice(
                (currentPage + 1) * imagesPerPage,
                (currentPage + 1) * imagesPerPage + imagesPerPage
            );

            await fetchThumbnailsAndImages(nextImageDataList);
        }
    };

    const [swiperWidth, setSwiperWidth] = useState(360);
    const [swiperHeight, setSwiperHeight] = useState(150);

    console.log(swiperHeight)

    const cardContentRef = useRef<HTMLDivElement | null>(null) as MutableRefObject<HTMLDivElement | null>;
    const setCardContentRef = useCallback((node: any) => {
        if (node !== null) {
            cardContentRef.current = node;
    
            // 지연 호출로 레이아웃이 안정된 후 크기를 가져옴
            setTimeout(() => {
                const { width, height } = node.getBoundingClientRect();
    
                if (height > 50) { // 극단적으로 작은 height 무시
                    setSwiperWidth(width - 115);
                    setSwiperHeight(height * 0.65);
                } else {
                    console.warn("Height is too small, skipping update.");
                }
    
                console.log('Updated sizes:', { width: width - 25, height: height * 0.65 });
            }, 50); // 50ms 정도 지연
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (cardContentRef.current) {
                const { width, height } = cardContentRef.current.getBoundingClientRect();
                setSwiperWidth(width - 115);
                setSwiperHeight(height * 0.65);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    const toggleCheckbox = () => {
        setCheckValue(!checkValue); // 현재 상태를 반전시킴
    };

    return (
        <>
            {loading ?
                (<div className="flex justify-center items-center h-full">
                    <Loader2 className="w-5 h-5 animate-spin" />
                </div>) :
                (<>
                    <CardHeader className="p-3">
                        <CardTitle className="flex justify-between items-center">
                            <span className='text-xs w-1/3'>Photos</span>

                            {filteredProjects && (
                                <Select
                                    value={selectedProject?.project_projectnumber || ''}
                                    onValueChange={(value) => {
                                        const project = findProjectByCode(value);
                                        setSelectedProject(project);
                                    }}
                                >
                                    <SelectTrigger className="w-[150px] text-[11px] font-normal mx-auto h-8 p-2 border-0">
                                        <SelectValue placeholder="Select a Project" />
                                    </SelectTrigger>
                                    <SelectContent className="text-[11px] p-0">
                                        {Object.keys(filteredProjects).map((division) => (
                                            <SelectGroup key={division} className="text-[11px]">
                                                <SelectLabel className="text-[11px]">{division}</SelectLabel>
                                                {filteredProjects[division].map((project: any) => (
                                                    <SelectItem
                                                        className="text-[11px]"
                                                        key={project.project_projectnumber}
                                                        value={project.project_projectnumber}
                                                    >
                                                        {project.project_projectname}
                                                    </SelectItem>
                                                ))}
                                            </SelectGroup>
                                        ))}
                                    </SelectContent>
                                </Select>
                            )}
                            <div className='w-1/3'>
                                <MonthPicker
                                    selectedMonth={date}
                                    onMonthChange={handleMonthChange}
                                    placeholder="Choose a month"
                                />
                            </div>
                        </CardTitle>
                    </CardHeader>

                    {thumBlobList.length === 0 &&
                        (
                            <div className="flex justify-center items-center h-full text-center text-gray-500 text-xs"> No data available</div>

                        )}
                    <CardContent ref={setCardContentRef} className="flex-1 flex flex-col items-start justify-start pl-4 relative">


                        <div className="absolute left-4 bottom-4 flex items-center space-x-1">
                            <Checkbox
                                id="auto"
                                className="h-3 w-3"
                                checked={checkValue}
                                onClick={toggleCheckbox}
                            />
                            <label
                                htmlFor="auto"
                                className="text-xs font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            >
                                자동전환
                            </label>
                        </div>

                        {thumBlobList.length > 0 &&
                            <div className="grid grid-cols-10 h-4/5 w-full">



                                <div className="col-span-10 relative">
                                    <section className="slider  h-full" style={{}}>
                                        <div className="flex slider__flex">
                                            <div className="slider__col">
                                                {/* 썸네일 */}
                                                <div
                                                    className="mr-2 slider__thumbs"
                                                    style={{ width: '75px' }}
                                                >
                                                    <Swiper
                                                        ref={navSwiperRef}
                                                        onSwiper={(swiper: any) => setThumbsSwiper(swiper)}
                                                        direction="vertical"
                                                        spaceBetween={4}
                                                        slidesPerView={4}
                                                        watchSlidesProgress={true}

                                                        // className="swiper-container1"
                                                        modules={[Thumbs]}
                                                        style={{ height: 120 }}
                                                    >
                                                        {thumBlobList && thumBlobList.map((v, index) => (
                                                            v &&
                                                            <SwiperSlide key={index}>

                                                                <img
                                                                    src={v}
                                                                    alt={`thumb-${index}`}
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        objectFit: 'cover',
                                                                    }}
                                                                    onClick={() => setCurrentIndex(index)}
                                                                />
                                                            </SwiperSlide>
                                                        ))}
                                                    </Swiper>
                                                </div>
                                            </div>
                                            <div className="flex flex-col items-center">
                                                {thumbsSwiper && 
                                  
                                                (
                                                    <div className="flex-1 w-full" style={{ width: swiperWidth }}>
                                                        <Swiper
                                                            thumbs={{ swiper: navSwiperRef.current }}
                                                            direction="horizontal"
                                                            slidesPerView={1}
                                                            navigation={true}
                                                            spaceBetween={10}
                                                            mousewheel={true}
                                                            onSlideChange={handleSlideChange}
                                                            className="swiper-container2"
                                                            watchSlidesProgress={true}
                                                            modules={[Navigation, Thumbs, Mousewheel, FreeMode]}
                                                            style={{ width: swiperWidth }}
                                                        >
                                                            {imageBlobList.map((v, index) => (
                                                                v &&
                                                                <SwiperSlide key={index}>
                                                                    <Zoom>
                                                                        <img
                                                                            src={v}
                                                                            style={{
                                                                                width: swiperWidth,
                                                                                height: swiperHeight,
                                                                                objectFit: 'cover',
                                                                            }}
                                                                            alt={`${index}`}
                                                                        />
                                                                    </Zoom>

                                                                    {/* 설명 부분 */}
                                                                    <div className="relative overflow-y-auto overflow-x-hidden h-1/5 w-full mt-1">
                                                                        <div className="text-center">
                                                                            <div className="text-[9px]">
                                                                                <span className="font-medium">
                                                                                    {imageMetaDataList[index]?.phototitle || "Untitled"}
                                                                                </span>
                                                                                <br />
                                                                                <span className="font-medium">
                                                                                    {imageMetaDataList[index]?.photodate?.slice(0, 10) || "No Date"}
                                                                                </span>
                                                                            </div>
                                                                            {/* <div className="text-[9px]">
                                                                                <div
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: imageMetaDataList[index]?.photodesc
                                                                                            ? imageMetaDataList[index].photodesc.replaceAll("\\n", "<br/>")
                                                                                            : "",
                                                                                    }}
                                                                                />
                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                </SwiperSlide>
                                                            ))}
                                                        </Swiper>

                                                    </div>
                                              )}
                                                
                                            </div>
                                        </div>
                                    </section>
                                </div>


                            </div>


                        }


                    </CardContent>
                </>)

            }
        </>)
};

export default Photos;

