
import React, { useEffect, useState } from 'react';
import api from '@/lib/axiosInstance';
import { Loader2 } from 'lucide-react';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import dayjs from 'dayjs';
import { Pie, PieChart, Label, Sector } from "recharts";
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart";
import { Button } from '@/components/ui/button';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { useParams } from "react-router-dom";

export function EnvAudit() {
    const [loading, setLoading] = useState(true);
const { projectCode } = useParams();
    const [envAudit1, setEnvAudit1] = useState<any>(null);
    const [envAudit2, setEnvAudit2] = useState<any>(null);
    const colors = ["#2563eb", "#60a5fa", "#34d399", "#f97316", "#a855f7"];
    const [selectedValue, setSelectedValue] = useState('정기');

    useEffect(() => {
        const fetchData = async () => {
            let body = {
                bpname: "환경심사",
                lineitem: "yes",
                record_fields:
                    "EnvAuditClass_spd;AuditStartDateDO;AuditFinishDateDO;QAProgressDA;EvaluationNoSPD",
                filter_criteria: {
                    join: "AND",
                    filter: [
                        {
                            field: "status",
                            value: "Terminated",
                            condition_type: "neq",
                        },
                        {
                            field: "ugenProjectNumber",
                            value: projectCode,
                            condition_type: "eq",
                        },
                    ],
                },
            };
            try {
                const res = await api.post(`/getemsbprecord?path=SKEC0001`, body);

                const fdata_a =
                    res.data.data.data.filter((v: any) => v.EnvAuditClass_spd === "정기")
                        .sort((a: any, b: any) => b.EvaluationNoSPD - a.EvaluationNoSPD).reverse()[0]

                const fdata_b = res.data.data.data.filter((v: any) => v.EnvAuditClass_spd === "특별")
                    .sort((a: any, b: any) => b.EvaluationNoSPD - a.EvaluationNoSPD).reverse()[0]

                const line_a =
                    fdata_a &&
                    Object.assign(fdata_a._bp_lineitems, {
                        EnvAuditClass_spd: fdata_a.EnvAuditClass_spd,
                        AuditStartDateDO:
                            fdata_a.AuditStartDateDO.slice(6, 10) +
                            "년 " +
                            fdata_a.AuditStartDateDO.slice(0, 2) +
                            "월 " +
                            fdata_a.AuditStartDateDO.slice(3, 5) +
                            "일",
                        AuditFinishDateDO:
                            fdata_a.AuditFinishDateDO.slice(6, 10) +
                            "년 " +
                            fdata_a.AuditFinishDateDO.slice(0, 2) +
                            "월 " +
                            fdata_a.AuditFinishDateDO.slice(3, 5) +
                            "일",
                        QAProgressDA: fdata_a.QAProgressDA,
                        EvaluationNoSPD: fdata_a.EvaluationNoSPD,
                    });
                setEnvAudit1(line_a);

                const line_b =
                    fdata_b &&
                    Object.assign(fdata_b._bp_lineitems, {
                        EnvAuditClass_spd: fdata_b.EnvAuditClass_spd,
                        AuditStartDateDO:
                            fdata_b.AuditStartDateDO.slice(6, 10) +
                            "년 " +
                            fdata_b.AuditStartDateDO.slice(0, 2) +
                            "월 " +
                            fdata_b.AuditStartDateDO.slice(3, 5) +
                            "일",
                        AuditFinishDateDO:
                            fdata_b.AuditFinishDateDO.slice(6, 10) +
                            "년 " +
                            fdata_b.AuditFinishDateDO.slice(0, 2) +
                            "월 " +
                            fdata_b.AuditFinishDateDO.slice(3, 5) +
                            "일",
                        QAProgressDA: fdata_b.QAProgressDA,
                        EvaluationNoSPD: fdata_b.EvaluationNoSPD,
                    });
                setEnvAudit2(line_b);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setLoading(false);
            }
        };

        if (projectCode) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [projectCode]);


    const sourceData = selectedValue === "정기" ? envAudit1 : envAudit2


    const chartData = sourceData && Object.entries(
        sourceData.reduce((acc:any, obj:any) => {
            const key = obj.EnvAuditFindCat_spd as string;
            if (!acc[key]) acc[key] = [];
            acc[key].push(obj);
            return acc;
        }, {} as Record<string, any[]>)
    )
        .map(([key, objs], index) => ({
            id: key,
            value: (objs as any[]).length,
            fill: colors[index]
        }))
        .filter((v) => v.value !== 0);


  
    const percentage = sourceData && sourceData["QAProgressDA"]

    const totalValue = React.useMemo(() => {
        return chartData && chartData.reduce((acc: any, curr: any) => acc + curr.value, 0);
    }, [chartData]);



    const chartConfig = chartData && chartData.reduce((config:any, item:any, index:any) => {
        config[item.id] = {
            label: item.id,
            color: colors[index % colors.length],
        };
        return config;
    }, {} as ChartConfig);

    if (loading) {
        return (
            <Card className="col-span-12 md:col-span-3 h-[47vh]">
                <CardHeader className="text-left">
                    <CardTitle className="text-base leading-none tracking-tight">환경 Issue 사항</CardTitle>
                </CardHeader>
                <CardContent className="flex items-center justify-center overflow-hidden">
                    <Loader2 className="w-5 h-5 animate-spin" />
                </CardContent>
            </Card>
        );
    }

    return (
        <Card className="col-span-12 md:col-span-3 h-[47vh]">
            <CardHeader className="text-left">
                <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                    <span>환경 Issue 사항</span>
                    {/* <span className="ml-auto text-xs">{dayjs(issueData?.genSubmitDO).format("YYYY년 MM월 DD일")}</span> */}
                </CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col h-full">
                {/* ToggleGroup positioned at the top-left */}
                <ToggleGroup
                    className="self-end -mb-8 z-40 gap-0"
                    size="sm"
                    type="single"
                    value={selectedValue}
                    onValueChange={setSelectedValue}
                >
                    <ToggleGroupItem className="h-6 rounded-[0px]" value="정기" aria-label="정기">
                        <span className="text-xs">정기</span>
                    </ToggleGroupItem>
                    <ToggleGroupItem className="h-6 rounded-[0px]" value="특별" aria-label="특별">
                        <span className="text-xs">특별</span>
                    </ToggleGroupItem>
                </ToggleGroup>

                {/* ChartContainer centered below ToggleGroup */}
                <div className="flex-grow flex justify-center items-center">
                    {chartData && chartData.length > 0 ? (
                        <ChartContainer
                            config={chartConfig}
                            className="mx-auto aspect-square max-h-[200px] w-full"
                        >
                            <PieChart>
                                <ChartTooltip
                                    content={<ChartTooltipContent nameKey="id" hideLabel />}
                                />
                                <Pie
                                    data={chartData}
                                    dataKey="value"
                                    nameKey="id"
                                    innerRadius={50}
                                    outerRadius={75}
                                    strokeWidth={1}
                                    labelLine={false}
                                    label={({ cx, cy, midAngle, outerRadius, value, name }) => {
                                        const RADIAN = Math.PI / 180;
                                        const labelRadius = outerRadius + 10;
                                        const x = cx + labelRadius * Math.cos(-midAngle * RADIAN);
                                        const y = cy - 5 + labelRadius * Math.sin(-midAngle * RADIAN);

                                        if (value / totalValue < 0.05) return null;

                                        const percentage = ((value / totalValue) * 100).toFixed(2);

                                        return (
                                            <text
                                                x={x}
                                                y={y}
                                                textAnchor={x > cx ? "start" : "end"}
                                                dominantBaseline="central"
                                                className="text-[10px] fill-gray-800"
                                            >
                                                <tspan x={x} dy="0em">{`${name} `}</tspan>
                                                <tspan x={x} dy="1.2em">{`${value.toFixed(0)} (${percentage}%)`}</tspan>
                                            </text>
                                        );
                                    }}
                                >
                                    <Label
                                        content={({ viewBox }: any) => {
                                            if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                                                return (
                                                    <text
                                                        x={viewBox.cx}
                                                        y={viewBox.cy}
                                                        textAnchor="middle"
                                                        dominantBaseline="middle"
                                                    >
                                                        <tspan
                                                            x={viewBox.cx}
                                                            y={viewBox.cy}
                                                            className="fill-foreground text-xl font-semibold"
                                                        >
                                                            {percentage.toLocaleString()}%
                                                        </tspan>
                                                        <tspan
                                                            x={viewBox.cx}
                                                            y={(viewBox.cy || 0) + 24}
                                                            className="fill-muted-foreground"
                                                        >
                                                            조치완료율
                                                        </tspan>
                                                    </text>
                                                );
                                            }
                                            return null;
                                        }}
                                    />
                                </Pie>
                            </PieChart>
                        </ChartContainer>
                    ) : (
                        <div className="flex justify-center items-center h-full text-xs text-slate-700">
                            No data available.
                        </div>
                    )}
                </div>
            </CardContent>
        </Card>
    );
}