import api from '@/lib/axiosInstance';


export const getSessionUsername = async () => {
  try {
    const response = await api.get(`/getsessionusername`);


    return response.data.username;
  }
  catch (error) {
    console.error(error);
    return "Invalid";
  }
};
