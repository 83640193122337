
import * as React from "react"
import { useParams } from "react-router-dom";
import api from "@/lib/axiosInstance";
import Timeline from "@/components/common/Timeline";
import dayjs from "dayjs";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {useAtomValue} from 'jotai'
import { sidebarCollapsedState   } from '@/atoms';

interface projectMilestoneData {
    label: string;
    plan_date: any;
    actual_date: string;
}

export function QTimeLine() {

     const { projectCode } = useParams()
    const [startDate, setStartDate] = React.useState<string | null>(null);
    const [endDate, setEndDate] = React.useState<string | null>(null);
    const [milestoneData, setMilestoneData] = React.useState<projectMilestoneData[] | null>(null);
    const sidebarCollapsed = useAtomValue(sidebarCollapsedState) ?? undefined;


    React.useEffect(() => {
        const fetchData = async () => {
            let body = {
                bpname: "Quality Critical Process",
                lineitem: "yes",
                filter_condition: "status=Active",
                filter_criteria: {
                    join: "AND",
                    filter: [
                        {
                            field: "QCPDisciplinePD",
                            value: "EMT",
                            condition_type: "eq",
                        },
                    ],
                },
            };

            const unifierData = await api.post(`/getemsbprecord?path=${projectCode}`, body)
                .then((c) => {
                    return c.data.data.data;
                })
                

            const total_lineitems =
                unifierData
                .filter((c:any) => c._bp_lineitems)
                .map((c:any) => c._bp_lineitems)
                .flat()
                .map((item: any) => ({
                    label: item.QCPActivityNM,
                    plan_date: dayjs(item.emsPlanFinishDO).format("YYYY-MM-DD"),
                    actual_date: item.emsActualFinishDO ? dayjs(item.emsActualFinishDO).format("YYYY-MM-DD") : undefined,
                }))
                    .sort((a: projectMilestoneData, b: projectMilestoneData) => {
                        const dateA = new Date(a.plan_date);
                        const dateB = new Date(b.plan_date);
                        return dateA.getTime() - dateB.getTime();
                    });


            const dates = total_lineitems
                .map((milestone: any) => milestone.actual_date || milestone.plan_date)
                .filter(Boolean) as string[]; // Filter out undefined

            const earliestDate = dates.reduce((a, b) => (a < b ? a : b), dates[0]);
            const latestDate = dates.reduce((a, b) => (a > b ? a : b), dates[0]);
            setStartDate(earliestDate)
            setEndDate(latestDate)
            setMilestoneData(total_lineitems);
        };
        fetchData();
    }, [projectCode]);


    return ( 
        <CardContent className={`flex items-center justify-center ${sidebarCollapsed ? "h-[20vh]":"h-[30vh]"}`}>
        <Timeline events={milestoneData} startDate={startDate} endDate={endDate} flagPosition="alternate" />
        </CardContent>
    )
}