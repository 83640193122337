import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { ProjectSummaryContent } from "@/components/overview/ProjectSummaryContent";
import { SafetyActivityContent } from "@/components/overview/SafetyContent";
import QualityActivitiesCard from "@/components/overview/QualityActivitiesCard";
import { OverviewTimeLine } from "@/components/overview/OverviewTimeLine";
import { OverviewProgressCard } from "@/components/overview/ProgressCard";
import { OverviewManEquipPieChart } from "@/components/overview/ManEquipPieChart";
import { useTranslation } from 'react-i18next';

export default function ProjectOverview() {
  const { t } = useTranslation();

  return (
    <div className="flex h-screen w-full p-4">
      <div className="grid grid-cols-1 md:grid-rows-3 md:grid-cols-8 gap-4 flex-1">

        {/* 첫 번째 카드: Project 개요 */}
        <Card className="row-span-3 md:col-span-2 col-span-8 rounded-xl border bg-card text-card-foreground shadow">
          <CardHeader className="text-left">
            <CardTitle className="text-base leading-none tracking-tight">{t('projectOverview')}</CardTitle>
          </CardHeader>
          <CardContent className="flex-grow items-center justify-center overflow-y-auto">
            <ProjectSummaryContent />
          </CardContent>
        </Card>

        {/* 두 번째 카드: Project Milestone */}
        <Card className="row-span-1 md:col-span-6 col-span-8 rounded-xl border bg-card text-card-foreground shadow flex flex-col">
          <CardHeader className="text-left">
            <CardTitle className="text-base leading-none tracking-tight">{t('projectMilestone')}</CardTitle>
          </CardHeader>
          <CardContent className="h-full flex-grow flex items-center justify-center overflow-hidden">
            <OverviewTimeLine />
          </CardContent>
        </Card>

        {/* 세 번째 카드: EPC Progress */}
        <Card className="row-span-1 md:col-span-2 col-span-8 rounded-xl border bg-card text-card-foreground shadow">
          <OverviewProgressCard />
        </Card>

        {/* 네 번째 카드: 인원   다섯 번째 카드: 장비*/}
        <OverviewManEquipPieChart />

        {/* 여섯 번째 카드: 안전활동 */}
        <Card className="row-span-1 md:col-span-3 col-span-8 rounded-xl border bg-card text-card-foreground shadow">
          <CardHeader className="text-left">
            <CardTitle className="text-base leading-none tracking-tight">{t('safety_activity')}</CardTitle>
          </CardHeader>
          <CardContent className="flex items-start justify-between px-6 py-2">
            <SafetyActivityContent />
          </CardContent>
        </Card>

        {/* 일곱 번째 카드: Quality Activities */}
        <QualityActivitiesCard />
      </div>
    </div>
  );
}