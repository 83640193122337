
import React, { useEffect, useState } from 'react';
import api from '@/lib/axiosInstance';
import { Loader2 } from 'lucide-react';
import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle, CardDescription
} from "@/components/ui/card";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip, ChartLegend,
    ChartLegendContent,
    ChartTooltipContent,
} from "@/components/ui/chart";
import { Bar, BarChart, XAxis, PolarAngleAxis, PolarGrid, Radar, RadarChart } from "recharts";
import { Select, SelectItem, SelectTrigger, SelectValue, SelectContent } from "@/components/ui/select";
import dayjs from 'dayjs';
import { useParams } from "react-router-dom";

interface EnvActionData {
    id: string;
    value: number;
    scope1: number | undefined;
    scope2: number | undefined;
    scope3: number | undefined;

}

interface EnvActionData2 {
    id: string;
    value: number;

}

interface EnvActionDataBar {
    month: string;
    scope1: number | undefined;
    scope2: number | undefined;

}

interface EnvActionData3 {
    id: string;
    scope1: number | undefined;
    scope2: number | undefined;
    scope3: number | undefined;

}

interface EnvActionState {
    barChartData: EnvActionDataBar[];
    pieChartTotal: EnvActionData2[];
    pieChartScope1: EnvActionData2[];
    pieChartScope2: EnvActionData2[];
    pieChartScope3: EnvActionData2[];
    RadarChart: EnvActionData3[];
    yearRate: string | number;
    companyRate: string | number;

}

export function EnvGHG() {
    const [loading, setLoading] = useState(true);
const { projectCode } = useParams();

    const [year, setYear] = useState(dayjs().year().toString()); // 현재 연도로 초기화
    const [envAction, setEnvAction] = useState<EnvActionState | null>(null);

    // 현재 연도를 기반으로 지난 5년(포함) 생성
    const years = Array.from({ length: 5 }, (_, i) =>
        dayjs().year() - i
    ).reverse(); // 최신 연도가 마지막으로 오도록 정렬

    // X축 고정 데이터 (1부터 12까지)
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            // X축 데이터 생성
            const xAxisData = Array.from({ length: 12 }, (_, i) => ({ month: `${String(i + 1).padStart(2, '0')}` }));
            const lastYear = (parseInt(year) - 1).toString(); // 작년 연도 계산

            // Helper function for summing gas values
            const calculateSum = (data: any[], key: string): number =>
                data.reduce((sum: number, item: any) => sum + (item[key] ?? 0), 0);

            try {
                // Company-level request body
                const companyBody = {
                    bpname: "환경실적 입력 요청/조회",
                    lineitem: "no",
                    filter_criteria: {
                        join: "AND",
                        filter: [
                            { field: "status", value: "Terminated", condition_type: "neq" },
                            { field: "year_from_2021_spd", value: year + "년", condition_type: "eq" },
                        ],
                    },
                };



                // Project-level request body
                const projectBody = {
                    bpname: "환경실적 입력",
                    lineitem: "no",
                    filter_criteria: {
                        join: "AND",
                        filter: [{ field: "status", value: "Terminated", condition_type: "neq" }],
                    },
                };

                // Fetch data
                const [resC, resP] = await Promise.all([
                    api.post(`/getemsbprecord?path=SKEC0001`, companyBody),
                    api.post(`/getemsbprecord?path=${projectCode}`, projectBody),
                ]);

                const projectDataThisYear = resP.data.data.data.filter((v: any) =>
                    v.EEIYearMonth.includes(year.slice(-2))
                );

                const projectDataLastYear = resP.data.data.data.filter((v: any) =>
                    v.EEIYearMonth.includes(lastYear.slice(-2))
                );


                // Calculate last year's gas totals
                const lastYearGasTotals =
                    calculateSum(projectDataLastYear, "EEIGas02") +
                    calculateSum(projectDataLastYear, "EEIGas03") +
                    calculateSum(projectDataLastYear, "EEIGas05") +
                    calculateSum(projectDataLastYear, "EEIGas10") +
                    calculateSum(projectDataLastYear, "EEIGas13") +
                    calculateSum(projectDataLastYear, "EEIGas04") +
                    calculateSum(projectDataLastYear, "EEIGas06") +
                    calculateSum(projectDataLastYear, "EEIGas15") +
                    calculateSum(projectDataLastYear, "EEIGas08") +
                    calculateSum(projectDataLastYear, "EEIGas07") +
                    calculateSum(projectDataLastYear, "EEIGas09")


                const companyDataThisYear = resC.data.data.data;

                const companyGasTotals =
                    calculateSum(companyDataThisYear, "EEIGas02") +
                    calculateSum(companyDataThisYear, "EEIGas03") +
                    calculateSum(companyDataThisYear, "EEIGas05") +
                    calculateSum(companyDataThisYear, "EEIGas10") +
                    calculateSum(companyDataThisYear, "EEIGas13") +
                    calculateSum(companyDataThisYear, "EEIGas04") +
                    calculateSum(companyDataThisYear, "EEIGas06") +
                    calculateSum(companyDataThisYear, "EEIGas15") +
                    calculateSum(companyDataThisYear, "EEIGas08") +
                    calculateSum(companyDataThisYear, "EEIGas07") +
                    calculateSum(companyDataThisYear, "EEIGas09")

                console.log(resC.data.data.data)
                console.log(companyGasTotals)
                // Create bar chart data
                const barChartData = projectDataThisYear.map((v: any) => ({
                    yearmonth: v.EEIYearMonth,
                    scope1: v.EEIGas08,
                    scope2: v.EEIGas07,
                }));

                // Calculate gas totals
                const gasTotals = {
                    EEIGas02: calculateSum(projectDataThisYear, "EEIGas02"),
                    EEIGas03: calculateSum(projectDataThisYear, "EEIGas03"),
                    EEIGas05: calculateSum(projectDataThisYear, "EEIGas05"),
                    EEIGas10: calculateSum(projectDataThisYear, "EEIGas10"),
                    EEIGas13: calculateSum(projectDataThisYear, "EEIGas13"),
                    EEIGas04: calculateSum(projectDataThisYear, "EEIGas04"),
                    EEIGas06: calculateSum(projectDataThisYear, "EEIGas06"),
                    EEIGas15: calculateSum(projectDataThisYear, "EEIGas15"),
                    EEIGas08: calculateSum(projectDataThisYear, "EEIGas08"),
                    EEIGas07: calculateSum(projectDataThisYear, "EEIGas07"),
                    EEIGas09: calculateSum(projectDataThisYear, "EEIGas09"),
                };

                const sumGasTotal = calculateSum(projectDataThisYear, "EEIGas02") +
                    calculateSum(projectDataThisYear, "EEIGas03") +
                    calculateSum(projectDataThisYear, "EEIGas05") +
                    calculateSum(projectDataThisYear, "EEIGas10") +
                    calculateSum(projectDataThisYear, "EEIGas13") +
                    calculateSum(projectDataThisYear, "EEIGas04") +
                    calculateSum(projectDataThisYear, "EEIGas06") +
                    calculateSum(projectDataThisYear, "EEIGas15") +
                    calculateSum(projectDataThisYear, "EEIGas08") +
                    calculateSum(projectDataThisYear, "EEIGas07") +
                    calculateSum(projectDataThisYear, "EEIGas09")


                const companyRate = ((sumGasTotal / companyGasTotals) * 100).toFixed(2)
                const yearRate = ((sumGasTotal / lastYearGasTotals) * 100).toFixed(2)
                // Update state
                setEnvAction({
                    barChartData: xAxisData.map((v) => ({
                        ...v,
                        ...barChartData.find((vf: any) => vf.yearmonth.includes(v.month)),
                    })),
                    pieChartTotal: [
                        { id: "Scope1", value: gasTotals.EEIGas08 },
                        { id: "Scope2", value: gasTotals.EEIGas07 },
                        { id: "Scope3", value: gasTotals.EEIGas09 },
                    ],
                    pieChartScope1: [
                        { id: "고정연소(SK)", value: gasTotals.EEIGas02, },
                        { id: "차량(SK)", value: gasTotals.EEIGas03, },
                        { id: "건설기계(SK)", value: gasTotals.EEIGas05, },
                    ],
                    pieChartScope2: [
                        { id: "전기", value: gasTotals.EEIGas10, },
                        { id: "구매전력", value: gasTotals.EEIGas13, },
                    ],
                    pieChartScope3: [
                        { id: "차량(Biz.P)", value: gasTotals.EEIGas04, },
                        { id: "건설기계(Biz.P)", value: gasTotals.EEIGas06, },
                        { id: "고정연소(Biz.P)", value: gasTotals.EEIGas15, },
                    ],
                    RadarChart: [
                        { id: "고정연소", scope1: gasTotals.EEIGas02, scope2: 0, scope3: gasTotals.EEIGas15 },
                        { id: "차량", scope1: gasTotals.EEIGas03, scope2: 0, scope3: gasTotals.EEIGas04 },
                        { id: "건설기계", scope1: gasTotals.EEIGas05, scope2: 0, scope3: gasTotals.EEIGas06 },
                        { id: "구매전력", scope1: 0, scope2: gasTotals.EEIGas13, scope3: 0 },
                    ],
                    companyRate,
                    yearRate
                });

                setLoading(false);
            } catch (error: any) {
                console.error("Failed to fetch data:", error.message);
                setEnvAction(null); // Clear state on error
                setLoading(false);
            }
        };

        if (projectCode) fetchData();
        else setLoading(false);
    }, [projectCode, year]);

    const chartConfig = {
        scope1: {
            label: "scope1",
            color: "hsl(var(--chart-1))",
        },
        scope2: {
            label: "scope2",
            color: "hsl(var(--chart-2))",
        },
        scope3: {
            label: "scope3",
            color: "hsl(var(--chart-3))",
        },
    } satisfies ChartConfig;


    console.log(envAction && envAction.barChartData)

    if (loading) {
        return (
            <Card className="col-span-12 md:col-span-6 h-[47vh]">
                <CardHeader className="text-left">
                    <CardTitle className="text-base leading-none tracking-tight">GHG Emission 현황</CardTitle>
                </CardHeader>
                <CardContent className="flex items-center justify-center overflow-hidden">
                    <Loader2 className="w-5 h-5 animate-spin" />
                </CardContent>
            </Card>
        );
    }

    if (!envAction) {
        return (
            <Card className="col-span-12 md:col-span-6 h-[47vh]">
                <CardHeader className="text-left">
                    <CardTitle className="text-base leading-none tracking-tight">GHG Emission 현황</CardTitle>
                </CardHeader>
                <CardContent className="flex items-center justify-center overflow-hidden">
                    <p className="text-sm text-slate-500">No data available</p>
                </CardContent>
            </Card>
        );
    }

    return (
        <Card className="col-span-12 md:col-span-6 h-[47vh]">
            <CardHeader className="text-left">
                <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
                    <span >GHG Emission 현황</span>
                    <Select
                        value={year}
                        onValueChange={(value) => setYear(value)}

                    >
                        <SelectTrigger className="w-[100px] text-xs">
                            <SelectValue placeholder="연도 선택" />
                        </SelectTrigger>
                        <SelectContent>
                            {years.map((y) => (
                                <SelectItem key={y} value={y.toString()}>
                                    {y}년
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </CardTitle>

            </CardHeader>
            <CardContent className="flex-grow flex flex-col p-0 pr-4 pl-4">
                <Table className="text-xs">
                    <TableHeader>
                        <TableRow>
                            <TableHead className="p-1 h-6"></TableHead>
                            <TableHead className="p-1 h-6">전년 대비</TableHead>
                            <TableHead className="p-1 h-6">전사 대비</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell className="p-1 h-6">Emission Rate</TableCell>
                            <TableCell className="p-1 h-6 font-bold text-blue-700">{envAction.yearRate}%</TableCell>
                            <TableCell className="p-1 h-6 font-bold text-blue-700">{envAction.companyRate}%</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                {/* 차트를 좌우로 배치 */}
                <div className="flex w-full max-h-[30vh] space-x-4">
                    {/* 왼쪽 차트 */}
                    <div className="flex-1">
                        <CardHeader className="p-2">
                            <CardTitle className="text-xs">배출원별 배출현황</CardTitle>
                            {/* <CardDescription className="text-xs">Scope1 데이터</CardDescription> */}
                        </CardHeader>
                        <CardContent className="flex-grow p-2">
                            <ChartContainer config={chartConfig} className="w-full max-h-[17vh]">
                                <RadarChart
                                    data={envAction.RadarChart}
                                    margin={{
                                        top: -40,
                                        bottom: -10,
                                    }}
                                >
                                    <ChartTooltip
                                        cursor={false}
                                        content={<ChartTooltipContent indicator="line" />}
                                    />
                                    <PolarAngleAxis dataKey="id" />
                                    <PolarGrid />
                                    <Radar dataKey="scope1" fill="var(--color-scope1)" />
                                    <Radar
                                        dataKey="scope2"
                                        fill="var(--color-scope2)"
                                    //   fillOpacity={0.6}
                                    />
                                    <Radar
                                        dataKey="scope3"
                                        fill="var(--color-scope3)"
                                        fillOpacity={0.6}
                                    />

                                    <ChartLegend className="mt-8" content={<ChartLegendContent />} />
                                </RadarChart>

                            </ChartContainer>
                        </CardContent>
                    </div>

                    {/* 오른쪽 차트 */}
                    <div className="flex-1">
                        <CardHeader className='p-2'>
                            <CardTitle className='text-xs'>월별 배출현황</CardTitle>
                            <CardDescription className='text-xs'>Scope1, Scope2</CardDescription>
                        </CardHeader>
                        <CardContent className="flex-grow p-2">
                            <ChartContainer config={chartConfig} className="w-full max-h-[17vh]">
                                <BarChart
                                    width={undefined} // 너비를 부모 컨테이너에 맞추기 위해 undefined 설정
                                    height={undefined} // 높이도 부모 컨테이너에 맞추기 위해 undefined 설정
                                    data={envAction.barChartData}
                                    className="w-full h-full"
                                >
                                    <XAxis
                                        dataKey="month"
                                        tickLine={false}
                                        tickMargin={10}
                                        axisLine={false}
                                        tickFormatter={(value) => {
                                            const date = new Date(Number(dayjs().format('YYYY')), value - 1, 1);
                                            return date.toLocaleDateString("ko-KR", {
                                                month: "short",
                                            });
                                        }}
                                    />
                                    <Bar dataKey="scope1" stackId="a" fill="var(--color-scope1)" />
                                    <Bar dataKey="scope2" stackId="a" fill="var(--color-scope2)" />
                                    <ChartTooltip
                                        content={
                                            <ChartTooltipContent
                                                labelFormatter={(value) => {
                                                    const date = new Date(Number(dayjs().format('YYYY')), value - 1, 1);
                                                    return date.toLocaleDateString("ko-KR", {
                                                        month: "short",
                                                    });
                                                }}
                                            />
                                        }
                                        cursor={false}
                                        defaultIndex={Number(dayjs().format("MM")) - 1}
                                    />
                                </BarChart>
                            </ChartContainer>
                        </CardContent>
                    </div>
                </div>
            </CardContent>
            <CardFooter>
                <Table className="text-xs">
                    <TableHeader>
                        <TableRow>
                            <TableHead className=' p-1 h-6'></TableHead>
                            <TableHead className=' p-1 h-6'>Scope1</TableHead>
                            <TableHead className=' p-1 h-6'>Scope2</TableHead>
                            <TableHead className=' p-1 h-6'>Scope3</TableHead>
                            <TableHead className=' p-1 h-6'></TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell className="p-1 h-6">GHG 배출현황</TableCell>
                            {/* Scope 1 */}
                            <TableCell className="p-1 h-6 font-semibold">
                                {envAction.pieChartTotal[0].value.toFixed(0)}{" "}
                                ({(
                                    (envAction.pieChartTotal[0].value /
                                        (envAction.pieChartTotal[0].value +
                                            envAction.pieChartTotal[1].value +
                                            envAction.pieChartTotal[2].value)) *
                                    100
                                ).toFixed(2)}
                                %)
                            </TableCell>
                            {/* Scope 2 */}
                            <TableCell className="p-1 h-6 font-semibold">
                                {envAction.pieChartTotal[1].value.toFixed(0)}{" "}
                                ({(
                                    (envAction.pieChartTotal[1].value /
                                        (envAction.pieChartTotal[0].value +
                                            envAction.pieChartTotal[1].value +
                                            envAction.pieChartTotal[2].value)) *
                                    100
                                ).toFixed(2)}
                                %)
                            </TableCell>
                            {/* Scope 3 */}
                            <TableCell className="p-1 h-6 font-semibold">
                                {envAction.pieChartTotal[2].value.toFixed(0)}{" "}
                                ({(
                                    (envAction.pieChartTotal[2].value /
                                        (envAction.pieChartTotal[0].value +
                                            envAction.pieChartTotal[1].value +
                                            envAction.pieChartTotal[2].value)) *
                                    100
                                ).toFixed(2)}
                                %)
                            </TableCell>
                            {/* Total */}
                            <TableCell className="p-1 h-6 font-semibold text-blue-700">
                                {(
                                    envAction.pieChartTotal[0].value +
                                    envAction.pieChartTotal[1].value +
                                    envAction.pieChartTotal[2].value
                                ).toFixed(0)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardFooter>
        </Card>
    );
}