import { PPgogressChartWithBullet } from "@/components/pprogress/PProgressChart";
import { PTimeLine } from "@/components/pprogress/PTimeLine";
import { Card} from "@/components/ui/card";


export default function ProcurementProgress() {

  return (
    <div className="flex flex-1 flex-col gap-4 p-4 w-full">
      {/* 마일스톤 */}
      <Card className="h-[39vh] w-full rounded-xl border bg-card text-card-foreground shadow">
        <PTimeLine />
      </Card>

      <PPgogressChartWithBullet/>
    </div>
  );
}