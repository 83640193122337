
import { useGetPartMilestoneData } from "@/lib/getData";
import Timeline from "@/components/common/Timeline";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle, CardFooter
} from "@/components/ui/card";
import { PartTimeLine } from "../common/partTimeLine";

export function CTimeLine() {

  const { oMilestoneData, cMilestoneData, startDate, endDate } = useGetPartMilestoneData();

    return (          
      <PartTimeLine oMilestoneData={oMilestoneData} MilestoneData={cMilestoneData} startDate={startDate} endDate={endDate} partName="Contrunction"/>
   
    )
    }