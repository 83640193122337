import { SidebarProvider, SidebarRail } from "@/components/ui/sidebar";
import { ProjectSidebar } from "@/pages/layouts/project-sidebar";
import React, { useEffect } from "react";
import { useSetAtom } from "jotai";
import { sidebarCollapsedState } from "@/atoms";
import { Outlet } from 'react-router-dom';


export default function ProjectLayout() {
  const [open, setOpen] = React.useState(true);
  const setSidebarCollapsed = useSetAtom(sidebarCollapsedState);


  useEffect(() => {
    setSidebarCollapsed(!open);
  }, [open]);


    return (
      <>

          <SidebarProvider open={open} onOpenChange={setOpen}>
            <ProjectSidebar />
            <main className="flex-1 antialiased">
              <SidebarRail />
              <Outlet />
            </main>
          </SidebarProvider>
   
      </>
    );
  }

