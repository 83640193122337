

import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  DataGridPremium, GridToolbarExport, GridToolbarContainer, GridToolbarQuickFilter,
  useGridApiRef, GridEditSingleSelectCell, GridActionsCellItem,  GRID_ROOT_GROUP_ID
} from '@mui/x-data-grid-premium';
import {sortBy} from 'lodash';
import api from '@/lib/axiosInstance';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import JSZip from 'jszip';
import MyDocumentViewerModal from '../viewers/modalClient';
import { logout } from '@/components/common/logout';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import { getSessionUsername } from '@/components/common/getName';
import { useToast } from "@/hooks/use-toast";
import { useParams } from "react-router-dom";
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogFooter, AlertDialogAction, AlertDialogCancel } from '@/components/ui/alert-dialog';
import { CircleAlertIcon, CloudDownload, Edit2, Edit2Icon, FileDownIcon, MessageCircleWarning } from 'lucide-react';
import { Badge } from "@/components/ui/badge"

const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
  '& .tree-table-group': {
    backgroundColor: theme.palette.grey[50],
  }

}));

const ClientTable = ({ options, tableData }) => {

const { projectCode } = useParams();

  const [open, setOpen] = useState(false);
  const [documentUrl, setDocumentUrl] = useState('');
  const [documenTitle, setDocumenTitle] = useState('');
  const [docuID, setdocuID] = useState('');
  const [username, setUsername] = useState(null)
  const apiRef = useGridApiRef();
  const [rows, setRows] = React.useState(tableData.map((v, index) => ({ ...v, id: index})));
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const { toast } = useToast();

  const [openDialog, setOpenDialog] = useState(false);
  const [changedRowsCount, setChangedRowsCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    const fetchUsername = async () => {
      const name = await getSessionUsername();
      if(name){
      setUsername(name);
      }else {
      setUsername("coadmin");

      }
    };

    fetchUsername();
  }, []);



  const handleLogoutClick = async () => {
    try {
      logout({ username, project: projectCode, client: false, tr: true });
    } catch (error) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'An error occurred. Please contact the system administrator.',
      });
    }
  };


  const [selectedRow, setSelectedRow] = React.useState({});


  useEffect(() => {
    setRows(sortBy(tableData.map((v, index) => ({
      ...v,
      id: index,
    })), "ReceivedDate").reverse())
  }, [tableData])



  const handleDownloadDocument = async (fileId) => {
    try {
      const response = await api.get(`/download/${fileId}`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.zip'); // 여기서 다운로드할 파일 이름 지정
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading the document:', error);
    }
  };



  const handleBulkDownload = async (selectedIds) => {
    const selectedRows = rows.filter(row => selectedIds.includes(row.id));
    const finalZip = new JSZip();
  
    try {
      for (const row of selectedRows) {
        const response = await api.get(`/download/${row.file_id}`, {
          responseType: 'blob',
        });
  
        const zipFile = await JSZip.loadAsync(response.data);
        zipFile.forEach((relativePath, file) => {
          finalZip.file(`${row.file_name}/${relativePath}`, file.async("blob"));
        });
      }
  
      const zipBlob = await finalZip.generateAsync({ type: 'blob' });
      const downloadUrl = window.URL.createObjectURL(zipBlob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'Documents.zip'); // Name of the final zip file
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading or zipping the documents:', error);

      toast({
        variant: "destructive",
        title: "Download Failed",
        description: 'Failed to download the documents. Please try again.',
      })

      
    }
  };

  const columns = [
    {
      field: 'No',
      headerName: 'TR No.',     
      flex: 1,
      renderHeader: () => (
        <strong>
          {'TR No.'} <br /> {'(Doc. No.)'}
        </strong>
      ),
    },


    {
      field: 'title',
      headerName: 'TR Title (Doc. Title)', 
      flex:2,
      renderHeader: () => (
        <strong>
          {'TR Title'} <br /> {'(Doc. Title)'}
        </strong>
      ),
    },


    {
      field: 'ReceivedDate',
      headerName: 'Recevied Date',
      type: 'date',
      flex:1,
    
    },
    {
      field: 'DueDate',
      headerName: 'Due Date',
      type: 'date',
      flex:1,
    },
    {
      field: 'DecisionBPK',
      headerName: 'Review Outcome',
      editable: true,
      type: 'singleSelect',
      valueOptions: options,
      flex:1,
      renderEditCell: (params) => {
        if (params.row.hierarchy.length === 1) {
          // hierarchy.length가 1인 경우, 편집 불가능한 상태를 표시
          return <span></span>;
        } else {
          // 편집 가능한 상태에서는 단일 선택 입력 컴포넌트를 렌더링
          return (
            <GridEditSingleSelectCell
              {...params}
              options={options}
              value={params.value}
              onChange={(event) => {
                event.stopPropagation(); // 여기에 추가
                params.api.setEditCellValue({ id: params.id, field: params.field, value: event.target.value }, event);
              }}
            />
          );
        }
      },
    },

    {
      field: 'RevNo',
      headerName: 'Rev. No.',
      flex:1,
    },

    {
      field: 'stageName',
      headerName: 'Issue Purpose (Stage Name)',
      renderHeader: () => (
        <strong>
          {'Issue Purpose'} <br /> {'(Stage Name)'}
        </strong>
      ),
      flex:2,
    },
    {
      field: 'viewDocument',
      headerName: 'Comment',
      flex:1,   align: 'center',
      renderCell: (params) => {
        if (params.row.file_id) {
          return (
            <>
              <GridActionsCellItem
                icon={<Edit2Icon className='h-4 w-4' />}
                label="Comment"
                sx={{ marginRight: '8px' }}

                onClick={() => handleOpenDocumentViewer(params.row.file_id)}
              />
              <GridActionsCellItem
                icon={<CloudDownload className='h-4 w-4'/>}
                label="Download"
                onClick={() => handleDownloadDocument(params.row.file_id)}
              />
            </>
          );
        } else {
          return (
            <span></span>
          );
        }
      },
    },

    {
      field: 'SubmittedDate',
      headerName: 'Submitted Date',
      type: 'date',
      flex:1,
    },
    {
      field: 'Submittedby',
      headerName: 'Submitted by',
      flex:1,
    }, {
      field: 'stepStatus',
      headerName: 'Step Status',
      flex:1,
      align: 'center',
      renderCell: (params) => {
        // hierarchy.length가 1 이상인 경우
        if (params.row.hierarchy.length > 1) {
          // 'Submitted' 상태 처리
          if (params.row.SubmittedDate) {
            return <Badge>Submitted</Badge>;
          }
          // 'Overdue' 상태 처리
          else if (dayjs().isAfter(dayjs(params.row.DueDate))) {
            return  <Badge variant="destructive">Overdue</Badge>
            ;
          }

        }
        // hierarchy.length가 1 미만인 경우, 빈 문자열 또는 기본값 반환
        return '';
      }
    }
  ];


  const handleOpenDocumentViewer = async (fileId) => {
    try {
      const response = await api.get(`/download/${fileId}`,
        {
          responseType: 'blob'
        }

      );

      // ZIP 파일 압축 해제
      const jszip = new JSZip();
      const zipContent = await jszip.loadAsync(response.data); // ZIP 파일 로드

      // 첫 번째 파일의 내용을 얻음
      const fileNames = Object.keys(zipContent.files);
      if (fileNames.length === 0) {
        throw new Error('ZIP file is empty');
      }

      const firstFileName = fileNames[0];
      const firstFile = zipContent.files[firstFileName];
      const fileData = await firstFile.async('blob');


      // 파일 이름을 상태로 설정
      setDocumenTitle(firstFileName);
      setdocuID(fileId)
      // Blob URL 생성 및 상태 설정
      setDocumentUrl(URL.createObjectURL(fileData));
      setSelectedRow(rows.find(v => v.file_id === fileId))
      setOpen(true);

    } catch (error) {
      console.error('Error fetching or unzipping the document:', error);
    }
  };


  const getTreeDataPath = (row) => row.hierarchy;

  const processRowUpdate = (newRow) => {
    console.log(newRow)
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };


  const handleOpenSubmitDialog = () => {
    const rowModels = apiRef.current.getRowModels();
    const rowValuesArray = Array.from(rowModels.values());

    // DecisionBPK 값이 변경된 행만 필터링
    const changedRows = rowValuesArray.filter((updatedRow) => {
      const originalRow = rows.find(row => row.record_no === updatedRow.record_no);
      return originalRow && originalRow.DecisionBPK !== updatedRow.DecisionBPK;
    }).filter(v => rowSelectionModel.includes(v.id));

    if (changedRows.length > 0) {
      setSelectedRows(changedRows);
      setChangedRowsCount(changedRows.length);
      setOpenDialog(true);
    } else {
      toast({
        variant: "info",
        title: "No Changes Detected",
        description: "There are no changes to submit.",
      });
    }
  };

  const handleSubmitChanges = async () => {
    setOpenDialog(false);

    try {
      const updatePromises = selectedRows.map(row =>
        api.get(`/updatesimpletr?project=${projectCode}&record=${row.record_no}&DecisionBPK=${row.DecisionBPK}&username=${username}&LineAutoSeq=${row.LineAutoSeq}`)
      );

      await Promise.all(updatePromises);

      toast({
        variant: "success",
        title: "Submitted!",
        description: "Your review changes have been submitted successfully.",
      });

      window.location.reload(); // 페이지 새로고침
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "An error occurred while submitting your reviews. Please try again.",
      });
    }
  };




  const initialRowsRef = React.useRef(rows);
  React.useEffect(() => {
    if (rows !== initialRowsRef.current) {
      apiRef.current.updateRows(rows);
      setRows(rows)
    }
  }, [apiRef, rows]);



  function getChipProps(params) {
    if (params.value === "Overdue") {
      return {
        icon: <CircleAlertIcon className='h-4 w-4 storke-red-600' />, // red[500]을 "red"로 변경
        label: params.value,
        style: {
          borderColor: "red" // red[500]을 "red"로 변경
        }
      };
    } 
    if (params.value === "Submitted") {
      return {
        // icon: <CheckCircleIcon style={{ fill: "blue" }} />, // blue[500]을 "blue"로 변경
        label: params.value,
        style: {
          borderColor: "blue" // blue[500]을 "blue"로 변경
        }
      };
    }
    else {
      return "";
    }
  }

  const toggleGroup = () => {
    const groups = apiRef.current.getRowNode(GRID_ROOT_GROUP_ID).children;

    if (groups.length > 1) {
      groups.forEach(groupId => {
        apiRef.current.setRowChildrenExpansion(
          groupId,
          !apiRef.current.getRowNode(groupId).childrenExpanded,
        );
      });

    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ fontSize: 'larger', fontWeight: 600,marginLeft:10}}>
          {projectCode === "20238S" ? "Transmittals (for TideWater)" : "Transmittals"}
        </div>
  
        <div>
          <Button
            size="small"
            onClick={toggleGroup}
            style={{ marginRight: '10px' }}
          >
            Toggle row expansion
          </Button>
          <GridToolbarExport style={{ marginRight: '10px' }} />
          <Button
            startIcon={<FileDownIcon className='h-4 w-4' />}
            size="small"
            onClick={() => handleBulkDownload(rowSelectionModel)}
            style={{ marginRight: '10px' }}
          >
            Selected Row File Download
          </Button>
          <GridToolbarQuickFilter style={{ marginRight: '10px' }} />
          <Button
          onClick={handleOpenSubmitDialog}
          style={{ color: 'snow', fontSize: '0.7rem', fontWeight: 600, backgroundColor: 'dodgerblue' }}
          >
            Submit Review
          </Button>
        </div>
      </GridToolbarContainer>
    );
  }
  
  const expansionState = useRef({}); // 이 객체는 각 행의 확장 상태를 저장합니다.

  useEffect(() => {
    const handleExpansionChange = (node) => {
      expansionState.current[node.id] = node.childrenExpanded || false;
    };

    const unsubscribe = apiRef.current.subscribeEvent('rowExpansionChange', handleExpansionChange);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 해제
    return () => {
      unsubscribe();
    };
  }, []);

  const isGroupExpanded = useCallback(
    (node) => expansionState.current[node.id] || false,
    []
  );

  const getRowClassName = (params) => {
    if (params.row && params.row.hierarchy.length === 1) {
      return 'tree-table-group';
    }
    return '';
  };


  return (
    <>
      <div className="sknavbar">
        <img src='/images/sklogo.svg' alt="Logo" className="sklogo" />
        <div className="button-group">


          <Button
            onClick={handleLogoutClick}
            style={{ color: 'snow', fontSize: '0.7rem', fontWeight: 600, backgroundColor: '#FF6F61' }}
          >
            Log out
          </Button>
        </div>
      </div>


      <div className="tableContainer">
        <MyDocumentViewerModal selectedRow={selectedRow} open={open} onClose={() => setOpen(false)} docuID={docuID} documenTitle={documenTitle} documentUrl={documentUrl} username={username} />
        {rows.length > 0 &&
          <Box
            sx={{
              height: "90vh",
              // marginTop: "40px",
              width: '100%',
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold', // 헤더 셀의 글자를 진하게 설정
              },
              '& .MuiDataGrid-columnHeaders': {
                lineHeight: 'normal !important',
              },
              '& .MuiDataGrid-cell': {
                fontSize: 12,
              },
              '& .notmatched': {
                backgroundColor: '#f2f3f3',
              },
              '& .notmatched.MuiDataGrid-cell--editing': {
                backgroundColor: '#f2f3f3',
                color: '#f2f3f3'
              },
              '& .notmatched input': {
                // backgroundColor: '#F0EDE5', 
                fontSize: 0
              },
              '& .notmatched.MuiDataGrid-cell': {
                backgroundColor: '#f2f3f3',
              },
              '& .actions': {
                color: 'text.secondary',
              },
              '& .textPrimary': {
                color: 'text.primary',
              },
            }}
          >
            <StyledDataGrid
              // checkboxSelection
              apiRef={apiRef}
              isGroupExpandedByDefault={isGroupExpanded}
              rows={initialRowsRef.current}
              columns={columns}
              treeData
              getTreeDataPath={getTreeDataPath}
              cellSelection
              pagination
              autoPageSize
              checkboxSelection
              disableRowSelectionOnClick
              isRowSelectable={(params) => params.row.hierarchy.length > 1}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
              density="compact"
              // processRowUpdate={processRowUpdate}
              slots={{ toolbar: CustomToolbar }}
              getRowClassName={getRowClassName}

            // slotProps={{
            //   toolbar: {
            //     showQuickFilter: true,
            //   },
            // }}
            />
          </Box>
        }
      </div>

      <AlertDialog open={openDialog} onOpenChange={setOpenDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Submit Changes</AlertDialogTitle>
            <AlertDialogDescription>
              Do you want to submit changes for the {changedRowsCount} reviews you have selected?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setOpenDialog(false)}>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleSubmitChanges}>Submit</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
export default ClientTable;
