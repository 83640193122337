

import * as React from "react"
import { useGetPTWData } from "@/lib/getData";
import PieChartWithString from "../common/PieChartwithString";
import {
    ChartConfig,
} from "@/components/ui/chart";


interface ChartData {
    id: string;
    value: number;
    fill:string;
}


export function PTW() {
    const { projectPTWData } = useGetPTWData();

    function createChartData(projectPTWData: any, criteria: string): ChartData[] {
        if (!projectPTWData) {
            return [];
        }
    
        const groupedData = projectPTWData.reduce((acc: { [key: string]: number }, curr: any) => {
            const key = String(curr[criteria]);
            if (!acc[key]) {
                acc[key] = 0;
            }
            acc[key] += Number(curr.workercount);
            return acc;
        }, {} as { [key: string]: number });
    
        return groupedData ? Object.entries(groupedData).map(([key, totalCount], index): ChartData => ({
            id: key as string,
            value: totalCount as number,
            fill: colors[index % colors.length] // 인덱스를 직접 사용
        })) : [];
    }

    const colors = ["#03045E", "#023E8A", "#0077B6", "#0096C7", "#00B4D8", "#48CAE4", "#90E0EF"];


    const getCount = (
        data: any,
        params?: any
    ): number => {
        if (!data) return 0;

        return data.filter((item: any) => {
            // asWorkStatus 속성이 있는지 확인 (타입 가드)
            if (!('asWorkStatus' in item)) return false;

            // 모든 조건 체크
            const workTypeMatch = !params?.workType || item.WorkType === params.workType;
            const statusMatch = !params?.workStatus || item.asWorkStatus === params.workStatus;

            return workTypeMatch && statusMatch;
        }).length;
    };

    const safetyTableData = [
        {
            type: '특별',
            ready: getCount(projectPTWData, {
                workType: '특별 유해위험',
                workStatus: '점검대기'
            }),
            complete: getCount(projectPTWData, {
                workType: '특별 유해위험',
                workStatus: '작업 중'
            }),
            stop: getCount(projectPTWData, {
                workType: '특별 유해위험',
                workStatus: '작업 중지'
            })
        },
        {
            type: '중점', ready: getCount(projectPTWData, {
                workType: '중점 유해위험',
                workStatus: '점검대기'
            }),
            complete: getCount(projectPTWData, {
                workType: '중점 유해위험',
                workStatus: '작업 중'
            }),
            stop: getCount(projectPTWData, {
                workType: '중점 유해위험',
                workStatus: '작업 중지'
            })
        },
        {
            type: '일상', ready: getCount(projectPTWData, {
                workType: '일상 유해위험',
                workStatus: '점검대기'
            }),
            complete: getCount(projectPTWData, {
                workType: '일상 유해위험',
                workStatus: '작업 중'
            }),
            stop: getCount(projectPTWData, {
                workType: '일상 유해위험',
                workStatus: '작업 중지'
            })
        }
    ];

    const getRowInfo = (row: { type: string; ready: number; complete: number; stop: number }) => {
        const sum = row.ready + row.complete + row.stop;
        let colorClass = '';
        switch (row.type) {
            case '특별': colorClass = 'text-red-500'; break;
            case '중점': colorClass = 'text-emerald-600'; break;
            case '일상': colorClass = 'text-gray-500'; break;
            default: colorClass = 'text-gray-500';
        }
        return { sum, colorClass };
    };

    const chartData = projectPTWData && createChartData(projectPTWData, "WorkType");
    const tableData = projectPTWData && createChartData(projectPTWData, "WorkType2");



    const chartConfig = chartData && chartData.reduce((config, { id }, index) => {
        // `index`에 따라 색상을 지정하거나 임의의 색상을 선택
        // const colors = ["#2563eb", "#60a5fa", "#34d399", "#f97316", "#a855f7"];
      
        config[id] = {
            label: id,
            color: colors[index % colors.length], // 색상 반복 사용
        };
        return config;
    }, {} as ChartConfig);

    const totalValue = React.useMemo(() => {
        return chartData && chartData.reduce((acc, curr) => acc + curr.value, 0);
    }, [chartData]);

    return (
        <>
            <div className="w-1/2 pr-4">
                <h3 className="text-sm font-medium mb-6 text-center">작업허가</h3>
                <div className="w-full">
                    <div className="grid grid-cols-4 gap-1 mb-0">
                        <div className="text-center rounded-md py-1 text-xs"></div>
                        <div className="text-center bg-gray-400 rounded-md py-1 text-xs font-bold text-white">점검대기</div>
                        <div className="text-center bg-emerald-600 rounded-md py-1 text-xs font-bold text-white">점검완료</div>
                        <div className="text-center bg-red-400 rounded-md py-1 text-xs font-bold text-white">작업중지</div>
                    </div>
                    {safetyTableData.map((row, index) => {
                        const { sum, colorClass } = getRowInfo(row);
                        return (
                            <div key={index} className="grid grid-cols-4 mb-0">
                                <div className="flex items-center  gap-2 text-xs pr-1">
                                    <span className={`font-bold ${colorClass}`}>{row.type}</span>
                                    <span className="text-gray-400">({sum})</span>
                                </div>
                                <div className="bg-gray-100 p-2">
                                    <div className="text-center rounded-full w-6 h-6 flex items-center justify-center bg-white mx-auto text-gray-500 border border-gray-500 text-xs">
                                        {row.ready}
                                    </div>
                                </div>
                                <div className="bg-emerald-100 p-2">
                                    <div className="text-center rounded-full w-6 h-6 flex items-center justify-center bg-white mx-auto text-emerald-600 border border-emerald-600 text-xs">
                                        {row.complete}
                                    </div>
                                </div>
                                <div className="bg-red-100 p-2">
                                    <div className="text-center rounded-full w-6 h-6 flex items-center justify-center bg-white mx-auto text-red-500 border border-red-500 text-xs">
                                        {row.stop}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="w-1/2 pl-4">
                {chartData && chartData?.length > 0 ?
                    <PieChartWithString
                        chartData={chartData}
                        tableData={tableData || []}
                        totalValue={totalValue || 0}
                        chartConfig={chartConfig || {}}
                        stringLabel="명"
                        tableLabel="유형별 인원"
                        tableHeader="유형"
                    />
                    :
                    <span className="text-xs text-gray-500">No data available</span>

                }
            </div>
        </>
    );
}