import { EQA } from "@/components/ems/eba";
import { EBPQA } from "@/components/ems/ebpqa";
import { EQC } from "@/components/ems/eqc";
import { KER } from "@/components/ems/ker";
import { LessonLearned } from "@/components/ems/ll";
import { EProgressCard } from "@/components/ems/progressContent";
import { ProjectInfo } from "@/components/ems/projectInfo";
import { QTimeLine } from "@/components/ems/quailityCP";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";


export default function EMSQuality() {


  return (
    // <div className="flex flex-1 flex-col gap-4 p-4 w-full">
    <div className="flex flex-col flex-1 overflow-auto p-4 w-full"> {/* 수정된 클래스명 */}
      {/* 첫 번째 그리드: Project Info, Engineering Progress, Lesson Learned, EGC */}

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <Card className="aspect-video">
          <CardHeader className="text-left">
            <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
              <span>Project Info.</span>
            </CardTitle>
          </CardHeader>
          <CardContent className="pb-0">
            <ProjectInfo/>
          </CardContent>
        </Card>
        <Card className="aspect-video">
          <CardHeader className="text-left">
            <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
              <span>Engineering Progress</span>
            </CardTitle>
          </CardHeader>
          <CardContent> 
            <EProgressCard/>
          </CardContent>
        </Card>
        <Card className="aspect-video">
          <CardHeader className="text-left">
            <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
              <span>Lesson Learned</span>
            </CardTitle>
          </CardHeader>
          <CardContent className="pl-4 pr-4 pb-0">
            <LessonLearned/>
          </CardContent>
        </Card>
        <Card className="aspect-video">
          <CardHeader className="text-left">
            <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
              <span>EQC</span>
              <span className="ml-auto flex items-center text-xs space-x-4 sm:flex font-normal">
                <div className="flex items-center space-x-1">
                  <span className="w-2 h-2 bg-[hsl(var(--chart-1))] inline-block"></span>
                  <span>보류</span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="w-2 h-2 bg-[hsl(var(--chart-2))] inline-block"></span>
                  <span>승인</span>
                </div>
              </span>
            </CardTitle>
          </CardHeader>
          <CardContent className="flex items-center justify-center pl-4 pr-4 pb-0">
            <EQC/>

          </CardContent>
        </Card>
      </div>

      <div className="flex flex-auto pt-4 pb-4 "> {/* 필요한 만큼만 공간을 차지하도록 변경 */}
        <Card className="w-full h-full">
          <CardHeader className="text-left">
            <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
              <span>Quality Critial Proocess</span>
            </CardTitle>
          </CardHeader>
            <QTimeLine />
        </Card>

      </div>

      <div className="grid auto-rows-min gap-4 md:grid-cols-3">
        <Card className="aspect-video">
          <CardHeader className="text-left">
            <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
              <span>KER/CER</span>
              <span className="ml-auto flex items-center text-xs space-x-4 sm:flex font-normal">
                <div className="flex items-center space-x-1">
                  <span className="w-2 h-2 bg-[hsl(var(--chart-1))] inline-block"></span>
                  <span>우수(S)</span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="w-2 h-2 bg-[hsl(var(--chart-2))] inline-block"></span>
                  <span>시정조치(CA)</span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="w-2 h-2 bg-[hsl(var(--chart-3))]  inline-block"></span>
                  <span>시정(C)</span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="w-2 h-2 bg-[hsl(var(--chart-4))]  inline-block"></span>
                  <span>관찰(C)</span>
                </div>
              </span>
            </CardTitle>
          </CardHeader>
          <CardContent className="flex items-center justify-center p-4">
            <KER />
          </CardContent>
        </Card>
        <Card className="aspect-video">
          <CardHeader className="text-left">
            <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
              <span>EQA</span>
              <span className="ml-auto flex items-center text-xs space-x-4 sm:flex font-normal">
                <div className="flex items-center space-x-1">
                  <span className="w-2 h-2 bg-[hsl(var(--chart-1))] inline-block"></span>
                  <span>준수(S)</span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="w-2 h-2 bg-[hsl(var(--chart-2))] inline-block"></span>
                  <span>시정조치(CA)</span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="w-2 h-2 bg-[hsl(var(--chart-3))]  inline-block"></span>
                  <span>시정(C)</span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="w-2 h-2 bg-[hsl(var(--chart-4))]  inline-block"></span>
                  <span>관찰(C)</span>
                </div>
              </span>
            </CardTitle>
          </CardHeader>
          <CardContent className="flex items-center justify-center p-4">
            <EQA />
          </CardContent>
        </Card>
        <Card className="aspect-video">
          <CardHeader className="text-left">
            <CardTitle className="flex items-center justify-between text-base leading-none tracking-tight">
              <span>EBPQA</span>
              <span className="ml-auto flex items-center text-xs space-x-4 sm:flex font-normal">
                <div className="flex items-center space-x-1">
                  <span className="w-2 h-2 bg-[hsl(var(--chart-1))] inline-block"></span>
                  <span>준수(S)</span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="w-2 h-2 bg-[hsl(var(--chart-2))] inline-block"></span>
                  <span>시정조치(CA)</span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="w-2 h-2 bg-[hsl(var(--chart-3))]  inline-block"></span>
                  <span>시정(C)</span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="w-2 h-2 bg-[hsl(var(--chart-4))]  inline-block"></span>
                  <span>관찰(C)</span>
                </div>
              </span>
            </CardTitle>
          </CardHeader>
          <CardContent className="flex items-center justify-center p-4">
            <EBPQA />

          </CardContent>
        </Card>
      </div>
    </div>
  );
}